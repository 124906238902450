import request from "@/utils/request";

//获取某个公司下的车辆序列号,首页使用
function getCarNoByCompanyId(data) {
  return request({
    url: "v2/Car/GetCarNoByCompanyId",
    method: "post",
    data
  });
}

//获取车辆列表,首页使用
function getCarListForHomePage(data) {
  return request({
    url: "v2/Car/GetCarListForHomePage",
    method: "post",
    data
  });
}

//获取车辆关联的控制器基本信息,RDM基本信息
function getCarControllers(data) {
  return request({
    url: "v2/Car/GetCarControllers",
    method: "post",
    data
  });
}

//创建车辆，查看MachineSN是否存在
function checkIfMachineSNExisted(data) {
  return request({
    url: "v2/Car/CheckIfMachineSNExisted",
    method: "post",
    data
  });
}
//创建车辆，查看MachineSN是否存在
function CheckIfRDMBeenUsed(data) {
  return request({
    url: "v2/Car/CheckIfRDMBeenUsed",
    method: "post",
    data
  });
}

//创建车辆，我的车辆使用
//
////页面点击保存车辆调用
              function createMachine(data) {
                return request({
                  url: "v2/Car/CreateMachine",
                  method: "post",
                  data
                });
              }
              
              //获取车辆列表详情，我的车辆使用
              function getMyCarDetailList_v2(data) {
                return request({
                  url: "v2/Car/GetMyCarDetailList_v2",
                  method: "post",
                  data
                });
              }

              //获取车辆详情，我的车辆使用
              function getOneCarDetail_v2(data) {
                return request({
                  url: "v2/Car/GetOneCarDetail_v2",
                  method: "post",
                  data
                });
              }
              
              //编辑车辆，我的车辆使用



//编辑车辆，我的车辆使用
//
////页面点击保存车辆调用
function editMachine(data) {
  return request({
    url: "v2/Car/EditMachine",
    method: "post",
    data
  });
}

//删除车辆，我的车辆使用
//
////页面删除车辆时调用
////车辆定义的MachineId
function deleteMachine(data) {
  return request({
    url: "v2/Car/DeleteMachine",
    method: "post",
    data
  });
}

//克隆车辆，我的车辆使用
//
////直接页面克隆车辆
function cloneMachine(data) {
  return request({
    url: "v2/Car/CloneMachine",
    method: "post",
    data
  });
}

//工厂生产创建车辆
function CreateMachine_Factory(data){
  return request({
    url: "v2/Car/CreateMachine_Factory",
    method: "post",
    data
  });
}
              
            

            export default {
                getCarNoByCompanyId,
getCarListForHomePage,
getCarControllers,
checkIfMachineSNExisted,
createMachine,
getMyCarDetailList_v2,
editMachine,
deleteMachine,
cloneMachine,
getOneCarDetail_v2,
CheckIfRDMBeenUsed,
CreateMachine_Factory
            };
