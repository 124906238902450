<template>
    <div class="popBox-box">
        <div class="popBox-content-box">
            <div class="popBox-content-img">
                <img src="../../../assets/image/car/jinggao.png" v-if="details.type == 1">
                <el-progress v-else :text-inside="true" :stroke-width="26" :show-text="false" :percentage="70" :color="customColor"></el-progress>
            </div>
            <div class="popBox-content-text">
                <div :class="['popBox-content-title', details.type == 2? 'popBox-content-loading' : '']">
                    {{details.title}}
                </div>
                <div class="popBox-content-desc">
                    {{details.desc}}
                </div>
                <div class="popBox-content-desc">
                    {{details.detail}}
                </div>
            </div> 
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    props: {
        details: {
            type: Object,
            default: () => {
                return {
                    title: '',
                    desc: '',
                    detail:'',
                    type : 0
                };
            }
        },
        customColor: {
            type: String,
            default: '#00B395',
        }
    },
    data() {
        return {

        };
    }
};
</script>
<style lang="less" scoped>
.popBox-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fdfdfd;
    opacity:0.8;
    .popBox-content-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        text-align: center;
        height: 300px;
        border-radius: 2px;
        box-sizing: border-box;
        padding-top: 30px;
        color: #000;

        .popBox-content-img img {
            width: 123px;
        }

        /deep/ .el-progress-bar__outer {
            background: #EFEFEF;
        }

        .popBox-content-text {
            .popBox-content-title {
                margin: 20px auto;
                font-size: 24px;
                color: #FF9A30;
            }

            .popBox-content-desc {
                font-size: 16px;
            }

            .popBox-content-loading {
                color: #00B395;
            }
        }
    }
}
</style>