import request from "@/utils/request";

              //获取当前用户身份信息
              function getUserInfo(data) {
                return request({
                  url: "v2/User/GetUserInfo",
                  method: "post",
                  data
                });
              }
              
              //修改当前用户的密码
              function changePassword(data) {
                return request({
                  url: "v2/User/ChangePassword",
                  method: "post",
                  data
                });
              }

               //修改当前用户的二次密码
               function changeSecondPassword(data) {
                return request({
                  url: "v2/User/ChangeSecondPassword",
                  method: "post",
                  data
                });
              }
              
              //获取时区列表
              function getTimezone(data) {
                return request({
                  url: "v2/User/GetTimezone",
                  method: "post",
                  data
                });
              }
              
              //修改用户时区
              function changeUserTimezone(data) {
                return request({
                  url: "v2/User/ChangeUserTimezone",
                  method: "post",
                  data
                });
              }
              
              //修改用户语言
              function changeUserLanguage(data) {
                return request({
                  url: "v2/User/ChangeUserLanguage",
                  method: "post",
                  data
                });
              }
              
            

            export default {
                getUserInfo,
changePassword,
changeSecondPassword,
getTimezone,
changeUserTimezone,
changeUserLanguage
            };