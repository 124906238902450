const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;
const ONE_DAY = 24 * 60 * 60 * 1000;
import { ConstDefinition } from "@/utils/constDefinition";

//多语言过滤器, 根据指定值获取翻译文本
export function i18n(d, vueInstance) {
    if (d && vueInstance && vueInstance.$t) {
        return vueInstance.$t(d);
    }
    return d;
}
export function notificationTypeIcon(d) {
    let result = d;
    if (d === ConstDefinition.NotificationType.Notice) {
        result = 'notificationType-info fa fa-info-circle';
    }
    if (d === ConstDefinition.NotificationType.Warning) {
        result = 'notificationType-warn fa fa-warning';
    }
    if (d === ConstDefinition.NotificationType.Error) {
        result = 'notificationType-error fa fa-times-circle';
    }
    return result;
}
export function notificationType(d) {
    let result = d;
    if (d === ConstDefinition.NotificationType.Notice) {
        result = 'notification.type_notice';
    }
    if (d === ConstDefinition.NotificationType.Warning) {
        result = 'notification.type_warning';
    }
    if (d === ConstDefinition.NotificationType.Error) {
        result = 'notification.type_error';
    }
    return result;
}
var moment = require('moment');
export function notificationTimeFormat(d,offset,t) {
    if (d) {
        //var date = new Date(d);
        offset = parseInt(offset.substring(4,6));        
        var dateUTC=moment(d).utc().add(offset, 'h').format('YYYY-MM-DD HH:mm:ss' || 'YYYY-MM-DDTHH:mm:ssZ')
        var date = new Date(dateUTC);
        //console.log("date==="+date);
        //var now = new Date();
        var nowUTC=moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss' || 'YYYY-MM-DDTHH:mm:ssZ')
        var now = new Date(nowUTC);
        //console.log("now==="+now);
        
        var timeOffset = now - date;
        // console.log(timeOffset);
        if (timeOffset < ONE_MINUTE) {
            //return 'Just now';
            return t("notification.JustNow");
        }
        if (timeOffset > ONE_MINUTE && timeOffset < ONE_HOUR) {
            return Math.floor(timeOffset / (ONE_MINUTE)).toFixed(0) + t("notification.MinutesAgo");
        }
        if (timeOffset > ONE_HOUR && timeOffset < ONE_DAY) {
            return Math.floor(timeOffset / (ONE_HOUR)).toFixed(0) +  t("notification.HoursAgo");
        }
        if (timeOffset > ONE_DAY) {
            return Math.floor(timeOffset / (ONE_DAY)).toFixed(0) +  t("notification.DaysAgo");
        }
    }
    return d;
}


export function voltageState(d) {

    if (d === ConstDefinition.VoltageState.Danger) {
        return 'Danger';
    }
    if (d === ConstDefinition.VoltageState.Normal) {
        return 'Normal';
    }
    if (d === ConstDefinition.VoltageState.Warning) {
        return 'Warning';
    }
    return d;
}

export function carStatus(d) {

    if (d === ConstDefinition.CarStatus.Offline) {
        return 'Offline';
    }
    if (d === ConstDefinition.CarStatus.Online) {
        return 'Online';
    }
    if (d === ConstDefinition.CarStatus.Error) {
        return 'Error';
    }
    return d;
}