import request from "@/utils/request";



// 车辆所属组
function GetOneMachineGroupLevel(data) {
    return request({
      url: "/v2/MachineGroup/GetOneMachineGroupLevel",
      method: "post",
      data
    });
}

export default {
    GetOneMachineGroupLevel
};