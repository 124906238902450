import request from "@/utils/request";

              //消息列表
              function queryNotificationList(data) {
                return request({
                  url: "v2/Notification/QueryNotificationList",
                  method: "post",
                  data
                });
              }
              
              //未读消息列表
              function queryUnReadNotificationList(data) {
                return request({
                  url: "v2/Notification/QueryUnReadNotificationList",
                  method: "post",
                  data
                });
              }
              
              //标记所有未读消息=>已读
              function clearAllUnReadNotifications(data) {
                return request({
                  url: "v2/Notification/ClearAllUnReadNotifications",
                  method: "post",
                  data
                });
              }
              
              //标记消息已读
              function markAsRead(data) {
                return request({
                  url: "v2/Notification/MarkAsRead",
                  method: "post",
                  data
                });
              }
              
              //标记消息为删除
              function markAsDeleted(data) {
                return request({
                  url: "v2/Notification/MarkAsDeleted",
                  method: "post",
                  data
                });
              }
              
              //创建消息
              function create(data) {
                return request({
                  url: "v2/Notification/Create",
                  method: "post",
                  data
                });
              }
              
            

            export default {
                queryNotificationList,
queryUnReadNotificationList,
clearAllUnReadNotifications,
markAsRead,
markAsDeleted,
create
            };