<template>
    <div class="icon-box">
        <div class="icon-title-box" v-if="iconData.title">{{iconData.title}}</div>
        <div class="icon-content-box">
            <el-row :gutter="20">
                <el-col :span="8"><div class="grid-content bg-purple"></div></el-col>
                <el-col :span="8"><div class="grid-content bg-purple"></div></el-col>
                <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
                <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
            </el-row>
            <div class="icon-list-box" v-for="(item,key) in iconData.data" :key="key">
                <div class="icon-list-content">
                    <template v-if="item.unit === 'BOOL'">
                        <div class="icon-list-content-Bool">
                            <el-tag :type="item.value =='OFF' ? 'info' : 'success'">{{item.value}}</el-tag>
                        </div>
                    </template>
                    
                    <div class="icon-list-content-value" v-else>
                         <img :src="checkExistData(ParamIcon,item.unit, 'value').src" class="icon-list-content-img"/>
                        {{item.value}} <span>{{item.unit}}</span>
                    </div>
                </div>
                <div class="icon-list-content-desc">{{item.desc}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { checkExistData, } from '@/utils';
import { ConstDefinition } from "@/utils/constDefinition";
export default {
    name: '',
    props: {
        iconData: {
            type: Object,
            default: () => {
                return {
                    title: '',
                    data: [],
                };
            }
        }
    },
    data() {
        return {
            ParamIcon:[
                {
                    src:require('@/assets/image/paramicon/wendu.png'),
                    value:'℃',
                },
                {
                    src:require('@/assets/image/paramicon/chizhi.png'),
                    value:'nm',
                },
                {
                    src:require('@/assets/image/paramicon/chart.png'),
                    value:'%',
                },
                {
                    src:require('@/assets/image/paramicon/jiaodu.png'),
                    value:'∠',
                },
                {
                    src:require('@/assets/image/paramicon/sudu.png'),
                    value:'g',
                },
                {
                    src : require('@/assets/image/paramicon/xuanzhuan.png'),
                    value:'rpm',
                },
                {
                    src:require('@/assets/image/paramicon/r.png'),
                    value:'R',
                },
                {
                    src:require('@/assets/image/paramicon/time.png'),
                    value:'ms',
                },
                {
                    src:require('@/assets/image/paramicon/time.png'),
                    value:'min',
                },
                {
                    src:require('@/assets/image/paramicon/time.png'),
                    value:'sec',
                },
                {
                    src:require('@/assets/image/paramicon/A.png'),
                    value:'A',
                },
                {
                    src:require('@/assets/image/paramicon/A.png'),
                    value:'mA',
                },
                {
                    src:require('@/assets/image/paramicon/hz.png'),
                    value:'HZ',
                },
                {
                    src:require('@/assets/image/paramicon/W.png'),
                    value:'KW',
                },
                {
                    src:require('@/assets/image/paramicon/h.png'),
                    value:'uH',
                },
                {
                    src:require('@/assets/image/paramicon/Ω.png'),
                    value:'mΩ',
                },
                {
                    src:require('@/assets/image/paramicon/Ω.png'),
                    value:'Ω',
                },
                {
                    src:require('@/assets/image/paramicon/V.png'),
                    value:'V',
                },
                {
                    src:require('@/assets/image/paramicon/dian.png'),
                    value:'Other',
                }
                
            ]

        };
    },
    created() {

    },
    methods: {
        checkExistData(array = [], value = '', key = '') {
            let data = checkExistData(array, value, key);

            if(data.isExist) {
                return data.data;
            } else {
                return {
                    [key]: value,
                };
            }
        },
    },

};
</script>
<style lang="less" scoped>
.icon-box {
    text-align: left;
    .icon-title-box {
        font-size: 14px;
        color: #333;
        margin: 20px 0;
        font-weight: bold;
    }

    .icon-content-box {

        &::after {
            content: '';
            display: block;
            overflow: hidden;
            width: 0;
            height: 0;
            clear: both;
        }
        .icon-list-box {
            padding: 25px 40px;
            box-sizing: border-box;
            border: 1px solid #dddddd;
            border-radius: 2px;
            height: 100px;
            margin-bottom: 12px;
            margin-right: 12px;
            float: left;
            width: calc(25% - 10px);

            &:nth-child(5n) {
                margin-right: 0;
            }
            .icon-list-content {
                height: 39px;

                .icon-list-content-value {
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    font-size: 30px;
                    font-weight: bold;
                    color: #333;

                    span {
                        font-size: 14px;
                        font-weight: 100;
                    }
                }
                .icon-list-content-value {
                    display: inline-block;
                    height: 36px;
                    line-height: 30px;
                    font-size: 30px;
                    font-weight: bold;
                    color: #333;

                    span {
                        font-size: 14px;
                        font-weight: 100;
                    }
                }
            }
            .icon-list-content-desc {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>