<template>
    <div class="RDM-box"  v-loading="loading">
        <el-row class="RDM-top">
            <el-col :span="16">
                <div class="title">
                    <span> 
                        {{$t('menu.RDMAssign')}}
                    </span>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="search">
                    <el-input
                        :placeholder="$t('addMachine.RDMNO')+'/'+$t('RDMManage.Model')"
                        suffix-icon="el-icon-search"
                        v-model="searchContent"
                        @change="getRDMList"
                    >
                    </el-input>
                </div>
            </el-col>
        </el-row>
        <el-row class="RDM-content">
            <el-col :span="24">
                <div class="RDM-content-top">
                    <el-button type="success" icon="fa fa-exchange" @click="openAssign" v-if="privilege.Assign">{{$t('RDMManage.Assign')}}</el-button>
                </div>
            </el-col>
            <el-col :span="24">
                <div class="RDM-table">
                    <div class="table-box">
                        <el-table :data="tableData" :row-class-name="vehicleTableRowClassName"
                         style="width: 100%;" 
                        @selection-change="handleSelectionChange"
                         :row-style="rowClass" >
                            <el-table-column
                                type="selection"
                                align="center"
                                width="55">
                            </el-table-column>
                            <el-table-column
                                type="index"
                                align="center"
                                :label="$t('common.SEQ')"
                                width="50"
                                sortable
                                :index="indexMethod"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="RDMNo"
                                align="center"
                                :label="$t('addMachine.RDMNO')"
                                width="250">
                            </el-table-column>
                            <el-table-column
                                prop="RDM.ModelNumber"
                                align="center"
                                :label="$t('RDMManage.Model')">
                            </el-table-column>
                            <el-table-column
                                align="center"
                                :label="$t('RDMManage.NetworkStatus')">
                            >
                            <template slot-scope="scope">
                                <i class="fa fa-wifi fa-lg gray" v-if="!scope.row.SIMCardInfo"></i>
                                <i class="fa fa-wifi fa-lg green" v-else-if="scope.row.SIMCardInfo.SIMStatus == 1"></i>
                                <div class="wifi-off" v-else></div>
                            </template>
                            </el-table-column>
                             <el-table-column
                                align="center"
                                :label="$t('common.operation')"
                                v-if="privilege.SIMCardStatusControl"
                                >
                            >
                            <template slot-scope="scope">
                                <div >
                                <i class="fa fa-power-off fa-lg gray" v-if="!scope.row.SIMCardInfo"></i>
                                <div class="rdmon" v-else-if="scope.row.SIMCardInfo.SIMStatus == 0" :title="$t('RDMManage.Activate')" @click="IsActive(scope.row,1,formatTimestampUTC(new Date(scope.row.SIMCardInfo.UTime),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting))"></div>
                                <i class="fa fa-power-off fa-lg red point" v-else :title="$t('RDMManage.Deactivate')" @click="IsActive(scope.row,0,formatTimestampUTC(new Date(scope.row.SIMCardInfo.UTime),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting))"></i>
                                </div>
                            </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="pagination">
                        <div
                            v-if="rdmSearch.total > userInfo.PageSize"
                            class="paginationCount"
                        >
                            {{ (rdmSearch.page - 1) * userInfo.PageSize + 1 }}-{{
                            rdmSearch.page * userInfo.PageSize
                            }}
                            {{ $t("common.Page_of") }} {{ rdmSearch.total }} {{ $t("common.Pages") }}
                        </div>
                        <div class="paginationDiv">
                            <el-pagination
                            background
                            :page-size="userInfo.PageSize"
                            @current-change="handleCurrentChange"
                            :hide-on-single-page="true"
                            layout="prev, pager, next"
                            :total="rdmSearch.total"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import rdmApi from "@/api/autogen/v2/rdm";
import { checkExistData,formatTimeStr,SubTime,now,formatTimestampUTC,deepClone,ifGrantedTheOperationForThisFunction} from '@/utils';
export default {
    data() {
        return {
            SIMCardOperationTimeLimit:3,
            companyList:[],
            searchContent:'',
            rdmSearch: {
                page: 1,
                total: 0,
            },
            tableData: [],
            muitipleData: [],
            selectRow: [],
            selectCompany:{},
            loading:false,
            privilege: {
                View: false,
                Assign: false,
                SIMCardStatusControl: false,
            }
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
        currentCompany(){
            
            return this.$store.state.companyEntity;
        }
    },
    watch: {
        muitipleData(data) {  //存储选中的row
            this.selectRow = [];
            if (data.length > 0) {
                data.forEach((item, index) => {
                    this.selectRow.push(this.tableData.indexOf(item));
                });
            }
        }
    },
    created(){
        let self = this;
        self.$bus.on("company-switch", self.companySwitch);//监听公司变化
        self.getRDMList();
        self.initialFunctionOperationPrivilege();
    },
    methods: {
        formatTimestampUTC,
        SubTime,
        now,
        ifGrantedTheOperationForThisFunction,
        initialFunctionOperationPrivilege()
        {
                let self = this;
                self.privilege.View = ifGrantedTheOperationForThisFunction("View");
                self.privilege.Assign = ifGrantedTheOperationForThisFunction("Assign");
                self.privilege.SIMCardStatusControl = ifGrantedTheOperationForThisFunction("SIMCardStatusControl");
        },
         //监听公司变化
        companySwitch(evtPayload){
            this.selectCompany = evtPayload;
            this.searchContent = '';
            this.getRDMList();
        },

        //分页序号连续
        indexMethod(index) {
            index = (index + 1) + (this.rdmSearch.page - 1) * this.userInfo.PageSize;
            return index;
        },

        /**
         * @desc 分页
         */
        handleCurrentChange(val) {
            this.rdmSearch.page = val;
            this.getRDMList();
        },


        //获取RDM列表
        getRDMList(){
            let param = {
                pageSize: this.userInfo.PageSize,
                pageIndex: this.rdmSearch.page,
                companyId: this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId,
                filters: this.searchContent
            };
            rdmApi.GetRDMList_v2(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.tableData = res.Data.RDMList;
                    this.rdmSearch.total = res.Data.TotalCount;
                }
            });
        },
        /**
         * @desc 自定义斑马纹
         */
        vehicleTableRowClassName({row, rowIndex}) {
            if (rowIndex%2==0) {
                 return '';
            } else {
                return 'even-row';
            }
        },

        handleSelectionChange(val) {
            this.muitipleData = val;
        },

        //选中行变色
        rowClass({ row, rowIndex }) {
            if (this.selectRow.includes(rowIndex)) {
                return { "background-color": "#E6F7FF" };
            }
        },

       //修改RDM Sim 状态
       IsActive(item,type,time){
            if(this.checkSIMCardOperationTime(time))
            {
                this.loading=true;
                let content = '';
                if(type == 1){
                    content = this.$t('RDMManage.confirmActivate');
                }else{
                    content = this.$t('RDMManage.confirmDeactivate');
                }
                let Url = require("@/assets/image/warning1.5x.png");
                this.$confirm("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    cancelButtonText: this.$t("common.cancel"),
                    center: true,
                }).then(() => {
                    let array = [];
                    array.push(item.SIMCardInfo.misisdn);
                    alert(item.SIMCardInfo.misisdn);
                    let prarm = {
                        msisdnList : array,
                        operType :type
                    };
                    rdmApi.ChangeSIMCardStatus(prarm).then((res)=>{
                        if(res&&res.IsSuccess){
                            let flag = 0;
                            if(res.Data.idsList[0].status){
                                flag = 1;
                            }

                            if (flag==1) {
                                Url = require("@/assets/image/success.png");
                                content= this.$t("common.operationSuccess");
                                this.getRDMList();
                            
                            }else{
                                Url = require("@/assets/image/error_76.png");
                                content= this.$t("common.operationFailure");

                            }
                            
                            this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: this.$t("common.confirm"),
                                center: true,
                                showClose:false
                            });
                        }
                        this.loading=false;
                    });
                }).catch(() => {
                    this.loading=false;       
                });
            }else{
                let Url = require("@/assets/image/error_76.png");
                let content=   this.$t("RDMManage.UpdateLimitTime");
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: this.$t("common.confirm"),
                                center: true,
                                showClose:false
                            });              
            }
        },
        checkSIMCardOperationTime (value){
        let nowTime=formatTimestampUTC(now(),'YYYY-MM-DD HH:mm:ss',this.userInfo.TimezoneSetting);
        let diff=SubTime(nowTime,value);
        let min=diff/60000;
        if(min>this.SIMCardOperationTimeLimit)
           return true;        
        else 
            return false;
        },

        //跳转到分配页面
        openAssign(){
            this.$store.commit("setIsAssignFunction",true);
            let array = [];
            
            this.muitipleData.forEach(item => {
                array.push(item.RDMNo); 
            });
            const Url = require("@/assets/image/warning1.5x.png");
            if(array.length <= 0) {
                
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("common.selectRDM")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }else{
                this.$router.push({name: 'RDMAssignByCompany',params:{rdms:array}});
            }
           
        }
        
    },
    beforeDestroy() {
        this.$bus.off("company-switch", this.companySwitch);
    },
    
};
</script>

<style lang="less" scoped>
    .RDM-box{
        margin-top : 10px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
        .RDM-top{
            border-bottom: 1px solid #e5e5e5;
            background: #fafafa;
            border-radius: 2px;
            padding: 0 18px;
            height: 79px;
            .title{
                font-size: 16px;
                font-weight: 700;
                line-height: 79px;
                float: left;
                margin-left:26px ;
            }
            .search{
                height: 48px;
                line-height: 79px;
            }
        }
        .RDM-content{
            padding: 20px;
            .RDM-content-top{
                float: left;
            }
            .RDM-table{
                margin-top: 21px;
                .table-box {
                    border: 1px solid #F2F2F2;

                    /deep/ .el-table__header tr th {
                        background: #FAFAFA;
                    }
                    .rdmon{
                        cursor: pointer;
                        width: 19px;
                        height: 19px;
                        margin:0 auto;
                        background:url('../../assets/image/rdmon.png');
                    }
                    .point{
                        cursor: pointer;
                    }
                    .wifi-off{
                        width: 21px;
                        height: 18px;
                        margin:0 auto;
                        background:url('../../assets/image/wifi_off.png');
                    }
                    .gray{
                        font-size: 21px;
                        color: #999999;
                    }

                    .green{
                        font-size: 21px;
                        color: #00b194;
                    }
                    .red{
                        font-size: 21px;
                        color: #fe686a;
                    }
                }
                .pagination {
                    margin-top: 20px;
                    text-align: right;
                    padding-bottom: 40px;
                    .paginationCount {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;
                    }
                    .paginationDiv {
                        display: inline-block;
                    }
                }
            }
        }
    }
</style>