//GeoFenceControl

function GeoFenceControl() {
  var _map = null;
  var _container = null;
}
GeoFenceControl.prototype.onAdd = function (map) {
  this._map = map;
  this._container = document.createElement('div');
  this._container.className = 'mapboxgl-ctrl';

  return this._container;

};
GeoFenceControl.prototype.onRemove = function (map) {
  this._container.parentNode.removeChild(this._container);
  this._map = undefined;
};

GeoFenceControl.prototype.setView = function (config, clickCallback) {

  while (this._container.hasChildNodes()) {
    this._container.removeChild(this._container.firstChild);
  }

  var label = document.createElement('label');
  label.innerText = config.title;
  var checkboxInput = document.createElement('input');
  checkboxInput.type = "checkbox";
  this._container.appendChild(checkboxInput);
  this._container.appendChild(label);

  checkboxInput.addEventListener('click', function (evt) {
    if (clickCallback) {
      clickCallback(evt);
    }
  });
};
module.exports = GeoFenceControl;