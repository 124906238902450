<template>
    <div class="machine-detail-box">
        <div class="row1">
            <div class="row1-header-box">
                <div class="row1-header-crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/RDMManage' }">{{ $t("menu.RDMManage") }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{detailsTitle.RDMNo}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="row1-body-box">
                <div :class="classObject">
                    <span v-for="(item,key) in tabsData" :key="key" @click="tabClick(key)" :class="{isSelected: key == activeKey}">
                        {{item.label}}
                    </span>
                </div>
                <div class="row1-content-box">
                    <component :is="tabsData[activeKey].component" :companyId="paramsData.companyId" :RDMNo="paramsData.RDMNo" @getHeaderData="getHeaderData"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Information from "./RDMInformation.vue";
import Parameter from "./RDMParameter.vue";
import RDMUpgrade from "./RDMUpgrade.vue";
//import { ifGrantedTheOperationForRDMManageFunction} from '@/utils';
export default {
    components: {
        Information,
        Parameter,
        RDMUpgrade,
    },
    data() {
        return {
            paramsData: {
                companyId: '',
                RDMNo: '',
            },
            tabsData: [
                {
                    label: this.$t("RDMManage.RDMDetail"),
                    component: Information,
                }, 
            ],
            activeKey: 0,
            detailsTitle: {
                RDMNo: '',
            },
            /*privilege: {
                ParameterQueryAndModify:false,
                SoftwareUpgrade:false,
            }*/
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
        classObject: function () {
            let classTmp= [
                    'row1-tab-box-'+this.tabsData.length
                ];
            console.log("classObject",classTmp);
            return classTmp;
        },
    },
    created() {
        this.initData();
        this.initialFunctionOperationPrivilege();
    },
    methods: {
        //ifGrantedTheOperationForRDMManageFunction,
        initialFunctionOperationPrivilege()
        {
            //this.privilege.ParameterQueryAndModify = ifGrantedTheOperationForRDMManageFunction("ParameterQueryAndModify");
            //this.privilege.SoftwareUpgrade = ifGrantedTheOperationForRDMManageFunction("SoftwareUpgrade");
            //if(this.privilege.ParameterQueryAndModify)
                this.tabsData.push({
                    label: this.$t("RDMManage.RDMParameterManage"),
                    component: Parameter,
                });
            //if(this.privilege.SoftwareUpgrade)
                this.tabsData.push({
                    label: this.$t("RDMManage.RDMSoftwareManage"),
                    component: RDMUpgrade,
                });
        },
        getHeaderData(data) {
            this.detailsTitle = data;
        },
        /**
         * @desc 初始化页面获取id
         */
        initData() {
            let { params, } = this.$route;
            if(params.companyId && params.RDMNo) {
                this.paramsData = params;
            } else {
                this.$router.go(-1);
                return; 
            }
        },
        tabClick(key) {
            if(this.activeKey != key) {
                this.activeKey = key;
            }
        },
        returnRDMList(){
            this.$router.push({
                path: '/RDMManage/'+this.paramsData.companyId,
            });
        }

    },
    mounted:{},
};
</script>

<style lang="less" scoped>
.machine-detail-box {
    min-width: 867px;
    .row1 {
        box-sizing: border-box;
        border-radius: 2px;
        .row1-header-box {
            height: 70px;
            line-height: 70px;
            color: #000;
            background: #fafafa;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            padding: 0 42px;

            .row1-header-crumbs {
                font-size: 16px;
                font-weight: bold;
                float: left;

                
                .el-breadcrumb {
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }

        .row1-body-box {
            padding: 32px 40px;
            background: #FFFFFF;

            .row1-tab-box-3 {
                margin-bottom: 32px;
                span {
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(33.3% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }
            .row1-tab-box-2 {
                margin-bottom: 32px;
                span {
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(50% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }
            .row1-tab-box-1 {
                margin-bottom: 32px;
                span {
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(100% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }

        }
    }
}
</style>