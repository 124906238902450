<!--
 * @Description: company list
 * @Author: dtc
 * @Date: 21/12/01
-->
<template>
    <div class="company-box" v-loading="loading">
        <div class="overall-box" :span="12">
            <div class="num-box" :span="6">
                <p>
                    <em>{{ $t("myvehicle.companylist") }}:</em>{{ $t("myvehicle.company") }}<em>({{totalCompanyCount}})</em>{{ $t("myvehicle.machine") }}<em
                        >({{totalMachineCount}})</em
                    >
                </p>
            </div>
            <div class="search-box" :span="6">
                <el-input
                    :placeholder="$t('myvehicle.companyName')"
                    suffix-icon="el-icon-search"
                    @change="searchData"
                    v-model="search"
                >
                </el-input>
            </div>
        </div>
        <div class="list-box" v-if="list.length > 0">
            <div class="list" v-for="(item, index) in list" :key="index" @click="skipDetails(item.CompanyId)">
                <img :src="item.ImageBase64 ? item.ImageBase64 : require('../../assets/image/company.png')" :alt="item.ImageName" />
                <div class="desc">
                    <div class="name">
                        <p>
                            {{ $t("myvehicle.companyName") }}:<em>{{ item.CompanyName }}</em>
                        </p>
                    </div>
                    <div class="info">
                        <p>
                             {{ $t("myvehicle.machineinformation") }}: 
                            <em>
                                <span class="color1">{{ item.MachineOnlineCount }}</span>&nbsp;/
                                <span class="color2">{{item.MachineOfflineCount}}</span>&nbsp;/
                                <span>{{item.MachineCount}}</span>
                            </em>
                        </p>
                        <p>
                            RDM: 
                            <em>
                                <span class="color3">{{ item.MachineRDMOnlineCount }}</span>&nbsp;/
                                <span class="color4">{{ item.MachineRDMOfflineCount}}</span>&nbsp;/
                                <span>{{item.MachineCount}}</span>
                            </em>
                        </p>
                        <p>
                            KSI: 
                            <em>
                                <span class="color3">{{ item.MachineKSIOnlineCount }}</span>&nbsp;/
                                <span class="color4">{{ item.MachineKSIOfflineCount }}</span>&nbsp;/
                                <span>{{item.MachineCount}}</span>
                            </em>
                        </p>
                    </div>
                </div>
            </div>
            <p class="more" v-if="totalCompanyCount > (pageParams.page * userInfo.PageSize)" @click="moreData">Loading more</p>
        </div>
        <div v-else class="none-data-box">
            {{ $t("common.empty") }}
        </div>
    </div>
</template>

<script>
import userApi from '@/api/autogen/v2/user';
import carApi from "@/api/autogen/v2/carList";
import { mapActions, } from 'vuex';
export default {
    data() {
        return {
            list: [
            ],
            pageParams: {
                page: 1,
                total: 0,
                size: 1,
            },
            search: '',
            totalCompanyCount: 0,
            totalMachineCount: 0,
            loading: false,
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
    },
    created() {
        this.isCurtis();
    },
    methods: {
        // ...mapActions(["setUserEntity"]),
        /**
         * @desc 获取用户信息
         */
        isCurtis() {
            if(this.userInfo.CompanyId != 0) {
                this.skipDetails(this.userInfo.CompanyId);
            }
                
        },
        /**
         * @desc 跳转详情
         */
        skipDetails(id) {
            this.$router.push({
                path: '/VehicleList/'+id,
            });
        },
        /**
         * @desc 初始化接口参数
         */
        getQueryData() {
            let params = {
                pageSize: this.userInfo.PageSize,
                pageIndex: this.pageParams.page,
                orderBy: [{
                    name: '',
                    orderBy: 0, 
                }],
                companyName: this.search
            };

            return params;
        },
        /**
         * @desc 获取列表数据
         */
        getMachineList() {
            this.loading = true;
            let params = this.getQueryData();

            carApi.getMachineList(params).then(res => {
                this.loading = false;
                this.list = this.list.concat(res.Data.CompanyMachineSummaryList); 

                this.totalCompanyCount = res.Data.TotalCompanyCount || 0;
                this.totalMachineCount = res.Data.TotalMachineCount || 0;
            });
        },
        /**
         * @desc 查询数据
         */
        searchData() {
            this.list = [];
            this.pageParams.page = 1;

            this.getMachineList();
        },
        /**
         * @desc 查看更多数据
         */
        moreData() {
            this.pageParams.page = this.pageParams.page + 1;

            this.getMachineList();
        },
    },
    mounted() {
        this.getMachineList();
    },
};
</script>

<style lang="less" scoped>
.company-box {
    width: 100%;
    height: 100%;
    position: relative;

    p {
        margin: 0;
    }

    em {
        font-style: inherit;
    }

    .overall-box {
        width: 100%;
        height: 80px;
        line-height: 80px;
        // margin-left: 45px;
        .num-box {
            color: #333;
            font-size: 16px;
            line-height: 80px;
            text-align: left;
            float: left;
            margin-left: 45px;
            em {
                font-weight: bold;
                padding-right: 14px;
            }
        }

        .search-box {
            width: 40%;
            height: 38px;
            float: right;
        }
    }

    .list-box {
        .list {
            height: 136px;
            min-width: 756px;
            color: #666;
            background: #fff;
            text-align: left;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 10px;
            transition: all 0.4s;
            cursor: pointer;
            border-radius: 2px;
            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }

            img {
                width: 83px;
                height: 83px;
                padding-left: 32px;
                padding-right: 45px;
                padding-top: 26px;
                display: block;
                float: left;
            }

            em {
                color: #333;
                font-weight: bold;
                font-size: 16px;
                padding-left: 13px;
            }

            .desc {
                display: block;
                /*float: left;*/

                .name {
                    padding-top: 40px;
                    padding-bottom: 30px;
                }

                .info {
                    p {
                        display: block;
                        float: left;
                        padding-right: 8%;

                        .color1 {
                            color: #0079C1;
                        }

                        .color2 {
                            color: #F4506B;
                        }

                        .color3 {
                            color: #00B194;
                        }
                        .color4 {
                            color: #909399;
                        }
                    }
                }
            }
        }

        .more{
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #fff;
            font-size: 16px;
            color: #333;
            transition: all 0.4s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 15px #409EFF;
            }
        }
    }
}
</style>