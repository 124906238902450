import request from "@/utils/request";

function getOSList(data) {
    return request({
        url: "v2/DevicePackage/GetOSVersionList",
        method: "post",
        data
    });
  }

  function checkFileType(suffixl)
  {
        var result="";
        let fileType=['csv','bsl','xls','vcl','eds','CSV','BSL','XLS','VCL','EDS'];
        result= fileType.some(function (item)
        {
            return item==suffixl;
        });
        if(result!="")
        {
            return true;
        }
        return false;
  }

  function  beforeAvatarUpload(file) {
      
    let fileName=file.name;
    let fileArr=fileName.split('.');
    let suffix=fileArr[fileArr.length-1];
    let isFileTypeOk=checkFileType(suffix);
    console.log("isFileTypeOk="+isFileTypeOk);
    return isFileTypeOk;
  }
  function SaveAndUpdateDeviceDefinitionAndFiles(data) {
    return request({
        url: "v2/DevicePackage/SaveAndUpdateDeviceDefinitionAndFiles",
        method: "post",
        data
    });
  }

  function checkIfOSExists(data) {
    return request({
        url: "v2/DevicePackage/CheckIfOSExists",
        method: "post",
        data
    });
  }

  function saveAndUpdateOSFiles(data) {
    return request({
        url: "v2/DevicePackage/SaveAndUpdateOSFiles",
        method: "post",
        data
    });
  }

  function checkIfDeviceExists(data) {
    return request({
        url: "v2/DevicePackage/CheckIfDeviceExists",
        method: "post",
        data
    });
  }

  function downLoadFile(filePath)
  {
    return request({
        url: "v2/DevicePackage/DownLoadFile?DownLoadPath="+filePath,
        method: "get",
        responseType:'blob',
        filePath
    });
  }

//根据路径获取文件名
function getFileName(path){
    var pos1 = path.lastIndexOf('/');
    var pos2 = path.lastIndexOf('\\');
    var pos  = Math.max(pos1, pos2);
    if( pos<0 )
        return path;
    else
        return path.substring(pos+1);
}
export default {
    getOSList,
    checkFileType,
    beforeAvatarUpload,
    SaveAndUpdateDeviceDefinitionAndFiles,
    downLoadFile,
    getFileName,
    checkIfOSExists,
    saveAndUpdateOSFiles,
    checkIfDeviceExists,
  };