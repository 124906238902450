import request from "@/utils/request";

              //获取车辆的实时参数, 必须指定nodeId,canAddress
              function getSpecifiedParameters(data) {
                return request({
                  url: "v2/ParameterMonitor/GetSpecifiedParameters",
                  method: "post",
                  data
                });
              }
              
              //获取车辆某个控制器的所有实时参数, 必须指定nodeId
              function getSpecifiedNodeParameters(data) {
                return request({
                  url: "v2/ParameterMonitor/GetSpecifiedNodeParameters",
                  method: "post",
                  data
                });
              }
              
              //车辆电压实时监测
              function getCarVoltageMonitor(data) {
                return request({
                  url: "v2/ParameterMonitor/GetCarVoltageMonitor",
                  method: "post",
                  data
                });
              }
              
            

            export default {
                getSpecifiedParameters,
getSpecifiedNodeParameters,
getCarVoltageMonitor
            };