<template>
  <div id="AddMachineMonitorConfigPage">
    <el-card class="machineCard">
      <!--面包屑-->
      <div class="breadcrumbView">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: 'CompanyList' }">{{$t("common.company")}}</el-breadcrumb-item>
          <el-breadcrumb-item :to="'/VehicleList/'+machineInfo.CompanyId">{{machineInfo.CompanyName}}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="$store.state.isEdit">{{
              $t("addMachine.EditMachine")
            }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else>{{
              $t("addMachine.addMachine")
            }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 步骤条 -->
      <div class="stepsView">
        <el-steps :active="active" align-center>
          <el-step :title="$t('addMachine.setp1')"></el-step>
          <el-step :title="$t('addMachine.setp2')"></el-step>
          <el-step :title="$t('addMachine.setp3')"></el-step>
          <el-step :title="$t('addMachine.setp4')"></el-step>
        </el-steps>
      </div>
      <!-- 设备配置信息 -->
      <div class="machineMonitorConfig-content">
        <el-row>
          <el-col class="DefaultMonitor">
            {{$t('addMachine.DefaultMachineMonitor')}}
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div v-for="(item,i) in defaultTemplateList" :key="item.Address" @click="editDefaultMonitor(item,i)" class="DefaultMonitorContent">
              {{item.DisplayParaName}}
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="DefaultMonitor">
            <span class="machine">{{$t('addMachine.MachineMonitor')}} </span>
            <div class="search">
              <el-input
                v-if="monitorList.length>0"
                v-model="search"
                :placeholder="$t('addMachine.name')">
                <i slot="suffix" class="fa fa-search unit"></i>
              </el-input>
            </div>
            <div class="templateSelect">
              <el-select
                v-if="templateList.length>0"
                v-model="template"
                filterable
                :placeholder="$t('addMachine.ImportFromTemplate')"
                >
                <el-option
                  v-for="item in templateList"
                  :key="item.MonitorTemplateId"
                  :label="item.TemplateName"
                  :value="item.MonitorTemplateId"
                >
                </el-option>
              </el-select>
            </div>
            <el-button v-if="templateList.length>0&&monitorList.length<30" class="importButton" @click="imporMonitor" type="primary">{{$t('addMachine.import')}}</el-button>
            <el-button v-if="monitorList.length>0&&monitorList.length<30" class="addButton" @click="addMonitor" type="primary">{{$t('addMachine.Add')}}</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="Monitor">
            <div v-if="monitorList.length==0" class="emptyMonitor">
              <div>
                <i class="el-icon-s-order"></i>
              </div>
              <div>
                {{$t('addMachine.NoMoreMonitor')}}, <span @click="addMonitor" class="addMonitor"> {{$t('addMachine.addmonitor')}}</span>
              </div>
            </div>
            <div v-else>
              <el-table
                ref="table"
                :cell-style="maskErrorItem"
                :data="monitorList.filter(data => !search || data.DisplayParaName.toLowerCase().includes(search.toLowerCase()))"
                height="500"
                >
                <el-table-column
                :label="$t('addMachine.DisplayName')"
                prop="DisplayParaName"
                min-width="12%"
                align="center">
              </el-table-column>
                <el-table-column
                  :label="$t('bizConfig.DeviceName')"
                  prop="DeviceName"
                  min-width="12%"
                  align="center">
                </el-table-column>               
                <el-table-column
                  :label="$t('addMachine.Device')"
                  prop="NodeId"
                  min-width="8%"
                  align="center">
                </el-table-column>
                <el-table-column
                    :label="$t('addMachine.ParameterNameAddress')"
                  min-width="21%"
                  prop="AddressMenuName"
                  align="center">
                </el-table-column>
                <el-table-column
                    :label="$t('addMachine.UpdateFrequency')"
                  prop="Frequency"
                  min-width="15%"
                  align="center">
                </el-table-column>
                <el-table-column
                    :label="$t('myvehicle.units')"
                  prop="Unit"
                  min-width="15%"
                  align="center">
                </el-table-column>
                <el-table-column
                    :label="$t('addMachine.DataAnalysis')"
                  min-width="12%"
                  align="center">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.OpenDataAnalysis==0" type="info">{{$t('common.Off')}}</el-tag>
                    <el-tag v-else type="success">{{$t('common.On')}}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                    :label="$t('common.operation')"
                  min-width="10%"
                  align="center">
                  <template slot-scope="scope">
                    <div class="operation"><i class="el-icon-edit" @click="editMonitor(scope.$index,scope.row)"></i></div>
                    <div class="operation"><i class="el-icon-delete" @click="deleteMonitor(scope.$index,scope.row)"></i></div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="footer">
            <el-button @click="back">{{
              $t("addMachine.Previous")
            }}</el-button>
            <el-button type="primary" @click="next">{{
              $t("addMachine.Next")
            }}</el-button>
          </el-col>
        </el-row>
        <el-dialog :title="$t('addMachine.monitorDialogTitle')" width="50%" :visible.sync="monitorDialog" @close="monitorDialogClose">
          <el-form ref="monitorForm" :model="monitorForm" label-width="30%" :rules="rules">
            <el-row>
              <el-form-item prop="DisplayParaName" :label="$t('addMachine.displayName') + ':'" >
                <el-col :span="12">
                   <el-input :disabled="isDefault" v-model="monitorForm.DisplayParaName" :placeholder="$t('addMachine.displayName')"/>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="Device" :label="$t('addMachine.deviceNodeID') + ':'" >
                <el-col :span="12">
                   <el-select
                    v-model="monitorForm.Device"
                    filterable
                    @change="DeviceNodeIDChange"
                    value-key="NodeId"
                    :disabled="(isSolveEdit?false:isEdit)||isDefault"
                    :placeholder="DeviceList.length>0 && !isDefault?DeviceList[0].DeviceName+'_'+DeviceList[0].NodeId:monitorForm.NodeId"
                    >
                    <el-option
                      v-for="item in DeviceList"
                      :key="item.NodeId"
                      :label="item.DeviceName+'_'+item.NodeId"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="Address" :label="$t('addMachine.address') + ':'" >
                <el-col :span="12">
                <div class="mask" v-show="isShowSelect" @click="isShowSelect = !isShowSelect"></div>
                  <el-popover
                    placement="bottom"
                    popper-class="addressPopover"
                    trigger="manual"
                    v-model="isShowSelect"
                    >
                    <el-select
                      v-model="monitorForm.Address"
                      slot="reference"
                      ref="addressSelect"
                      :disabled="isDefault"
                      @click.native="isShowSelect = !isShowSelect"
                      >
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-select
                      v-model="filterText"
                      filterable
                      remote
                      reserve-keyword
                      @change="addressSearchChange"
                      :placeholder="$t('addMachine.faultSearch')"
                      :remote-method="remoteMethod"
                      value-key="ParIndex"
                      :loading="loading">
                      <i slot="suffix" class="fa fa-search unit"></i>
                      <el-option
                        v-for="item in treeOptions"
                        :key="item.ParIndex"
                        :label="item.MenuLevel==7?item.MenuName + '-(' + item.Address+')':item.MenuName"
                        :value="item">
                      </el-option>
                    </el-select>
                    <el-tree
                      class="filter-tree"
                      node-key="ParIndex"
                      lazy
                      :load="loadTree"
                      @node-click="nodeClick"
                      :data="AddressList"
                      :default-expanded-keys="expandedList"
                      highlight-current
                      accordion
                      :props="defaultProps"
                      ref="tree">
                      <span class="custom-tree-node" slot-scope="{ data }">
                        <span>{{data.MenuLevel ==7 ? data.MenuName + '-(' + data.Address+')' : data.MenuName}}</span>
                      </span>
                    </el-tree>
                  </el-popover>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="Frequency" :label="$t('addMachine.frequency') + ':'" >
                <el-col :span="12">
                   <el-select
                    v-model="monitorForm.Frequency"
                    filterable
                    :placeholder="FrequencyList[0]+''"
                    >
                    <el-option
                      v-for="item in FrequencyList"
                      :key="item"
                      :label="item+''"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="Unit" :label="$t('myvehicle.units') + ':'" >
                <el-col :span="12">
                   <el-input :disabled="isDefault" v-model="monitorForm.Unit" :placeholder="$t('myvehicle.units')"/>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="OpenDataAnalysis" :label="$t('addMachine.openDataAnalysis') + ':'" >
                <el-col :span="12">
                   <el-switch
                      v-model="monitorForm.OpenDataAnalysis"
                      :disabled="isDefault"
                      active-value="1"
                      inactive-value="0">
                    </el-switch>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row class="border">
              <el-form-item>
                <el-col class="dialogFooter">
                  <el-button @click="monitorDialogCancel">{{$t('common.cancel')}}</el-button>
                  <el-button v-if="isEdit||isDefault" type="primary" @click="changeMonitor">{{$t('addMachine.Submit')}}</el-button>
                  <el-button v-else type="primary" @click="submitMonitor">{{$t('addMachine.Submit')}}</el-button>
                </el-col>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>
        <el-dialog :title="$t('addMachine.saveTemplate')" width="50%" :visible.sync="templateDialog" @close="templateDialogClose">
          <el-form ref="templateForm" :model="templateForm" label-width="24%" :rules="rules">
           <el-row>
              <el-form-item prop="UsedAsTemplate" :label="$t('addMachine.UsedAsTemplate') + ':'" >
                <el-col :span="10">
                  <el-radio-group v-model="templateForm.UsedAsTemplate">
                    <el-radio :label="true">{{ $t('common.yes') }}</el-radio>
                    <el-radio :label="false">{{ $t('common.no') }}</el-radio>
                  </el-radio-group>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row v-if="templateForm.UsedAsTemplate">
              <el-form-item prop="TemplateName" :label="$t('addMachine.templateName') + ':'" >
                <el-col :span="10">
                   <el-input v-model="templateForm.TemplateName" :placeholder="$t('addMachine.templateName')"/>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row v-if="templateForm.UsedAsTemplate">
              <el-form-item prop="Remark" :label="$t('addMachine.remark') + ':'" >
                <el-col :span="18">
                   <el-input type="textarea" :rows="4" v-model="templateForm.Remark" :placeholder="$t('bizConfig.RemarkInformation')"/>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row class="border">
              <el-form-item>
                <el-col class="dialogFooter">
                  <el-button @click="templateDialogCancel">{{$t('common.cancel')}}</el-button>
                  <el-button type="primary" :disabled="disabled" @click="templateSubmit">{{$t('addMachine.Submit')}}</el-button>
                </el-col>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
import carApi from "@/api/autogen/v2/car";
import carDeviceApi from "@/api/autogen/v2/cardevices";
import monitorApi from "@/api/autogen/v2/monitortemplateconfig";
export default {
  name: "AddMachineMonitorConfigPage",
  data() {
    var checkAddress = (rule, value, callback) => {
      if (this.monitorForm.Address == null || this.monitorForm.Address == "") {
        callback(
          new Error(
            this.$t("addMachine.address") + this.$t("common.required")
          )
        );
      } else {
        callback();
      }
    };
    var checkDevice = (rule, value, callback) => {
      if (this.monitorForm.NodeId == null || this.monitorForm.NodeId == "") {
        callback(
          new Error(
            this.$t("addMachine.deviceNodeID") + this.$t("common.required")
          )
        );
      } else {
        callback();
      }
    };

    var checkTemplateName =async (rule, value, callback) => {
      if (value === null || value === "" || value === undefined) {
        callback(
            new Error(
                this.$t("addMachine.templateName") + this.$t("common.required")
            )
        );
      }else {
        let data ={
          "templateName": value,
          "companyId": this.machineInfo.CompanyId
        };
        let flag = undefined;
        await monitorApi.checkTemplateNameExisted(data).then(resp=>{
          if (resp){
            flag =resp.Data;
          }
        });
        if (flag){
          callback(
              new Error(this.$t("addMachine.templateNameExited")));
        } else {
          callback();
        }
      }
    };

    var checkUnit = (rule, value, callback) => {
      if (this.monitorForm.Unit == null || this.monitorForm.Unit == ""|| this.monitorForm.Unit.trim() == "") {
        callback(
          new Error(
            this.$t("myvehicle.units") + this.$t("common.required")
          )
        );
      } else {
        callback();
      }
    };
    // {required: true,message:this.$t("addMachine.templateName") + this.$t("common.required"),trigger: "blur",},
    return {
      //车辆信息
      machineInfo:{},
      //监控参数列表
      monitorList:[],
      //监控参数表单
      monitorForm:{
        Frequency: 30,
        OpenDataAnalysis:'0',
        Unit:"none"
      },
      tempDevice:'',
      // 模板列表
      templateList:[],
      // 导入后存在异常的索引
      errorItemsIndex:[],
      templateForm:{
        UsedAsTemplate:true,
      },
      // 模板下拉组件内容
      template:"",
      //默认监控参数列表
      defaultTemplateList:[],
      //设备列表
      DeviceList:[],
      // Address下拉框内容，不显示
      options:[],
      // Address远程搜索内容
      treeOptions: [],
      // Address树形结构列表
      AddressList:[],
      // 默认展开节点列表
      expandedList:[],
      loading:false,
      // 是否显示popover
      isShowSelect:false,
      // 更新频率列表
      FrequencyList:[30,60,120],
      // Address过滤选中的对象
      filterText: {},
      // 树形默认结构
      defaultProps: {
        children: 'children',
        label: 'MenuName',
        isLeaf: 'leaf',
      },
      //是否为编辑车辆信息
      isEditMachine:false,
      //是否为编辑监控参数
      isEdit:false,
      //是否为异常修复
      isSolveEdit:false,
      //是否为默认监控参数
      isDefault:false,
      // 按钮是否可操作
      disabled: false,
      //步骤条步骤
      active:3,
      //模板参数列表过滤条件
      search:"",
      //当前编辑的行位置
      activeIndex:0,
      //当前编辑的行Id位置
      activeidIndex:0,
      //弹窗flag
      monitorDialog:false,
      templateDialog:false,
      rules:{
        DisplayParaName: [{required: true,message:this.$t("addMachine.displayName") + this.$t("common.required"),trigger: "blur",},],
        Device: [{required: true,validator:checkDevice,trigger: "blur",},],
        Address: [{required: true,validator: checkAddress,trigger: "blur",},],
        Frequency: [{required: true,message:this.$t("addMachine.frequency") + this.$t("common.required"),trigger: "blur",},],
        Unit: [{required: true,validator:checkUnit,trigger: "blur",},],
        OpenDataAnalysis: [{required: true,message:this.$t("addMachine.openDataAnalysis") + this.$t("common.required"),trigger: "blur",},],
        TemplateName: [{ required: true,validator: checkTemplateName, trigger: 'blur' }],
        UsedAsTemplate: [{required: true,message:this.$t("addMachine.UsedAsTemplate") + this.$t("common.required"),trigger: "blur",},],
      }
    };
  },
  watch: {
    isShowSelect (val) {
      // 隐藏select自带的下拉框
      this.$refs.addressSelect.blur();
    },
  },
  created() {
    this.machineInfo = this.$route.params;
    if(this.machineInfo.MachineComponents != null){
      this.DeviceList = this.machineInfo.MachineComponents.DeviceList;
    }
    if(this.machineInfo.MachineId != null){
      this.isEditMachine = true;
    }
    if(this.machineInfo.MachineComponents.MachineTypeMonitorTemplate.MonitorParameterList.length>0){
      this.getMonitorList();
    }else{
      this.getSystemDefaultMonitorList();
    }
    this.getMonitorTemplateList();
  },
  mounted(){

  },
  methods: {
    getMonitorList(){
      var self = this;
      var list = this.machineInfo.MachineComponents.MachineTypeMonitorTemplate.MonitorParameterList;
      list.forEach(item => {
        if(item.IsDefaultMonitor == 1){
          this.defaultTemplateList.push(item);
        }else{
          self.DeviceList.forEach(device => {
            if(device.NodeId == item.NodeId){
              item.DeviceName = device.DeviceName;
              item.AddressMenuName = item.ParameterName + '-(' + item.Address + ')';
              this.monitorList.push(item);
            }
          });
        }
      });
    },
    //编辑默认监控模板
    editDefaultMonitor(data,index){
      this.isDefault = true;
      this.monitorForm = data;
      this.monitorForm.NodeId = "0x00";
      this.activeIndex = index;
      this.monitorForm.OpenDataAnalysis = '1';
      this.monitorDialog = true;
    },
    // 获取默认监控模板列表
    getSystemDefaultMonitorList(){
      var self = this;
      monitorApi.getSystemDefaultMonitorList().then(res=>{
        if(res.IsSuccess){
          self.defaultTemplateList = res.Data;
          self.defaultTemplateList.forEach(item => {
            item.NodeId = "0x00";
            item.OpenDataAnalysis = '1';
            item.IsDefaultMonitor = 1;
          });
        }
      });
    },
    // 获取监控模板列表
    getMonitorTemplateList(){
      var self = this;
      var param = {
        CompanyId: this.machineInfo.CompanyId,
      };
      monitorApi.getMonitorTemplateList(param).then(res=>{
        if(res.IsSuccess){
          self.templateList = res.Data.MonitorTemplateList;
        }
      });
    },
    //Address远程搜索下拉框变更
    addressSearchChange(value){
      this.expandedList = [value.ParIndex];
      let tmpMap = {};
      tmpMap.value = value.Address;
      tmpMap.label = value.MenuName + '-(' + value.Address+')';
      this.options = [];
      this.options.push(tmpMap);
      this.monitorForm.Address = value.Address;
      this.monitorForm.AddressMenuName = value.MenuName;
      this.monitorForm.BitSelect = value.BitSelect;
      this.monitorForm.DataType = value.DataType;
      this.monitorForm.ParameterName = value.MenuName;
      if(value.Unit==null || value.Unit.trim()=="")
        this.monitorForm.Unit="none";
      else 
        this.monitorForm.Unit= value.Unit;
      console.log("unit=",value.Unit);
      this.isShowSelect = false;
    },

    // 远程搜索
    remoteMethod(query){
      if (query !== '') {
        var self = this;
        this.loading = true;
        var param = {
          DeviceId:this.monitorForm.DeviceId,
          FilterCondition: query,
        };
        carDeviceApi.getDeviceTotalMenuAndParameterList(param).then(res=>{
          if(res.IsSuccess){
              self.loading = false;
              self.treeOptions = res.Data;
            }else{
            this.treeOptions = [];
            console.log(res);
          }
        }).catch((e)=>{
          this.treeOptions = [];
          console.log(e);
        });
      } else {
        this.treeOptions = [];
      }
    },
    //选中Address
    nodeClick(){
      var node = this.$refs.tree.getCurrentNode();
      if(node.MenuLevel == 7){
        let tmpMap = {};
        tmpMap.value = node.Address;
        tmpMap.label = node.MenuName + '-(' + node.Address+')';
        this.options = [];
        this.options.push(tmpMap);
        this.monitorForm.Address = node.Address;
        this.monitorForm.AddressMenuName = node.MenuName + '-(' + node.Address+')';
        this.monitorForm.BitSelect = node.BitSelect;
        this.monitorForm.DataType = node.DataType;
        this.monitorForm.ParameterName = node.MenuName;
        if(node.Unit==null || node.Unit.trim()=="")
          this.monitorForm.Unit="none";
        else 
          this.monitorForm.Unit= node.Unit;
        this.isShowSelect = false;
      }else{
        return false;
      }
    },
    //树形结构懒加载方法
    loadTree(node, resolve){
      var param = {
        DeviceId:this.monitorForm.DeviceId,
        ParentIndex:node.data.ParIndex,
      };
      carDeviceApi.getDeviceChildrenMenuAndParameterList(param).then(res=>{
        if(res.IsSuccess){
          var data = res.Data;
          data.forEach(item => {
            if (item.MenuLevel == 7) {
              item.leaf = true;
            }
          });
          resolve(data);
        }else{
          // self.AddressList = [];
          console.log(res);
        }
      }).catch((e)=>{
        self.AddressList = [];
        console.log(e);
      });
    },
    //设备下拉框变更
    DeviceNodeIDChange(value){
      var self = this;
      var param = {
        DeviceId:value.DeviceId,
        ParentIndex:-1,
        FilterCondition:"",
      };
      carDeviceApi.getDeviceChildrenMenuAndParameterList(param).then(res=>{
        if(res.IsSuccess){
          self.AddressList = res.Data;
        }else{
          self.AddressList = [];
        }
      }).catch((e)=>{
        self.AddressList = [];
        console.log(e);
      });
      this.monitorForm.DeviceName = value.DeviceName;
      this.monitorForm.NodeId = value.NodeId;
      this.monitorForm.DeviceId = value.DeviceId;
    },
    //保存更改的数据
    changeMonitor(){
      this.$refs["monitorForm"].validate((valid) => {
        if (valid) {
          if(this.monitorForm.IsDefaultMonitor == 0){
            if (this.isSolveEdit){
              if(this.$data.errorItemsIndex.some((value => {return value ===this.activeidIndex;}))){
                this.$data.errorItemsIndex.forEach((v,index)=>{
                  if (v === this.activeidIndex){
                    this.$data.errorItemsIndex.splice(index,1);
                  }
                });

              }
            }
          if (this.isSolveEdit){
            this.monitorForm.AddressMenuName =this.monitorForm.ParameterName+'-('+this.monitorForm.Address+')';
          }
            this.monitorList[this.activeIndex] = this.monitorForm;
          }else{
            this.defaultTemplateList[this.activeIndex] = this.monitorForm;
          }
          this.isSolveEdit=false;
          this.monitorDialog = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑模板参数
    editMonitor(index,row){
      console.log("editMonitor======",row);
      this.isEdit =true;
      this.monitorForm = JSON.parse(JSON.stringify(row));
      this.monitorForm.OpenDataAnalysis= this.monitorForm.OpenDataAnalysis.toString();
      if(this.errorItemsIndex.length!==0){
        if (this.$data.errorItemsIndex.some((value => {return value ===row.idIndex;}))){
          this.monitorForm.Address='';
          this.isSolveEdit =true;
        }
      }
      this.activeIndex = index;
      this.activeidIndex=row.idIndex;
      var self = this;

      var param = {
        DeviceId:this.monitorForm.DeviceId,
        ParentIndex:-1,
      };
      carDeviceApi.getDeviceChildrenMenuAndParameterList(param).then(res=>{
        if(res.IsSuccess){
          self.AddressList = res.Data;
        }else{
          self.AddressList = [];
        }
      }).catch((e)=>{
        self.AddressList = [];
        console.log(e);
      });
      this.monitorDialog = true;
    },
    //删除模板参数
    deleteMonitor(index,row){
      var self = this;
      const Url = require("@/assets/image/error_76.png");
      const tip=this.$t('addMachine.IsDeleteData');
      this.$confirm("<img src=" + Url + "><p class='text'>"+tip+"</p>", '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t("addMachine.Delete"),
        cancelButtonText: this.$t("common.cancel"),
        iconClass:'warningTipIcon',
        customClass:"warningTip",
        center: true,
        showClose:false
      }).then(() => {
        console.log("monitorList==",this.monitorList);
        self.monitorList.splice(index,1);
        console.log("monitorList==",this.monitorList);
        //如果删除的为有问题的数据，则减少错误列表      
        console.log("errorItemsIndex==",self.errorItemsIndex);
        //console.log("row.idIndex==",row.idIndex);
        self.errorItemsIndex.forEach(v=>{
             if(v==row.idIndex)
             self.errorItemsIndex.splice(v,1,-1);
        });
        console.log("errorItemsIndex==",self.errorItemsIndex);
        /*let tmpList=self.errorItemsIndex.filter((value) => {
              return value!=-1
        });
        console.log("tmpList==",tmpList);
        self.errorItemsIndex=tmpList;
        console.log("errorItemsIndex33==",self.errorItemsIndex);*/
      }).catch(() => {
      });
    },
    //添加监控参数
    addMonitor(){
      this.isEdit = false;
      this.monitorDialog = true;
      if (this.monitorForm.Device!=''){
        this.monitorForm.DeviceId =this.monitorForm.Device.DeviceId;
        this.monitorForm.DeviceName=this.monitorForm.Device.DeviceName;
        this.monitorForm.NodeId=this.monitorForm.Device.NodeId;
        this.monitorForm.OpenDataAnalysis = '0';
        var self = this;

        var param = {
          DeviceId:this.monitorForm.DeviceId,
          ParentIndex:-1,
        };
        carDeviceApi.getDeviceChildrenMenuAndParameterList(param).then(res=>{
          if(res.IsSuccess){
            self.AddressList = res.Data;
          }else{
            self.AddressList = [];
          }
        }).catch((e)=>{
          self.AddressList = [];
          console.log(e);
        });
      }
    },
    imporMonitor(){
      if (this.template === undefined || this.template ==='' ){
        this.$message({
          message: this.$t('addMachine.importYourTemplate'),
          type: 'warning',
          duration:1000
        });
      }else {
        let deviceIdList=[];
        this.$data.DeviceList.forEach( item =>{
          deviceIdList.push(item.DeviceId);
        });
        let data={
          "monitorTemplateId": this.template,
          "deviceIdList": deviceIdList
        };
        monitorApi.CheckImportMonitorTemplate(data).then(resp=>{
          let arrToMonitorList = this.makeArrToMonitorList(resp.Data);
          this.$data.monitorList=arrToMonitorList.toMonitorList;

          if (arrToMonitorList.count === 0){
            this.$message({
              message: this.$t('common.ImportedTip1'),
              type: 'success',
              duration:1500
            });
          }else {
            this.$message({
              message: this.$t('common.ImportedTip2')+arrToMonitorList.count,
              type: 'warning',
              duration:2000
            });
          }
        });
      }
    },
    makeArrToMonitorList(arr){
      let newData={
        toMonitorList:[],
        count: 0,
        errorItems:[]
      };
      if( arr ){
        arr.forEach((item, index)=>{
          let temp =item;
          temp.AddressMenuName = temp.ParameterName+'-('+temp.Address+')';
          temp.idIndex=index;
          this.$data.DeviceList.forEach(device=>{
            if (device.NodeId === item.NodeId){
              temp.DeviceName=device.DeviceName;
            }
          });
          if (!item.IsNodeIdExisted || !item.IsParameterCANAddressExisted){
            newData.errorItems.push(index);
            temp.NodeId ='';
            temp.AddressMenuName = '';
            newData.count +=1;
          }
          else if (item.Unit==null||item.Unit.trim()==""){
            newData.errorItems.push(index);            
            newData.count +=1;
          }
          newData.toMonitorList.push(temp);
        });
      }
      this.$data.errorItemsIndex = newData.errorItems;
      return newData;
    },
    maskErrorItem(row){
      if (this.$data.errorItemsIndex.length!==0){
        if (this.$data.errorItemsIndex.some((value => {return value ===row.row.idIndex;}))&&row.columnIndex==2&&!row.row.IsNodeIdExisted){
          return 'color:#c91f37;font-weight:Bold;border:1px solid #c91f37;';
        }
        if (this.$data.errorItemsIndex.some((value => {return value ===row.row.idIndex;}))&&row.columnIndex==3&&!row.row.IsParameterCANAddressExisted){
          return 'color:#c91f37;font-weight:Bold;border:1px solid #c91f37;';
        }
        //console.log("row",row);
        //console.log("row.Unit",row.row.Unit);
        //console.log("row.Address ",row.row.Address);
        if (row.columnIndex==5&&row.row.Unit==null||row.row.Unit==""||row.row.Unit.trim()==""){
          return 'color:#c91f37;font-weight:Bold;border:1px solid #c91f37;';
        }
      }
    },
    //关闭添加监控参数弹窗
    monitorDialogCancel(){
      this.monitorDialog = false;
      this.isSolveEdit=false;
    },
    //重启RDM
    resetRDM(){
            let params = {
                machineId: this.machineInfo.MachineId,
                RDMNO:this.machineInfo.RDMNO,
            };
            monitorApi.resetRDM(params).then((res) => {
                if(res.Data){
                  console.log("reset RDM result==");
                  console.log(res);
                }else{
                    const Url = require("@/assets/image/warning1.5x.png");
                    this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.SendConfigurationInformationToRDMFail")+"!,"+this.$t("myvehicle.machine")+"("+this.machineInfo.MachineSN+")</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
            });
        },
    //保存监控参数
    submitMonitor(){
      var self = this;
      this.$refs["monitorForm"].validate((valid) => {
        if (valid) {
          self.monitorForm.NodeId = self.monitorForm.Device.NodeId;
          self.tempDevice=self.monitorForm.Device;
          self.monitorForm.IsDefaultMonitor = 0;
          self.monitorList.push(self.monitorForm);
          self.monitorDialog = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //监控参数弹窗关闭回调
    monitorDialogClose(){
      this.AddressList = [];
      this.filterText = "";
      this.isDefault = false;
      this.isSolveEdit=false;
      this.monitorForm = {
        Frequency: 30,
        OpenDataAnalysis:'0',
        Device:this.tempDevice

      };
    },
    // 检查MachineSN是否存在
    checkIfMachineSNExisted(param){
      var self = this;
      carApi.checkIfMachineSNExisted(param).then(res=>{
        if(res.IsSuccess){
          if(res.Data){
            this.$message.error(this.$t('myvehicle.MachineSNExist'));
            return false;
          }else {
            self.CheckIfRDMBeenUsed(param);
          }
        }else{
          console.log(res);
        }
      }).catch((e)=>{
        console.log(e);
      });
    },
    // 检查RDM是否被使用
    CheckIfRDMBeenUsed(param){
      var self = this;
      carApi.CheckIfRDMBeenUsed(param).then(res=>{
        if(res.IsSuccess){
          if(res.Data){
            this.$message.error(this.$('common.RDM')+this.$('common.used'));
            return false;
          }else{
            if(this.isEditMachine){
              self.editMachine(param.MonitorTemplateId);
            }else{
              self.createMachine(param.MonitorTemplateId);
            }
          }
        }else{
          console.log(res);
        }
      }).catch((e)=>{
        console.log(e);
      });
    },
    // 创建车辆
    createMachine(MonitorTemplateId){
      var fd = new FormData();
      fd.append("MachineSN",this.machineInfo.MachineSN);
      fd.append("RDMNO",this.machineInfo.RDMNO);
      fd.append("CompanyId",this.machineInfo.CompanyId);
      fd.append("VoltMin",this.machineInfo.MachineComponents.MachineType.VoltMin);
      fd.append("VoltMax",this.machineInfo.MachineComponents.MachineType.VoltMax);
      fd.append("ImageFile",this.machineInfo.MachineComponents.MachineType.ImagePath);
      fd.append("Remark",this.machineInfo.MachineComponents.MachineType.Remark);
      fd.append("TypeId",this.machineInfo.TypeId);
      fd.append("TypeVersion",this.machineInfo.TypeVersion);
      fd.append("MonitorTemplateId",MonitorTemplateId);
      carApi.createMachine(fd).then(res=>{
        if(res.IsSuccess && res.Data){
          this.resetRDM();
          this.$router.push({
            path: '/VehicleList/'+this.machineInfo.CompanyId,
          });
        }else{
          console.log(res);
        }
      }).catch((e)=>{
        console.log(e);
      });
    },
    // 编辑车辆
    editMachine(MonitorTemplateId){
      var fd = new FormData();
      fd.append("MachineId",this.machineInfo.MachineId);
      fd.append("VoltMin",this.machineInfo.MachineComponents.MachineType.VoltMin);
      fd.append("VoltMax",this.machineInfo.MachineComponents.MachineType.VoltMax);
      fd.append("ImageFile",this.machineInfo.MachineComponents.MachineType.ImagePath);
      fd.append("Remark",this.machineInfo.MachineComponents.MachineType.Remark);
      fd.append("TypeId",this.machineInfo.TypeId);
      fd.append("TypeVersion",this.machineInfo.TypeVersion);
      fd.append("MonitorTemplateId",MonitorTemplateId);
      carApi.editMachine(fd).then(res=>{
        if(res.IsSuccess && res.Data){
          console.log("editMachine ok...");
          this.resetRDM();
          console.log("resetRDM ok...");
          this.$router.push({
            path: '/VehicleList/'+this.machineInfo.CompanyId,
          });
        }else{
          console.log(res);
        }
      }).catch((e)=>{
        console.log(e);
      });
    },
    //模板弹窗关闭回调
    templateDialogClose(){
      this.templateForm = {
        UsedAsTemplate:true,
      };
    },
    //关闭模板弹窗
    templateDialogCancel(){
      this.templateDialog = false;
    },
    // 编辑时保存监控模板
    editMachineMonitorList(){
      var self = this;
      var data = this.machineInfo.MachineComponents.MachineTypeMonitorTemplate.MonitorTemplateInformation;
      var template = {
        MonitorTemplateId:data.MonitorTemplateId,
        CompanyId:data.CompanyId,
        TemplateName: data.TemplateName,
        UsedAsTemplate: data.UsedAsTemplate,
        Remark: data.Remark,
        MonitorParameterList: this.monitorList.concat(this.defaultTemplateList),
      };
      monitorApi.editMachineMonitorList(template).then(res=>{
        if(res.IsSuccess){
          self.editMachine(this.machineInfo.MachineComponents.MachineTypeMonitorTemplate.MonitorTemplateInformation.MonitorTemplateId);
        }else{
          console.log(res);
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    // 保存监控模板和车辆信息
    templateSubmit(){
      this.disabled = true;
      var self = this;
      var template = {
        CompanyId:this.machineInfo.CompanyId,
        TemplateName: this.templateForm.TemplateName,
        UsedAsTemplate:  this.templateForm.UsedAsTemplate,
        Remark: this.templateForm.Remark,
        MonitorParameterList: this.monitorList.concat(this.defaultTemplateList),
      };
      this.$refs["templateForm"].validate((valid) => {
        if (valid) {
          monitorApi.createMonitorTemplate(template).then(res=>{
            if(res.IsSuccess){
              var param = {
                MachineSN: self.machineInfo.MachineSN,
                RDMNO:self.machineInfo.RDMNO,
                CompanyId:self.machineInfo.CompanyId,
                VoltMin:self.machineInfo.MachineComponents.MachineType.VoltMin,
                VoltMax:self.machineInfo.MachineComponents.MachineType.VoltMax,
                ImageFile: self.machineInfo.MachineComponents.MachineType.ImagePath,
                Remark:self.machineInfo.MachineComponents.MachineType.Remark,
                CarTypeInformation: {
                  TypeId:self.machineInfo.TypeId,
                  TypeVersion:self.machineInfo.TypeVersion,
                },
                MonitorTemplateId:res.Data,
              };              
              if(this.isEditMachine){
                self.editMachine(res.Data);
              }else {
                self.checkIfMachineSNExisted(param);
              }   
              this.disabled = false;            
            }else{
              this.disabled = false;
              console.log(res);
            }
          }).catch((e) => {
                    const Url = require("@/assets/image/warning1.5x.png");
                    const errMessage = this.$t("Error.MonitorParameterError");
                    this.$alert("<img src=" + Url + "><p class='text'>"+ errMessage +"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
            this.disabled = false;
            console.log(e);
          });
        } else {
          console.log("error submit!!");
          self.disabled = false;
          return false;
        }
      });
    },
    //下一步
    next(){
        let self = this;    
        let tmpList=self.errorItemsIndex.filter((value) => {
              return value!=-1;
        });
        console.log("tmpList==",tmpList);
        //alert(tmpList.length);

        if (tmpList.length===0){
          if(this.isEditMachine){
              let param = {
                CompanyId:self.machineInfo.CompanyId,
                MonitorTemplateId: self.machineInfo.MachineComponents.MachineTypeMonitorTemplate.MonitorTemplateInformation.MonitorTemplateId,
                MonitorParameterList: self.monitorList.concat(this.defaultTemplateList),
              };
              monitorApi.checkIfNewMonitorTemplate(param).then(res=>{
                if(res.IsSuccess){
                  if(res.Data.IsCreateNewMonitorTemplate){
                    self.templateDialog = true;
                  }else{
                    self.editMachineMonitorList();
                  }
                }
              });
          }else{
            this.templateDialog = true;          
          }
        }else {
          this.$confirm(this.$t('common.deleteComfirmTip'), this.$t('common.Tip'), {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.cancel'),
            type: 'warning'
          }).then(() => {
            
            this.errorItemsIndex.forEach(v=>{
              console.log("errorItemsIndex.v==",v);
              if(v!=-1){               
                this.monitorList.forEach(m=>{                           
                    if(m!=-1&&m.idIndex==v )
                    {
                      console.log("monitorList.m==",m);   
                      console.log("monitorList.m==",m.index);         
                      console.log("monitorList.m.idIndex==",m.idIndex);
                      //this.monitorList.splice(m,1,-1);
                      this.monitorList.splice(m,1);
                      console.log("monitorList==",this.monitorList);
                    }
                  });
                //this.monitorList.splice(v,1,-1)
                }
            });
            
            let tempList= this.monitorList;
            let list =tempList.filter((value) => {
              return value!=-1;
            });
            console.log("monitorList list==",list);
            this.monitorList=list;
            this.errorItemsIndex=[];
            this.next();
            this.$message({
              type: 'success',
              message: this.$t('common.operationSuccess')
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('common.deletedcancel')
            });
          });
        }
    },
    back(){
      this.machineInfo.MachineComponents.MachineTypeMonitorTemplate.MonitorParameterList = this.monitorList.concat(this.defaultTemplateList);
      this.$router.push({name:'AddMachineFaultConfig',params:this.machineInfo});
    },
  },
};
</script>

<style lang="less">
/*车辆信息样式 */
.machineMonitorConfig-content{
  padding: 0 12% 1% 12%;
  .el-col{
    text-align: left;
  }
  .el-select {
    display: block;
  }
  .DefaultMonitor{
    color: #333333;
    font-weight: 700;
    padding: 20px 0 20px 20px;
    display: flex;
    .machine{
      line-height: 40px;
    }
    .search{
      flex: 0.3;
      margin-left: 10px;
      .unit {
        margin: 12px 10px;
        color: #666;
        font-size: 15px;
      }
    }
    .templateSelect{
      flex: 0.7;
      display: inline-block;
      margin-left: 10px;
    }
    .el-button{
      width: 110px;
      height: 38px;
    }
    .importButton{
      margin-left: 10px;
    }
  }
  .DefaultMonitorContent{
    background: #fafafa;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    padding: 15px 20px;
  }
  .emptyMonitor{
    background: #fafafa;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    padding: 15px 20px;
    height: 500px;
    text-align: center;
    font-size: 18px;
    .el-icon-s-order{
      font-size: 100px;
      margin-top: 150px;
      margin-bottom: 10px;
      color: #ccc;
    }
    .addMonitor{
      color: #047BC2;
      font-weight: 700;
    }
  }

  .el-table{
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    width: 100%;
    .operation{
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
      .el-icon-delete{
        margin-left: 20px;
        color: #FF4D4F;
      }
      .el-icon-edit{
        color: #047BC2;
      }
    }
    .el-table__expand-icon > .el-icon{
      display: none;
    }
    .el-table__expanded-cell{
      padding: 20px 25px;
      background: #fafafa;
    }
    tr:hover>td{
      background: #fff !important;
    }
    th{
      background-color: #fafafa;
      font-weight: 400;
      color: #333;
    }
    td{
      border-bottom: 1px solid #f0f0f0;
    }
  }
  .el-textarea__inner{
    padding: 10px 15px !important;
  }
  /*底部按钮样式 */
  .footer{
    text-align: center;
    margin: 30px 0;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  .border{
    padding-top: 20px;
    border-top: 1px solid #eaeef0;
    margin: 0 -2%;
  }
  .el-input.is-disabled .el-input__inner{
    border-color: #ccc;
  }
  /*弹窗底部按钮样式 */
  .dialogFooter{
    text-align: left;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  /*弹窗标题样式 */
  .el-dialog__header {
    padding: 20px !important;
    height: auto !important;
    text-align: left;
    background: #f2f2f2;
    border-bottom: 1px solid #eaeef0;
  }
  .mask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 11;
  }
}
/*Address下拉框样式*/
.addressPopover{
  width: 20%;
  padding: 20px !important;
  max-height: 300px;
  overflow: auto;
  .el-select__input{
    border: 0px none !important;
    box-shadow: transparent 0px 0px 0px 0px;
  }
  .el-select{
    width: 100%;
  }
  .unit {
    margin: 12px 10px;
    color: #666;
    font-size: 15px;
    z-index: 999;
  }
  .el-input__validateIcon{
    display: none;
  }
  .el-input__inner{
    border: 0px solid transparent;
    background: #f2f2f2;
  }
  .el-tree{
    margin: 10px 0px;
  }
  .el-tree-node__content:hover {
    background-color: transparent;
    color: #007ac2;
  }
  .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #f4f6f9;
  }
}

</style>
