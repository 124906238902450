<template>
    <div class="card-box">
        <div class="card-header-box">
            <span v-if="title" class="card-header-title">{{title}}</span>
            <div class="card-header-custom">
                <slot name="header" ></slot>
            </div>
        </div>
        <div class="card-body-box">
            <slot name="content"></slot>
        </div>
        <div class="card-footer-box">
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {

        };
    },
    created() {

    },
    methods: {

    },

};
</script>
<style lang="less" scoped>
.card-box {
    border: 1px solid #dddddd;
    border-radius: 2px;
    text-align: left;

    .card-header-box {
        padding-top: 35px;
        margin-bottom: 17px;
        
        .card-header-title {
            font-size: 16px;
            color: #333333;
            padding-left: 30px;
            font-weight: bold;
            background: url('../../assets/image/icon.png') left center no-repeat;
        }

        .card-header-custom {
            float: right;
        }
    }

    .card-body-box {
        padding: 0px 30px;
    }
}
</style>