<template>
    <div class="history-data-box">
        <div class="history-data-date">
            <el-date-picker
                style="width: 240px; height: 26px; line-height: 20px !important"
                v-model="daterange"
                :editable="false"
                type="daterange"
                range-separator="~"
                :start-placeholder="$t('common.startdate')"
                :end-placeholder="$t('common.enddate')"
                @change="refreshData()"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
        </div>
        <Card :title="$t('myvehicle.Parametric_Analysis')" class="marginBottom">
            <div class="param-box" slot="content">
                <el-button type="info" class="arrow" plain icon="el-icon-arrow-left" @click="scroll_left()"></el-button>
                <div class="param-list">
                    <div class="param-row" :class="{active: item.ParameterName == paramActive}"
                    v-for="(item,key) in MonitorParameterList" 
                    :key="key" @click="getParamAnalysis(item)">{{item.ParameterName}}</div>
                    
                </div>
                <el-button type="info" class="arrow" plain icon="el-icon-arrow-right" @click="scroll_right()"></el-button>
            </div>
            <div
                class="history-latest-box"
                slot="content"
                ref="analysis-line-chart-box"
            ></div>
        </Card>
        <Card :title="$t('myvehicle.Fault_Statistic')" class="marginBottom">
            <div class="history-fault-box" slot="content">
                <div class="history-fault-pie-box" ref="pie-chart-box"></div>
                <div class="history-fault-bar-box" ref="bar-chart-box"></div>
            </div>
        </Card>
        <Card :title="$t('myvehicle.Fault_Affect_Time')" class="marginBottom">
           <div class="history-fault-box" slot="content">
                <div class="history-fault-pie-box" ref="pie-chart-dur"></div>
                <div class="history-fault-bar-box" ref="bar-chart-dur"></div>
            </div>
        </Card>
        <Card :title="$t('myvehicle.Machine_Efficiency')" class="marginBottom">
            <div class="history-fault-box" slot="content">
                <div
                    class="history-fault-pie-box"
                    ref="effic-pie-chart-box"
                ></div>
                <div
                    class="history-fault-bar-box"
                    ref="effic-line-chart-box"
                ></div>
            </div>
        </Card>
        <Card :title="$t('myvehicle.History_location')">
            <div
                class="history-map-box"
                id="history-map-box"
                slot="content"
            ></div>
        </Card>
    </div>
</template>
<script>
import "@/assets/css/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import MapboxWordViewControl from "@/components/geo/mapboxWordViewControl";
import GeoFenceControl from "@/components/geo/geoFenceControl";
import Card from "@/components/ui/Card.vue";
import geoFenceApi from "@/api/autogen/v2/geoFence";
import carApi from "@/api/autogen/v2/carList";
import faultApi from '@/api/autogen/v2/fault';
import geoApi from "@/api/autogen/v2/cargeo";
import efficiencyApi from "@/api/autogen/v2/efficiency";
import { formatTimestampUTC,checkExistData ,formatTimeStr,addTime} from '@/utils';
export default {
    name: "",
    components: {
        Card,
    },
    props: {
        companyId: {
            type: String,
            default: '',
        },
        machineId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            daterange: "",
            AnalysisDailyFaultDurationList:[],
            AnalysisDailyFaultFrequencyList:[],
            faultParam:{
                startDay: "",
                endDay: "",
                filterMachineSNList: [],
                faultTopN: 5
            },
            FrequencyClickDate:'',
            DurationClickDate:'',
            carDetails: {

            },
            paramActive:'',
            rdmDeviceType:{},
            paramMonitorList:[],
            defaultMapCenterPoint: [120.58586216387619, 31.203448584425516], //默认地图中心点的位置
            defaultMapShowLevel: 4, //默认地图显示级别
            mapInstance: null, //地图对象
            flag_showGeoFenceAsMarkerOnMap: false, //是否在地图上显示电子围栏
            geoFencePolygonList: [], // 电子围栏polyline对象列表
            carGeoList:[],
            AnalysisDailyEfficiencyList:[],
            efficiencyParam:{
                StartTime: "",
                EndTime: "",
                filterMachineSNList: []
            },
            EfficiencyClickDate:'',
            KSIOnTimeColor:'#52c41a',
            KSIOffTimeColor:'#909399'
        };
    },
    computed:{
        userInfo() {
            return this.$store.state.userEntity;
        },
        MonitorParameterList(){
            let list = [];
            if(this.carDetails&&this.carDetails.MachineComponents&&this.carDetails.MachineComponents.MachineTypeMonitorTemplate){
                this.carDetails.MachineComponents.MachineTypeMonitorTemplate.MonitorParameterList.forEach((item)=>{
                    if(item.OpenDataAnalysis == 1){
                        list.push(item);
                    }
                });
            }
            return list;
        },
        DailyFaultDurationChart(){
            let source = [],dimensions=[],data = [],series=[];
            let date = [];
            if(this.AnalysisDailyFaultDurationList){
                //console.log("AnalysisDailyFaultDurationList has value...");
                dimensions.push('Fault');
                this.AnalysisDailyFaultDurationList.forEach(item => {
                    let effctHour = item.EffectTimeDuration/1000/60/60;
                    //console.log("AnalysisDailyFaultDurationList effctHour",effctHour);
                    

                    if(!dimensions.includes(item.Fault)){                        
                        dimensions.push(item.Fault);
                        let serie = { type: 'bar' };
                        series.push(serie);
                        
                    }
                    if(this.DurationClickDate === formatTimeStr(item.StatisticDay,'YYYY-MM-DD')){
                            let array = {
                                    name : item.Fault,
                                    value : effctHour
                                };
                            data.push(array);
                            
                        }else{
                             let array = {
                                    name : item.Fault,
                                    value : 0
                                };
                            data.push(array);
                        }
                     if(!date.includes(item.StatisticDay)){
                        date.push(item.StatisticDay);
                        let ob = {};
                        
                        ob['Fault'] = formatTimeStr(item.StatisticDay,'YYYY-MM-DD');
                        ob[item.Fault] = effctHour;
                        this.AnalysisDailyFaultDurationList.forEach((item1)=>{
                            if(item.StatisticDay === item1.StatisticDay){
                                let effctHour1 = item1.EffectTimeDuration/1000/60/60;
                                ob[item1.Fault] = effctHour1;
                            }
                        });
                        source.push(ob);
                        
                     }
                });
                console.log("Duration series",series);
                console.log("Duration dimensions",dimensions);
                console.log("Duration data",data);
                console.log("Duration source",source);
            }
            let totalData ={
                source : source,
                dimensions: dimensions,
                data : data,
                series : series
            };
            return totalData;
        },
        DailyFaultFrequencyChart(){
            console.log(" this.AnalysisDailyFaultFrequencyList", this.AnalysisDailyFaultFrequencyList);
            let source = [],dimensions=[],data = [],series=[];
            let date = [];
            if(this.AnalysisDailyFaultFrequencyList){
                dimensions.push('Fault');
                this.AnalysisDailyFaultFrequencyList.forEach(item => {
                    if(!dimensions.includes(item.Fault)){                                               
                        dimensions.push(item.Fault);
                        let serie = { type: 'bar' };
                        series.push(serie);
                        
                    }
                    if(this.FrequencyClickDate === formatTimeStr(item.StatisticDay,'YYYY-MM-DD')){                            
                            let array = {
                                    name : item.Fault,
                                    value : item.Frequency
                                };
                            data.push(array);                    
                    }
                    else{
                            let array = {
                                    name : item.Fault,
                                    value : 0
                                };
                            data.push(array);
                    }
                    if(!date.includes(item.StatisticDay)){
                        date.push(item.StatisticDay);
                        let ob = {};
                        ob['Fault'] = formatTimeStr(item.StatisticDay,'YYYY-MM-DD');
                        this.AnalysisDailyFaultFrequencyList.forEach((item1)=>{
                            if(item.StatisticDay === item1.StatisticDay){
                                ob[item1.Fault] = item1.Frequency;
                            }
                        });
                        source.push(ob);
                       
                    }
                    
                });
                console.log("Frequency series",series);
                console.log("Frequency dimensions",dimensions);
                console.log("Frequency source",source);
                console.log("Frequency data",data);
            }
            let totalData ={
                source : source,
                dimensions: dimensions,
                data : data,
                series : series
            };
            return totalData;
        },
        DailyEfficiencyTimeChart(){
            let BarSource = [],BarDimensions=[],PieData = [],BarSeries=[];
            let date = [];
            if(this.AnalysisDailyEfficiencyList){
                BarDimensions.push('Efficiency');
                let KSIOnName=this.$t("common.KSIOnTime");
                let TractionTimeName=this.$t("common.TractionTime");
                let LiftingTimeName=this.$t("common.LiftingTime");
                let WorkTimeName=this.$t("common.WorkTime");
                let FreeTimeName=this.$t("common.FreeTime");
                let WorkTimeTotalSec = 0;
                let FreeTimeTotalSec = 0;
                this.AnalysisDailyEfficiencyList.forEach(item => {
                    let KSISec = item.KSIOnTime;
                    let TractionSec = item.TractionTime;
                    let LiftingSec = item.LiftingTime;
                    let WorkTimeSec = item.WorkTime;
                    let FreeTimeSec = item.FreeTime;
                    let Efficiency = item.Efficiency;
                    WorkTimeTotalSec+=WorkTimeSec;
                    FreeTimeTotalSec+=FreeTimeSec;

                    if(!BarDimensions.includes(item.StatisticDay)){
                       //console.log(this.EfficiencyClickDate);
                        if(!BarDimensions.includes(WorkTimeName))
                        {
                                BarDimensions.push(WorkTimeName);
                                let serie = { type: 'bar' };
                                BarSeries.push(serie);
                        }
                        if(!BarDimensions.includes(FreeTimeName))
                        {
                                BarDimensions.push(FreeTimeName);
                                let serie = { type: 'bar' };
                                BarSeries.push(serie);            
                        }                                                      
                    }
                    if(this.EfficiencyClickDate === formatTimeStr(item.StatisticDay,'YYYY-MM-DD')){                           
                                let arrayWorkTime = {
                                        name : WorkTimeName,
                                        value : WorkTimeSec
                                    };
                                PieData.push(arrayWorkTime);
                                let arrayFreeTime = {
                                        name : FreeTimeName,
                                        value : FreeTimeSec
                                    };
                                PieData.push(arrayFreeTime);    
                        }else /*if(this.EfficiencyClickDate !="total")*/
                        {
                                let arrayWorkTime = {
                                        name : WorkTimeName,
                                        value : 0
                                    };
                                    PieData.push(arrayWorkTime);
                                let arrayFreeTime = {
                                        name : FreeTimeName,
                                        value : 0
                                    };
                                    PieData.push(arrayFreeTime);
                        }
                     if(!date.includes(item.StatisticDay)){
                        date.push(item.StatisticDay);
                        let ob = {};
                        
                        ob['Efficiency'] = formatTimeStr(item.StatisticDay,'YYYY-MM-DD');
                        ob[WorkTimeName] = WorkTimeSec;
                        ob[FreeTimeName] = FreeTimeSec;
                        BarSource.push(ob);                        
                     }                     
                });
                /*if(this.EfficiencyClickDate =="total")
                {
                    console.log("111111",this.EfficiencyClickDate);
                    PieData=[]
                            let arrayWorkTime = {
                                    name : WorkTimeName,
                                    value : WorkTimeTotalSec
                                }
                                PieData.push(arrayWorkTime)
                            let arrayFreeTime = {
                                    name : FreeTimeName,
                                    value :FreeTimeTotalSec
                                }
                                PieData.push(arrayFreeTime)
                    console.log("2222222",this.EfficiencyClickDate);
                    console.log("Efficiency PieData 2222222222",PieData);
                }*/
                console.log("Efficiency BarSeries",BarSeries);
                console.log("Efficiency BarDimensions",BarDimensions);
                console.log("Efficiency PieData",PieData);
                console.log("Efficiency BarSource",BarSource);
            }
            let totalData ={
                BarSource : BarSource,
                BarDimensions: BarDimensions,
                PieData : PieData,
                BarSeries : BarSeries
            };
            return totalData;
        },
        carAddress(){
            let list = [];
            if(this.carGeoList.length>0){
                this.carGeoList.forEach((item)=>{
                    let array = [];
                    array.push(item.Longitude);
                    array.push(item.Latitude);
                    list.push(array);
                });
            }
            return list;
        }
    },
    created() {
        let self = this;
        self.getDateRange();
        
        // self.setGeoLine()
        self.getCarDetails().then((val)=>{
            self.GetRDMDeviceTypeDefinition().then((val)=>{
                 self.refreshData();
            });
           
        });
    },
    methods: {
        //获取车辆位置历史轨迹
        getHistoryLocation(){
            let self = this;
            let starttime  = '';
            let endtime = '';
            if(self.daterange&&self.daterange.length>0){
                starttime = formatTimeStr(self.daterange[0]);
                if(self.daterange.length>1){
                    endtime = formatTimeStr(addTime(self.daterange[1],"d",1));
                }
            } 
            let param = {
                device: {
                    deviceType: self.rdmDeviceType.DeviceType,
                    deviceVaries: self.rdmDeviceType.DeviceVaries,
                    deviceSerialNumber: self.carDetails.RDMNO
                },
                startTimeUtc: starttime,
                endTimeUtc: endtime,
            };
            geoApi.queryCarGeoHistory(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    self.carGeoList= res.Data;
                    //console.log("carGeoList",res.Data.length);
                    self.initmap();
                }
            });
        },

        setGeoLine(){
            let self = this;
            let array = self.carAddress;
            self.mapInstance.on("load", function () {
                self.mapInstance.addLayer({
                    id: 'lines',
                    type: 'line',
                    source: {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [{
                                type: 'Feature',
                                properties: {
                                    color: '#F7455D' // red
                                },
                                geometry: {
                                    type: 'LineString',
                                    coordinates: self.carAddress
                                }
                            }]
                        }
                    },
                    paint: {
                    'line-width': 3,
                    // Use a get expression (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-get)
                    // to set the line-color to a feature property value.
                    'line-color': ['get', 'color']
                    }
                });
                
            });
            self.mapInstance.fitBounds(self.carAddress);
            self.mapInstance.setZoom(8);
        },


        //左滑
        scroll_left() {
            let content = document.querySelector(".param-list");
            content.scrollLeft -= 50;
        },
        //右滑
        scroll_right() {
            let content = document.querySelector(".param-list");
            content.scrollLeft += 40;
        },


        getDateRange(){
            let start = this.$utils.formatTimeStr(this.$utils.addTime(this.$utils.nowTimStr(),'d',-7),'YYYY-MM-DD');
            let end = this.$utils.formatTimeStr(this.$utils.nowTimStr(),'YYYY-MM-DD');
            let arry = [];
            arry.push(start);
            arry.push(end);
            this.daterange = arry;
        },

        getCarDetails() {
            let params = {
                companyId: this.companyId,
                machineId: this.machineId,
            };
            return carApi.getCarDetails(params).then(res => {
                if(res.Data) {
                    this.carDetails = res.Data;
                }
            });
        },

        //获取RDM类型信息
        GetRDMDeviceTypeDefinition(){
            let self = this;
            let param = {
                rdmno : self.carDetails.RDMNO
            };
            return carApi.GetRDMDeviceTypeDefinition(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    self.rdmDeviceType = res.Data;
                }
            });
        },

        //获取参数历史数据
        getParamAnalysis(item){
            let self = this;
            self.paramActive = item.ParameterName;
            
            let starttime  = '';
            let endtime = '';
            let starttimeTmp  = '';
            let endtimeTmp = '';
            let groupByStep="1D";
            if(self.daterange&&self.daterange.length>0){
                starttime = formatTimeStr(self.daterange[0]);
                starttimeTmp = formatTimeStr(self.daterange[0]);
                if(self.daterange.length>1){
                    endtimeTmp = formatTimeStr(self.daterange[1]);
                    endtime = formatTimeStr(addTime(self.daterange[1],"d",1));
                }

                if(starttimeTmp==endtimeTmp)
                {
                     groupByStep="1H";
                }
            } 
            
            let analysisParam ={
                device: {
                    deviceType: self.rdmDeviceType.DeviceType,
                    deviceVaries: self.rdmDeviceType.DeviceVaries,
                    deviceSerialNumber: self.carDetails.RDMNO
                },
                canAddress: item.Address,
                nodeId: item.NodeId,
                startTimeUtc: starttime,
                endTimeUtc: endtime,
                groupByStep: groupByStep
            };
            carApi.GetCarParameterMonitor(analysisParam).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    self.paramMonitorList = res.Data.ValueList;
                    if(item.Address=="0x000004")
                    {
                        //CANAddress="0x000004",即为interlocktime，则值已经将s转换成小时
                        item.Unit="Hour";
                    }
                    self.initParamMonitorLine(item.ParameterName,item.Unit,groupByStep);
                }
            });
        },

        initParamMonitorLine(ParameterName,Unit,groupByStep){
            if(this.paramMonitorList){
                let xDataList = [];
                let yDataList = [];
                this.paramMonitorList.forEach((element) => {
                    let value = element.Metrics;
                    if(element.Metrics == null){
                        value = 0;
                    }
                    yDataList.push(value);
                    if(groupByStep=="1H")
                    {
                        xDataList.push(formatTimestampUTC(new Date(element.TimestampUtc),'HH',this.userInfo.TimezoneSetting));
                    }else{
                         xDataList.push(formatTimestampUTC(new Date(element.TimestampUtc),'YYYY-MM-DD',this.userInfo.TimezoneSetting));
                    }
                   
                });
                let doms = this.$refs['analysis-line-chart-box']; 
                let  option = {
                        grid: {
                            left: '3%',
                            right: '3%',
                        },
                        color: ['#13A1C8'],
                        tooltip: {
                            trigger: 'axis',
                            formatter: "<font color='#13A1C8'>&nbsp;●&nbsp;</font>"+'{b} <br>'+ ParameterName +' : {c} '+Unit
                        },
                        xAxis: {
                            type: 'category',
                            data: xDataList
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                data: yDataList,
                                type: 'line',
                                smooth: true,
                                lineStyle: {
                                    width: 4,
                                },
                            }
                        ]
                    },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
        refreshData(){
            let self = this;
            let starttime  = '';
            let endtime = '';
            if(self.daterange&&self.daterange.length>0){
                starttime = self.daterange[0];
                if(self.daterange.length>1){
                    endtime = self.daterange[1];
                }
            } 
            self.faultParam.startDay = starttime;
            self.faultParam.endDay =endtime;
            let array = [];
            array.push(this.carDetails.MachineSN);
            self.faultParam.filterMachineSNList = array;

            self.efficiencyParam.StartTime= starttime;
            self.efficiencyParam.EndTime= endtime;
            self.efficiencyParam.filterMachineSNList= array;

            self.refreshDurationData();
            self.refreshFrequencyData();
            self.refreshEfficiencyData();
            self.getParamAnalysis(this.MonitorParameterList[0]);  
            self.getHistoryLocation();
        },

        //更新时长数据
        refreshDurationData(){
            let self = this;
            let P1 =  self.getAnalysisDailyFaultDuration();
            Promise.all([P1]).then(function (resp) {
                self.initDailyFaultDurationBar();
                self.initDailyFaultDurationPie();
            });
            
        },

        //更新次数数据
        refreshFrequencyData(){
            let self = this;
            let P1 = self.getAnalysisDailyFaultFrequency();
            Promise.all([P1]).then(function (resp) {
                self.initDailyFaultFrequencyBar();
                self.initDailyFaultFrequencyPie();
            });
            
        },

        //更新车辆效率数据
        refreshEfficiencyData(){
            let self = this;
            let P1 = self.getAnalysisDailyEfficiency();
            Promise.all([P1]).then(function (resp) {               
                self.initDailyEfficiencyTimeBar();
                self.initDailyEfficiencyTimePie();
            });
            
        },

        //获取故障按日影响时长
        getAnalysisDailyFaultDuration(){
            return faultApi.AnalysisDailyFaultDuration(this.faultParam).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.AnalysisDailyFaultDurationList = res.Data.AnalysisDailyFaultDurationList;
                    if(res.Data.AnalysisDailyFaultDurationList.length>0){
                        this.DurationClickDate =  formatTimeStr(this.AnalysisDailyFaultDurationList[0].StatisticDay,'YYYY-MM-DD');
                    }
                }
            });
        },


        //获取故障按日影响次数
        getAnalysisDailyFaultFrequency(){
            return faultApi.AnalysisDailyFaultFrequency(this.faultParam).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.AnalysisDailyFaultFrequencyList = res.Data.AnalysisDailyFaultFrequencyList;
                    if(res.Data.AnalysisDailyFaultFrequencyList.length>0){
                        this.FrequencyClickDate =  formatTimeStr(this.AnalysisDailyFaultFrequencyList[0].StatisticDay,'YYYY-MM-DD');
                    }
                }
            });
        },

         //获取车辆效率分析数据
         getAnalysisDailyEfficiency(){
            return efficiencyApi.GetCarEfficienyAnalysis(this.efficiencyParam).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.AnalysisDailyEfficiencyList = res.Data.AnalysisDailyEfficiencyTimeList;
                    if(res.Data.AnalysisDailyEfficiencyTimeList.length>0){
                        this.EfficiencyClickDate =  formatTimeStr(this.AnalysisDailyEfficiencyList[0].StatisticDay,'YYYY-MM-DD');
                        //this.EfficiencyClickDate =  "total"
                    }
                }
            });
        },

        formatPieLegendOrXYSeriesLabel(param,length)
        {
            
            let newParamName="";
            let paramLength=param.length;
            let provideNumber=length;
            let rowNumber=Math.ceil(paramLength/provideNumber);
            if(paramLength>provideNumber)
            {
                for(var i=0;i<rowNumber;i++){  
                    let TmpStr="";
                    let start=i*provideNumber;
                    let end=start+provideNumber;
                    if(i==rowNumber)
                        TmpStr=param.substring(start,paramLength);
                    else
                        TmpStr=param.substring(start,end)+"\n";
                    newParamName+=TmpStr;
                }

            }
             else
                newParamName=param;
            return newParamName;                    
                        
        },
        //故障按天影响次数Bar
        initDailyFaultFrequencyBar(){
            let self = this;
            if(self.AnalysisDailyFaultFrequencyList){
                let doms = self.$refs['bar-chart-box'] ;
                let unit=this.$t("common.Frequency");
                let xSeries=this.$t("common.Day");
                let option = {
                    legend: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (param){
                            let forstr=param[0].name+":</br>";
                            for(var i=0, h=param.length;i<h;i++){   
                                let seriesName=param[i].name;
                                let paramvalue=param[i].value[param[i].seriesName];
                                if(paramvalue==undefined)
                                    paramvalue=0;
                                forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+param[i].seriesName+":"+paramvalue+" "+unit+"</br>";
                            }
                            return forstr;
                        },
                    },
                    dataset: {
                        dimensions: self.DailyFaultFrequencyChart.dimensions,
                        source: self.DailyFaultFrequencyChart.source
                    },
                    xAxis: { type: 'category', name:xSeries,
                        axisLabel:{
                            //rotate:15,
                            formatter:function(param)
                            {
                                return self.formatPieLegendOrXYSeriesLabel(param,10);                                
                            }
                        },
                    },
                    yAxis: {name:unit},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: self.DailyFaultFrequencyChart.series
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
                myCharts.on('click', function (params) {
                    console.log("params.data.Fault======");
                    console.log(params.data.Fault);
                    self.FrequencyClickDate =  params.data.Fault;
                    self.initDailyFaultFrequencyPie();
                });
            }
        },

        //故障按天影响次数Pie
        initDailyFaultFrequencyPie(){
            let self=this;
            if(this.AnalysisDailyFaultFrequencyList){
                let doms = this.$refs['pie-chart-box'];                 
                let unit=this.$t("common.Frequency");
                let  option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: function(a)
                        {
                            
                            return(
                                self.FrequencyClickDate+ ":<br/><font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":"+a.value+" "+unit +" ("+a.percent.toFixed(2) +"%)"
                            );
                        },
                    },
                    legend: {
                        left: 'left',
                        top: 'center',
                        orient: 'vertical',
                        icon: 'circle',
                        formatter:function(param)
                        {
                           return self.formatPieLegendOrXYSeriesLabel(param,10);
                        },
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ["70%", "53%"], 
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: this.DailyFaultFrequencyChart.data
                        }
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },

        //故障按天影响时长Bar
        initDailyFaultDurationBar(){
            let self = this;
            if(self.AnalysisDailyFaultDurationList){
                let doms = self.$refs['bar-chart-dur'] ;
                let unit=this.$t("common.Hour");
                let xSeries=this.$t("common.Day");
                let option = {
                    legend: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (param){
                            //console.log("param",param);
                            //console.log("a",a);
                            let forstr=param[0].name+":</br>";
                            for(var i=0, h=param.length;i<h;i++){   
                                let seriesName=param[i].name;
                                let paramvalue=param[i].value[param[i].seriesName];
                                if(paramvalue==undefined)
                                    paramvalue=0;
                                forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+param[i].seriesName+":"+paramvalue.toFixed(2)+" "+unit+"</br>";
                            }
                            return forstr;
                        },
                    },
                    dataset: {
                        dimensions: self.DailyFaultDurationChart.dimensions,
                        source: self.DailyFaultDurationChart.source
                    },
                    xAxis: { type: 'category',
                            name:xSeries,
                            axisLabel:{
                                    //rotate:15,
                                    interval:0,
                                    formatter:function(param)
                                    {
                                        return self.formatPieLegendOrXYSeriesLabel(param,10);                                
                                    }
                            },
                         },
                    yAxis: {name:unit},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: self.DailyFaultDurationChart.series
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
                myCharts.on('click', function (params) {
                    self.DurationClickDate =  params.data.Fault;
                    self.initDailyFaultDurationPie();
                });
            }
        },

        //故障按天影响时长Pie
        initDailyFaultDurationPie(){
            let self=this;
            if(this.AnalysisDailyFaultDurationList){
                let doms = this.$refs['pie-chart-dur']; 
                let unit=this.$t("common.Hour");
                let  option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: function(a)
                        {
                            //console.log("a",a);
                            return(
                                self.DurationClickDate+ ":<br/><font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":"+a.value.toFixed(2)+" "+unit +" ("+a.percent.toFixed(2) +"%)"
                            );
                        },
                    },
                    legend: {
                        left: 'left',
                        top: 'center',
                        orient: 'vertical',
                        icon: 'circle',
                        formatter:function(param)
                        {
                           return self.formatPieLegendOrXYSeriesLabel(param,10);
                        },
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ["70%", "53%"],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: this.DailyFaultDurationChart.data
                        }
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
        //车辆效率Bar
        initDailyEfficiencyTimeBar(){
            let self = this;
            if(self.AnalysisDailyEfficiencyList){
                let doms = self.$refs['effic-line-chart-box'] ;
                let unit=this.$t("common.Seconds");
                let xSeries=this.$t("common.Day");
                let KSIOnTimeName=this.$t("common.KSIOnTime");
                let KSIOffTimeName=this.$t("common.KSIOffTime");
                let option = {
                    legend: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (param){
                            //console.log("param",param);
                            //console.log("a",a);
                            let forstr=param[0].name+":</br>";
                            let KSIOnTime=0;
                            for(var i=0, h=param.length;i<h;i++){   
                                let seriesName=param[i].name;
                                let paramvalue=param[i].value[param[i].seriesName];                               
                                if(paramvalue==undefined)
                                    paramvalue=0;
                                KSIOnTime+=paramvalue;
                                forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+param[i].seriesName+":"+paramvalue.toFixed(2)+" "+unit+"</br>";
                            }
                            forstr+="<font color="+self.KSIOnTimeColor+">&nbsp;●&nbsp;</font>"+KSIOnTimeName+":"+KSIOnTime.toFixed(2)+" "+unit+" ("+(KSIOnTime.toFixed(2)*100/(24*60*60)).toFixed(2)+"%)"+"</br>";
                            forstr+="<font color="+self.KSIOffTimeColor+">&nbsp;●&nbsp;</font>"+KSIOffTimeName+":"+(24*60*60-KSIOnTime.toFixed(2))+" "+unit+"</br>";
                            return forstr;
                        },
                    },
                    dataset: {
                        dimensions: self.DailyEfficiencyTimeChart.BarDimensions,
                        source: self.DailyEfficiencyTimeChart.BarSource
                    },
                    xAxis: { type: 'category',
                            name:xSeries,
                            axisLabel:{
                                    //rotate:15,
                                    interval:0,
                                    formatter:function(param)
                                    {
                                        return self.formatPieLegendOrXYSeriesLabel(param,10);                                
                                    }
                            },
                         },
                    yAxis: {name:unit},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: self.DailyEfficiencyTimeChart.BarSeries
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
                myCharts.on('click', function (params) {
                    self.EfficiencyClickDate =  params.data.Efficiency;
                    self.initDailyEfficiencyTimePie();
                });
            }
        },
        //车辆效率Pie
        initDailyEfficiencyTimePie(){
            let self=this;
            if(this.AnalysisDailyEfficiencyList){
                let doms = this.$refs['effic-pie-chart-box'];                 
                let unit=this.$t("common.Seconds");
                let  option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: function(a)
                        {
                            
                            return(
                                self.EfficiencyClickDate+ ":<br/><font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":"+a.value+" "+unit +" ("+a.percent.toFixed(2) +"%)"
                            );
                        },
                    },
                    legend: {
                        left: 'left',
                        top: 'center',
                        orient: 'vertical',
                        icon: 'circle',
                        formatter:function(param)
                        {
                           return self.formatPieLegendOrXYSeriesLabel(param,10);
                        },
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ["70%", "53%"], 
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: this.DailyEfficiencyTimeChart.PieData
                        }
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
         //初始化地图
        initmap() {
            let self = this;
            mapboxgl.accessToken =
                "pk.eyJ1IjoiNjI2NjgwMTA4IiwiYSI6ImNrb3RyNjQwdDA1eXMzMW12Z2hvYm5leXIifQ.CegbhRdTZq4v4cRw-aCDdQ"; //这里请换成自己的token
            self.mapInstance = new mapboxgl.Map({
                container: "history-map-box", // container id 绑定的组件的id
                style: "mapbox://styles/mapbox/streets-v11", //地图样式，可以使用官网预定义的样式,也可以自定义
                center: self.defaultMapCenterPoint, // 初始坐标系，这个是南京建邺附近
                zoom: self.defaultMapShowLevel, // starting zoom 地图初始的拉伸比例
                pitch: 0, //地图的角度，不写默认是0，取值是0-60度，一般在3D中使用
                bearing: 0, //地图的初始方向，值是北的逆时针度数，默认是0，即是正北
                antialias: false, //抗锯齿，通过false关闭提升性能
            });
            // Add zoom and rotation controls to the map.
            this.mapInstance.addControl(new mapboxgl.NavigationControl());
            //添加定位的control
            /*this.mapInstance.addControl(
                new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true,
                    },
                    trackUserLocation: true,
                })
            );*/

            //添加language控件
            var languageFlag = sessionStorage.getItem("language");
            if (languageFlag == "ch") {
                languageFlag = "zh-Hans";
            }
            if (mapboxgl.getRTLTextPluginStatus() !== "loaded") {
                mapboxgl.setRTLTextPlugin(
                    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.0/mapbox-gl-rtl-text.js"
                );
            }
            this.mapInstance.addControl(
                new MapboxLanguage({
                    defaultLanguage: languageFlag,
                })
            );
            this.setGeoLine();
            this.addWorldViewControl();
            //添加一个自定义的控件， 用于控制是否显示电子围栏
            this.addControl4ShowGeoFence();
        },
        addWorldViewControl() {
            var self = this;
            var availableWorldviews = [
                {
                code: "CN",
                name: "common.mapcode_China",
                },
                // {
                //   code: 'IN',
                //   name: 'common.mapcode_India'
                // },
                // {
                //   code: 'JP',
                //   name: 'common.mapcode_Japan'
                // },
                //{
                //code: "US",
                //name: "common.mapcode_USA",
                //},
            ];
            //var worldviewOnMapLoad = "US";
            var worldviewOnMapLoad = "CN";
            for (let index = 0; index < availableWorldviews.length; index++) {
                const element = availableWorldviews[index];
                if (element.code == worldviewOnMapLoad) {
                element.checked = true;
                } else {
                element.checked = false;
                }
                element.displayName = self.$t(element.name);
            }
            var MapboxWordViewCtl = new MapboxWordViewControl();
            self.mapInstance.addControl(MapboxWordViewCtl);
            var wordViewConfig = {
                id: "mapInstance",
                title: self.$t("common.mapcode_desc"),
                wordViewArray: availableWorldviews,
            };
            MapboxWordViewCtl.setWordView(wordViewConfig, function (evt) {
                // console.log(evt);
                if (evt.target.value) {
                self.switchWorldview(evt.target.value);
                }
            });

            // Wait for the map to finish loading.
            this.mapInstance.on("load", function () {
                self.filterLayers(self.mapInstance, worldviewOnMapLoad);
            });
        },
        addControl4ShowGeoFence() {
            var self = this;

            var config = { title: self.$t("homepage.showGeoFenceOnMap") };
            //add to map
            var geoFenceCtr = new GeoFenceControl();
            self.mapInstance.addControl(geoFenceCtr);
            geoFenceCtr.setView(config, function (evt) {
                self.flag_showGeoFenceAsMarkerOnMap = evt.target.checked;
                self.showGeoFenceAsMarkerOnMap();
            });
        },
        switchWorldview(worldview) {
            this.filterLayers(this.mapInstance, worldview);
        },

        filterLayers(map, worldview) {
            // The "admin-0-boundary-disputed" layer shows boundaries
            // at this level that are known to be disputed.
            map.setFilter("admin-0-boundary-disputed", [
                "all",
                ["==", ["get", "disputed"], "true"],
                ["==", ["get", "admin_level"], 0],
                ["==", ["get", "maritime"], "false"],
                ["match", ["get", "worldview"], ["all", worldview], true, false],
            ]);
            // The "admin-0-boundary" layer shows all boundaries at
            // this level that are not disputed.
            map.setFilter("admin-0-boundary", [
                "all",
                ["==", ["get", "admin_level"], 0],
                ["==", ["get", "disputed"], "false"],
                ["==", ["get", "maritime"], "false"],
                ["match", ["get", "worldview"], ["all", worldview], true, false],
            ]);
            // The "admin-0-boundary-bg" layer helps features in both
            // "admin-0-boundary" and "admin-0-boundary-disputed" stand
            // out visually.
            map.setFilter("admin-0-boundary-bg", [
                "all",
                ["==", ["get", "admin_level"], 0],
                ["==", ["get", "maritime"], "false"],
                ["match", ["get", "worldview"], ["all", worldview], true, false],
            ]);
        },

        showGeoFenceAsMarkerOnMap() {
            var self = this;

            // //清理geoFence polygon
            for (let index = 0; index < this.geoFencePolygonList.length; index++) {
                const item = this.geoFencePolygonList[index];
                this.mapInstance.removeLayer(item.polygon);
                this.mapInstance.removeSource(item.source);

                var layerId = item.polygon;
                self.mapInstance.off("click", layerId, self.showPopupForLayer);
            }
            this.geoFencePolygonList = [];

            if (self.flag_showGeoFenceAsMarkerOnMap == false) {
                return;
            }

            var param = {
                companyId: this.carDetails.CompanyId,
            };
            if (param.companyId == null) {
                return;
            }
            geoFenceApi
                .getFences(param)
                .then((res) => {
                console.log(res);
                var result = res;
                if (result.IsSuccess) {
                    var geoFenceList = result.Data;
                    for (let index = 0; index < geoFenceList.length; index++) {
                    const geoFence = geoFenceList[index];

                    if (geoFence.GeoPath) {
                        var geoPath = JSON.parse(geoFence.GeoPath);
                        var coordinates = [];
                        for (let index = 0; index < geoPath.length; index++) {
                        const geoPoint = geoPath[index];
                        coordinates.push([geoPoint.Longitude, geoPoint.Latitude]);
                        }
                        var sourceId = "source" + geoFence.FenceId;
                        var layerId = "polygon" + geoFence.FenceId;
                        // Add a data source containing GeoJSON data.
                        self.mapInstance.addSource(sourceId, {
                        type: "geojson",
                        data: {
                            type: "Feature",
                            geometry: {
                            type: "Polygon",
                            // These coordinates outline Maine.
                            coordinates: [coordinates],
                            },
                            properties: {
                            description: geoFence.FenceName,
                            },
                        },
                        });
                        // Add a new layer to visualize the polygon.

                        self.mapInstance.addLayer({
                        id: layerId,
                        type: "fill",
                        source: "source" + geoFence.FenceId, // reference the data source
                        layout: {},
                        paint: {
                            "fill-color":"#ff0000",
                            "fill-opacity": 0.3,
                        },
                        });

                        self.mapInstance.on("click", layerId, self.showPopupForLayer);

                        this.geoFencePolygonList.push({
                        fenceId: geoFence.FenceId,
                        polygon: layerId,
                        source: sourceId,
                        coordinates: coordinates,
                        });
                    }
                    }

                    // zoom the map to the polygon
                    if (this.geoFencePolygonList.length > 0) {
                    self.mapInstance.fitBounds(
                        this.geoFencePolygonList[0].coordinates
                    );
                    }
                }
                })
                .catch(function (error) {
                console.log(error);
                });
        },
    },
    mounted() {
        
    },
};
</script>
<style lang="less" scoped>
.history-data-box {
    text-align: left;
    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }
    .history-data-date {
        margin-bottom: 30px;

        .el-range-editor.el-input__inner {
            border-radius: 25px;
        }
        /deep/ .el-date-editor .el-range__icon {
            margin-left: 3px;
            line-height: 20px;
        }
        /deep/ .el-date-editor .el-range__close-icon {
            line-height: 20px;
        }
        /deep/ .el-date-editor .el-range-separator {
            line-height: 20px;
        }
    }
    .param-box{
        width: 100%;
        display: flex;
        justify-content:space-between;
        .arrow{
            width: 14px;
            height: 26px;
            padding: 0px 0px;
            i{
                font-style: normal;
            }
        }
        .param-list{
            width: 100%;
            display: flex;
            justify-content: flex-start ;
            overflow-x: hidden;
            .param-row{
                margin: 0px 5px;
                padding: 0px 5px;
                min-width: 100px;
                height: 26px;
                background: #f2f2f2;
                border-radius: 2px 2px 0px 0px;
                font-size: 14px;
                text-align: center;
                line-height: 26px;
                color: #666666;
                cursor: pointer;
                &:hover{
                    background: #0079c1;
                    color: #ffffff;
                }
            }
            .active{
                background: #0079c1;
                color: #ffffff;
            }
        }
        
    }
    .history-latest-box {
        width: 100%;
        height: 300px;
    }

    .history-fault-box {
        height: 300px;

        .history-fault-pie-box {
            width: 30%;
            height: 100%;
            float: left;
        }

        .history-fault-bar-box {
            width: 70%;
            height: 100%;
            float: right;
        }
    }

    .history-map-box {
        width: 100%;
        height: 563px;
    }
}
</style>
