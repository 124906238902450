<template>
  <div class="main">
    <div v-if="readOnly">{{ currentCompanyName }}</div>
    <el-dropdown @command="handleCommand" v-if="!readOnly">
      <span class="el-dropdown-link">
        {{ currentCompanyName
        }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="d in companyItems"
          :key="d.CompanyName"
          :command="d"
        >
          {{ d.CompanyName }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
export default {
  name: "CompanyDropdown",
  props: {
    companyItems: { type: Array, default: null },
  },
  data() {
    return {
      currentCompany: null,
    };
  },

  computed: {
    ...mapGetters(["companyEntity"]),
    readOnly() {
      return !this.companyItems || this.companyItems.length <= 1;
    },
    currentCompanyName() {
      if (this.companyEntity) {
        return this.companyEntity.CompanyName;
      }
      if (this.companyItems && this.companyItems.length > 0) {
        return this.companyItems[0].CompanyName;
      }
      return this.$t("common.switchCompany");
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["setCurrentCompany",""]),
    handleCommand(command) {
      this.currentCompany = command;
      this.setCurrentCompany(command);
      console.log("company-switch:", command);
      this.$bus.emit("company-switch", command);
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  cursor: pointer;
}
.el-dropdown-link {
  min-width: 150px;
  font-size: 14px;
  text-align: left;
  color: #333333;
}
</style>