console.log("import MapboxConfig");

window.MapboxConfig = {};

window.MapboxConfig.MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiNjI2NjgwMTA4IiwiYSI6ImNrb3RyNjQwdDA1eXMzMW12Z2hvYm5leXIifQ.CegbhRdTZq4v4cRw-aCDdQ';

window.MapboxConfig.availableWorldviews =
  [
    {
      code: 'CN',
      name: 'common.mapcode_China'
    },
    // {
    //   code: 'IN',
    //   name: 'common.mapcode_India'
    // },
    // {
    //   code: 'JP',
    //   name: 'common.mapcode_Japan'
    // },
    //{
    //  code: 'US',
    //  name: 'common.mapcode_USA'
    //}
  ];
//window.MapboxConfig.defaultWorldView = 'US';
window.MapboxConfig.defaultWorldView = 'CN';


//WorldViewControl
function MapboxWordViewControl() {
  var _map = null;
  var _container = null;
}
MapboxWordViewControl.prototype.onAdd = function (map) {
  this._map = map;
  this._container = document.createElement('div');
  this._container.className = 'mapboxgl-ctrl';

  return this._container;

};
MapboxWordViewControl.prototype.onRemove = function (map) {
  this._container.parentNode.removeChild(this._container);
  this._map = undefined;
};

MapboxWordViewControl.prototype.setWordView = function (wordViewConfig, clickCallback) {

  while (this._container.hasChildNodes()) {
    //当div下还存在子节点时 循环继续
    this._container.removeChild(this._container.firstChild);
  }

  var wordViewArray = wordViewConfig.wordViewArray;

  if (wordViewArray && wordViewArray.length > 0) {

    var ctrlDiv = document.createElement('div');
    ctrlDiv.setAttribute('class', 'mapboxgl-ctrl-group');

    var button = document.createElement('button');
    button.setAttribute('class', 'mapboxgl-ctrl-worldview-button');
    button.setAttribute('type', 'button');
    button.setAttribute('title', wordViewConfig.title);
    button.setAttribute('aria-label', wordViewConfig.title);
    button.setAttribute('aria-pressed', 'false');
    button.name = wordViewConfig.id;
    ctrlDiv.appendChild(button);

    var span = document.createElement('span');
    span.setAttribute('class', 'mapboxgl-ctrl-worldview mapboxgl-ctrl-icon ');
    span.setAttribute('aria-hidden', 'true');
    span.name = wordViewConfig.id;
    button.appendChild(span);


    this._container.appendChild(ctrlDiv);

    var wvDiv = document.createElement('div');
    wvDiv.id = 'mapboxgl-ctrl-worldview-div-' + wordViewConfig.id;
    wvDiv.setAttribute('class', 'mapboxgl-ctrl-worldview-div-hide');
    this._container.appendChild(wvDiv);


    for (let index = 0; index < wordViewArray.length; index++) {
      const element = wordViewArray[index];

      var itemDiv = document.createElement('div');
      var itemInput = document.createElement('input');
      var itemLabel = document.createElement('label');
      itemDiv.appendChild(itemInput);
      itemDiv.appendChild(itemLabel);
      wvDiv.appendChild(itemDiv);

      itemInput.type = 'radio';
      itemInput.id = element.code;
      itemInput.value = element.code;
      itemInput.checked = element.checked;
      itemInput.name = "toggle-worldview";

      itemLabel.innerText = element.displayName;
      itemLabel.setAttribute('for', element.code);

      itemInput.addEventListener('click', function (evt) {
        if (clickCallback) {
          clickCallback(evt);
        }
      });
    }
  }

  button.addEventListener('click', function (evt) {
    var id = evt.target.name;
    var div = document.getElementById("mapboxgl-ctrl-worldview-div-" + id);
    var cls = div.getAttribute('class');
    if (cls == 'mapboxgl-ctrl-worldview-div-show') {
      div.setAttribute('class', 'mapboxgl-ctrl-worldview-div-hide');
    }
    else {
      div.setAttribute('class', 'mapboxgl-ctrl-worldview-div-show');
    }
  });

};

module.exports = MapboxWordViewControl;