
import request from "@/utils/request";

// 我的车辆列表
function getMachineList(data) {
    return request({
      url: "/v2/Car/GetMyCompanyMachineSummary_v2",
      method: "post",
      data
    });
}

// 车辆列表数量统计
function getOneCompany(data) {
  return request({
    url: "/v2/Car/GetOneCompanyMachineSummary_v2",
    method: "post",
    data
  });
}


//获取车辆组group
function getMachineTypeList(data) {
  return request({
    url: "/v2/CarType/GetMachineTypeList",
    method: "post",
    data
  });
}

//获取车辆列表详情，我的车辆使用
function getMyCarDetailList_v2(data) {
  return request({
    url: "/v2/Car/GetMyCarDetailList_v2",
    method: "post",
    data
  });
}

//获取某个公司的车辆summary信息
function getCompanyMachine(data) {
  return request({
    url: "/v2/Car/GetOneCompanyMachineSummary_v2",
    method: "post",
    data
  });
}

//删除某个公司的车辆信息
function deleteMachine(data) {
  return request({
    url: "/v2/Car/DeleteMachine",
    method: "post",
    data
  });
}

//获取rom列表信息
function getRomList(data) {
  return request({
    url: "/v2/RDM/GetRDMList",
    method: "post",
    data
  });
}

//克隆rom列表信息
function cloneMachine(data) {
  return request({
    url: "/v2/Car/CloneMachine",
    method: "post",
    data
  });
}

//获取车辆详细信息
function getCarDetails(data) {
  return request({
    url: "/v2/Car/GetOneCarDetail_v2",
    method: "post",
    data
  });
}

//获取车辆故障信息
function getMyCarActive(data) {
  return request({
    url: "/v2/Car/GetMyCarActiveFaultList_v2",
    method: "post",
    data
  });
}

//获取监控参数
function getMyCarMonitorList(data) {
  return request({
    url: "/v2/Car/GetMyCarMonitorList_v2",
    method: "post",
    data
  });
}

//获取监控参数
function getParameterList(data) {
  return request({
    url: "/v2/SystemConfig/GetParameterUnitDefinitionList",
    method: "post",
    data
  });
}

//判断设备是否被其他使用
function getMachineLockStatus(data) {
  return request({
    url: "/v2/Car/GetMachineLockStatus",
    method: "post",
    data
  });
}
  
//获取菜单参数
function getMenuAndParameter(data) {
  return request({
    url: "/v2/CarDevices/GetDeviceChildrenMenuAndParameterList",
    method: "post",
    data
  });
}

//获取近半小时电压
function GetOneCarVoltageMonitor(data) {
  return request({
    url: "/v2/Car/GetOneCarVoltageMonitor",
    method: "post",
    data
  });
}

//通知设备开始上传参数
function startUploadData(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x08_CommandToDoPFUForController",
    method: "post",
    data
  });
}

//停止设备开始上传参数
function stopUploadData(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x0A_CommandToStop",
    method: "post",
    data
  });
}

//实时上传参数
function getDeviceParameterValueList(data) {
  return request({
    url: "/v2/CarDevices/GetDeviceParameterValueList",
    method: "post",
    data
  });
}


//提交上传参数
function submitData(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x0B_CommandToDoUpdateParameterValueForController",
    method: "post",
    data
  });
}

//車輛控制操作列表
function queryEvents(data) {
  return request({
    url: window.GeoFenceAPI + "/fence/queryEvents",
    method: "post",
    data
  });
}

//车辆锁定解锁状态
function lockStatus(data) {
  return request({
    url: "/v2/CarControl/Lock",
    method: "post",
    data
  });
}

//车辆限速
function limitedSpeed(data) {
  return request({
    url: "/v2/CarControl/LimitedSpeed",
    method: "post",
    data
  });
}

//车辆远程操作开启和关闭
function changeRemoteStatus(data) {
  return request({
    url: "/v2/CarControl/ChangeRemoteStatus",
    method: "post",
    data
  });
}

//车辆起升功能开启和关闭
function changeRaiseUpStatus(data) {
  return request({
    url: "/v2/CarControl/ChangeRaiseUpStatus",
    method: "post",
    data
  });
}

//重置远程状态，所有状态都恢复到初始状态。RemoteStatus=开启，LimitLevel=unlimit，LocksStatus=unlock，RaiseUpStatus=开启。
function resetRemoteStatus(data) {
  return request({
    url: "/v2/CarControl/ResetRemoteStatus",
    method: "post",
    data
  });
}
//upgrade模块下
//列表
function getUpgradeMachineTypeList(data) {
  return request({
    url: "/v2/Car/GetAvliableUpgradeMachineTypeList",
    method: "post",
    data
  });
}

//添加
function addMachineUpgradeData(data) {
  return request({
    url: "/v2/Car/MachineUpgradeFromExistedForm",
    method: "post",
    data
  });
}


//二次验证
function SecondPasswordCheckForModify(data) {
  return request({
    url: "/v2/Login/SecondPasswordCheckForModify",
    method: "post",
    data
  });
}

//安全验证
function CommandToGetSCUStatus(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x15_CommandToGetSCUStatus",
    method: "post",
    data
  });
}

//发布车型时检测车型名称是否已经使用
function CheckIfMachineTypeNameDuplicate(data){
  return request({
    url: "/v2/CarType/CheckIfMachineTypeNameDuplicate",
    method: "post",
    data
  });
}

//发布车型
function PublishMachineType(data) {
  return request({
    url: "/v2/CarType/PublishMachineType",
    method: "post",
    data
  });
}

//车辆组level -- 我的车辆 <br>选择条件-车辆组
function GetMachineGroupLevelByAuthUserAndCompany(data){
  return request({
    url: "/v2/MachineGroup/GetMachineGroupLevelByAuthUserAndCompany",
    method: "post",
    data
  });
}

//获取RDM的类型定义信息
function GetRDMDeviceTypeDefinition(data){
  return request({
    url: "/v2/RDM/GetRDMDeviceTypeDefinition",
    method: "post",
    data
  });
}

//获取历史监控数据
function GetCarParameterMonitor(data){
  return request({
    url: "/v2/ParameterMonitor/GetCarParameterMonitor",
    method: "post",
    data
  });
}

//获取车辆的控制器软件版本
function getMachineControlllerSoftwareVersion(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x0C_CommandToGetDeviceSoftwareVersionForMachineController",
    method: "post",
    data
  });
}


//导出车辆
function DownLoadCompanyCarsDetail(data){
  return request({
    url: "v2/Car/DownLoadCompanyCarsDetail",
    method: "post",
    data
  });
}

export default {
  getMachineList, 
  getMachineTypeList,
  getMyCarDetailList_v2,
  getCompanyMachine,
  deleteMachine,
  getRomList,
  cloneMachine,
  getOneCompany,
  getCarDetails,
  getMyCarActive,
  getMyCarMonitorList,
  getParameterList,
  getMachineLockStatus,
  getMenuAndParameter,
  GetOneCarVoltageMonitor,
  startUploadData,
  stopUploadData,
  getDeviceParameterValueList,
  submitData,
  queryEvents,
  lockStatus,
  limitedSpeed,
  changeRemoteStatus,
  changeRaiseUpStatus,
  resetRemoteStatus,
  getUpgradeMachineTypeList,
  addMachineUpgradeData,
  SecondPasswordCheckForModify,
  CommandToGetSCUStatus,
  CheckIfMachineTypeNameDuplicate,
  PublishMachineType,
  GetMachineGroupLevelByAuthUserAndCompany,
  GetRDMDeviceTypeDefinition,
  GetCarParameterMonitor,
  getMachineControlllerSoftwareVersion,
  DownLoadCompanyCarsDetail
};