<!--
 * @Description: VehicleList
 * @Author: dtc
 * @Date: 21/12/01
-->
<template>
    <div class="company-detail-box" style="min-width: 780px;">
        <div class="row1" :span="12">
            <div class="row1-header-box" :span="12">
                <div class="row1-header-crumbs" :span="6">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/CompanyList' }" v-if="userInfo.CompanyId == 0"
                        >{{ $t("myvehicle.company") }}</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{carDetails.CompanyName}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="row1-header-btn" :span="6">                  
                    <span @click="addMachine" v-if="privilege.Add">{{ $t("myvehicle.addMachine") }}</span>
                    <span @click="DownLoadCompanyCarsDetail" >{{ $t("myvehicle.exportMachine") }}</span>
                    <span @click="doOpenMachineGroup" v-if="privilege.MachineGroupManage">{{ $t("myvehicle.groupManage") }}</span>
                    <span @click="returnList" v-if="userInfo.CompanyId == 0">{{ $t("myvehicle.backList") }}</span>
                </div>
            </div>
        </div>
        <div class="row1" :span="12">
            <div class="row1-body-box" :span="12">
                <div class="online-state" :span="4">
                    <div class="carMain">
                        <div class="carMainTop">
                            <div class="carOnlineCount">
                                <span :title="$t('common.CarOnlineCount')">
                                {{ carDetails.MachineOnlineCount }}
                                </span>
                            </div>
                            /
                            <div class="carOfflineCount" >
                                <span :title="$t('common.CarOfflineCount')">
                                {{ carDetails.MachineOfflineCount }}
                                </span>
                            </div>
                            /
                            <div class="carCount" >
                                <span :title="$t('common.CarTotalCount')">
                                {{ carDetails.MachineCount }}
                                </span>
                            </div>
                        </div>
                        <div class="carMainBottom">
                            <div class="imgLogo">
                                <img src="../../assets/image/icon-car.png" />
                            </div>
                            <span class="carMainText">{{ $t("myvehicle.vehicleInformation") }}</span>
                        </div>
                    </div>
                    <div class="progress">
                        <el-progress-ext
                            type="semicircle"
                            :width="102"
                            :percentage="calculatePercent(carDetails.MachineOnlineCount, carDetails.MachineCount)"
                            :color="progressColorblue"
                            :backgroundColor="progressBackgroundColor"
                            ></el-progress-ext>
                    </div>
                </div>
                <div class="online-state row1-body-border" :span="4">
                    <div class="carMain">
                        <div class="carMainTop">
                            <div class="onlineCount">
                                <span :title="$t('common.RDMOnlineCount')">
                                {{ carDetails.MachineRDMOnlineCount }}
                                </span>
                            </div>
                            /
                            <div class="offlineCount">
                                <span :title="$t('common.RDMOfflineCount')">
                                {{ carDetails.MachineRDMOfflineCount }}
                                </span>
                            </div>
                            /
                            <div class="carCount">
                                <span :title="$t('common.CarTotalCount')">
                                {{ carDetails.MachineCount}}
                                </span>
                            </div>
                        </div>
                        <div class="carMainBottom">
                            <div class="imgLogo">
                                <img src="../../assets/image/rdm.png" />
                            </div>
                            <span class="carMainText">{{ $t("myvehicle.rdmStatus") }}</span>
                        </div>
                    </div>
                    <div class="progress">
                        <el-progress-ext
                            type="semicircle"
                            :width="102"
                            :percentage="calculatePercent(carDetails.MachineRDMOnlineCount, carDetails.MachineCount)"
                            :color="progressColorgreen"
                            :backgroundColor="progressBackgroundColor"
                            ></el-progress-ext>
                    </div>
                </div>
                <div class="online-state" :span="4">
                    <div class="carMain">
                        <div class="carMainTop">
                            <div class="onlineCount">
                                <span :title="$t('common.KSIOnlineCount')">
                                {{ carDetails.MachineKSIOnlineCount }}
                                </span>
                            </div>
                            /
                            <div class="offlineCount">
                                <span :title="$t('common.KSIOfflineCount')">
                                {{ carDetails.MachineKSIOfflineCount }}
                                </span>
                            </div>
                            /
                            <div class="carCount">
                                <span :title="$t('common.CarTotalCount')">
                                {{ carDetails.MachineCount }}
                                </span>
                            </div>
                        </div>
                        <div class="carMainBottom">
                            <div class="imgLogo">
                                <img src="../../assets/image/rdm.png" />
                            </div>
                            <span class="carMainText">{{ $t("myvehicle.ksiStatus") }}</span>
                        </div>
                    </div>
                    <div class="progress">
                        <el-progress-ext
                            type="semicircle"
                            :width="102"
                            :percentage="calculatePercent(carDetails.MachineKSIOnlineCount, carDetails.MachineCount)"
                            :color="progressColorgreen"
                            :backgroundColor="progressBackgroundColor"
                            ></el-progress-ext>
                    </div>
                </div>
            </div>
        </div>
        <div class="row2">
            <span class="row-label-box">{{ $t("myvehicle.chooseGroup") }}:</span>
             <el-cascader
                v-model="machineGroupId"
                :placeholder="$t('common.pleaseChoose')"
                :options="groupList"
                :props="cateProps"
                @change="getMyCarDetailList_v2"
                filterable
                :show-all-levels="false"
                clearable
                >
            </el-cascader>

        </div>
        <div class="row3">
            <div class="row3-header-label">
               {{ $t("myvehicle.machineList") }}:
            </div>
            <div class="row3-body-box">
                <div class="row3-body-search">
                    <div class="row3-body-search-btn">
                        <span @click="deleteData(2)" v-if="privilege.Delete">
                            <i class="el-icon-delete"></i>{{ $t("addMachine.Delete") }}
                        </span>
                        <!--目前未实现-->
                        <!--span v-if="privilege.BatchDelete">
                            <i class="el-icon-edit-outline" ></i>{{ $t("myvehicle.batchEdit") }}
                        </span v-if="privilege.BatchUpgrade">
                        <span>
                            <i class="el-icon-refrigerator"></i>{{ $t("myvehicle.batchUpgradeSoftware") }}
                        </span-->
                        <span @click="openMachineType()" v-if="privilege.PublishMachineType">
                            <i class="el-icon-position"></i>{{ $t("myvehicle.releaseModel") }}
                        </span>
                    </div>
                    <div class="row3-body-search-input">
                        <el-input
                            :placeholder="$t('addMachine.MachineSN')"
                            suffix-icon="el-icon-search"
                            @change="getMyCarDetailList_v2"
                            v-model="search"
                        >
                        </el-input>
                    </div>
                </div>
                <div class="row3-body-table">
                    <div class="row3-body-table-box">
                        <el-table :data="tableData" :row-class-name="vehicleTableRowClassName"
                         style="width: 100%;" max-height="700" 
                        @selection-change="handleSelectionChange" @filter-change="filterChange"
                         :row-style="rowClass" >
                            <el-table-column
                                type="selection"
                                align="center"
                                width="55">
                            </el-table-column>
                            <el-table-column
                                type="index"
                                align="center"
                                :label="$t('common.SEQ')"
                                width="50"
                                :index="indexMethod"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="MachineSN"
                                align="center"
                                :label="$t('addMachine.MachineSN')"
                                width="200">
                            </el-table-column>                            
                            <el-table-column
                                prop="RDMNO"
                                :label="$t('addMachine.RDMNO')"
                                width="150">
                                <template slot-scope="scope">
                                    <i class="qrcode" @click="openRDMDetails(scope)" :title="$t('RDMManage.RDM_Detail')"  v-if="privilege.GrantRDMFunction">{{scope.row.RDMNO}}</i>
                                    <span v-else>{{scope.row.RDMNO}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="TypeName"
                                align="center"
                                :label="$t('myvehicle.machineType')"
                                column-key="TypeName"
                                :filter-multiple="false"
                                :filters="machineTypeList"
                                width="300">
                            </el-table-column>
                            <el-table-column
                                prop="CarBundleStatus"
                                align="center"
                                :label="$t('myvehicle.machineStatus')"
                                column-key="CarBundleStatus"
                                :filter-multiple="false"
                                :filters="rowStatusList"
                                 >
                                <template slot-scope="scope">
                                    <el-tag  :type="getBindtatus(scope.row.CarBundleStatus)"
                                    >{{checkExistData(rowStatusList,scope.row.CarBundleStatus, 'value').text}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="CarRDMStatus"
                            align="center"
                            :label="$t('myvehicle.rdmStatus')"
                            column-key="CarRDMStatus"
                            :filter-multiple="false"
                            :filters="rowStatusList"
                             >
                                <template slot-scope="scope">
                                    <el-tag  :type="getBindtatus(scope.row.CarRDMStatus)"
                                    >{{checkExistData(rowStatusList,scope.row.CarRDMStatus, 'value').text}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="CarKSIStatus"
                                align="center"
                                :label="$t('myvehicle.ksiStatus')"
                                column-key="CarKSIStatus"
                                :filter-multiple="false"
                                :filters="ksiStatusList"
                                 >
                                <template slot-scope="scope">
                                     <el-tag :type="getBindtatus(scope.row.CarRDMStatus,1)" v-if="!scope.row.CarRDMStatus"
                                    >--</el-tag>
                                    <el-tag :type="getBindtatus(scope.row.CarKSIStatus)" v-else
                                    >{{checkExistData(ksiStatusList,scope.row.CarKSIStatus, 'value').text}}</el-tag>
                                    <!--span v-if="!scope.row.CarRDMStatus" class="ksiComment"> {{ $t("myvehicle.KSIStatusComment") }}</span-->
                                </template>
                            </el-table-column>
                            
                            <el-table-column
                                :label="$t('common.operation')"
                                align="center"
                                fixed="right"
                                width="250">
                                <template slot-scope="scope">
                                    <div class="row3-table-operation">
                                        <i v-if="privilege.Edit" class="el-icon-edit" @click="editMachine(scope.row)" :title="$t('common.edit')"></i>                                       
                                        <i class="el-icon-document-remove" @click="openDetails(scope)" :title="$t('common.vehicleDetail')"></i>
                                        <i v-if="privilege.Clone" class="el-icon-document-copy" @click="clone(scope)" :title="$t('common.clone')"></i>
                                        <i v-if="privilege.Delete" class="el-icon-delete" @click="deleteData(1,scope.row.MachineId)" :title="$t('common.delete')"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="pagination">
                        <div
                            v-if="carSearch.total > userInfo.PageSize"
                            class="paginationCount"
                        >
                            {{ (carSearch.page - 1) * userInfo.PageSize + 1 }}-{{
                            carSearch.page * userInfo.PageSize
                            }}
                            {{ $t("common.Page_of") }} {{ carSearch.total }} {{ $t("common.Pages") }}
                        </div>
                        <div class="paginationDiv">
                            <el-pagination
                            background
                            :page-size="userInfo.PageSize"
                            @current-change="handleCurrentChange"
                            :hide-on-single-page="true"
                            layout="prev, pager, next"
                            :total="carSearch.total"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Clone v-if="cloneConfig" @close="cloneConfig = false" @submit="submit" :editData="editData" :loading="loading"></Clone>
        <el-dialog
          :title="$t('myvehicle.createMachineType')"
          :visible.sync="machineTypeShow"
          width="950px"  class="el-dialog-custom"
        >
            <el-form ref="machineTypeForm" :model="machineTypeForm" label-width="230px" :rules="rules" v-loading="typeLoading" :element-loading-text="loadingText">
                <el-form-item
                    :label="$t('myvehicle.machineType_Name')+':'"
                    prop="TypeName"
                >
                    <el-input v-model="machineTypeForm.TypeName" 
                    :placeholder="$t('myvehicle.machineType_Name')" 
                    class="from-input"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('myvehicle.TypeQRCode')+':'"
                    prop="QRCode"
                >
                    <vue-qr  :text="QRCode" :size="200" v-if="QRCode != ''"></vue-qr>
                </el-form-item>
                <el-form-item
                    :label="$t('addMachine.remark')+':'"
                    prop="Remark"
                >
                    <el-input type="textarea"
                    :rows="5"
                     v-model="machineTypeForm.Remark" :placeholder="$t('bizConfig.RemarkInformation')"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('addMachine.picture') + ':'"
                    prop="picture"
                    >
                        <el-upload
                        action="#"
                        ref="picture"
                        :class="[machineTypeForm.ImageFile.length > 0 ? 'hide' : '']"
                        list-type="picture-card"
                        :file-list="machineTypeForm.ImageFile"
                        :auto-upload="false"
                        :on-change="handleChangePicture"
                        :on-remove="handleRemovePicture"
                        :on-preview="handlePreviewPicture"
                        accept=".jpg, .jpeg, .png, .gif"
                        >
                        <i
                            :class="isUploading ? 'el-icon-loading' : 'el-icon-plus'"
                        ></i>
                        <div class="upload">
                            {{ isUploading ? "Uploading" : "Upload" }}
                        </div>
                        </el-upload>
                    
                </el-form-item>
                 <el-dialog
                :title="$t('addMachine.uploadPicture')"
                width="508px"
                :visible.sync="dialogVisible"
                append-to-body
              >
                <el-upload
                  class="Rebulid"
                  action="#"
                  multiple
                  :file-list="cropPictureList"
                  :auto-upload="false"
                  :on-change="handleRebuildPicture"
                  :on-remove="handleRemovePicture"
                  accept=".jpg, .jpeg, .png, .gif"
                >
                  <el-button>{{ $t("addMachine.Rebulid") }}</el-button>
                  <div class="fileName" v-if="machineTypeForm.ImageFile.length > 0">
                    {{ $t("addMachine.support") }}
                  </div>
                </el-upload>
                <div class="cropper-content">
                  <div class="cropper-box">
                    <div class="cropper">
                      <!-- 裁剪图片组件 -->
                      <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.outputSize"
                        :outputType="option.outputType"
                        :info="option.info"
                        :canScale="option.canScale"
                        :autoCrop="option.autoCrop"
                        :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight"
                        :fixed="option.fixed"
                        :fixedNumber="option.fixedNumber"
                        :full="option.full"
                        :fixedBox="option.fixedBox"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :centerBox="option.centerBox"
                        :height="option.height"
                        :infoTrue="option.infoTrue"
                        :maxImgSize="option.maxImgSize"
                        :enlarge="option.enlarge"
                        :mode="option.mode"
                        @realTime="realTime"
                      >
                      </vue-cropper>
                    </div>
                  </div>
                  <!--预览效果图-->
                  <div class="show-preview">
                    <div class="previewsTitle">
                      {{ $t("addMachine.Preview") }}
                    </div>
                    <div class="preview">
                      <img :src="url" style="height: 100px; width: 100px" />
                    </div>
                  </div>
                </div>
                <div class="tip">
                  {{ $t("addMachine.tip") }}
                </div>
                <div class="footerButton">
                  <el-button @click="dialogVisible = false">{{
                    $t("common.cancel")
                  }}</el-button>
                  <el-button @click="cropPicture" type="primary">{{
                    $t("addMachine.Submit")
                  }}</el-button>
                </div>
              </el-dialog>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogCancel" :disabled="typeDisabled">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="createMachineType" :disabled="typeDisabled">{{$t('addMachine.Submit')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import carApi from "@/api/autogen/v2/carList";
import ElProgressExt from "@/components/ui/ElProgressExt.vue";
import Clone from "./Clone.vue";
import { checkExistData,ifGrantedTheOperationForThisFunction,ifGrantedTheOperationForRDMManageFunction } from '@/utils';
import { ConstDefinition } from "@/utils/constDefinition";
import vueQr from 'vue-qr';
import deviceApi from "@/api/Device";
export default {
    inject:['openMachineGroup'],
    data() {
        var checkTypeName =  (rule, value, callback) => {
            let data={
                typeName:value,
                companyId: this.id,
                Id:-1
            };
            carApi.CheckIfMachineTypeNameDuplicate(data).then(res=>{
            if(res.IsSuccess&&!res.Data){
                callback();
            }else {
                callback(
                    new Error(
                        this.$t("myvehicle.TypeNameExited")
                    )
                );

            }
            });
        };
        return {
            id: '',
            carDetails: {
            },
            //裁剪图片对象
            option: {
                img: "", //裁剪图片的地址
                outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
                outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
                info: true, //图片大小信息
                canScale: true, //图片是否允许滚轮缩放
                autoCrop: true, //是否默认生成截图框
                autoCropWidth: 105, //默认生成截图框宽度
                autoCropHeight: 145, //默认生成截图框高度
                fixed: true, //是否开启截图框宽高固定比例
                fixedBox: false, // 固定截图框大小 不允许改变
                fixedNumber: [1.05, 1.45], //截图框的宽高比例
                canMove: true, //上传图片是否可以移动
                canMoveBox: true, //截图框能否拖动
                original: false, //上传图片按照原始比例渲染
                centerBox: true, //截图框是否被限制在图片里面
                height: true, //是否按照设备的dpr 输出等比例图片
                infoTrue: true, //true为展示真实输出图片宽高，false展示看到的截图框宽高
                maxImgSize: 3000, //限制图片最大宽度和高度
                enlarge: 1, //图片根据截图框输出比例倍数
                full: true, // 输出原图比例截图 props名full
                // mode: '290px 290px'  //图片默认渲染方式
            },
            //实时预览的路径
            url: "",
            //是否上传中
            isUploading: false,
            //group下拉选项
            chooseGroupOptions: [],
            //进度颜色
            progressColor: "#0079C1",
             //进度颜色
            progressColorblue: "#0079C1",
            //进度颜色
            progressColorgreen: "#00B194",
            progressBackgroundColor: "#04223A",
            //列表查询条件
            search: '',
            tableData: [],
            //类型下拉选项
            machineTypeList: [],
            // row状态
            rowStatusList: [
                {
                    text: this.$t("common.carState_offline"),
                    value: ConstDefinition.CarStatus.Offline,
                },
                {
                    text: this.$t("common.carState_online"),
                    value: ConstDefinition.CarStatus.Online,
                },
                {
                    text: this.$t("common.carState_fault"),
                    value: ConstDefinition.CarStatus.Error,
                },
            ],
            // KSI状态
            ksiStatusList: [
                {
                    text: this.$t("common.carKSIState_off"),
                    value: ConstDefinition.KSIStatus.ElectricOff,
                },
                {
                    text: this.$t("common.carKSIState_on"),
                    value: ConstDefinition.KSIStatus.ElectricOn,
                },
            ],
            carSearch: {
                page: 1,
                total: 0,
            },
            cloneConfig: false,
            muitipleData: [],
            selectRow: [],
            editData: {},
            loading: false,
            carRDMStatus: -1,
            carKSIStatus: -1,
            typeId: 0,
            typeVersion: 0,
            machineTypeShow:false,
            cropPictureList: [],
            machineTypeForm:{
                MachineId : null,
                TypeName:'',
                Remark:'',
                ImageFile:[],
            },
            dialogVisible:false,
            rules: {
                TypeName: [
                    {
                        required: true,
                        message:
                        this.$t("myvehicle.machineType_Name") + this.$t("common.required"),
                        trigger: "blur",
                    },
                    {
                        validator: checkTypeName,
                        trigger:'blur'
                    },
                ],
            },
            typeLoading : false,
            typeDisabled :false,
            loadingText: this.$t("myvehicle.loading_publishtype"),
            groupList:[],
            cateProps:{
                multiple: true,
                expandTrigger: 'click',
                label:'MachineGroupName',
                value:'MachineGroupId',
                children:'children',
                emitPath:false,
                checkStrictly :true
            },
            machineGroupId:[],
            privilege: {
                View: false,
                Add: false,
                Delete: false,
                Edit: false,
                PublishMachineType: false,
                MachineGroupManage: false,                
                Clone:false,
                BatchDelete:false,
                BatchUpgrade:false,
                GrantRDMFunction:false,
            }
        };
    },
    components: {
        ElProgressExt,
        Clone,
        vueQr
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
        QRCode(){
            let code = '';
            if(this.machineTypeForm.TypeName.length>0){
                code = this.id + '_' + this.machineTypeForm.TypeName; 
            }
            return  code;
        },
    },
    created() {
        this.initData();
        this.initialFunctionOperationPrivilege();
    },
    watch: {
        muitipleData(data) {  //存储选中的row
            this.selectRow = [];
            if (data.length > 0) {
                data.forEach((item, index) => {
                    this.selectRow.push(this.tableData.indexOf(item));
                });
            }
        }
    },
    methods: {
        ifGrantedTheOperationForThisFunction,
        ifGrantedTheOperationForRDMManageFunction,
        initialFunctionOperationPrivilege()
        {
            this.privilege.View = ifGrantedTheOperationForThisFunction("View");
            this.privilege.Delete = ifGrantedTheOperationForThisFunction("Delete");
            this.privilege.Add = ifGrantedTheOperationForThisFunction("Add");
            this.privilege.Edit = ifGrantedTheOperationForThisFunction("Edit");
            this.privilege.PublishMachineType = ifGrantedTheOperationForThisFunction("PublishMachineType");
            this.privilege.MachineGroupManage = ifGrantedTheOperationForThisFunction("MachineGroupManage");
            this.privilege.Clone = ifGrantedTheOperationForThisFunction("Clone");
            this.privilege.BatchDelete = ifGrantedTheOperationForThisFunction("BatchDelete");
            this.privilege.BatchUpgrade = ifGrantedTheOperationForThisFunction("BatchUpgrade");
            this.privilege.GrantRDMFunction = ifGrantedTheOperationForRDMManageFunction("View");
            console.log("this.privilege.GrantRDMFunction==");
            console.log(this.privilege.GrantRDMFunction);
        },
        //实时预览函数
        realTime() {
            var self = this;
            this.$refs.cropper.getCropData(async (data) => {
                self.url = data;
            });
        },
        //打开图片剪切弹窗
        handlePreviewPicture(file) {
            this.dialogVisible = true;
        },
        //选择图片
        handleChangePicture(file, fileList) {
            if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
                this.$message({
                message: this.$t('common.PictureRequire'),
                type: "error",
                });
                this.machineTypeForm.ImageFile = [];
            } else {
                this.machineTypeForm.ImageFile = [];
                this.isUploading = true;
                setTimeout(() => {
                console.log(fileList);
                fileList = [file];
                var img = URL.createObjectURL(file.raw); // 获取URL
                this.machineTypeForm.ImageFile = fileList;
                this.cropPictureList = fileList;
                this.option.img = img;
                this.isUploading = false;
                }, 1000);
            }
        },
        //重新选择图片
        handleRebuildPicture(file, fileList) {
            if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
                this.$message({
                message: this.$t('common.PictureRequire'),
                type: "error",
                });
            } else {
                this.machineTypeForm.ImageFile = [];
                this.machineTypeForm.ImageFile[0] = file;
                let fileName = file.name;
                let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
                if (regex.test(fileName.toLowerCase())) {
                var img = URL.createObjectURL(file.raw); // 获取URL
                this.option.img = img;
                this.machineTypeForm.ImageFile[0].url = img;
                this.cropPictureList = [file];
                } else {
                this.$message.error(this.$t('common.ToSelectImage'));
                }
            }
        },
        //剪切图片
        cropPicture() {
            var self = this;
            //获取截图的blob数据
            this.$refs.cropper.getCropData(async (data) => {
                var file = this.dataURLtoFile(data, self.machineTypeForm.ImageFile[0].name);
                file.uid = self.machineTypeForm.ImageFile[0].uid;
                self.machineTypeForm.ImageFile[0].raw = file;
                self.machineTypeForm.ImageFile[0].url = data;
                self.machineTypeForm.ImageFile[0].size = file.size;
                self.dialogVisible = false;
            });
        },
        //删除图片
        handleRemovePicture() {
            setTimeout(() => {
                this.machineTypeForm.ImageFile = [];
                this.cropPictureList = [];
            }, 1000);
        },

        //打开发布车型页面
        openMachineType(){
            let array = [];
            array = this.muitipleData.map(item => {
                this.machineTypeForm.MachineId = item.MachineId;
                return item.MachineId;
            });
            
            if(array.length <= 0) {
                const Url = require("@/assets/image/warning1.5x.png");
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("common.selectMachine")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }else if(array.length > 1){
                const Url = require("@/assets/image/warning1.5x.png");
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.selectOneMachine")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }
            this.machineTypeForm ={
                ...this.machineTypeForm,
                TypeName:'',
                Remark:'',
                ImageFile:[],
            };
            this.machineTypeShow =true;
        },

        dialogCancel(){
            this.machineTypeShow =false;
        },


        //发布车型
        createMachineType(){
            this.$refs["machineTypeForm"].validate((valid) => {
                if (valid) {
                    this.typeLoading =true;
                    this.typeDisabled = true;
                    let fd = new FormData();
                    fd.append("MachineId", this.machineTypeForm.MachineId);
                    fd.append("TypeName", this.machineTypeForm.TypeName);
                    fd.append("QRCode", this.QRCode);
                    if(this.machineTypeForm.ImageFile.length>0){
                        fd.append("ImageFile", this.machineTypeForm.ImageFile[0].raw);
                    }
                    fd.append("Remark", this.machineTypeForm.Remark);
                    carApi.PublishMachineType(fd).then(res=>{
                        let content = '';
                        let Url = '';
                        if(res.IsSuccess && res.Data){
                            Url = require("@/assets/image/success.png");
                            content= this.$t("common.operationSuccess");
                        }else{
                            Url = require("@/assets/image/error_76.png");
                            content= this.$t("common.operationFailure");

                        }
                        this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: this.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        this.typeLoading =false;
                        this.typeDisabled = false;
                        this.machineTypeShow =false;
                        this.getMachineTypeList();
                    }).catch((e) => {
                        let Url = require("@/assets/image/error_76.png");
                        let content= this.$t("common.operationFailure");
                        this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: this.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        this.typeLoading =false;
                        this.typeDisabled = false;
                        this.machineTypeShow =false;
                        console.log(e);
                    });
                }
            });
        },
        /**
         * @desc 初始化页面获取id
         */
        initData() {
            let { params, } = this.$route;

            if(params.companyId) {
                this.id = params.companyId;
                this.refreshPage();
            } else {
                this.$router.go(-1);
                return; 
            }
        },

        //分页序号连续
        indexMethod(index) {
            index = (index + 1) + (this.carSearch.page - 1) * this.userInfo.PageSize;
            return index;
        },
        /**
         * @desc 渲染整个页面
         */
        refreshPage() {

            this.getCompanyMachine();
            this.getMachineTypeList();
            this.getMyCarDetailList_v2();
            this.GetMachineGroupLevelByAuthUserAndCompany();
        },

        //获取车辆组
        GetMachineGroupLevelByAuthUserAndCompany(){
            let param = {
                companyId : this.id
            };
            carApi.GetMachineGroupLevelByAuthUserAndCompany(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data&&res.Data.children){
                     this.groupList = res.Data.children;
                }
            });
        },

        /**
         * @desc 初始化接口参数
         */
        getQueryData(type = false) {
            let params = {
                companyId: this.id,
            };
            let machineGroupId = '';
            if(this.machineGroupId.length>0){
                machineGroupId = this.machineGroupId.join(",");
            }else{
                machineGroupId = 0-this.id;
            }

            if(type) {
                params = {
                    ...params,
                    pageSize: this.userInfo.PageSize,
                    pageIndex: this.carSearch.page,
                    // orderBy: [
                    //     {
                    //         name: this.search,
                    //         orderBy: 0,
                    //     }
                    // ],
                    machineGroupId: machineGroupId,
                    machineSN: this.search,
                    typeId: this.typeId,
                    typeVersion: this.typeVersion,
                    carRDMStatus: this.carRDMStatus,
                    carKSIStatus: this.carKSIStatus,
                };
                //为true时查询列表数据
            }

            return params;
        },
        handleSelectionChange(val) {
            this.muitipleData = val;
        },

        //选中行变色
        rowClass({ row, rowIndex }) {
            if (this.selectRow.includes(rowIndex)) {
                return { "background-color": "#E6F7FF" };
            }
        },

        /**
         * @desc 自定义斑马纹
         */
        vehicleTableRowClassName({row, rowIndex}) {
            if (rowIndex%2==0) {
                 return '';
            } else {
                return 'even-row';
            }
        },

        submit(params) {
            this.loading = true;
            carApi.cloneMachine(params).then((res) => {
                this.loading = false;
                let content = '';
                let Url = '';
                if (res.Data) {
                    Url = require("@/assets/image/success.png");
                    content= this.$t("common.operationSuccess");
                    
                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.operationFailure");

                }
                
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                this.cloneConfig = false;
                this.getMyCarDetailList_v2();
            })
            .catch(() => {
                this.loading = false;
            });
        },
        checkExistData(array = [], value = '', key = '') {
            let data = checkExistData(array, value, key);

            if(data.isExist) {
                return data.data;
            } else {
                return {
                    [key]: value,
                };
            }
        },
        /**
         * @desc 删除二次确认
         */
        deleteData(type, data) {
            let array = [];

            if(type == 1) {
                array.push(data);
            } else {
                array = this.muitipleData.map(item => {
                    return item.MachineId;
                });
            }

            if(array.length <= 0) {
                const Url = require("@/assets/image/warning1.5x.png");
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("common.selectMachine")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("common.deleteComfirm")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                this.deleteMachine(array);
            }).catch(() => {       
            });
        },
        /**
         * @desc 删除车辆信息
         */
        deleteMachine(arr) {
            let params = {
                machineIdList: arr,
            };

            carApi.deleteMachine(params).then(res => {
                if(!res) {
                    this.$message.error(this.$t("common.operationFailure"));
                    return; 
                }
                this.$message.success(this.$t("common.operationSuccess"));

                this.getMyCarDetailList_v2();
            });
        },
        /**
         * @desc 获取车辆summary信息
         */
        getCompanyMachine() {
            let params = this.getQueryData();

            carApi.getOneCompany(params).then(res => {
                this.carDetails = res.Data;
            });
        },
        /**
         * @desc 获取车辆类型
         */
        getMachineTypeList() {
            let params = this.getQueryData();

            carApi.getMachineTypeList(params).then(res => {
                this.machineTypeList = res.Data && res.Data.map(item => {
                    return {
                        ...item,
                        text: item.TypeName,
                        value: item.TypeId + '/' + item.TypeVersion,
                    };
                });
            });
        },
        /**
         * @desc 获取车辆列表详情
         */
        getMyCarDetailList_v2() {
            let params = this.getQueryData(true);

            carApi.getMyCarDetailList_v2(params).then(res => {
                this.carSearch.total = res.TotalCount;
                this.tableData = res.Data;
            });
        },

        /**
         * @desc 添加车辆
         */
        addMachine(){
          this.$store.commit("setIsEdit",false);
            var param = {
                CompanyId: this.id,
                CompanyName: this.carDetails.CompanyName,
            };
            this.$router.push({
                name: "AddMachineBasicInfo",
                params: param,
            });
        },
        /**
         * @desc 编辑车辆
         */
        editMachine(row){
          this.$store.commit("setIsEdit",true);
            var param={
                MachineId:row.MachineId,
                CompanyId:row.CompanyId,
                CompanyName: this.carDetails.CompanyName,
            };
            this.$router.push({
                name: "AddMachineBasicInfo",
                params: param,
            });
        },
        /**
         * @desc 克隆数据
         */
        clone({row}) {
            this.cloneConfig = true;

            this.editData = row;
        },
        /**
         * @desc 分页
         */
        handleCurrentChange(val) {
            this.carSearch.page = val;
            this.getMyCarDetailList_v2();
        },

        //tag类型
        getBindtatus(bindStatus) {
            const bindColor = {
                0: 'info',
                1: 'success',
                2: 'danger'
            };
            return bindColor[bindStatus];
        },

        /**
         * @desc 过滤数据
         */
        filterChange(filters) {
            let typeId = 0,
                typeVersion = 0,
                carRDMStatus = -1,
                carKSIStatus = -1;

            if(filters.TypeName && filters.TypeName.length > 0) {
                let value = filters.TypeName[0].split('/');

                typeId = value[0];
                typeVersion = value[1];
            }

            if(filters.CarRDMStatus && filters.CarRDMStatus.length > 0) {
                carRDMStatus = filters.CarRDMStatus[0];
            }

            if(filters.CarKSIStatus && filters.CarKSIStatus.length > 0) {
                carKSIStatus = filters.CarKSIStatus[0];
            }

            this.typeId = typeId;
            this.typeVersion = typeVersion;
            this.carRDMStatus = carRDMStatus;
            this.carKSIStatus = carKSIStatus;
            this.handleCurrentChange(1);
        },
        /**
         * @desc 计算进度百分比
         */
        calculatePercent(val, total) {
            if (!total) return 0;

            let percent = ((val * 100) / total).toFixed(1);
            return parseFloat(percent);
        },
        /**
         * @desc 返回我的车辆列表页
         */
        returnList() {
            this.$router.push({path: '/CompanyList'});
        },
        /**
         * @desc 打开详情
         */
        openDetails({row}) {
            this.$router.push({path: '/MachineDetails/' + row.CompanyId + '/' + row.MachineId});
        },
         /**
        * @desc 打开详情
        */
        openRDMDetails({row}) {
            this.$router.push({path: '/RDMDetails/' +row.CompanyId+ '/' + row.RDMNO});
        },
        doOpenMachineGroup()
        {
            this.openMachineGroup();
        },
        /**
         * @desc 导出车辆
         */
        DownLoadCompanyCarsDetail(){
            var param = {
                    CompanyId: this.id
            };
            console.log("param",param);
            carApi.DownLoadCompanyCarsDetail(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    let filePath = res.Data;
                    console.log("filePath",filePath);
                    let FileName=deviceApi.getFileName(filePath);
                    console.log("FileName",FileName);
                    deviceApi
                    .downLoadFile(filePath)
                    .then(resContent=>
                    { 
                        if ('download' in document.createElement('a')) { // 非IE下载
                            const elink = document.createElement('a');
                            elink.download = FileName;
                            elink.style.display = 'none';
                            elink.href = URL.createObjectURL(resContent);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        } else { // IE10+下载
                            navigator.msSaveBlob(resContent, FileName);
                        }
                    });
                }
            });
        },
    },
    mounted: {},  
};
</script>

<style lang="less" scoped>
/*重新选择图片上传组件样式 */
  .Rebulid {
    width: 100%;
    padding: 0px 14px 13px 14px;
    .el-upload {
      text-align: left;
      width: 100%;
    }
    .el-button {
      height: 34px;
      background: #ffffff;
      border: 1px solid #eaeef0;
      border-radius: 2px !important;
      margin-right: 11px;
      color: #0079c1;
    }
    .fileName {
      display: inline-block;
      color: #7a7a7a;
    }
  }
  /*裁剪框样式 */
  .cropper-content {
    .cropper-box {
      display: inline-block;
      width: 290px;
      .cropper {
        width: auto;
        height: 294px;
        .cropper-box {
          background: #898989;
        }
      }
    }
    /*预览区域样式 */
    .show-preview {
      height: 292px;
      width: 150px;
      text-align: center;
      border: 1px solid #eaeef0;
      display: inline-block;
      vertical-align: top;
      .previewsTitle {
        margin-top: 15px;
        color: #696969;
      }
      .preview {
        overflow: hidden;
        width: 100px !important;
        height: 100px !important;
        border-radius: 50px;
        border: 1px solid #0079c1;
        margin: 45px auto;
        display: inline-block;
      }
    }
  }
  .tip {
    padding: 0px 14px 13px 14px;
    width: 100%;
    text-align: left;
    color: #fd990d;
  }

  /*弹窗底部按钮样式 */
  .footerButton {
    text-align: center;
  }
.el-dialog-custom {
    text-align: left;
    font-size: 14px;
    /deep/ .el-dialog__title{
        font-weight: 700;
        font-size: 16px;
    }
    .el-input {
        width: 300px;
    }
    .el-textarea{
        width: 555px;
    }
    /*上传组件样式 */
    /deep/ .el-upload--picture-card {
        width: 102px;
        height: 102px;
        line-height: 102px;
    }
    /deep/ .el-upload-list__item {
        width: 102px !important;
        height: 102px !important;
    }
    /deep/ .upload {
        display: inline-block;
        top: 0;
        position: absolute;
        left: 17px;
        padding-top: 20px;
        color: #555555;
        width: 70px;
    }
    /deep/ .el-icon-plus {
        color: #555555;
        vertical-align: top;
        margin-top: 20px;
        font-size: 20px;
        line-height: 40px;
    }
    /deep/ .hide .el-upload {
        display: none;
    }
    button {
        width: 110px;
        height: 38px;
    }
    /deep/ .el-dialog__footer{
        text-align: center;
    }
}
.company-detail-box {

    .row-label-box {
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    .row1 {
        box-sizing: border-box;
        border-radius: 2px;
        .row1-header-box {
            height: 70px;
            line-height: 70px;
            color: #000;
            background: #fafafa;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            padding: 0 2%;

            .row1-header-crumbs {
                font-size: 16px;
                font-weight: bold;
                float: left;
                height: 70px;
                .el-breadcrumb {
                    vertical-align: middle;
                    display: inline-block;
                }
            }

            .row1-header-btn {
                color: #fff;
                font-size: 14px;
                float: right;

                span {
                    display: inline-block;
                    margin-left: 10px;
                    min-width: 120px;
                    background: #0079C1;
                    height: 38px;
                    line-height: 38px;
                    cursor: pointer;
                    border-radius: 2px;

                    &:last-child {
                        background: #FF9933;
                    }

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

        .row1-body-box {
            height: 142px;
            display: flex;
            justify-content: space-between;
            padding: 0 42px;
            background: #fff;
            border-radius: 2px;
            border-top: rgba(0, 0, 0, 0.1) solid 1px;
            .online-state {
                display: flex;
                flex-grow: 1;
                flex-direction:column;
                align-items: center;
                justify-content: space-around;
                .carMain {
                    display: inline-block;
                    .carMainTop {
                        font-size: 30px;
                        font-weight: 700;
                        display: flex;
                        flex-wrap: nowrap;

                        .carOnlineCount {
                            width: 90px;
                            text-align: center;
                            color: #0079c1;
                        }
                        .carOfflineCount {
                            width: 90px;
                            text-align: center;
                            color: #f34863;
                        }
                        .onlineCount {
                            width: 90px;
                            text-align: center;
                            color: #00B194;
                        }
                        .offlineCount {
                            width: 90px;
                            text-align: center;
                            color: #909399;
                        }
                        .carCount {
                            width: 90px;
                            text-align: center;
                            color: #04223a;
                        }
                    }
                    .carMainBottom {
                        padding-top: 10px;
                        height: 19px;
                        .imgLogo {
                            height: 19px;
                            display: inline-block;
                        }
                        .carMainText {
                            height: 19px;
                            font-size: 14px;
                            line-height: 19px;
                            font-weight: 400;
                            text-align: center;
                            color: #666666;
                            margin-left: 6px;
                            display: inline-block;
                        }
                    }
                }
                .carChart {
                    width: 145px;
                    height: 91px;
                }                
            }

            .row1-body-border {
                border-left: #ffffff solid 1px;
                border-right: #ffffff solid 1px;
                border-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .row2 {
        background: #fff;
        padding: 0 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;
        height: 108px;

        /deep/ .el-cascader {
            flex: 1;
            margin-left: 12px;
        }
    }

    .row3 {
        background: #fff;
        border-radius: 2px;
        .row3-header-label {
            height: 80px;
            line-height: 80px;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
            background: #FAFAFA;
            padding: 0 42px;
        }

        .row3-body-box {
            padding: 0 42px;

            .row3-body-search {
                height: 70px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px; 

                .row3-body-search-btn {
                    span {
                        display: inline-block;
                        margin-left: 25px;
                        cursor: pointer;

                        i {
                            margin-right: 10px;
                        }

                        &:hover {
                            color: #0079C1;
                            text-decoration: underline;
                        }

                        &:last-child {
                            background: #0079C1;
                            color: #fff;
                            border-radius: 2px;
                            padding: 10px 20px;
                        }
                    }
                }
            }

            .row3-body-table {

                .row3-body-table-box {
                    border: 1px solid #F2F2F2;

                    /deep/ .el-table__header tr th {
                        background: #FAFAFA;
                    }

                    .row3-table-operation {
                        display: flex;
                        justify-content: space-around;
                        color: #0079C1;

                        i {
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            &:last-child {
                                color: #FF4D4F;
                            }
                        }
                    }
                }
                .pagination {
                    margin-top: 20px;
                    text-align: right;
                    padding-bottom: 40px;
                    .paginationCount {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;
                    }
                    .paginationDiv {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
.qrcode {
        font-size: 14px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
    }
.ksiComment {
   font-size: 12px;
   color: #909399;
}

</style>