<template>
  <div id="AddPNPage">
    <el-card class="pnCard">
      <!--面包屑-->
      <div class="breadcrumbView">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/PNManage' }"
            > {{$t("menu.PNManage")}}</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="$store.state.isEdit">{{
            $t("RDMManage.Edit")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else>{{
              $t("RDMManage.Add")
            }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- PN信息 -->
      <div class="pnInfo-content">
        <el-form
          ref="basicForm"
          :model="basicForm"
          label-width="23%"
          :rules="rules"
        >        
          <el-row>
            <el-form-item :label="$t('common.company') + ':'" prop="CompanyId">
              <el-col :span="11">
                <el-select
                  v-model="basicForm.CompanyId"
                  filterable
                  v-if="companyList.length > 0"          
                  :placeholder="companyList[0].CompanyName"
                >
                  <el-option
                    v-for="(item, key) in companyList"
                    :key="key"
                    :label="item.CompanyName"
                    :value="item.CompanyId"                  
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="$t('RDMManage.ProductNo') + ':'" prop="ProductNumber">
              <el-col :span="11">
                <el-input
                  class="MachineSN"
                  v-model="basicForm.ProductNumber"
                  :placeholder="$t('RDMManage.ProductNo')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              :label="$t('RDMManage.ProductType') + ':'"
              prop="ProductType"
            >
              <el-col :span="18">
                <el-radio-group v-model="basicForm.ProductType"  >
                  <el-radio :label="0">{{$t('RDMManage.ProductType_General')}}</el-radio>
                  <el-radio :label="1">{{$t('RDMManage.ProductType_Particular')}}</el-radio>
                </el-radio-group>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              :label="$t('RDMManage.ThridCloudSupport') + ':'"
              prop="IsCloud"
            >
              <el-col :span="18">
                <el-radio-group v-model="basicForm.IsCloud"  >
                  <el-radio :label="0">{{$t('RDMManage.ThridCloudSupport_No')}}</el-radio>
                  <el-radio :label="1">{{$t('RDMManage.ThridCloudSupport_Yes')}}</el-radio>
                </el-radio-group>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <div class="foot">
              <el-button @click="back">{{$t("addMachine.Return")}}</el-button>
              <el-button @click="clearForm">{{ $t("bizConfig.clear")}}</el-button>
              <el-button type="primary" @click="Submit">{{ $t("bizConfig.save") }}</el-button>
            </div>
        </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import pnApi from "@/api/autogen/v2/product";
import carApi from "@/api/autogen/v2/car";
import companyApi from "@/api/autogen/v2/orgnization";
import productApi from "@/api/autogen/v2/product";
export default {
  name: "AddPNPage",
  data() {
    var checkPN =  (rule, value, callback) => {
      let data={
        CompanyId:this.basicForm.CompanyId,
        ProductNumber:value
      };
      if(this.isEdit){
        callback();
      }
      pnApi.CheckIfProductNumberDuplicate(data).then(res=>{
        if(res.IsSuccess&&!res.Data){
          callback();
      }else {
          callback(
              new Error(
                  this.$t("RDMManage.PNExisted")
              )
          );

        }
      });
    };

    return {
      //表单数据
      basicForm: {
        CompanyId:"",
        ProductNumber:"",
        ProductType:0,
        IsCloud:0
      }, 
      //公司下拉框数据
      companyList:[],
      pnSearch: {
                page: 1,
                total: 0,
            },
      isEdit: false,     
      rules: {
        CompanyId: [
          {
            required: true,
            message:
              this.$t("common.company") + this.$t("common.required"),
            trigger: "blur",
          }
        ],
        ProductNumber: [
          {
            required: true,
            message:
              this.$t("RDMManage.ProductNo") + this.$t("common.required"),
            trigger: "blur",
          },
          {
            validator: checkPN,
            trigger:'blur'
          }
        ],  
        ProductType: [
          {
            required: true,
            message:
              this.$t("RDMManage.ProductType") + this.$t("common.required"),
            trigger: "blur",
          }
        ], 
        IsCloud: [
          {
            required: true,
            message:
              this.$t("RDMManage.ThridCloudSupport") + this.$t("common.required"),
            trigger: "blur",
          }
        ],           
      },
    };
  },
  computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
  },
  created() {
    this.initData();
    let self = this;
  },
  mounted() {},
  methods: {
    buildCompanyItems() {
            let self = this;
            companyApi.getCompanies().then((resp) => {
                let companyItems = resp.Data;
                for (var i = 0; i < companyItems.length; i++) {
                    self.companyList.push({
                        CompanyName: companyItems[i].CompanyName,
                        CompanyId: companyItems[i].CompanyId,
                    });
                }
                //console.log(companyList);
            });
        },     
        //清理设备文件表单数据
    clearForm(){
      this.basicForm={
        CompanyId:"",
        ProductNumber:"",
        ProductType:0,
        IsCloud:0
      };
    },     
 // assgin
 Submit(){
  let self = this;
  self.$refs["basicForm"].validate((valid) => {
    if (valid) {
      self.loading = true;
      let param = self.basicForm;
      if(!this.isEdit)
      {
        pnApi.CreateProductNumber(param).then((res)=>{
        if(res&&res.IsSuccess){
            let content = '';
            let Url = '';
            if (res.Data) {
                Url = require("@/assets/image/success.png");
                content= this.$t("common.operationSuccess");
                this.back();
                }else{
                      Url = require("@/assets/image/error_76.png");
                      content= this.$t("common.operationFailure");
                }
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                          dangerouslyUseHTMLString: true,
                          confirmButtonText: this.$t("common.confirm"),
                          center: true,
                          showClose:false
                });                                     
        }else{
                      Url = require("@/assets/image/error_76.png");
                      content= this.$t("common.operationFailure");
                      this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                          dangerouslyUseHTMLString: true,
                          confirmButtonText: this.$t("common.confirm"),
                          center: true,
                          showClose:false
                     });  
                }
                 
        self.loading =false;
        }).catch((e) => {
                        let Url = require("@/assets/image/error_76.png");
                        let content= self.$t("common.operationFailure");
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.loading =false;
        }); 

      }
      else
      {
        pnApi.EditProductNumber(param).then((res)=>{
        if(res&&res.IsSuccess){
            let content = '';
            let Url = '';
            if (res.Data) {
                Url = require("@/assets/image/success.png");
                content= this.$t("common.operationSuccess");
                this.back();
                }else{
                      Url = require("@/assets/image/error_76.png");
                      content= this.$t("common.operationFailure");
                }
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                          dangerouslyUseHTMLString: true,
                          confirmButtonText: this.$t("common.confirm"),
                          center: true,
                          showClose:false
                });                                     
        }else{
                      Url = require("@/assets/image/error_76.png");
                      content= this.$t("common.operationFailure");
                      this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                          dangerouslyUseHTMLString: true,
                          confirmButtonText: this.$t("common.confirm"),
                          center: true,
                          showClose:false
                     });  
                }
                 
        self.loading =false;
        }).catch((e) => {
                        let Url = require("@/assets/image/error_76.png");
                        let content= self.$t("common.operationFailure");
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.loading =false;
        }); 
      }
           
      } 
    }); 
  }, 
    //初始化页面数据
    initData(){
      var self = this;
      var params =  this.$route.params;
      if(params.ID!=null){
          this.isEdit = true;
      }
      if(this.isEdit)
      {
        let self = this;
        var param = {
          ID: params.ID
        };
        pnApi.GetProductNumberList(param).then((resp) => {
          this.basicForm = resp.Data[0];   
          console.log(resp.Data);    
          console.log(this.basicForm);          
        });

      }

      this.buildCompanyItems();     
    },
    back() {
      this.$router.push({
        path: '/PNManage/',
      });
    },
  },
};
</script>

<style lang="less">
.pnCard .el-card__body {
  padding: 0px !important;
}
.pnCard {
  border: 0px !important;
}
/*面包屑样式 */
.breadcrumbView {
  height: 30px;
  border-bottom: 1px solid #eaeef0;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
}
.breadcrumbView .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
}
/*PN信息样式 */
.pnInfo-content {
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  .el-select {
    display: block;
  }
  .el-form-item__label {
    padding-right: 20px;
  }
  /*底部按钮样式 */
  .footer {
    text-align: left;
    .el-button {
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover {
      background: transparent !important;
    }
    .el-button--primary {
      margin-left: 20px;
    }
  }
  /*隐藏数字框右边按钮 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .tip {
    padding: 0px 14px 13px 14px;
    width: 100%;
    text-align: left;
    color: #fd990d;
    margin-top: -10px;
  }
  /*弹窗底部按钮样式 */
  .footerButton {
    .el-button {
      width: 110px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #cbcbcd;
      border-radius: 4px !important;
    }
    .el-button--primary {
      margin-left: 20px;
    }
  }
}
/*下拉框选项样式 */
.el-popper {
  .el-select-dropdown__item:hover {
    background-color: #fafafa;
  }
  .selected {
    font-weight: 400 !important;
    color: #3399ff !important;
    background-color: #ecf5ff;
  }
  .hover {
    background-color: #ecf5ff;
  }
}

input{
  border-radius: 3px;
}
/*输入框鼠标悬停添加阴影 */
input:hover,
textarea:hover,
.is-focus input {
  box-shadow: 0px 0px 8px 0px rgb(51 153 255 / 80%);
  border: 1px solid #3399ff !important;
  border-radius: 3px;
}
.el-input.is-disabled .el-input__inner{
  border-color: #ccc !important;
}
</style>
