<template>
  <div class="switch-menu">
    <div class="switch-button">
      <i
        v-show="isMenuCollapse == false"
        class="el-icon-s-fold"
        @click="switchMenuCollapse"
      ></i>
      <i
        v-show="isMenuCollapse"
        class="el-icon-s-unfold"
        @click="switchMenuCollapse"
      ></i>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "NavMenuSwitch",
  props: {},
  components: {},
  created() {},
  data() {
    return {
      isMenuCollapse: false,
    };
  },
  methods: {
    switchMenuCollapse() {
      this.isMenuCollapse = !this.isMenuCollapse;
      this.$emit("switch-menu-collapse", this.isMenuCollapse);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.switch-menu {
  padding-top: 0;
  padding-left: 19px; 
  text-align: left;
  font-size: 19px;
  cursor: pointer;
}

.switch-button i:hover {
  color: #0079c1;
}
</style>
