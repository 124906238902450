<template>
  <div id="AddMachineBasicInfoPage">
    <el-card class="machineCard">
      <!--面包屑-->
      <div class="breadcrumbView">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: 'CompanyList' }"
            > {{$t("common.company")}}</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="'/VehicleList/'+machineInfo.CompanyId">{{machineInfo.CompanyName}}</el-breadcrumb-item>

          <el-breadcrumb-item v-if="$store.state.isEdit">{{
            $t("addMachine.EditMachine")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else>{{
              $t("addMachine.addMachine")
            }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 步骤条 -->
      <div class="stepsView">
        <el-steps :active="active" align-center>
          <el-step :title="$t('addMachine.setp1')"></el-step>
          <el-step :title="$t('addMachine.setp2')"></el-step>
          <el-step :title="$t('addMachine.setp3')"></el-step>
          <el-step :title="$t('addMachine.setp4')"></el-step>
        </el-steps>
      </div>
      <!-- 车辆信息 -->
      <div class="machineBasicInfo-content">
        <el-form
          ref="basicForm"
          :model="basicForm"
          label-width="23%"
          :rules="rules"
        >
          <el-row>
            <el-form-item
              :label="$t('addMachine.MachineSN') + ':'"
              prop="MachineSN"
            >
              <el-col :span="11">
                <el-input
                  :disabled="isEdit"
                  class="MachineSN"
                  v-model="basicForm.MachineSN"
                  :placeholder="$t('addMachine.MachineSN')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <!--el-row>
            <el-form-item
              :label="$t('addMachine.MachineName') + ':'"
            >
              <el-col :span="11">
                <el-input
                  class="MachineSN"
                  v-model="basicForm.MachineName"
                  :placeholder="$t('addMachine.MachineName')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row-->
          <el-row>
            <el-form-item :label="$t('addMachine.RDMNO') + ':'" prop="RDMNO">
              <el-col :span="11">
                <el-select
                  v-model="basicForm.RDMNO"
                  filterable
                  :disabled="isEdit"
                  :placeholder="RDMNoList[0]"
                >
                  <el-option
                    v-for="item in RDMNoList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              :label="$t('addMachine.voltageRange') + ':'"
              prop="voltageRange"
            >
              <el-col :span="5">
                <el-input
                  type="number"
                  class="voltageRange"
                  v-model="basicForm.VoltMin"
                >
                  <p slot="suffix" class="unit">V</p>
                </el-input>
              </el-col>
              <el-col :span="1"> ~ </el-col>
              <el-col :span="5">
                <el-input
                  type="number"
                  class="voltageRange"
                  v-model="basicForm.VoltMax"
                >
                  <p slot="suffix" class="unit">V</p>
                </el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="$t('addMachine.remark') + ':'" prop="Remark">
              <el-col :span="18">
                <el-input
                  type="textarea"
                  :rows="5"
                  class="remark"
                  v-model="basicForm.Remark"
                  :placeholder="$t('addMachine.remark')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              :label="$t('addMachine.picture') + ':'"
              prop="picture"
            >
              <el-col :span="1">
                <el-upload
                  action="#"
                  ref="picture"
                  :class="[basicForm.picture.length > 0 ? 'hide' : '']"
                  list-type="picture-card"
                  :file-list="basicForm.picture"
                  :auto-upload="false"
                  :on-change="handleChangePicture"
                  :on-remove="handleRemovePicture"
                  :on-preview="handlePreviewPicture"
                  accept=".jpg, .jpeg, .png, .gif"
                >
                  <i
                    :class="isUploading ? 'el-icon-loading' : 'el-icon-plus'"
                  ></i>
                  <div class="upload">
                    {{ isUploading ? $t('common.Uploading'):$t('common.Upload') }}
                  </div>
                </el-upload>
              </el-col>
              <!-- 裁剪图片弹窗 -->
              <el-dialog
                :title="$t('addMachine.uploadPicture')"
                width="508px"
                :close-on-click-modal="false"
                :visible.sync="dialogVisible"
              >
                <el-upload
                  class="Rebulid"
                  action="#"
                  multiple
                  :file-list="cropPictureList"
                  :auto-upload="false"
                  :on-change="handleRebuildPicture"
                  :on-remove="handleRemovePicture"
                  accept=".jpg, .jpeg, .png, .gif"
                >
                  <el-button>{{ $t("addMachine.Rebulid") }}</el-button>
                  <div class="fileName" v-if="basicForm.picture.length > 0">
                    {{ $t("addMachine.support") }}
                  </div>
                </el-upload>
                <div class="cropper-content">
                  <div class="cropper-box">
                    <div class="cropper">
                      <!-- 裁剪图片组件 -->
                      <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.outputSize"
                        :outputType="option.outputType"
                        :info="option.info"
                        :canScale="option.canScale"
                        :autoCrop="option.autoCrop"
                        :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight"
                        :fixed="option.fixed"
                        :fixedNumber="option.fixedNumber"
                        :full="option.full"
                        :fixedBox="option.fixedBox"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :centerBox="option.centerBox"
                        :height="option.height"
                        :infoTrue="option.infoTrue"
                        :maxImgSize="option.maxImgSize"
                        :enlarge="option.enlarge"
                        :mode="option.mode"
                        @realTime="realTime"
                      >
                      </vue-cropper>
                    </div>
                  </div>
                  <!--预览效果图-->
                  <div class="show-preview">
                    <div class="previewsTitle">
                      {{ $t("addMachine.Preview") }}
                    </div>
                    <div class="preview">
                      <img :src="url" style="height: 100px; width: 100px" />
                    </div>
                  </div>
                </div>
                <div class="tip">
                  {{ $t("addMachine.tip") }}
                </div>
                <div>
                  <el-button @click="dialogVisible = false">{{
                    $t("common.cancel")
                  }}</el-button>
                  <el-button @click="cropPicture" type="primary">{{
                    $t("addMachine.Submit")
                  }}</el-button>
                </div>
              </el-dialog>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item>
              <el-col class="footer">
                <el-button @click="back">{{
                  $t("addMachine.Return")
                }}</el-button>
                <el-button type="primary" @click="next">{{
                  $t("addMachine.Next")
                }}</el-button>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import rdmApi from "@/api/autogen/v2/rdm";
import carApi from "@/api/autogen/v2/car";
export default {
  name: "AddMachineBasicInfoPage",
  data() {
    var checkVolt = (rule, value, callback) => {
      if (this.basicForm.VoltMin == "" || this.basicForm.VoltMax == "") {
        callback(
          new Error(
            this.$t("addMachine.voltageRange") + this.$t("common.required")
          )
        );
      } 
      /*else if (this.basicForm.VoltMin >= this.basicForm.VoltMax || this.basicForm.VoltMin <= 0 ) {
        callback(
          new Error(
            this.$t("addMachine.voltageRangeRule")
          )
        );
      } */
      else {
        var VoltMin=this.basicForm.VoltMin;
        var VoltMax=this.basicForm.VoltMax;
        if((VoltMin % 1 === 0)&&VoltMax % 1 === 0)
        {
          if((VoltMin/1) >= (VoltMax/1) ||(VoltMin/1) <= 0 )
          {
            callback(
                    new Error(
                      this.$t("addMachine.voltageRangeRule")
                    )
                  );
          }else{
          callback();
          }
        }else{
          callback(
          new Error(
            this.$t("addMachine.voltageRangeTypeRule")
          )
        );
        }
      }
    };
    var checkMachineSN =  (rule, value, callback) => {
      let data={
        machineSN:value,
        companyId: this.machineInfo.CompanyId
      };
      if(this.isEdit){
        callback();
      }
      carApi.checkIfMachineSNExisted(data).then(res=>{
        if(res.IsSuccess&&!res.Data){
          callback();
      }else {
          callback(
              new Error(
                  this.$t("addMachine.MachineSNExited")
              )
          );

        }
      });
    };

    return {
      //表单数据
      basicForm: {
        MachineSN:"",
        MachineName:"",
        RDMNO:"",
        VoltMin:"",
        VoltMax:"",
        Remark:"",
        picture:[],
      },
      //裁剪图片对象
      option: {
        img: "", //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 105, //默认生成截图框宽度
        autoCropHeight: 145, //默认生成截图框高度
        fixed: true, //是否开启截图框宽高固定比例
        fixedBox: false, // 固定截图框大小 不允许改变
        fixedNumber: [1.05, 1.45], //截图框的宽高比例
        canMove: true, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: false, //上传图片按照原始比例渲染
        centerBox: true, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: true, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        full: true, // 输出原图比例截图 props名full
        // mode: '290px 290px'  //图片默认渲染方式
      },
      cropPictureList: [],
      //RDMNo下拉框数据
      RDMNoList: [],
      //车辆信息
      machineInfo:{
        MachineComponents:{
          MachineType: {},
          DeviceList:[],
          MachineTypeMonitorTemplate:{
            MonitorParameterList:[],
          },
          Rdm:{},
          rdmSoftList:[],
        }
      },
      //步骤条步骤
      active: 0,
      //实时预览的路径
      url: "",
      //是否上传中
      isUploading: false,
      dialogVisible: false,
      //公司ID
      companyId: 0,
      isEdit: false,
      rules: {
        MachineSN: [
          {
            required: true,
            message:
              this.$t("addMachine.MachineSN") + this.$t("common.required"),
            trigger: "blur",
          },
          {
            validator: checkMachineSN,
            trigger:'blur'
          },
        ],
        RDMNO: [
          {
            required: true,
            message: this.$t("addMachine.RDMNO") + this.$t("common.required"),
            trigger: "blur",
          },
        ],
        voltageRange: [
          { required: true, validator: checkVolt, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    //初始化页面数据
    initData(){
      var self = this;
      var params =  this.$route.params;
      this.machineInfo.CompanyId = params.CompanyId;
      if(params.CompanyName != null && params.CompanyName != ""){
        this.machineInfo.CompanyName = params.CompanyName;
      }
      if(params.MachineComponents != null){
        if(params.MachineId!=null){
          this.isEdit = true;
        }
        this.machineInfo = params;
        if(this.machineInfo.MachineComponents.MachineType!=null){
          this.basicForm = this.machineInfo.MachineComponents.MachineType;
          this.basicForm.MachineSN = this.machineInfo.MachineSN;
          this.basicForm.RDMNO = this.machineInfo.RDMNO;
          var file = this.machineInfo.MachineComponents.MachineType.ImagePath;
          if(file != null && file != ""){
            this.basicForm.picture = [
              {
                raw:file,
                url:URL.createObjectURL(file),
                size:file.size,
                uid:file.uid,
                status:"ready",
                percentage:0,
                name:file.name
              },
            ];
            this.cropPictureList = this.basicForm.picture;
            this.option.img = this.basicForm.picture[0].url;
          }else{
            this.basicForm.picture = [];
          }
        }
      }
      if(params.MachineId!=null&&params.MachineComponents == null){
        this.isEdit = true;
        var param = {
          CompanyId: params.CompanyId,
          MachineId: params.MachineId
        };
        carApi.getOneCarDetail_v2(param).then(res=>{
          if(res.IsSuccess){
            self.machineInfo = res.Data;
            this.basicForm = this.machineInfo.MachineComponents.MachineType;
            this.basicForm.MachineSN = this.machineInfo.MachineSN;
            this.basicForm.RDMNO = this.machineInfo.RDMNO;
            if ( res.Data.Remark){
              this.basicForm.Remark=res.Data.Remark;
            }
            if (res.Data.ImageBase64){
              this.basicForm.picture =[];
              let blobFile=this.dataURLtoFile(res.Data.ImageBase64,"record.jpg");
              console.log(blobFile);
              let img =URL.createObjectURL(blobFile);
              this.option.img = img;
              console.log(img);
              let file ={
                raw: blobFile,
                status:"ready",
                url:res.Data.ImageBase64
            };
              this.basicForm.picture.push(file);
              this.cropPictureList = [file];
            }else {
              this.basicForm.picture =[];
            }
            }
        });
      }
      this.getRDMList();
    },
    //获取RDM列表
    getRDMList() {
      var self = this;
      var param = {
        CompanyId: this.machineInfo.CompanyId,
      };
      rdmApi.getRDMList(param).then((res) => {
        if (res.IsSuccess) {
          self.RDMNoList = res.Data;
        }
      });
    },
    //下一步
    next() {
      var self = this;
      self.machineInfo.MachineSN = self.basicForm.MachineSN;
      self.machineInfo.RDMNO = self.basicForm.RDMNO;
      self.machineInfo.MachineComponents.MachineType.VoltMin = self.basicForm.VoltMin;
      self.machineInfo.MachineComponents.MachineType.VoltMax = self.basicForm.VoltMax;
      self.machineInfo.MachineComponents.MachineType.Remark = self.basicForm.Remark==null?"":self.basicForm.Remark;
      if(self.basicForm.picture.length>0){
        self.machineInfo.MachineComponents.MachineType.ImagePath = self.basicForm.picture[0].raw;
        self.machineInfo.MachineComponents.MachineType.ImageName = self.basicForm.picture[0].name;
      }else{
        self.machineInfo.MachineComponents.MachineType.ImagePath = "";
        self.machineInfo.MachineComponents.MachineType.ImageName = "";
      }
      this.$refs["basicForm"].validate((valid) => {
        if (valid) {
          this.$router.push({
            name: "AddMachineDevicesConfig",
            params: self.machineInfo,
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //实时预览函数
    realTime() {
      var self = this;
      this.$refs.cropper.getCropData(async (data) => {
        self.url = data;
      });
    },
    //打开图片剪切弹窗
    handlePreviewPicture(file) {
      this.dialogVisible = true;
    },
    //选择图片
    handleChangePicture(file, fileList) {
      console.log( this.$refs.picture);
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
        this.$message({
          message: this.$t('common.PictureRequire'),
          type: "error",
        });
        this.basicForm.picture = [];
      } else {
        this.basicForm.picture = [];
        this.isUploading = true;
        setTimeout(() => {
          console.log(fileList);
          fileList = [file];
          var img = URL.createObjectURL(file.raw); // 获取URL
          this.basicForm.picture = fileList;
          this.cropPictureList = fileList;
          this.option.img = img;
          this.isUploading = false;
        }, 1000);
      }
    },
    //重新选择图片
    handleRebuildPicture(file, fileList) {
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
        this.$message({
          message: this.$t('common.PictureRequire'),
          type: "error",
        });
      } else {
        this.basicForm.picture = [];
        this.basicForm.picture[0] = file;
        let fileName = file.name;
        let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
        if (regex.test(fileName.toLowerCase())) {
          var img = URL.createObjectURL(file.raw); // 获取URL
          this.option.img = img;
          this.basicForm.picture[0].url = img;
          this.cropPictureList = [file];
        } else {
          this.$message.error(this.$t('common.ToSelectImage'));
        }
      }
    },
    //剪切图片
    cropPicture() {
      var self = this;
      //获取截图的blob数据
      this.$refs.cropper.getCropData(async (data) => {
        var file = this.dataURLtoFile(data, self.basicForm.picture[0].name);
        file.uid = self.basicForm.picture[0].uid;
        self.basicForm.picture[0].raw = file;
        self.basicForm.picture[0].url = data;
        self.basicForm.picture[0].size = file.size;
        self.dialogVisible = false;
      });
    },
    //删除图片
    handleRemovePicture() {
      setTimeout(() => {
        this.basicForm.picture = [];
        this.cropPictureList = [];
      }, 1000);
    },
    //将base64转换为file
    dataURLtoFile: function (dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    back() {
      this.$router.push({
        path: '/VehicleList/'+this.machineInfo.CompanyId,
      });
    },
    isInteger(value) {
      return typeof value === 'number' && value % 1 === 0;
    },
  },
};
</script>

<style lang="less">
.machineCard .el-card__body {
  padding: 0px !important;
}
.machineCard {
  border: 0px !important;
}
/*面包屑样式 */
.breadcrumbView {
  height: 30px;
  border-bottom: 1px solid #eaeef0;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
}
.breadcrumbView .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
}
/*步骤条样式*/
.stepsView {
  padding: 0 12% 1% 12%;
  border-bottom: 1px solid #eaeef0;
  .el-step__icon-inner {
    font-weight: 400;
  }
  .is-finish .el-step__icon,
  .is-process .el-step__icon {
    background-color: #409eff;
    color: #fff;
    border: 3px solid #b3d7ed !important;
  }
  .el-step__title{
    font-size: 14px;
  }
  .is-wait .el-step__icon {
    background-color: #aebacb;
    color: #fff;
    border: 3px solid #e9ecf1 !important;
  }
  .is-finish {
    color: #333333 !important;
  }
  .is-process {
    font-weight: 400 !important;
  }
  .el-step__line {
    left: 58% !important;
    right: -42% !important;
    height: 4px !important;
    background: #e1e7ef;
    i{
      border: 0px solid transparent;
    }
  }
}
/*车辆信息样式 */
.machineBasicInfo-content {
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  .el-select {
    display: block;
  }
  .el-form-item__label {
    padding-right: 20px;
  }
  /*电压单位样式 */
  .unit {
    margin: 0px;
    padding-right: 8px;
    color: #333333;
  }
  /*上传组件样式 */
  .el-upload--picture-card {
    width: 102px;
    height: 102px;
    line-height: 102px;
  }
  .el-upload-list__item {
    width: 102px !important;
    height: 102px !important;
  }
  .upload {
    display: inline-block;
    top: 0;
    position: absolute;
    left: 17px;
    padding-top: 20px;
    color: #555555;
    width: 70px;
  }
  .el-icon-plus {
    color: #555555;
    vertical-align: top;
    margin-top: 20px;
    font-size: 20px;
    line-height: 40px;
  }
  .hide .el-upload {
    display: none;
  }
  /*底部按钮样式 */
  .footer {
    text-align: left;
    .el-button {
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover {
      background: transparent !important;
    }
    .el-button--primary {
      margin-left: 20px;
    }
  }
  /*隐藏数字框右边按钮 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /*弹窗标题样式 */
  .el-dialog__header {
    padding: 20px !important;
    padding-bottom: 10px !important;
    height: auto !important;
    text-align: left;
    background: #fafbfc;
    border-bottom: 1px solid #eaeef0;
  }
  /*重新选择图片上传组件样式 */
  .Rebulid {
    width: 100%;
    padding: 0px 14px 13px 14px;
    .el-upload {
      text-align: left;
      width: 100%;
    }
    .el-button {
      height: 34px;
      background: #ffffff;
      border: 1px solid #eaeef0;
      border-radius: 2px !important;
      margin-right: 11px;
      color: #0079c1;
    }
    .fileName {
      display: inline-block;
      color: #7a7a7a;
    }
  }
  /*裁剪框样式 */
  .cropper-content {
    .cropper-box {
      display: inline-block;
      width: 290px;
      .cropper {
        width: auto;
        height: 294px;
        .cropper-box {
          background: #898989;
        }
      }
    }
    /*预览区域样式 */
    .show-preview {
      height: 292px;
      width: 150px;
      text-align: center;
      border: 1px solid #eaeef0;
      display: inline-block;
      vertical-align: top;
      .previewsTitle {
        margin-top: 15px;
        color: #696969;
      }
      .preview {
        overflow: hidden;
        width: 100px !important;
        height: 100px !important;
        border-radius: 50px;
        border: 1px solid #0079c1;
        margin: 45px auto;
        display: inline-block;
      }
    }
  }
  .tip {
    padding: 0px 14px 13px 14px;
    width: 100%;
    text-align: left;
    color: #fd990d;
    margin-top: -10px;
  }
  /*弹窗底部按钮样式 */
  .footerButton {
    .el-button {
      width: 110px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #cbcbcd;
      border-radius: 4px !important;
    }
    .el-button--primary {
      margin-left: 20px;
    }
  }
}
/*下拉框选项样式 */
.el-popper {
  .el-select-dropdown__item:hover {
    background-color: #fafafa;
  }
  .selected {
    font-weight: 400 !important;
    color: #3399ff !important;
    background-color: #ecf5ff;
  }
  .hover {
    background-color: #ecf5ff;
  }
}

input{
  border-radius: 3px;
}
/*输入框鼠标悬停添加阴影 */
input:hover,
textarea:hover,
.is-focus input {
  box-shadow: 0px 0px 8px 0px rgb(51 153 255 / 80%);
  border: 1px solid #3399ff !important;
  border-radius: 3px;
}
.el-input.is-disabled .el-input__inner{
  border-color: #ccc !important;
}
</style>
