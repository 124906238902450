<template>
    <div class="soft-box">
        <el-row class="soft-top">
            <el-col :span="16">
             <div class="title">
                <span> 
                    {{$t('RDMManage.SoftwareManage')}}
                </span>
            </div>
            </el-col>
            <el-col :span="8">
                <div class="search">
                    <el-input
                        :placeholder="$t('common.version')+'/'+$t('common.Description')"
                        suffix-icon="el-icon-search"
                        v-model="searchContent"
                        @change="GetRDMSoftwareList"
                    >
                    </el-input>
                </div>
            </el-col>
        </el-row>
        <el-row class="soft-content">
            <el-col :span="24">
                <div class="soft-content-top">
                    <el-button style=" margin-top: 1%;" type="primary" icon="el-icon-plus" @click="openAddSoftWare" v-if="privilege.Add">{{$t('RDMManage.NewSoftware')}}</el-button>
                    <el-button style=" margin-top: 1%;" type="success" icon="el-icon-collection-tag" @click="setDefault" v-if="privilege.DefaultSetting">{{$t('RDMManage.SetDefault')}}</el-button>
                    <el-button style=" margin-top: 1%;" type="danger" icon="el-icon-delete" @click="deleteData(0)"  v-if="privilege.Delete">{{$t('common.delete')}}</el-button>
                </div>
            </el-col>            
            <el-col :span="24">
                <div class="soft-table">
                    <div class="table-box">
                        <el-table :data="tableData" :row-class-name="vehicleTableRowClassName"
                         style="width: 100%;" 
                        @selection-change="handleSelectionChange"
                         :row-style="rowClass" >
                            <el-table-column
                                align="center"
                                width="40"
                                class-name="default"
                                >
                                <template slot-scope="scope">
                                    <i v-if="scope.row.IsDefault == 1" class="fa fa-bookmark green"></i>
                                </template>
                            </el-table-column>
                            <el-table-column
                                type="selection"
                                align="center"
                                width="55">
                            </el-table-column>
                            <el-table-column
                                type="index"
                                align="center"
                                :label="$t('common.SEQ')"
                                width="50"
                                :index="indexMethod"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="Version"
                                align="center"
                                :label="$t('common.version')"
                                width="250">
                            </el-table-column>
                            <el-table-column
                                align="center"
                                :label="$t('RDMManage.IsDefault')"
                                width="100">
                                
                                <template slot-scope="scope">
                                    <span v-if="scope.row.IsDefault == 1">{{$t('common.yes')}}</span>
                                    <span v-else>{{$t('common.no')}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="Remark"
                                align="center"
                                :label="$t('common.Description')"
                                >
                            </el-table-column>                           
                            <el-table-column
                                align="center"
                                :label="$t('RDMManage.UploadTime')"
                                width="250">
                                <template slot-scope="scope">
                                    {{formatTimestampUTC(new Date(scope.row.UTime),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('common.operation')"
                                align="center"
                                fixed="right"
                                width="250"
                                v-if="privilege.Delete"
                                >
                                <template slot-scope="scope">
                                    <div class="table-operation">
                                        <i class="el-icon-delete" @click="deleteData(1,scope.row.RDMDefId)" :title="$t('common.delete')"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="pagination">
                        <div
                            v-if="rdmSearch.total > userInfo.PageSize"
                            class="paginationCount"
                        >
                            {{ (rdmSearch.page - 1) * userInfo.PageSize + 1 }}-{{
                            rdmSearch.page * userInfo.PageSize
                            }}
                            {{ $t("common.Page_of") }} {{ rdmSearch.total }} {{ $t("common.Pages") }}
                        </div>
                        <div class="paginationDiv">
                            <el-pagination
                            background
                            :page-size="userInfo.PageSize"
                            @current-change="handleCurrentChange"
                            :hide-on-single-page="true"
                            layout="prev, pager, next"
                            :total="rdmSearch.total"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-dialog
            :title="$t('RDMManage.NewSoftware')"
            :visible.sync="dialogVisible"
            width="30%"
            class="el-dialog-custom"
            center
            :before-close="handleClose">
          
            <div class="uploadFile">
                <el-upload ref="FileMenu"
                    list-type="text"
                    :class="[FileFlag?'avatar-uploader FileMenu' : 'avatar-uploader FileMenu emptyFile']"
                    :auto-upload="false"
                    :on-change="handleChangeSoftFile"
                    :file-list="SoftFile"
                    action="#"
                    accept=".fdev">
                    <div class="upload">
                    <div v-if="SoftFile.length>0" >
                        <img :src="imageUrl" class="el-icon-folder-opened"/><div class="Add haveFile">{{SoftFile[0].name}}</div>
                    </div>
                    <i v-else class="el-icon-plus avatar-uploader-icon">
                        <div class="Add">{{$t('RDMManage.NewSoftware')}}</div>
                    </i>
                    </div>
                </el-upload>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="saveSoftware">{{$t('addMachine.Submit')}}</el-button>
                <el-checkbox v-model="isDefault">{{$t("RDMManage.SetDefault")}}</el-checkbox>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import rdmApi from "@/api/autogen/v2/rdm";
import {formatTimestampUTC,ifGrantedTheOperationForThisFunction} from '@/utils';
export default {
    data() {
        return {
            searchContent:'',
            rdmSearch: {
                page: 1,
                total: 0,
            },
            tableData: [],
            muitipleData: [],
            selectRow: [],
            dialogVisible:false,
            Loading:false,
            loadingText:"",
            FileFlag:true,
            SoftFile:[],
            isDefault:false,
            //上传文件成功后图片路径
            imageUrl: require('@/assets/image/ESEDevicePackage_file_icon.png'),
            privilege: {
                View: false,
                Add: false,
                Delete: false,
                Edit: false,
                DefaultSetting: false,
            }
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
    },
    watch: {
        muitipleData(data) {  //存储选中的row
            this.selectRow = [];
            if (data.length > 0) {
                data.forEach((item, index) => {
                    this.selectRow.push(this.tableData.indexOf(item));
                });
            }
        }
    },
    created(){
        let self = this;
        self.GetRDMSoftwareList();
        self.initialFunctionOperationPrivilege();
    },
    methods: {
        formatTimestampUTC,
        ifGrantedTheOperationForThisFunction,
        initialFunctionOperationPrivilege()
        {
                let self = this;
                self.privilege.View = ifGrantedTheOperationForThisFunction("View");
                self.privilege.Add = ifGrantedTheOperationForThisFunction("Add");
                self.privilege.Delete = ifGrantedTheOperationForThisFunction("Delete");
                self.privilege.Edit = ifGrantedTheOperationForThisFunction("Edit");
                self.privilege.DefaultSetting = ifGrantedTheOperationForThisFunction("DefaultSetting");
        },
        //验证软件
        handleChangeSoftFile(file,fileList){
            if (!/\.(fdev)$/.test(file.name)) {
                this.$message({
                    message: this.$t("addMachine.OnlyFDEVFile"),
                    type: "error",
                });
                return;
            } 
            let fd = new FormData();
            let isDefault = 0;
            if(this.isDefault){
                isDefault = 1;
            }else{
                isDefault = 0;
            }   
            fd.append("RDMFile", file.raw);
            fd.append("IsDefault", isDefault);
            rdmApi.CheckIfRDMDefinitionExists(fd).then((res)=>{
                if(res&&res.IsSuccess){
                    if(!res.Data){
                        fileList=[file];
                        this.SoftFile=fileList;
                        this.FileFlag = true;
                    }else{
                      this.$message.error(this.$t('RDMManage.IsSoftware'));  
                    }
                }else{
                    this.$message.error(res.ErrorMsg);
                }
            });
            
            
           
        },

        //保存软件
        saveSoftware(){
            if(this.SoftFile.length<=0){
                this.$message.error(this.$t("common.selectSoftware"));
                return;
            }
            let fd = new FormData();
            let isDefault = 0;
            if(this.isDefault){
                isDefault = 1;
            }else{
                isDefault = 0;
            }   
            fd.append("RDMFile", this.SoftFile[0].raw);
            fd.append("IsDefault", isDefault);
            rdmApi.AddOrEditRDMDefinition(fd).then((res)=>{
                let Url = '';
                let content = '';
                if(res&&res.IsSuccess&&res.Data){
                    Url = require("@/assets/image/success.png");
                    content= this.$t("common.operationSuccess");
                    this.dialogVisible = false;
                    this.GetRDMSoftwareList();
                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.operationFailure");
                }
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
            });
        },

        //打开新增软件弹窗
        openAddSoftWare(){
            this.SoftFile = [];
            this.FileFlag = true;
            this.isDefault = false;
            this.dialogVisible = true;
        },
        //设置软件默认
        setDefault(){
            let array = [];
            
            array = this.muitipleData.map(item => {
                return item.RDMDefId;
            });
            const Url = require("@/assets/image/warning1.5x.png");
            if(array.length <= 0) {
                
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("common.selectSoftware")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }else if(array.length >1){
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("common.selectOnlyOne")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("RDMManage.IsSetDefault")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                let param ={
                    rdmDefId : array[0]
                };
                rdmApi.SetDefaultRDMSoftware(param).then((res)=>{
                    let content = '';
                    let Url = '';
                    if (res&&res.Data) {
                        Url = require("@/assets/image/success.png");
                        content= this.$t("common.operationSuccess");
                        this.GetRDMSoftwareList();
                        
                    }else{
                        Url = require("@/assets/image/error_76.png");
                        content= this.$t("common.operationFailure");

                    }
                    
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                });

            }).catch(() => {       
            });
        },


         /**
         * @desc 删除二次确认
         */
        deleteData(type, data) {
            let array = [];

            if(type == 1) {
                array.push(data);
            } else {
                array = this.muitipleData.map(item => {
                    return item.RDMDefId;
                });
            }

            if(array.length <= 0) {
                const Url = require("@/assets/image/warning1.5x.png");
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("common.selectSoftware")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("common.deleteComfirm")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                this.deleteSoft(array);
            }).catch(() => {       
            });
        },
        /**
         * @desc 删除软件信息
         */
        deleteSoft(arr) {
            let params = arr;

            rdmApi.DeleteRDMSoftware(params).then(res => {
                if(!res) {
                    this.$message.error(this.$t("common.operationFailure"));
                    return; 
                }
                this.$message.success(this.$t("common.operationSuccess"));

                this.GetRDMSoftwareList();
            });
        },


        //获取软件列表
        GetRDMSoftwareList(){
            let param = {
                pageSize: this.userInfo.PageSize,
                pageIndex: this.rdmSearch.page,
                filters: this.searchContent
            };
            rdmApi.GetRDMSoftwareList(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.tableData = res.Data;
                    this.rdmSearch.total = res.TotalCount;
                }
            });
        },

        //分页序号连续
        indexMethod(index) {
            index = (index + 1) + (this.rdmSearch.page - 1) * this.userInfo.PageSize;
            return index;
        },

        /**
         * @desc 分页
         */
        handleCurrentChange(val) {
            this.rdmSearch.page = val;
            this.GetRDMSoftwareList();
        },


        
        /**
         * @desc 自定义斑马纹
         */
        vehicleTableRowClassName({row, rowIndex}) {
            if (rowIndex%2==0) {
                 return '';
            } else {
                return 'even-row';
            }
        },

        handleSelectionChange(val) {
            this.muitipleData = val;
        },

        //选中行变色
        rowClass({ row, rowIndex }) {
            if (this.selectRow.includes(rowIndex)) {
                return { "background-color": "#E6F7FF" };
            }
        },

        
    },
    beforeDestroy() {
    },
    
};
</script>
<style lang="less" scoped>
    .el-dialog-custom {
        
        font-size: 14px;
        /deep/ .el-dialog__header{
            text-align: left;
        }
        /deep/ .el-dialog__body{
            text-align: center;
        }
        /deep/ .el-dialog__title{
            font-weight: 700;
            font-size: 16px;
        }
        button {
            width: 110px;
            height: 38px;
        }
        .el-button + .el-button {
            margin-left: 20px;
        }
        .el-button + .el-button + .el-checkbox{
            margin-left: 20px;
        }
        /*上传组件样式*/
        .uploadFile{
            display: inline-block;
            width: 30%;
            margin-right: 2%;
        }
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 4vw;
            padding: 30% 0 20% 0;
        }
        .emptyFile .el-upload{
            border: 1px dashed #ff0000 !important;
        }

        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 100%;
            height: 178px;
            text-align: center;
        }
        .avatar {
            width: 24%;
            height: 100%;
            display: inline-block;
        }
          .Add{
            width: 100%;
            line-height: 20px;
            font-size: 13px;
            font-family: Arial, Arial-Regular;
            font-weight: 400;
            text-align: center;
            color: #555555;
            margin-top:15% ;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            padding-right: 10px;
        }
        /*上传组件中有文件时图片位置*/
        .el-icon-folder-opened{
            padding: 2.5px 0;
        }
        /*上传组件中有文件时文件名位置*/
        .haveFile{
            padding-top: 3px;
        }
        .upload{
            width: 100%;
            font-family: Arial, Arial-Regular;
            font-weight: 400;
            text-align: center;
            color: #555555;
        }
    }
    .el-table .default{
        height: 21px;
    }
    .soft-box{
        margin-top : 10px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10);
        .soft-top{
            border-bottom: 1px solid #e5e5e5;
            background: #fafafa;
            border-radius: 2px;
            padding: 0 18px;
            height: 79px;
            .title{
                font-size: 16px;
                font-weight: 700;
                line-height: 79px;
                float: left;
                margin-left:26px ;
            }
            .search{
                height: 48px;
                line-height: 79px;
            }
        }
        .soft-content{
            padding: 20px;
            .soft-content-top{
                float: left;
            }
            .soft-table{
                margin-top: 21px;
                .table-box {
                    border: 1px solid #F2F2F2;

                    /deep/ .el-table__header tr th {
                        background: #FAFAFA;
                    }
                    .green{
                        color: #00b194;
                    }
                    .table-operation {
                        display: flex;
                        justify-content: space-around;
                        color: #0079C1;

                        i {
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            &:last-child {
                                color: #FF4D4F;
                            }
                        }
                    }
                }
                .pagination {
                    margin-top: 20px;
                    text-align: right;
                    padding-bottom: 40px;
                    .paginationCount {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;
                    }
                    .paginationDiv {
                        display: inline-block;
                    }
                }
            }
        }
    }
</style>