<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import("./assets/public.less"); //必须在这里引入, 因为less需要通过less-loader做前处理, 不能在style里引入.

import userApi from "@/api/autogen/v2/user";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["userEntity", "language"]),
  },
  created() {
    let self = this;
    if (self.language == "en") {
      require("./assets/fonts/fonts-en.css");
    } else {
      require("./assets/fonts/fonts-ch.css");
    }

    if (!self.userEntity) {
      self.$router.push({
        name: "Login",
      });
    } else {
      //check auth
      userApi
        .getUserInfo()
        .then((resp) => {
          if (resp) {
            console.log("getUserInfo success");
          } else {
            self.$router.push({
              name: "Login",
            });
          }
        })
        .catch((err) => {});
    }
  },
};
</script>
<style>
html,
body {
  margin: 0px;
  background: #ffffff;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
