import request from "@/utils/request";

              //用户登录
              function login(data) {
                return request({
                  url: "Login/Login",
                  method: "post",
                  data
                });
              }
              
              //获取当前用户的所拥有的页面
              function searchPermissionList(data) {
                return request({
                  url: "Login/SearchPermissionList",
                  method: "post",
                  data
                });
              }
              
              //修改密码
              function updatePassword(data) {
                return request({
                  url: "Login/UpdatePassword",
                  method: "post",
                  data
                });
              }
              
              //修改修改操作权限的密码
              function updateUpdateAndUpgradePassword(data) {
                return request({
                  url: "Login/UpdateUpdateAndUpgradePassword",
                  method: "post",
                  data
                });
              }
              
              //用户登录
              function checkUserInfoWithModifyPermission(data) {
                return request({
                  url: "Login/checkUserInfoWithModifyPermission",
                  method: "post",
                  data
                });
              }
              
              //
              function testJWTAuthorize(data) {
                return request({
                  url: "Login/testJWTAuthorize",
                  method: "post",
                  data
                });
              }
              
              //
              function testToken(data) {
                return request({
                  url: "Login/testToken",
                  method: "post",
                  data
                });
              }
              
              //
              function testAllowAnonymous(data) {
                return request({
                  url: "Login/testAllowAnonymous",
                  method: "post",
                  data
                });
              }
              
            

            export default {
                login,
searchPermissionList,
updatePassword,
updateUpdateAndUpgradePassword,
checkUserInfoWithModifyPermission,
testJWTAuthorize,
testToken,
testAllowAnonymous
            };