import request from "@/utils/request";

              //获取所有公司列表
              function getCompanies(data) {
                return request({
                  url: "v2/Orgnization/GetCompanies",
                  method: "post",
                  data
                });
              }
              
              // 获取当前用户有权限查看的公司列表
//
////1. Curtis用户 可以查询所有公司
////2. 普通用户 只能查询自己的公司
              function getCompaniesByUserPrivilege(data) {
                return request({
                  url: "v2/Orgnization/GetCompaniesByUserPrivilege",
                  method: "post",
                  data
                });
              }
              
            

            export default {
                getCompanies,
getCompaniesByUserPrivilege
            };