<template>
    <div class="type-box">
        <el-row class="top">
            <el-col :span="12">
                <div class="top-title">
                    <span>{{ $t("FactoryProduct.MachineTypeList") }}:</span>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="top-search">
                    <el-input
                        :placeholder="$t('FactoryProduct.machineTypeName')"
                        suffix-icon="el-icon-search"
                         @change="getMachineTypelist(0)"
                        v-model="typeName"
                    >
                    </el-input>
                </div>
                <div class="top-class">
                    <el-row>
                        <el-col :span="12">
                            <div :class="cardClass" @click="getListType(0)">
                                <span>
                                    {{ $t("FactoryProduct.card")}}
                                </span>
                            </div>
                        </el-col>
                        <el-col :span="12">
                             <div :class="listClass" @click="getListType(1)">
                                <span>
                                    {{ $t("FactoryProduct.list")}}
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                    
                   
                </div>
                
            </el-col>
        </el-row>
        <el-row class="content"  v-if="IsHaveType&&ClassType" v-loading="loading">
            <el-button class="arrow left" type="primary" icon="el-icon-d-arrow-left" @click="last" 
            :disabled="pageParams.pageIndex == 1? true:false" v-if="typeList.length>0"></el-button>
            <el-button class="arrow right" type="primary" icon="el-icon-d-arrow-right" 
            :disabled="pageParams.pageIndex >= pageParams.total ? true:false" @click="next" v-if="typeList.length>0"></el-button>
            <el-col :span="6" v-for="(item, index) in typeList" :key="index">
                <el-card class="widthcard" body-style="width:100%;height:100%;box-sizing:border-box;" @click.native="createMachineFactory(item)">
                    <el-row>
                        <div class="imgDiv">
                            <img :src="item.ImageBase64 ? item.ImageBase64 : require('../../assets/image/emptycar.png')" 
                            :class="item.ImageBase64 ? 'typeimg':'emptyimg'" 
                            :alt="item.ImageName"/>
                        </div>
                    </el-row>
                    <el-row>
                        <div class="title">
                             <el-popover
                                placement="right"
                                trigger="hover"
                                popper-class="popover-type-box"
                                >
                                <vue-qr :text="QRCode[index]" :size="200"></vue-qr>
                                <span class="title" slot="reference">
                                    {{item.TypeName}} 
                                </span>
                                </el-popover>
                        </div>
                    </el-row>
                    <el-row>
                        <div class="type-dec">
                            <span >  {{$t('common.version')}}:{{item.TypeVersion}}</span>
                        </div>
                    </el-row>
                    <el-row>
                        <div class="version">
                            <el-popover
                                placement="bottom"
                                trigger="hover"
                                popper-class="popover-type-box"
                                v-for="(item1, index) in item.DeviceList" :key="index"
                            >
                                <span>  {{item1.Remark}}</span>
                                <div class="version-tag" slot="reference" >
                                    <span class="version-text"> {{item1.DeviceName}} ({{item1.SystemDisplayVersion}})</span>
                                </div>
                            </el-popover>
                            <div class="voltage-tag">
                                <span class="voltage"> {{$t('common.battery')}}:{{item.VoltMin}} V~{{item.VoltMax}} V</span>
                            </div>
                        </div>
                    </el-row>
                    <el-row>
                         <div class="type-dec">
                            {{item.Remark}}
                        </div>
                    </el-row>
                </el-card>
            </el-col>
            
            
        </el-row>
        <el-row class="content" v-loading="loading" v-else-if="IsHaveType&&!ClassType">
            <el-card class="boxcard" body-style="width:100%;height:100%;box-sizing:border-box;" 
            v-for="(item, index) in typeList" :key="index" @click.native="createMachineFactory(item)">
                <el-row class="info">
                    <el-col :xs="11" :sm="9" :md="7" :lg="5" :xl="3">
                        <div class="imgDiv">
                            <img :src="item.ImageBase64 ? item.ImageBase64 : require('../../assets/image/emptycar.png')" 
                            :class="item.ImageBase64 ? 'typeimg':'emptyimg'" 
                            :alt="item.ImageName"/>
                        </div>
                    </el-col>
                    <el-col :xs="13" :sm="15" :md="17" :lg="19" :xl="21">
                        <el-row class="type-div">
                            <div class="name">
                                {{item.TypeName}}
                            </div>
                            <div class="version">
                                <el-popover
                                    placement="bottom"
                                    trigger="hover"
                                    popper-class="popover-type-box"
                                    v-for="(item1, index) in item.DeviceList" :key="index"
                                >
                                    <span>  {{item1.Remark}}</span>
                                    <div class="version-tag" slot="reference" >
                                        <span class="version-text"> {{item1.DeviceName}} ({{item1.SystemDisplayVersion}})</span>
                                    </div>
                                </el-popover>
                                <div class="voltage-tag">
                                    <span class="voltage"> {{$t('common.battery')}}:{{item.VoltMin}} V~{{item.VoltMax}} V</span>
                                </div>
                            </div>
                            <div class="type-dec">
                                {{item.Remark}}
                            </div>
                        </el-row>
                    </el-col>
                </el-row>
            </el-card>
            <div class="more" v-if="pageParams.pageIndex < pageParams.total" @click="getMachineTypelist(1)">{{$t('common.loadingMore')}}</div>
        </el-row>
        <el-row v-else>
            <div class="type-empty">
                <img src="../../assets/image/big_car.png" />
                <div class="type-empty-text">
                    <span>{{$t("FactoryProduct.noMachineTypeText")}}</span>
                </div>
            </div>
        </el-row>
    </div>
</template>
<script>
import carTypeApi from '@/api/autogen/v2/cartype';
import vueQr from 'vue-qr';
export default {
    components: {
        vueQr
    },
    data() {
        return {
            typeList: [
            ],
            pageParams: {
                pageIndex: 1,
                total: 0,
            },
            search: '',
            loading: false,
            currentCompany:{},
            typeName:'',
            IsHaveType : true,
            cardClass:'top-class-text active',
            listClass:'top-class-text',
            ClassType: true ,
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
        QRCode() {
           return this.typeList.map((item) =>
           {
                //return item.Id+","+item.CompanyId+","+item.TypeName+","+item.TypeId+","+item.TypeVersion;
                return item.CompanyId+","+item.TypeName+","+item.TypeId+","+item.TypeVersion;
           });           
       },
    },
    created() {
        let self = this;
        self.getMachineTypelist(0);
    },
    methods: {
        //切换列表显示方式
        getListType(type){
            if(type === 0){
                this.cardClass = 'top-class-text active';
                this.listClass = 'top-class-text';
                this.ClassType = true;
            }else{
                this.ClassType = false;
                this.cardClass = 'top-class-text';
                this.listClass = 'top-class-text active';
            }
            this.typeName = '';
            this.getMachineTypelist(0);
        },

        //card 上一页
        last(){
            this.typeName = '';
            this.pageParams.pageIndex = this.pageParams.pageIndex - 1;
            this.getMachineTypelist(1);
        },
        //card 下一页
        next(){
            this.typeName = '';
            this.pageParams.pageIndex = this.pageParams.pageIndex + 1;
            this.getMachineTypelist(1);
        },

        
        //获取车型列表
        getMachineTypelist(type){
            this.loading =true;
            let pageSize = 0;
            if(this.ClassType){
                pageSize = 4;
                if(type === 0){
                    this.pageParams.pageIndex = 1;
                }
            }else{
                pageSize = this.userInfo.PageSize;
                if(type === 0){
                    this.pageParams.pageIndex = 1;
                }else if(type === 1){
                    this.pageParams.pageIndex = this.pageParams.pageIndex + 1;
                }
            }
            let params = {
                pageSize: pageSize,
                pageIndex: this.pageParams.pageIndex,
                companyId:this.userInfo.CompanyId,
                typeName: this.typeName
            };
            carTypeApi.GetPublishTypeList(params).then((res)=>{
                if(res.IsSuccess){
                    if(type === 0 || this.ClassType){
                        this.typeList = res.Data.PublishMachineTypeViewModelsList;
                    }else{
                        this.typeList = this.typeList.concat(res.Data.PublishMachineTypeViewModelsList); 
                    }
                    
                    this.pageParams.total = res.Data.TotalPage;
                    
                }
                if(this.typeList.length>0){
                        this.IsHaveType = true;
                    }else{
                        this.IsHaveType = false;
                    }
                this.loading = false;
            });
        },

        //跳转创建车辆页面
        createMachineFactory(item){
            var param = {
                companyId: this.userInfo.CompanyId,
                typeId : item.TypeId,
                typeVersion: item.TypeVersion,
                typeName:item.TypeName,
                monitorTemplateId:item.MonitorTemplateId
            };
            this.$router.push({
                name: "CreateMachineFactory",
                params: param,
            });
        }

        
    },
    mounted() {
    },
    beforeDestroy() {
    },
};
</script>

<style lang="less" scoped>
    .type-box{
        width: 100%;
        position: relative;
        .top{
            height: 40px;
            margin: 10px 0 20px 0px;
            .top-title{
                text-align: left;
                font-weight: 700;
                font-size: 16px;
                margin-right:62px ;
                line-height: 40px;
                margin-left: 46px;
            }
            .top-class{
                margin-right: 13px;
                float: right;
                width: 118px;
                .top-class-text{
                    height: 40px;
                    font-size: 14px;
                    line-height: 40px;
                    background: #ffffff;
                    border: 1px solid #dddddd;
                    border-radius: 2px;
                    cursor: pointer;
                }
                .active{
                    color: #ffffff;
                    background: #0079c1;
                    border-radius: 2px 0px 0px 2px;
                }
            }
            
            .top-search{
                width: 368px;
                line-height: 40px;
                float: right;
                
            }
        }
        .content{
            box-sizing: border-box;
            position: relative;
            padding: 0 20px;
            .arrow{
                width: 47px;
                height: 100px;
                font-size: 30px;
                position: absolute;
                padding: 8px 8px;
                top: 50%;
                z-index: 10;
                transform: translateY(-50%);
                i{
                    font-style: normal;
                }
            }
            .left{
                left:1px;
            }
            .right{
                right: 1px;
            }
            .widthcard{
                margin: 0 10px;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 0 15px #409EFF;
                }

                .imgDiv{
                    height: 210px;
                    background: #f2f2f2;
                    border-radius: 2px;
                    margin: 0 auto;
                    .emptyimg {
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        left: 50%;
                    }
                    .typeimg{
                        width: 100%;
                        height: 210px;
                    }
                        
                }
                .title{
                    margin-top:23px ;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 700;
                    text-align: left;
                }
                .version{
                    width: 100%;
                    margin-top:12px ;
                    .version-tag{
                        cursor: pointer;
                        &:hover {
                            border-color: #3399FF;
                        }
                        min-height: 25px;
                        width: 100%;
                        border: 1px solid #999999;
                        margin-bottom: 10px;
                        float: left;
                        text-align: center;
                        .version-text{
                            height: 25px;
                            line-height: 25px;
                            font-size: 14px;
                            
                        }
                        
                    }
                    .voltage-tag{
                        line-height: 25px;
                        min-height: 25px;
                        width: 100%;
                        border: 1px solid #999999;
                        margin-bottom: 10px;
                        float: left;
                        text-align: center;
                        .voltage{
                            color: #00B194;
                        }
                    }
                }
                .type-dec{
                    word-wrap:break-word;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #333333;
                    width: 100%;
                }
            }



            .boxcard{
                cursor: pointer;
                margin-bottom: 10px;
                transition: all 0.4s;
                border-radius: 2px;
                width: 100%;
                &:hover {
                    box-shadow: 0 0 15px #409EFF;
                }
                
                .info{
                    box-sizing:border-box;
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: relative;
                    .imgDiv{
                        width: 160px;
                        height: 124px;
                        background: #f2f2f2;
                        border-radius: 2px;
                        margin-top: 8px;
                        margin-left: 8px;
                        .emptyimg {
                            display: block;
                            position: absolute;
                            margin-top: 31px;
                            margin-left: 37px;
                        }
                        .typeimg{
                            width: 160px;
                            height: 124px;
                        }
                        
                    }
                    .type-div{
                        margin-top: 8px;
                        .name{
                            font-size: 18px;
                            font-weight: 700;
                            text-align: left;
                            color: #333333;
                            text-align: left;
                            letter-spacing: 0.18px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .version{
                            width: 100%;
                            margin-top:12px ;
                            .version-tag{
                                cursor: pointer;
                                &:hover {
                                    border-color: #3399FF;
                                }
                                height: 25px;
                                width: 271px;
                                border: 1px solid #999999;
                                margin-right:10px ;
                                margin-bottom: 10px;
                                float: left;
                                text-align: center;
                                .version-text{
                                    height: 25px;
                                    line-height: 25px;
                                    font-size: 14px;
                                    
                                }
                                
                            }
                            .voltage-tag{
                                line-height: 25px;
                                height: 25px;
                                width: 271px;
                                border: 1px solid #999999;
                                margin-right:10px ;
                                margin-bottom: 10px;
                                float: left;
                                text-align: center;
                                .voltage{
                                    color: #00B194;
                                }
                            }
                        }
                        .type-dec{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            color: #333333;
                            width: 100%;
                        }
                        .operation{
                            float: right;
                            margin-right: 14px;
                            img{
                                cursor: pointer;
                            }
                        }
                    }
                    
                }
            }
            .more{
                height: 60px;
                line-height: 60px;
                text-align: center;
                background: #fff;
                font-size: 16px;
                color: #333;
                transition: all 0.4s;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 0 15px #409EFF;
                }
            }
        }
        .type-empty{
            position: absolute;
            transform:translateY(30%);
            width: 100%;
            .type-empty-text{
                font-size: 30px;
                font-family: Adobe Heiti Std, Adobe Heiti Std-R;
                font-weight: R;
                text-align: center;
                color: #999999;
                margin-top: 44px;
            }
            .type-empty-button{
                margin-top: 29px;
                font-size: 16px;
                .type-empty-link{
                    color: #3394cd;
                    cursor: pointer;
                }
            }
        }
    }
    .qrcode {
        font-size: 14px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
    }
    
</style>