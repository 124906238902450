<template>
  <div id="AddMachineDevicesConfigPage">
    <el-card class="machineCard">
      <!--面包屑-->
      <div class="breadcrumbView">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: 'CompanyList' }">{{$t("common.company")}}</el-breadcrumb-item>
          <el-breadcrumb-item :to="'/VehicleList/'+machineInfo.CompanyId">{{machineInfo.CompanyName}}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="$store.state.isEdit">{{
              $t("addMachine.EditMachine")
            }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else>{{
              $t("addMachine.addMachine")
            }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 步骤条 -->
      <div class="stepsView">
        <el-steps :active="active" align-center>
          <el-step :title="$t('addMachine.setp1')"></el-step>
          <el-step :title="$t('addMachine.setp2')"></el-step>
          <el-step :title="$t('addMachine.setp3')"></el-step>
          <el-step :title="$t('addMachine.setp4')"></el-step>
        </el-steps>
      </div>
      <!-- 设备配置信息 -->
      <div class="machineDevicesConfig-content">
        <div v-if="deviceList.length==0">
          <el-form ref="devicesConfig" :model="devicesConfig" label-width="23%" :rules="rules">
            <el-row>
              <el-form-item
                :label="$t('addMachine.Type') + ':'"
                prop="DeviceType"
              >
                <el-col :span="11">
                  <el-radio-group v-model="devicesConfig.DeviceType" @change="clearFile">
                    <el-radio :label="deviceTypeList[0].value">{{deviceTypeList[0].label}}</el-radio>
                    <el-radio :label="deviceTypeList[1].value">{{deviceTypeList[1].label}}</el-radio>
                  </el-radio-group>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                :label="$t('addMachine.DeviceNodeId') + ':'"
                prop="DeviceNodeId"
              >
                <el-col :span="5">

                <el-select
                  v-model="devicesConfig.DeviceNodeId"
                  filterable
                  :placeholder="NodeIdList[0]==null?'':NodeIdList[0].CodeName"
                >
                  <el-option
                    v-for="item in NodeIdList"
                    :key="item.CodeName"
                    :label="item.CodeName"
                    :value="item.CodeName"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                :label="$t('addMachine.DeviceFile') + ':'"
                prop="DeviceFile"
              >
                <el-col :span="5">
                  <el-upload ref="DeviceFile"
                    list-type="picture-card"
                    :accept="deviceFileType"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="handleChangeDeviceFile"
                    :file-list="devicesConfig.DeviceFile"
                    action="#">
                    <div v-if="devicesConfig.DeviceFile.length>0" >
                      <img :src="imageUrl" class="el-icon-folder-opened"/><div class="haveFile">{{devicesConfig.DeviceFile[0].name}}</div>
                    </div>
                    <div  v-else>
                      <i class="el-icon-plus"></i>
                      <div class="upload">{{ $t('common.Upload') }}</div>
                    </div>
                  </el-upload>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item :label="$t('addMachine.remark') + ':'" prop="Remark">
                <el-col :span="18">
                  <el-input
                      type="textarea"
                      :rows="5"
                      class="remark"
                      v-model="devicesConfig.Remark"
                      :placeholder="$t('addMachine.remark')"
                  ></el-input>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item>
                <el-col class="dialogFooter">
                  <el-button @click="back">{{$t('common.cancel')}}</el-button>
                  <el-button type="primary" :disabled="disabled" @click="submit">{{$t('addMachine.Submit')}}</el-button>
                </el-col>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
        <div v-else>
          <el-row>
            <el-col :span="15">
              <span class="device">{{$t('addMachine.Devices')}} </span>
              <span class="type">{{ $t('addMachine.List') }}:{{deviceTypeList[0].label}}（<span class="count">{{deviceCount.se}}</span>）</span><span>{{deviceTypeList[1].label}}（<span class="count">{{deviceCount.f}}</span>）</span>
            </el-col>
            <el-col :span="9" class="addButton">
              <el-button v-if="IsPublishedType=='1' && $store.state.isEdit" type="primary" @click="addDevice" :disabled="true">{{$t('addMachine.Add')}}</el-button>
              <el-button v-else type="primary" @click="addDevice">{{$t('addMachine.Add')}}</el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-table
              :data="deviceListTemp"
              height="500"
              :default-sort="{prop: 'index', order: 'ascending'}"
              >
              <el-table-column
                prop="index"
                :label="$t('bizConfig.NO')"
                sortable
                align="center">
              </el-table-column>
              <el-table-column
                prop="DeviceName"
                :label="$t('bizConfig.DeviceName')"
                align="center">
              </el-table-column>
              <el-table-column
                prop="DeviceType"
                :formatter="DeviceTypeFormatter"
                align="center">
                <template slot="header" slot-scope="scope">
                    <el-popover popper-class="typePopper" placement="bottom" width="150" trigger="click">
                      <div class="firstItem">
                        <el-checkbox :indeterminate="isIndeterminate" label="All" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
                      </div>
                      <div v-for="item in deviceTypeList" :key="item.value" class="typeItem">
                        <el-checkbox :label="item.label"  v-model="item.isCheck" @change="handleCheckedTypeChange"></el-checkbox>
                      </div>
                      <!-- 这里的{{scope.row}}作用是为了解决scope没有被使用时的报错，若不使用slot-scope会导致多选框选不中的异常 -->
                    <div class="filter" slot="reference">{{ $t('addMachine.Type')  }}{{scope.row}}<i class="el-icon-arrow-down"></i></div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                prop="NodeId"
                :label="$t('addMachine.NodeId')"
                align="center">
              </el-table-column>
              <el-table-column
                prop="OSVersion"
                :label="$t('bizConfig.DsviceVersion')"
                :formatter="VersionFormatter"
                align="center">
              </el-table-column>
              <el-table-column
                prop="Remark"
                :label="$t('addMachine.remark')"
                align="center">
              </el-table-column>
              <el-table-column
                  :label="$t('bizConfig.Operation')"
                align="center">
                <template v-if="IsPublishedType!='1' && $store.state.isEdit" slot-scope="scope">
                  <div class="operation" @click="editDevice(scope.row)" ><i class="el-icon-edit"></i></div>                 
                  <div class="operation" @click="deleteDevice(scope.$index,scope.row)" ><i class="el-icon-delete"></i></div> 
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <el-row>
            <el-col class="footer">
              <el-button @click="back">{{
                $t("addMachine.Previous")
              }}</el-button>
              <el-button type="primary" @click="next">{{
                $t("addMachine.Next")
              }}</el-button>
            </el-col>
          </el-row>
        </div>
        <el-dialog
          :title="dialogTitle"
          :visible.sync="dialogVisible"
          width="40%">
          <el-form ref="devicesConfig" :model="devicesConfig" label-width="30%" :rules="rules">
            <el-row>
              <el-form-item
                :label="$t('addMachine.Type') + ':'"
                prop="DeviceType"
              >
                <el-col v-if="dialogStatus == 0" :span="18">
                  <el-radio-group v-model="devicesConfig.DeviceType"  @change="clearFile">
                    <el-radio :label="deviceTypeList[0].value">{{deviceTypeList[0].label}}</el-radio>
                    <el-radio :label="deviceTypeList[1].value">{{deviceTypeList[1].label}}</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col v-else :span="18">
                  {{deviceType}}
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                :label="$t('addMachine.NodeId') + ':'"
                prop="DeviceNodeId"
              >
                <el-col :span="10">

                <el-select
                  v-model="devicesConfig.DeviceNodeId"
                  filterable
                  :disabled="dialogStatus == 1"
                  :placeholder="NodeIdList[0]==null?'':NodeIdList[0].CodeName"
                >
                  <el-option
                    v-for="item in NodeIdList"
                    :key="item.CodeName"
                    :label="item.CodeName"
                    :value="item.CodeName"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                :label="$t('addMachine.DeviceFile') + ':'"
                prop="DeviceFile"
              >
                <el-col :span="10">

                  <el-upload ref="DeviceFile"
                    :accept="deviceFileType"
                    list-type="picture-card"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="handleChangeDeviceFile"
                    :file-list="devicesConfig.DeviceFile"
                    action="#">
                    <div v-if="devicesConfig.DeviceFile.length>0" >
                      <img :src="imageUrl" class="el-icon-folder-opened"/><div class="haveFile">{{devicesConfig.DeviceFile[0].name}}</div>
                    </div>
                    <div  v-else>
                      <i class="el-icon-plus"></i>
                      <div class="upload">{{ $t('common.Upload') }}</div>
                    </div>
                  </el-upload>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item :label="$t('addMachine.remark') + ':'" prop="Remark">
                <el-col :span="18">
                  <el-input
                      type="textarea"
                      :rows="5"
                      class="remark"
                      v-model="devicesConfig.Remark"
                      :placeholder="$t('addMachine.remark')"
                  ></el-input>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row class="border">
              <el-form-item>
                <el-col class="dialogFooter">
                  <el-button @click="dialogCancel">{{$t('common.cancel')}}</el-button>
                  <el-button v-if="dialogStatus == 0" type="primary" @click="submit" :disabled="disabled">{{$t('addMachine.Submit')}}</el-button>
                  <el-button v-else type="primary" @click="submitEdit" >{{$t('addMachine.Submit')}}</el-button>
                </el-col>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
import deviceApi from "@/api/autogen/v2/cardevices";
import systemConfigApi from "@/api/autogen/v2/systemConfig";
export default {
  name: "AddMachineDevicesConfigPage",
  data() {
    var checkDeviceFile = (rule, value, callback) => {
      let flag1 = !/\.(fdev)$/.test(this.devicesConfig.DeviceFile[0].name)&&this.devicesConfig.DeviceType ===2;
      let flag2 =!/\.(edev)$/.test(this.devicesConfig.DeviceFile[0].name)&&this.devicesConfig.DeviceType ===0;
      if (this.devicesConfig.DeviceFile.length ===0 || flag1 ||flag2){
        if (flag1){
          callback(
              new Error(
                  this.$t("addMachine.OnlyEDEVFile")
              )
          );
        }else if (flag2){
          callback(
              new Error(
                  this.$t("addMachine.OnlyEDEVFile")
              )
          );
        }else {
          new Error(
              this.$t("addMachine.NoFile")
          );
        }

      } else {
        callback();
      }
    };
    var checkNodeId = (rule, value, callback) => {
      var self = this;
      var flag = false;
      this.deviceList.forEach(item => {
        if(value == item.NodeId && self.devicesConfig.DeviceId != item.DeviceId){
          flag = true;
        }
      });
      if (flag) {
        callback(
          new Error(
            this.$t("addMachine.exitNodeId")
          )
        );
      } else {
        callback();
      }
    };
    return {
      //
      deviceFileType:'.edev',
      //车辆信息
      machineInfo:{},
      //设备配置表单数据
      devicesConfig:{
        DeviceType:0,
        DeviceNodeId:"",
        DeviceFile:[],
        Remark:"",
      },
      deviceType:"",
      //设备列表
      deviceList:[],
      deviceListTemp:[],
      NodeIdList:[],
      IsPublishedType:"0",
      //设备类型
      deviceTypeList:[
        {
          label:"E/SE",
          value:0,
          isCheck:true,
        },
        {
          label: this.$t('addMachine.FSeries'),
          value:1,
          isCheck:true,
        },
      ],
      deviceCount:{
        se:0,
        f:0
      },
      // 按钮是否可操作
      disabled: false,
      //上传文件成功后图片路径
      imageUrl: require('@/assets/image/ESEDevicePackage_file_icon.png'),
      DeviceFileFlag:true,
      //步骤条步骤
      active:1,
      checkAll:false,
      isIndeterminate:true,
      //列表索引
      deviceIndex:0,
      //弹窗flag
      dialogVisible:false,
      dialogTitle:"",
      dialogStatus:0,
      rules:{
        DeviceType: [
          {
            required: true,
            message:
              this.$t("addMachine.Type") + this.$t("common.required"),
            trigger: "change",
          },
        ],
        DeviceNodeId: [
          {
            required: true,
            message:
              this.$t("addMachine.DeviceNodeId") + this.$t("common.required"),
            trigger: "blur",
          },
          {
            validator: checkNodeId,
            trigger:'blur'
          }
        ],
        DeviceFile: [
          {
            required: true,
            message:
              this.$t("addMachine.DeviceFile") + this.$t("common.required"),
            trigger: "blur",
          },
          {
            validator: checkDeviceFile,
            trigger:'blur'
          },
        ],
      }
    };
  },
  created() {
    var self = this;
    this.machineInfo = this.$route.params;
    if(this.machineInfo.MachineComponents.DeviceList.length>0){
      this.deviceList = this.machineInfo.MachineComponents.DeviceList;
      this.deviceListTemp = this.machineInfo.MachineComponents.DeviceList;
      this.IsPublishedType=this.machineInfo.MachineComponents.MachineType.IsPublishedType;
      console.log("IsPublishedType==");
      console.log(this.IsPublishedType);
      var index = 1;
      this.deviceList.forEach(item => {
        if(item.DeviceType == 0){
          self.deviceCount.se++;
        }else{
          self.deviceCount.f++;
        }
        if(item.index==null){
          item.index = index;
          index++;
        }
      });
      this.deviceIndex = this.deviceList[this.deviceList.length-1].index;
    }
    this.getNodeIdList();
  },

  watch:{
    'devicesConfig.DeviceType':{
      handler(val){
        if (val===0){
          this.deviceFileType='.edev';
        }else {
          this.deviceFileType='.fdev';
        }
      },
      deep:true
    }

  },
  mounted(){

  },
  methods: {
    //编辑设备
    editDevice(row){
      if (Number(row.DeviceType)===0){
        this.deviceFileType='.edev';
      }else {
        this.deviceFileType='.fdev';
      }
      var self = this;
      this.dialogStatus = 1;
      this.dialogTitle = this.$t("addMachine.EditDeviceDialogTitle");
      this.devicesConfig = {
        DeviceId:row.DeviceId,
        DeviceType:Number(row.DeviceType),
        DeviceNodeId:row.NodeId,
        DeviceFile:row.DeviceFilesList.length>0?row.DeviceFilesList:[],
        Remark:row.Remark,
      };
      this.deviceTypeList.forEach(item => {
        if(item.value === row.DeviceType){
          self.deviceType = item.label;
        }
      });
      this.dialogVisible = true;
    },
    //提交编辑
    submitEdit(){
      var self = this;
      this.disabled = true;
      this.$refs["devicesConfig"].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          fd.append("DeviceType", self.devicesConfig.DeviceType);
          fd.append("DeviceNodeId", self.devicesConfig.DeviceNodeId);
          fd.append("DeviceFile", self.devicesConfig.DeviceFile[0].raw);
          fd.append("Remark", self.devicesConfig.Remark);
          deviceApi.addDevice(fd).then(res=>
          {
            console.log("res"+res);
            if(res.IsSuccess)
            {
              var data = res.Data;
              data.DeviceFilesList = self.devicesConfig.DeviceFile;
              this.deviceList.forEach((item,index) => {
                if(item.DeviceId === self.devicesConfig.DeviceId){
                  data.index=item.index;
                  this.deviceList.splice(index,1,data);
                  self.clearForm();
                }
              });
              self.handleCheckedTypeChange();
              self.NodeIdList.forEach(item => {
                if(self.devicesConfig.DeviceNodeId === item.CodeName){
                  item.disabled = true;
                }
              });
              self.dialogVisible = false;
              self.clearForm();
            }else{
              self.$message.error(res.ErrorMsg);
            }
            self.disabled = false;
          }).catch(() => {
            self.disabled = false;
          });
      this.handleCheckedTypeChange();
      this.dialogVisible = false;
      this.disabled = false;
        }
      });
    },
    //删除设备
    deleteDevice(index,row){
      var self = this;
      const Url = require("@/assets/image/error_76.png");
      const tip=this.$t('addMachine.IsDeleteData');
      this.$confirm("<img src=" + Url + "><p class='text'>"+tip+"</p>", '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t("addMachine.Delete"),
        cancelButtonText: this.$t("common.cancel"),
        iconClass:'warningTipIcon',
        customClass:"warningTip",
        center: true,
        showClose:false
      }).then(() => {
        var param = {
          DeviceIdList:[row.DeviceId],
        };
        var device = {};
        for (let i = 0; i < self.deviceList.length; i++) {
          if (self.deviceList[i].DeviceId == row.DeviceId) {
            if (self.deviceList[i].DeviceType == 0) {
              self.deviceCount.se -= 1;
            } else {
              self.deviceCount.f -= 1;
            }
            device = self.deviceList.splice(i, 1);
          }
          self.handleCheckedTypeChange();
        }
        self.NodeIdList.forEach(item => {
          if (device[0].NodeId == item.CodeName) {
            item.disabled = false;
          }
        });
      });
    },
    //打开添加设备弹窗
    addDevice(){
      this.dialogStatus = 0;
      this.dialogTitle = this.$t("addMachine.AddDeviceDialogTitle"),
      this.dialogVisible = true;
      this.clearForm();
    },
    dialogCancel(){
      this.clearForm();
      this.dialogVisible = false;
    },
    //获取NodeId列表
    getNodeIdList(){
      var self = this;
      systemConfigApi.getNodeIdList().then(res => {
         if(res.IsSuccess){
            self.NodeIdList = res.Data;
            self.NodeIdList.forEach(item => {
              for (let i = 0; i < self.deviceList.length; i++) {
                if(self.deviceList[i].NodeId == item.CodeName){
                  item.disabled = true;
                }
              }
            });
         }
      });
    },
    // 清除表单数据
    clearForm(){
      this.devicesConfig={
        DeviceType:0,
        DeviceNodeId:"",
        DeviceFile:[],
        Remark:"",
      };
    },

    //切换类型时，清空文件
    clearFile(){
      this.devicesConfig.DeviceFile = [];
    },
    //全选
    handleCheckAllChange(){
      var self = this;
      this.deviceTypeList.forEach(item => {
        item.isCheck = self.checkAll;
      });
      if(self.checkAll){
        self.deviceListTemp = self.deviceList;
      }else{
        self.deviceListTemp = [];
      }
      this.isIndeterminate = false;
    },
    //Type复选框变更
    handleCheckedTypeChange(){
      var self = this;
      self.deviceListTemp = [];
      var falg = true;
      this.deviceTypeList.forEach(data => {
        if(!data.isCheck){
          falg = false;
        }
        if(data.isCheck){
          self.deviceList.forEach(item => {
            if(item.DeviceType == data.value){
              self.deviceListTemp.push(item);
            }
          });
        }
      });
      if(falg){
        this.isIndeterminate = false;
        this.checkAll = true;
      }else{
        this.isIndeterminate = true;
        this.checkAll = false;
      }

    },
    //设备类型格式化
    DeviceTypeFormatter(row, column) {
      return row.DeviceType == 0?"E/SE":"F Series";
    },
    //设备版本格式化
    VersionFormatter(row, column) {
      return "OS:"+row.OSVersion+",VCL"+row.VCLVersion;
    },
    // 保存设备
    submit(){
      var self = this;
      self.disabled = true;
      this.$refs["devicesConfig"].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          fd.append("DeviceType", self.devicesConfig.DeviceType);
          fd.append("DeviceNodeId", self.devicesConfig.DeviceNodeId);
          fd.append("DeviceFile", self.devicesConfig.DeviceFile[0].raw);
          fd.append("Remark", self.devicesConfig.Remark);
          deviceApi.addDevice(fd).then(res=>
          {
            console.log("res"+res);
            if(res.IsSuccess)
            {
              var data = res.Data;
              self.deviceIndex+=1;
              data.index = self.deviceIndex;
              data.DeviceFilesList = self.devicesConfig.DeviceFile;
              self.deviceList.push(data);
              self.handleCheckedTypeChange();
              if(self.devicesConfig.DeviceType==0){
                self.deviceCount.se+=1;
              }else{
                self.deviceCount.f+=1;
              }
              self.NodeIdList.forEach(item => {
                if(self.devicesConfig.DeviceNodeId == item.CodeName){
                  item.disabled = true;
                }
              });
              self.dialogVisible = false;
              self.clearForm();
            }else{
              self.$message.error(res.ErrorMsg);
            }
            self.disabled = false;
          }).catch(() => {
            self.disabled = false;
          });
        } else {
          console.log("error submit!!");
          self.disabled = false;
          return false;
        }
      });
    },
    selectLegend(d) {
      if (d.label === "all" && d.isCheck) {
        this.deviceTypeList.forEach(function (item) {
          item.isCheck = true;
        });
      } else if (d.label === "all" && !d.isCheck) {
        this.deviceTypeList.forEach(function (item) {
          item.isCheck = false;
        });
      } else {
        return;
      }
    },
    //上传文件
    handleChangeDeviceFile(file, fileList){
       if (this.devicesConfig.DeviceType ===0) {
         if (!/\.(edev)$/.test(file.name)) {
           this.$message({
             message:this.$t('common.FileRequire') +"edev",
             type: "error",
           });
           return;
         }else {
           this.devicesConfig.DeviceFile = [];
           this.devicesConfig.DeviceFile = [file];
         }
       } else if (this.devicesConfig.DeviceType ===1){
           if (!/\.(fdev)$/.test(file.name)) {
               this.$message({
                 message: this.$t('common.FileRequire')+"fdev",
                 type: "error",
               });
             return;
           } else {
             this.devicesConfig.DeviceFile = [];
             this.devicesConfig.DeviceFile = [file];
           }

      }
    },
    //下一步
    next(){
      this.machineInfo.MachineComponents.DeviceList = this.deviceList;
      this.$router.push({name:'AddMachineFaultConfig',params:this.machineInfo});
    },
    back(){
      this.$router.push({name:'AddMachineBasicInfo',params:this.machineInfo});
    },
  },
};
</script>

<style lang="less">
/*车辆信息样式 */
.machineDevicesConfig-content{
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  /*隐藏数字框右边按钮 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /*NodeId样式 */
  .unit {
    margin: 0px 0px 0px 10px;
    color: #333333;
  }
  .el-col{
    text-align: left;
    .el-input--prefix .el-input__inner {
      padding-left: 34px;
    }
  }
  .el-upload--picture-card{
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 27% 0;
    line-height: inherit;
    height: auto;
    i{
      font-weight: 1000;
      color: #555555;
    }
    .haveFile{
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow: hidden;
      padding: 0 10%;
    }
  }
  .device{
    font-weight: 700;
  }
  .type{
    margin: 0 30px 0 10px;
  }
  .count{
    color:#0066CC;
    font-weight: 700;
  }
  .addButton{
    text-align: right !important;
    .el-button{
      width: 110px;
      height: 38px;
      border-radius: 4px;
    }
  }
  .el-table{
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    width: 100%;
    .filter i{
      margin-left: 8px;
    }
    .operation{
      display: inline-block;
      font-size: 20px;
      .el-icon-delete{
        margin-left: 30px;
        color: #FF4D4F;
      }
      .el-icon-edit{
        color: #047BC2;
      }
    }
    th{
      background-color: #fafafa;
    }
    td{
      border-bottom: 1px solid #f0f0f0;
    }
  }
  /*底部按钮样式 */
  .footer{
    text-align: center;
    margin: 30px 0;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  .border{
    padding-top: 20px;
    border-top: 1px solid #eaeef0;
    margin: 0 -2%;
  }
  /*弹窗底部按钮样式 */
  .dialogFooter{
    text-align: left;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  /*弹窗标题样式 */
  .el-dialog__header {
    padding: 20px !important;
    padding-bottom: 10px !important;
    height: auto !important;
    text-align: left;
    background: #f2f2f2;
    border-bottom: 1px solid #eaeef0;
  }
}
.warningTip{
  text-align: center;
  width: 504px !important;
  .el-message-box__header{
    padding: 0;
  }
  .el-message-box__content{
    padding:0;
  }
  .text{
    margin-top: 27px;
    margin-bottom: 30px;
    font-size: 16px;
  }
  .el-button {
    width: 110px;
    height: 38px;
    background: #fff;
    font-size: 14px;
  }
  .el-button--default:hover {
    background: transparent;
  }
  .el-button--primary {
    margin-left: 20px !important;
    background: #0079c1;
  }
  .el-button--primary:hover {
    background: #0369a6;
  }
}
.typePopper{
  padding:0px !important;
  .typeItem{
    padding: 0px 30px 10px 30px;
  }
  .firstItem{
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 30px;
    margin-bottom: 10px;
  }
  .el-checkbox__inner{
    border-radius: 2px;
  }
}

</style>
