<template>
    <div class="infofrmation-box">
        <Card :title="$t('myvehicle.vehicleBasicInformation')" class="marginBottom">
            <div class="infofrmation-basic-box" slot="content">
                <div class="infofrmation-basic-title">
                    {{carDetails.MachineSN}}
                </div>
                <div class="infofrmation-basic-table">
                    <table cellpadding="0" cellspacing="0">
                        <tr>
                            <td rowspan="3" class="infofrmation-basic-table-img">
                                <img src="../../assets/image/car/cardefault.png" class="car">
                                <template v-if="carDetails.CarBundleStatus == 0">
                                    <img src="../../assets/image/car/lixian.png" class="car-status">
                                </template>
                                <template v-else-if="carDetails.CarBundleStatus == 1">
                                </template>
                                 <template v-else>
                                    <img src="../../assets/image/car/error.png" class="car-status">
                                </template>
                            </td>
                            <td>
                                <span class="weight">{{$t('myvehicle.machineType')}}:</span>
                                <el-popover
                                placement="right"
                                width="200"
                                trigger="click">
                                <vue-qr  v-if="QRCode" :text="QRCode" :size="200"></vue-qr>
                                <span class="qrcode" slot="reference">
                                    {{carDetails.TypeName.replace(this.companyId+"_AutoCreated_MachineType_","Basic Machine Type_")}}
                                    
                                </span>
                                </el-popover>
                            </td>
                            <td>
                                <span class="weight">RDM No:</span>
                                <span  class="qrcode" @click="openRDMDetails()" :title="$t('RDMManage.RDM_Detail')" v-if="privilege.GrantRDMFunction">
                                    {{carDetails.RDMNO}}
                                    <el-tag :type="getBindtatus(carDetails.CarRDMStatus,1)"
                                    >{{checkExistData(rowStatusList,carDetails.CarRDMStatus, 'value').text}}</el-tag>
                                </span>
                                 <span :title="$t('RDMManage.RDM_Detail')" v-else>
                                    {{carDetails.RDMNO}}
                                    <el-tag :type="getBindtatus(carDetails.CarRDMStatus,1)"
                                    >{{checkExistData(rowStatusList,carDetails.CarRDMStatus, 'value').text}}</el-tag>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="weight">{{$t('myvehicle.ksiStatus')}}:</span>
                                <span>
                                    <el-tag :type="getBindtatus(carDetails.CarRDMStatus,1)" v-if="!carDetails.CarRDMStatus"
                                    >--</el-tag>
                                    <el-tag :type="getBindtatus(carDetails.CarKSIStatus,1)" v-else
                                    >{{checkExistData(ksiStatusList,carDetails.CarKSIStatus, 'value').text}}</el-tag>
                                     <!--span v-if="!carDetails.CarRDMStatus" class="ksiComment"> {{ $t("myvehicle.KSIStatusComment") }}</span-->
                                </span>
                            </td>
                            <td>
                                <span class="weight">{{$t('myvehicle.simStatus')}}:</span>
                                <span>
                                    <el-tag :type="getBindtatus(carDetails.CarRDMStatus,1)" v-if="!carDetails.CarRDMStatus"
                                    >--</el-tag>
                                    <el-tag :type="getBindtatus(carDetails.RDMSIMStatus,1)" v-else
                                    >{{checkExistData(RDMSIMStatus,carDetails.RDMSIMStatus, 'value').text}}</el-tag>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="weight">{{$t('myvehicle.batteryStatus')}}:</span>
                                <span>
                                    <el-tag :type="getBindtatus(carDetails.CarRDMStatus,1)"  v-if="!carDetails.CarRDMStatus"
                                    >--</el-tag>
                                    <el-tag :type="getBindtatus(carDetails.BatteryVoltageStatus,2)" v-else
                                    >{{checkExistData(BatteryVoltageStatus,carDetails.BatteryVoltageStatus, 'value').text}}</el-tag>
                                </span>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </div>
                <div class="infofrmation-basic-list">
                    <div class="infofrmation-basic-list-icon">
                        <img src="../../assets/image/icon_rdm.png">
                    </div>
                    <div class="infofrmation-basic-list-content">
                        <div class="infofrmation-basic-list-title" v-if="carDetails.MachineComponents && carDetails.MachineComponents.RDMProperties">
                            {{carDetails.RDMNO}}({{carDetails.MachineComponents.RDMProperties.NodeId}}),{{carDetails.Remark}}
                        </div>
                        <div class="infofrmation-basic-list-desc" v-if="carDetails.MachineComponents">
                            <p>
                                <span>{{ $t("myvehicle.latestVersion") }}：</span>
                                <span>{{carDetails.MachineComponents.RDMProperties.LatestVersion}}</span>
                            </p>
                            <p>
                                <span>{{ $t("myvehicle.fieldVersion") }}：</span>
                                <span>{{carDetails.MachineComponents.RDMProperties.FeildDisplayVersion}}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="infofrmation-basic-list-box" v-if="carDetails.MachineComponents && carDetails.MachineComponents.DeviceList">
                    <div class="infofrmation-basic-list" v-for="(item, key) in carDetails.MachineComponents.DeviceList" :key="key">
                        <div class="infofrmation-basic-list-icon">
                            <img src="../../assets/image/icon_controller.png">
                        </div>
                        <div class="infofrmation-basic-list-content">
                            <div class="infofrmation-basic-list-title">
                                {{item.DeviceName}}({{item.NodeId}}),{{item.Remark}}
                            </div>
                            <div class="infofrmation-basic-list-desc">
                                <p>
                                    <span>{{ $t("myvehicle.systemVersion") }}：</span>
                                    <span>{{item.SystemDisplayVersion}}</span>
                                </p>
                                <p>
                                    <span>{{ $t("myvehicle.fieldVersion") }}：</span>
                                    <span>{{item.FeildDisplayVersion}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="infofrmation-basic-footer" slot="footer">
                <div class="basic-footer-label">
                    <span>{{ $t("myvehicle.memberof") }}:</span>
                    <span>{{machineGroupStr}}</span>
                </div>
                <div class="basic-footer-btn">
                    <span @click="doOpenMachineGroup" v-if="privilege.MachineGroupManage">{{ $t("myvehicle.groupManage") }}</span>
                </div>
            </div>
        </Card>
        <Card :title="$t('myvehicle.vehicleFault')" class="marginBottom">
            <div class="infofrmation-vehicle-box" slot="content">
                <div  v-if="carActiveDetails.ListDeviceOnlineFault && carActiveDetails.ListDeviceOnlineFault.length > 0">
                    <div class="infofrmation-vehicle-title">
                        {{carActiveDetails.MachineFaultCount}} {{ $t("myvehicle.FaultForMachine") }} !
                        <span  v-for="(item,id) in carActiveDetails.ListDeviceOnlineFault" :key="id">
                            {{item.DeviceFaultCount}} {{ $t("myvehicle.FaultForController") }} {{item.DeviceName}} !
                        </span>
                    </div>
                    <div class="infofrmation-vehicle-content" >
                        <div  v-for="(item,id) in carActiveDetails.ListDeviceOnlineFault" :key="id">
                            <div class="infofrmation-vehicle-list" v-for="(item1,id) in item.ListOnlineFault" :key="id">
                                <span class="infofrmation-vehicle-list-code">{{item1.FaultCode}}:</span>
                                <span class="infofrmation-vehicle-list-code">{{item1.FaultName}}; </span>
                                <span class="infofrmation-vehicle-list-content">{{$t('bizConfig.Remark')}}: &nbsp;{{item1.Remark}};&nbsp;&nbsp;</span>
                                <span class="infofrmation-vehicle-list-content">{{$t('myvehicle.faultStartTime')}}: &nbsp;{{formatTimestampUTC(new Date(item1.TimestampUtc_FaultBegin),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}</span>
                            </div>  
                        </div>
                    </div>
                </div>
                <div v-else class="emptyfault">
                    <div>
                        <img class="el-icon-s-order" src="../../assets/image/car/cargood.png">
                    </div>
                    <div>
                        <span  class="faulttext">{{ $t("myvehicle.goodCondition") }}</span>
                    </div>
                </div>
            </div>
            
        </Card>
        <Card :title="$t('myvehicle.faultAffectTime')" class="marginBottom">
            <div class="infofrmation-fault-box" slot="content">
                <div class="infofrmation-fault-pie-box" ref="pie-chart-box"></div>
                <div class="infofrmation-fault-bar-box" ref="bar-chart-box"></div>
            </div>
        </Card>
        <Card :title="$t('myvehicle.latestHalfHourBatteryVoltage')" class="marginBottom">
            <div class="infofrmation-latest-box" slot="content" ref="line-chart-box"></div>
        </Card>
        <Card :title="$t('myvehicle.parameterMonitor')">
            <div slot="header" class="infofrmation-parameter-header">
                <span v-for="(item,key) in tabData" :key="key" :class="{isSelected: item.value == tabActive}" @click="tabClick(item.value)">
                    {{item.label}}
                </span>
                <span v-if="iscollapse == 0" class="collapse" @click="switchCard(1)">{{ $t("myvehicle.collapse") }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                <span v-else class="collapse" @click="switchCard(0)">{{ $t("myvehicle.expand") }}<i class="el-icon-arrow-right el-icon--right"></i></span>
            </div>
            <div v-if="iscollapse == 0" class="infofrmation-parameter-box" slot="content">
                <IconContent v-for="(item,key) in iconData" :key="key" :iconData="item"></IconContent>
            </div>
        </Card>
    </div>
</template>
<script>
import Card from "@/components/ui/Card.vue";
import IconContent from "./components/IconContent.vue";
import carApi from "@/api/autogen/v2/carList";
import faultApi from '@/api/autogen/v2/fault';
import machinegroupApi from "@/api/autogen/v2/machinegroup";
import { checkExistData,formatTimeStr,formatTimestampUTC,deepClone,ifGrantedTheOperationForThisFunction,ifGrantedTheOperationForRDMManageFunction,formatTimeUTCStr} from '@/utils';
import { ConstDefinition } from "@/utils/constDefinition";
import vueQr from 'vue-qr';

export default {
    name: '',
    components: {
        Card,
        IconContent,
        vueQr
    },
    props: {
        companyId: {
            type: String,
            default: '',
        },
        machineId: {
            type: String,
            default: '',
        },
    },
    inject:['openMachineGroup'],
    data() {
        return {
            timer: null,
            carDetails: {

            },
            carActiveDetails: {

            },
            iconData: [],

            tabData: [
                {
                    label: this.$t("myvehicle.class"),
                    value: 1,
                },
                {
                    label: this.$t("myvehicle.mix"),
                    value: 2,
                },
            ],
            paramsUnitData: [],
            iconUnitData: {},
            tabActive: 1,
            iscollapse : 0,
            parameterValue: 1,
            // row状态
            rowStatusList: [
                {
                    text: this.$t("common.carState_offline"),
                    value: ConstDefinition.CarStatus.Offline,
                },
                {
                    text: this.$t("common.carState_online"),
                    value: ConstDefinition.CarStatus.Online,
                },
                {
                    text: this.$t("common.carState_fault"),
                    value: ConstDefinition.CarStatus.Error,
                },
            ],
            // KSI状态
            ksiStatusList: [
                {
                    text: this.$t("common.carKSIState_off"),
                    value: ConstDefinition.KSIStatus.ElectricOff,
                },
                {
                    text: this.$t("common.carKSIState_on"),
                    value: ConstDefinition.KSIStatus.ElectricOn,
                },
            ],
            RDMSIMStatus: [
                {
                    text: this.$t("common.rdmSimState_off"),
                    value: ConstDefinition.RDMSIMStatus.ShutDown,
                },
                {
                    text: this.$t("common.rdmSimState_on"),
                    value: ConstDefinition.RDMSIMStatus.Normal,
                },
            ],
            BatteryVoltageStatus: [
                {
                    text: this.$t("common.batteryVoltageStatus_normal"),
                    value: ConstDefinition.VoltageState.Normal,
                },
                {
                    text: this.$t("common.batteryVoltageStatus_warn"),
                    value: ConstDefinition.VoltageState.Warning,
                },
                {
                    text: this.$t("common.batteryVoltageStatus_error"),
                    value: ConstDefinition.VoltageState.Danger,
                },
            ],
            ParamClass:[
                {
                    text: this.$t("myvehicle.measurementClass"),
                    value: ConstDefinition.ParamClass.Class1,
                },
                {
                    text: this.$t("myvehicle.physicalclass"),
                    value: ConstDefinition.ParamClass.Class2,
                },
                {
                    text: this.$t("myvehicle.timeClass"),
                    value: ConstDefinition.ParamClass.Class3,
                },
                {
                    text: this.$t("myvehicle.deviceClass"),
                    value: ConstDefinition.ParamClass.Class4,
                },
                {
                    text: this.$t("myvehicle.switchClass"),
                    value: ConstDefinition.ParamClass.Class5,
                },
                {
                    text: this.$t("myvehicle.otherClass"),
                    value: ConstDefinition.ParamClass.Class6,
                },
            
            ],
            QRCode :'',
            AnalysisTotalFaultDurationList:[],
            colors: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de", "#3ba272","#fc8452","#9a60b4","	#ea7ccc","#800080"],
            machineGroup:{},
            machineGroupStr:'',
            privilege:{
                MachineGroupManage:false,
                GrantRDMFunction:false,
            }
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
        TotalFaultDurationChart(){
            let xDataList = [];
            let yDataList = [];
            let data = [];
            if(this.AnalysisTotalFaultDurationList){
                this.AnalysisTotalFaultDurationList.forEach(item => {
                    let effctHour = item.EffectTimeDuration/1000/60/60;
                    xDataList.push(effctHour);
                    yDataList.push(item.Fault);
                    let array = {
                        name : item.Fault,
                        value : effctHour
                    };
                    data.push(array);
                });
            }
            let totalData = {
                xDataList : xDataList,
                yDataList : yDataList,
                data : data
            };
            console.log("Information Fault Duration totalData",totalData);
            return totalData;
        }
    },
    created() {
        this.refreshPage();
        this.initialFunctionOperationPrivilege();
    },
    methods: {
        formatTimeStr,
        formatTimestampUTC,
        formatTimeUTCStr,
        ifGrantedTheOperationForThisFunction,
        ifGrantedTheOperationForRDMManageFunction,
        initialFunctionOperationPrivilege()
        {
                let self = this;
                self.privilege.MachineGroupManage = ifGrantedTheOperationForThisFunction("MachineGroupManage");
                self.privilege.GrantRDMFunction = ifGrantedTheOperationForRDMManageFunction("View");
                console.log("this.privilege.GrantRDMFunction==");
                console.log(self.privilege.GrantRDMFunction);
        },
        //递归树接口json
        setTreeJson(array){
            for(let key in array){
                this.machineGroupStr = this.machineGroupStr + " > " + array[key].MachineGroupName;
                if(array[key].children){
                    this.setTreeJson(array[key].children);
                }
            }
        },

        //车辆的车辆组织架构
        GetOneMachineGroupLevel(){
            let param ={
                companyId : this.companyId,
                machineId :this.machineId
            };
            machinegroupApi.GetOneMachineGroupLevel(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.machineGroup=res.Data;
                    if(this.machineGroup){
                        this.machineGroupStr = this.machineGroup.MachineGroupName;
                        if(this.machineGroup.children){
                            this.setTreeJson(this.machineGroup.children);
                        }
                    }
                }
            });
        },

        checkExistData(array = [], value = '', key = '') {
            let data = checkExistData(array, value, key);

            if(data.isExist) {
                return data.data;
            } else {
                return {
                    [key]: value,
                };
            }
        },
        //折叠展开
        switchCard(type){
            this.iscollapse = type;
        },
        //tag类型
        getBindtatus(bindStatus,type) {
            let bindColor = {};
            if(type == 1){
                bindColor = {
                    0: 'info',
                    1: 'success',
                    2: 'danger'
                };
            }else if(type == 2){
                bindColor = {
                    0: 'success',
                    1: 'warning',
                    2: 'danger'
                };
            }
            
            return bindColor[bindStatus];
        },

        tabClick(value, type) {
            if(this.tabActive != value || type == 'init') {
                this.tabActive = value;

                let iconData = [];

                //1class2mix
                if(value == 1) {
                    let noUnitData=[];  
                    let ExistedUnitData=[];                   
                    this.paramsUnitData.forEach(item => {
                        let obj = {
                            title: checkExistData(this.ParamClass, item.ClassName, 'value').data.text,
                            data: [],
                        };
                        
                        this.iconUnitData && 
                        this.iconUnitData.ListDeviceOnlineMonitorParameter && 
                        this.iconUnitData.ListDeviceOnlineMonitorParameter.forEach(values => {
                            values.ListOnlineParameterDetail.forEach(val => {
                                let dataChange = checkExistData(item.parameterUnitDefList, val.Unit, 'Unit');
                                if(dataChange.isExist)
                                {
                                    obj.data.push({value: val.Value, desc: val.DisplayParaName, unit: val.Unit == 'BOOL' ? val.Unit : dataChange.data.DispalyUnit});
                                    ExistedUnitData.push(val);
                                }
                            });    
                        });
                        iconData.push(obj);
                    });

                    //查找其他未找到单位的数据
                    let objOtherData = [];
                    let title=checkExistData(this.ParamClass, "Class6", 'value').data.text;
                    iconData.forEach(iconClass=>{
                        if(iconClass.title==title)
                            objOtherData=iconClass.data;
                    });
                   
                    this.iconUnitData && 
                    this.iconUnitData.ListDeviceOnlineMonitorParameter && 
                    this.iconUnitData.ListDeviceOnlineMonitorParameter.forEach(values => {
                            values.ListOnlineParameterDetail.forEach(val => {
                                let existedFlag=false;
                                ExistedUnitData.forEach(exited=>{
                                    if(val.Value==exited.Value && val.DisplayParaName==exited.DisplayParaName&& val.Unit==exited.Unit&& val.DispalyUnit==exited.DispalyUnit)
                                            existedFlag=true;
                                });
                            if(!existedFlag)
                            {
                                objOtherData.push({value: val.Value, desc: val.DisplayParaName, unit: "" });
                            }    
                        });                        
                    }); 
                    iconData.forEach(iconClass=>{
                        if(iconClass.title==title)
                            iconClass.data=objOtherData;
                    });             
                    
                } else if(value == 2) {
                    let array = [];

                    this.paramsUnitData.forEach(item => {
                        item.parameterUnitDefList.forEach(value => {
                            array.push({...value, ClassName: item.ClassName});
                        });
                    });

                    let obj = {
                        title: '',
                        data: [],
                    };
                    this.iconUnitData && 
                    this.iconUnitData.ListDeviceOnlineMonitorParameter && 
                    this.iconUnitData.ListDeviceOnlineMonitorParameter.forEach(values => {
                        values.ListOnlineParameterDetail.forEach(val => {
                            let dataChange = checkExistData(array, val.Unit, 'Unit');

                            if(dataChange.isExist) {
                                val.DispalyUnit = dataChange.data.DispalyUnit;
                            }
                            obj.data.push({value: val.Value, desc: val.DisplayParaName, unit: val.DispalyUnit == 'BOOL' ? val.DispalyUnit : (val.DispalyUnit ? val.DispalyUnit : val.Unit)});
                        });
                    });
                    iconData.push(obj);
                }

                this.iconData = iconData;
            }
        },
        getMyCarMonitorList() {
            let params = {
                machineId: this.machineId,
            };
            carApi.getMyCarMonitorList(params).then(res => {
                let obj = {};

                if(res.Data) {
                    obj = res.Data;
                }
                this.iconUnitData = obj;
                this.tabClick(this.tabActive, 'init');
            });
        },
        //定时获取参数值用
        getMyCarMonitorValueList() {
            let self=this;
            self.GetOneMachineGroupLevel();
            self.getCarDetails().then((val)=>{
                self.getParameterList();
                self.getMyCarMonitorList();               
                self.getAnalysisTotalFaultDuration().then(val=>{
                    self.initTotalFaultDurationPie();
                    self.initTotalFaultDurationBar();                    
                });    
                self.initVoltageLine();          
            });
        },

        getParameterList() {
            let params = {
                machineId: this.machineId,
            };
            carApi.getParameterList(params).then(res => {
                let array = [];

                if(res.Data) {
                   array = res.Data;
                }
                this.paramsUnitData = array;                
            });
        },

         //初始化定时器
        initTime() {
            this.timer = window.setInterval(() => {
                this.getMyCarMonitorValueList();
                //this.getCarDetails();
                //this.getMyCarActive();
            }, 30000);
        },

         //清除定时器
        clearTime() {
            window.clearInterval(this.timer);
            this.timer = null;
        },

        getCarDetails() {
            let params = {
                companyId: this.companyId,
                machineId: this.machineId,
            };
            return carApi.getCarDetails(params).then(res => {
                if(res.Data) {
                    this.carDetails = res.Data;
                    if(this.carDetails.MachineComponents.MachineType && this.carDetails.MachineComponents.MachineType.QRCode)
                        this.QRCode = this.carDetails.MachineComponents.MachineType.QRCode;
                    this.$emit('getHeaderData', this.carDetails);
                    this.getMyCarActive();
                }
            });
        },
        getMyCarActive() {
            let params = {
                rdmno: this.carDetails.MachineComponents.RDMProperties ? this.carDetails.MachineComponents.RDMProperties.RDMNo : '',
                machineId: this.machineId,
            };
            carApi.getMyCarActive(params).then(res => {
                if(res.Data) {
                    this.carActiveDetails = res.Data;
                }
            });
        },
        refreshPage() {
            let self=this;
            self.GetOneMachineGroupLevel();
            self.getCarDetails().then((val)=>{
                self.getParameterList();
                self.getMyCarMonitorList();               
                self.getAnalysisTotalFaultDuration().then(val=>{
                    self.initTotalFaultDurationPie();
                    self.initTotalFaultDurationBar();                    
                });
                self.initVoltageLine();             
                self.initTime();   
            });          
        },

        //获取故障整体影响时长
        getAnalysisTotalFaultDuration(){
            let array = [];
            array.push(this.carDetails.MachineSN);
            var dateTime= new Date();
            let param = {
                    startDay: formatTimeStr(dateTime,'YYYY-MM-DD'),
                    endDay: formatTimeStr(dateTime,'YYYY-MM-DD'),
                    filterMachineSNList: array,
                    faultTopN: 5
            };
            return faultApi.AnalysisTotalFaultDuration(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.AnalysisTotalFaultDurationList = res.Data.AnalysisTotalFaultDurationList;
                }
            });
        },
        formatPieLegendOrXYSeriesLabel(param,length)
        {
            
            let newParamName="";
            let paramLength=param.length;
            let provideNumber=length;
            let rowNumber=Math.ceil(paramLength/provideNumber);
            if(paramLength>provideNumber)
            {
                for(var i=0;i<rowNumber;i++){  
                    let TmpStr="";
                    let start=i*provideNumber;
                    let end=start+provideNumber;
                    if(i==rowNumber)
                        TmpStr=param.substring(start,paramLength);
                    else
                        TmpStr=param.substring(start,end)+"\n";
                    newParamName+=TmpStr;
                }

            }
             else
                newParamName=param;
            return newParamName;                    
                        
        },
        //故障整体影响时长Pie
        initTotalFaultDurationPie(){
            let self=this;
            if(this.AnalysisTotalFaultDurationList){
                let doms = this.$refs['pie-chart-box']; 
                let unit=this.$t("common.Hour");                
                let  option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: function(a)
                        {
                            //console.log("a",a);
                            return(
                                "<font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":<br/>"+a.value.toFixed(2)+" "+unit +" ("+a.percent.toFixed(2) +"%)"
                            );
                        },
                    },
                    legend: {
                        left: 'left',
                        top: 'center',
                        orient: 'vertical',
                        icon: 'circle',
                        formatter:function(param)
                        {
                           return self.formatPieLegendOrXYSeriesLabel(param,10);
                        },
                    },
                    color:this.colors,
                    series: [
                        {
                            type: 'pie',
                            radius: ['40%', '60%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: this.TotalFaultDurationChart.data
                        }
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
        //故障整体影响时长Bar
        initTotalFaultDurationBar(){
            let self = this;
            if(self.AnalysisTotalFaultDurationList){
                let doms = self.$refs['bar-chart-box'] ;
                let unit=this.$t("common.Hour");
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (param){
                            let forstr="";
                            for(var i=0, h=param.length;i<h;i++){   
                                forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+ param[i].name+": "+param[i].value.toFixed(2)+" "+unit+"";
                            }
                            return forstr;
                        },
                    },
                    grid: {
                        top: '5%',
                        left: '0%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                        name:unit
                    },
                    yAxis: {
                        type: 'category',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: true,
                        },
                        axisLabel:{
                            //rotate:15,
                            formatter:function(param)
                            {
                                return self.formatPieLegendOrXYSeriesLabel(param,5);                                
                            }
                        },
                        data: self.TotalFaultDurationChart.yDataList
                    },
                    series: [
                        {
                            itemStyle: {
                                normal: {
                                    color: function(params) {
                                        var colorList = self.colors; 
                                        return colorList[params.dataIndex]; 
                                    }
                                }
                            },
                            name: '',
                            type: 'bar',
                            barWidth: 6,
                            data: self.TotalFaultDurationChart.xDataList
                        },
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
        initVoltageLine() {
            let param = {
                machineId: this.machineId,
                StartTimeUtc: this.$utils.formatTimeUTCStr(this.$utils.addTime(this.$utils.nowTimUTCStr(), "m", -30)),
                EndTimeUtc: this.$utils.nowTimUTCStr(),
            };
            //console.log("this.$utils.nowTimStr()"+this.$utils.nowTimStr());
            //console.log("this.$utils.formatTimeStr(this.$utils.addTime(this.$utils.nowTimStr(), m, -30))"+this.$utils.formatTimeStr(this.$utils.addTime(this.$utils.nowTimStr(), "m", -30)));
            console.log("this.$utils.nowTimUTCStr()"+this.$utils.nowTimUTCStr());
            console.log("this.$utils.formatTimeUTCStr(this.$utils.addTime(this.$utils.nowTimStr(), m, -30))"+this.$utils.formatTimeUTCStr(this.$utils.addTime(this.$utils.nowTimUTCStr(), "m", -30)));
            let xDataList = [];
            let yDataList = [];
            carApi.GetOneCarVoltageMonitor(param).then((resp) => {
                if (resp && resp.IsSuccess) {
                    
                    resp.Data.forEach((element) => {
                        yDataList.push(element.Voltage);
                        xDataList.push(formatTimestampUTC(new Date(element.TimestampUtc),'HH:mm:ss',this.userInfo.TimezoneSetting));
                    });                    
                    let unit=this.$t("common.Voltage");
                    let xSeriesUnit=this.$t("common.Time");
                    let SecondUnit=this.$t("common.Second");
                    let doms = this.$refs['line-chart-box']; 
                    let option = {
                        grid: {
                            left: '3%',
                            right: '3%',
                        },
                        color: ['#FF9367'],
                        tooltip: {
                            trigger: 'axis',
                            //formatter: '{b}:</br>{c}v'
                            formatter: function (param){
                                
                                let forstr="";
                                    for(var i=0, h=param.length;i<h;i++){   
                                        forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+ param[i].name+SecondUnit+"  "+param[i].value.toFixed(2)+" "+unit+"";
                                    }
                                    return forstr;
                            },
                        },
                        xAxis: {
                            type: 'category',
                            data: xDataList,
                            name:xSeriesUnit
                        },
                        yAxis: {
                            type: 'value',
                            name:unit
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            start: 0,
                            end: 10
                            },
                            {
                            start: 0,
                            end: 10
                            }
                        ],
                        series: [
                            {
                                data: yDataList,
                                type: 'line',
                                smooth: true,
                                lineStyle: {
                                    width: 4,
                                },
                            }
                        ]
                    },
                    myCharts = echarts.getInstanceByDom(doms);

                    if (typeof myCharts !== "undefined") {
                        myCharts.clear();
                    }
                    myCharts = echarts.init(doms);
                    myCharts.setOption(option);
                }
            });
           
        },
         /**
        * @desc 打开详情
        */
        openRDMDetails() {
            this.$router.push({path: '/RDMDetails/' +this.companyId+ '/' + this.carDetails.RDMNO});
        },
        doOpenMachineGroup()
        {
            this.openMachineGroup();
        }
    },
    mounted() {
        //this.initVoltageLine();
        
    },
    beforeDestroy() {
        this.clearTime();
    },

};
</script>
<style lang="less">
.emptyfault{
    padding: 15px 20px;
    height: 355px;
    text-align: center;
    font-size: 24px;
    .el-icon-s-order{
      font-size: 100px;
      margin-top: 111px;
      margin-bottom: 10px;
      color: #ccc;
    }
    .faulttext{
      color: #0079c1;
      font-weight: 700;
    }
  }
.infofrmation-box {
    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }

    .weight {
        font-weight: bold;
    }
    .qrcode {
        font-size: 14px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
    }
    .infofrmation-basic-box {
        .infofrmation-basic-title {
            margin-bottom: 9px;
            height: 48px;
            line-height: 48px;
            background: #0079c1;
            border: 1px solid #0079c1;
            color: #fff;
            font-size: 16px;
            padding-left: 25px;
        }

        .infofrmation-basic-table {
            margin-bottom: 9px;

            table {
                width: 100%;
                border-bottom: 1px solid #cccccc;

                tr {

                    td {
                        /*padding-left: 40px;*/
                        padding-left: 2.5%;
                        height: 48px;
                        border: 1px solid #cccccc;
                        border-right: 0;
                        border-bottom: 0;
                        box-sizing: border-box;

                        &:last-child {
                            border-right: 1px solid #cccccc;
                        }

                        &.infofrmation-basic-table-img {
                            width: 145px;
                            padding: 0;
                            position: relative;
                            text-align: center;

                            .car {
                                width: 73px;
                            }

                            .car-status {
                                position: absolute;
                                top: 20px;
                                left: 20px;
                                width: 51px;
                                height: 52px;
                            }
                        }

                        .weight {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .infofrmation-basic-list {
            margin-bottom: 9px;
            height: 72px;
            border: 1px solid #cccccc;
            border-radius: 0px 2px 2px 0px;

            .infofrmation-basic-list-icon {
                width: 146px;
                box-sizing: border-box;
                height: 100%;
                background: #F1F9FE;
                border-right: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;

                img {
                    width: 59px;
                    height: 60px;
                }
            }

            .infofrmation-basic-list-content {               
                float: left;
                font-size: 14px;
                color: #333;
                margin-top: 0.5%;
                /*padding-left: 9%;*/
                margin-left: 2.5%;

                .infofrmation-basic-list-title {
                    /*float: left;*/
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 0.5%;
                    /*padding-left: 47px;*/
                    /*margin-left: 47px;*/
                }
                .infofrmation-basic-list-desc {
                    p {
                        /*float: left;*/
                        /*margin-right: 40px;*/
                        /*padding-left: 47px;*/
                        margin-bottom: 0.2%;
                    }
                }
            }
        }
    }
    
    .infofrmation-basic-footer {
        margin-top: 30px;
        height: 70px;
        background: #f2f2f2;
        border-top: 1px solid #dddddd;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .basic-footer-label {
            color: #333;
            font-size: 16px;

            span:first-child {
                font-weight: bold;
                margin-right: 10px;
            }
        }

        .basic-footer-btn {
            float: right;

            span {
                background: #ff9900;
                border-radius: 2px;
                color: #fff;
                display: inline-block;
                padding: 10px 15px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .infofrmation-vehicle-box {
        .infofrmation-vehicle-title {
            height: 48px;
            background: #fef5f7;
            border: 1px solid #fadada;
            border-radius: 2px;
            color: #F64040;
            background: url('../../assets/image/car/warning.png') 20px center no-repeat;
            background-color: #FEF5F7;
            background-size: 20px;
            box-sizing: border-box;
            padding-left: 60px;
            line-height: 48px;
        }

        .infofrmation-vehicle-content {
            max-height: 355px;
            overflow-y: auto;
            margin: 30px auto;

            .infofrmation-vehicle-list {
                margin-bottom: 10px;
                .infofrmation-vehicle-list-code {
                    color: #F64040;
                    font-size: 14px;
                    margin-right: 10px;
                    font-weight: bold;
                }

                .infofrmation-vehicle-list-content {
                    color: #333333;
                    font-size: 14px;
                }
            }
        }
    }

    .infofrmation-fault-box {
        height: 300px;

        .infofrmation-fault-pie-box {
            width: 40%;
            height: 100%;
            float: left;
        }

        .infofrmation-fault-bar-box {
            width: 60%;
            height: 100%;
            float: right;
        }
    }

    .infofrmation-latest-box {
        width: 100%;
        height: 350px;
    }

    .infofrmation-parameter-header {
        margin-right: 30px;

        .collapse{
            min-width:78px;
            margin-left: 15px;
        }

        span {
            width: 59px;
            text-align: center;
            font-size: 14px;
            color: #333;
            border: 1px solid #dddddd;
            border-radius: 2px;
            display: inline-block;
            padding: 5px 10px;
            cursor: pointer;

            &.isSelected {
                color: #fff;
                background: #0079C1;
            }
        }

        /deep/.el-input__inner {
            margin-left: 10px;
            height: 30px;
            width: 100px;
        }

        /deep/.el-select .el-input .el-select__caret {
            line-height: 30px;
        }
    }

    .none-data-box{
        height: 512px;
        text-align: center;
        font-size: 24px;
    }
}
.ksiComment {
   font-size: 12px;
   color: #909399;
}
</style>