import request from "@/utils/request";

              //车辆统计信息: 车辆状态信息
              function getCarStatusStatisticInfo(data) {
                return request({
                  url: "v2/HomePage/GetCarStatusStatisticInfo",
                  method: "post",
                  data
                });
              }
              
              //车辆统计信息: 车辆RDM配置信息
              function getRDMConfigInfo(data) {
                return request({
                  url: "v2/HomePage/GetRDMConfigInfo",
                  method: "post",
                  data
                });
              }
              
              //统计信息: 车辆故障信息
              function getFaultInfo(data) {
                return request({
                  url: "v2/HomePage/GetFaultInfo",
                  method: "post",
                  data
                });
              }
              
              //统计信息: 故障数量趋势(近一段时间), 故障分类
              function getFaultStatistic(data) {
                return request({
                  url: "v2/HomePage/GetFaultStatistic",
                  method: "post",
                  data
                });
              }
              
              //获取电池电压过低的预警信息
              function getVoltageStateInfo(data) {
                return request({
                  url: "v2/HomePage/GetVoltageStateInfo",
                  method: "post",
                  data
                });
              }
              
            

            export default {
                getCarStatusStatisticInfo,
getRDMConfigInfo,
getFaultInfo,
getFaultStatistic,
getVoltageStateInfo
            };