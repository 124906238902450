
import request from "@/utils/request";



//车辆效率分析
function GetCarEfficienyAnalysis(data) {
  return request({
    url: "/v2/Efficiency/GetCarEfficienyAnalysis",
    method: "post",
    data
  });
}


export default {
  GetCarEfficienyAnalysis
};