import request from "@/utils/request";

              //用户登录
              function login(data) {
                return request({
                  url: "v2/Login/Login",
                  method: "post",
                  data
                });
              }
              
            

            export default {
                login
            };