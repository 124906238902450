

//使用递归的方式实现数组、对象的深拷贝
export function deepClone(obj) {
    //判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
    var objClone = Array.isArray(obj) ? [] : {};
    //进行深拷贝的不能为空，并且是对象或者是
    if (obj && typeof obj === "object") {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] && typeof obj[key] === "object") {
                    objClone[key] = deepClone(obj[key]);
                } else {
                    objClone[key] = obj[key];
                }
            }
        }
    }
    return objClone;
}
var moment = require('moment');
export function now() {
    return new Date();
}
export function nowTimStr() {
    return formatTimeStr(now());
}
export function nowTimUTCStr() {
    return formatTimeUTCStr(now());
}
export function formatTimeStr(d, formatPattern) {
    return moment(d).format(formatPattern || 'YYYY-MM-DDTHH:mm:ssZ');
}
export function formatTimeUTCStr(d, formatPattern) {
    return moment(d).utc().format(formatPattern || 'YYYY-MM-DDTHH:mm:ssZ');
}
export function addTime(d, flag, value) {
    return moment(d).add(value, flag);
}
//毫秒
export function SubTime(d, value) {
    return moment(d).diff(moment(value));
}

export function formatTimestampUTC(d, formatPattern,offset){
    offset = parseInt(offset.substring(4,6));
    return moment(d).utc().add(offset, 'h').format(formatPattern || 'YYYY-MM-DDTHH:mm:ssZ');
}


//查询该数组里是否有当前值
export function checkExistData(array = [], value = '', key = '') {
    let index = array.findIndex(item => {
        if (key) {
            return item[key] == value;
        } else {
            return item === value;
        }
    });

    return {
        isExist: index != -1,
        index,
        data: array[index],
    };
}

//转el-tree树形结构
  /*
  格式化树形
  datas需要过滤的数据
  最外层节点的pid
  defaultProp为树形数据的默认配置
  */

  export function getListData(datas,bigPid,defaultProp) {
    if(!defaultProp){
      defaultProp={
          label: 'label',
          id:'id',
          pid:'pid',
      };
    }  
    let dataArray = [];
    datas.forEach(function(data) {
      let pid = data[defaultProp.pid];
      if (pid == bigPid) {
        let objTemp = {};
        for(var pl in data){ //数组对象遍历
            objTemp[pl] = data[pl];
        }
        dataArray.push(objTemp);
      }
    });
    return data2treeDG(datas, dataArray, defaultProp);
  }
  function  data2treeDG(datas, dataArray, defaultProp) {
      for (let j = 0; j < dataArray.length; j++) {
        let dataArrayIndex = dataArray[j];
        let childrenArray = [];
        let Id = dataArrayIndex[defaultProp.id];
        for (let i = 0; i < datas.length; i++) {
          let data = datas[i];
          let pid = data[defaultProp.pid];
          if (pid == Id) {
            //判断是否为儿子节点
            let objTemp = {};
            for(var pl in data){ //数组对象遍历
                objTemp[pl] = data[pl];
            }
            childrenArray.push(objTemp);
          }
        }
        dataArrayIndex.children = childrenArray;
        if (childrenArray.length > 0) {
          //有儿子节点则递归
          data2treeDG(datas, childrenArray, defaultProp);
        }
      }
      return dataArray;
    }
//检查用户是否有某项操作功能的权限
export function ifGrantedTheOperationForThisFunction(operation)
{   
    console.log("LastMenuId:",sessionStorage.getItem("LastMenuId"));
    let currentFunctionMenuIdID=sessionStorage.getItem("LastMenuId");
    var  functionOperationPermissions = sessionStorage.getItem(currentFunctionMenuIdID);
    console.log("functionOperationPermissions:",functionOperationPermissions);
    if (functionOperationPermissions && functionOperationPermissions.match(operation))
    {
        return true;
    }else{
        return false;
    }
}

//检查用户是否有消息中心的某项权限
export function ifGrantedTheOperationForNotificationFunction(operation)
{   
    var  functionOperationPermissions = sessionStorage.getItem("0101");
    console.log("functionForNotificationPermissions:",functionOperationPermissions);
    if (functionOperationPermissions && functionOperationPermissions.match(operation))
    {
        return true;
    }else{
        return false;
    }
}

//检查用户是否有我的车辆的某项权限
export function ifGrantedTheOperationForMyMachineFunction(operation)
{   
    var  functionOperationPermissions = sessionStorage.getItem("0201");
    console.log("functionForMyMachinePermissions:",functionOperationPermissions);
    if (functionOperationPermissions && functionOperationPermissions.match(operation))
    {
        return true;
    }else{
        return false;
    }
}

//检查用户是否有RDM详情的某项权限
export function ifGrantedTheOperationForRDMManageFunction(operation)
{   
    var  functionOperationPermissions = sessionStorage.getItem("0602");
    console.log("functionForRDMManagePermissions:",functionOperationPermissions);
    if (functionOperationPermissions && functionOperationPermissions.match(operation))
    {
        return true;
    }else{
        return false;
    }
}