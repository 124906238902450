<template>
  <div class="user-dropdown">
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        <i class="el-icon-user-solid el-icon--right"></i>
        <div class="user_tip">{{ $t("common.welcome") }}, {{ userName }}</div>
      </span>

      <el-dropdown-menu slot="dropdown" class="user-dropdown-menu">
        <el-dropdown-item
          class="dropdownItem"
          v-for="d in dropdownItems"
          :key="d.name"
          :command="d"
        >
          {{ d.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "UserDropdown",
  props: {
    userName: String,
    dropdownItems: Array,
  },
  data() {
    return {};
  },
  methods: {
    handleCommand(command) {
      this.$emit("dropdown-item-click", command);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.user-dropdown {
}
.el-dropdown-link {
  cursor: pointer;
  color: #565d6a;
  padding-bottom: 31px;
  padding-top: 36px;
}
.el-dropdown-link:hover {
  color: #0079c1;
}
.el-icon-user-solid {
  font-size: 17px;
}

.user_tip {
  height: 12px;
  font-size: 13px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: left;
  display: inline-block;
}
.user-dropdown-menu {
  width: 193px !important;
}
</style>
