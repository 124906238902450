<template>
  <div id="ESEDevicePackage" style="min-width: 486px;">
  <el-card class="formCard">
    <el-form ref="form" :model="form" label-width="24%" :rules="rules" class="marginTop60">
      <div class="formItem">
        <el-form-item :label="$t('bizConfig.DeviceName')+':'" prop="DeviceName" class="marginTop50">
          <el-input class="DeviceName" v-model="form.DeviceName" maxlength="60" :placeholder="$t('bizConfig.DeviceName')"></el-input>
        </el-form-item>
      </div>
      <div class="formItem">
        <el-form-item :label="$t('bizConfig.OSVersion')+':'" prop="DOSVersion"  class="marginTop50">
          <div class="marginTop-20 OSVersion-menu">
            <el-tabs validate-event="false" class="OSVersion-content">
              <el-tab-pane v-for="item in options" :key="item.label">
                <span slot="label" class="OSVersion-content-title">{{item.label}}</span>
                <el-button :class="[form.OSVersion===data?'':'tabButton']" :type="form.OSVersion===data?'primary' :''" v-for="data in item.value" @click="setOSVersion(data)" :key="data">{{data}}</el-button>
              </el-tab-pane>
            </el-tabs>
            <div class="SplitLine"></div>
            <el-button class="AddOS-Button" @click="openDialog" v-if="privilege.NewOS">
              <i class="el-icon-plus"></i>{{$t('bizConfig.AddOs')}}
            </el-button>
          </div>
          <el-input v-model="form.OSVersion" style="display:none"></el-input>
        </el-form-item>
      </div>
      <div class="formItem">
        <el-form-item :label="$t('bizConfig.AddFile')+':'" prop="FileMenu"  class="marginTop50">
          <div class="width760" :span="12">
            <div class="uploadFile" :span="3">
              <el-upload ref="FileMenu"
                list-type="text"
                :class="[DeviceMenuFileFlag?'avatar-uploader FileMenu' : 'avatar-uploader FileMenu emptyFile']"
                :auto-upload="false"
                :on-change="handleChangeDeviceMenuFile"
                :file-list="DeviceMenuFile"
                action="#"
                accept=".xls">
                <div class="upload">
                  <div v-if="DeviceMenuFile.length>0" >
                    <img :src="imageUrl" class="el-icon-folder-opened"/><div class="Add haveFile">{{DeviceMenuFile[0].name}}</div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon">
                    <div class="Add">{{$t('bizConfig.DeviceMenuFile')}}</div>
                  </i>
                </div>
              </el-upload>
            </div>
            <div class="uploadFile" :span="3">
              <el-upload
                list-type="text" ref="VCLCodeFile"
                :class="[VCLCodeFileFlag?'avatar-uploader FileVCLCode' : 'avatar-uploader FileVCLCode emptyFile']"
                :auto-upload="false"
                :on-change="handleChangeVCLCodeFile"
                :file-list="VCLCodeFile"          
                action="#"
                accept=".vcl"
                  
                >
                <div class="upload">
                  <div v-if="VCLCodeFile.length>0" >
                    <img :src="imageUrl" class="el-icon-folder-opened"/><div class="Add haveFile">{{VCLCodeFile[0].name}}</div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon">
                    <div class="Add">{{$t('bizConfig.VCLCodFile')}}</div>
                  </i>
                </div>
              </el-upload>
            </div>
            <div class="uploadFile" :span="3">
              <el-upload
                list-type="text" ref="OSBSLFile"
                :class="[OSBSLFileFlag?'avatar-uploader FileSoftwareParimaryOSBSL' : 'avatar-uploader FileSoftwareParimaryOSBSL emptyFile']"
                  :auto-upload="false"
                  :on-change="handleChangeOSBSLFile"
                  :file-list="OSBSLFile"          
                  action="#"
                  accept=".bsl"
                >
                <div class="upload">
                  <div v-if="OSBSLFile.length>0" >
                    <img :src="imageUrl" class="el-icon-folder-opened"/><div class="Add haveFile">{{OSBSLFile[0].name}}</div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon">
                    <div class="Add">{{$t('bizConfig.OSBSLFile')}}</div>
                  </i>
                </div>
              </el-upload>      
            </div>
            <div class="uploadFile" :span="3">
              <el-upload
              list-type="text" ref="VCLBSLFile"
              :class="[VCLBSLFileFlag?'avatar-uploader FileSoftwareVCLBSL' : 'avatar-uploader FileSoftwareVCLBSL emptyFile']"
                :auto-upload="false"
                :on-change="handleChangeVCLBSLFile"
                :file-list="VCLBSLFile"          
                action="#"
                accept=".bsl"
                
              >
                <div class="upload">
                  <div v-if="VCLBSLFile.length>0" >
                    <img :src="imageUrl" class="el-icon-folder-opened"/><div class="Add haveFile">{{VCLBSLFile[0].name}}</div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon">
                    <div class="Add">{{$t('bizConfig.VCLBSLFile')}}</div>
                  </i>
                </div>
              </el-upload>  
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="formItem">
        <el-form-item :label="$t('bizConfig.Remark')+':'" prop="remark"  class="marginTop35">
          <div  class="marginTop-20">
            <el-input type="textarea" :rows="3" class="remark" :placeholder="$t('bizConfig.RemarkInformation')" v-model="form.Remark">{remark}</el-input>
          </div>
          <div class="buttonLeft">
            <el-button class="defaultButton" type="default" @click="clearDevice">{{$t('bizConfig.clear')}}</el-button>
            <el-button class="defaultButton primaryButton" type="primary" @click="checkDeciveISExits" v-if="privilege.Add">{{$t('bizConfig.generate')}}</el-button>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </el-card>
    <!--Dialog弹窗-->
    <el-dialog
      :title="$t('bizConfig.NewOS')"
      :visible.sync="dialogVisible"
      width="929px" append-to-body 
      @close="closeNewOS">
      <el-form ref="newOSFrom" :model="newOSFrom" label-width="13%" :rules="rules">
        <el-form-item :label="$t('bizConfig.OSVersion')+':'" prop="OSVersion">
          <el-input class="OSVersion" v-model="newOSFrom.OSVersion" maxlength="60" :placeholder="$t('bizConfig.OSVersion')"></el-input>
        </el-form-item>
        <div>
          <el-form-item :label="$t('bizConfig.AddFile')+':'" prop="FileEDS"  class="marginTop30">
            <div class="uploadFile">
              <el-upload ref="FileEDS"
                list-type="text"
                :class="[FileEDSFlag?'newOS-upload FileEDS' : 'newOS-upload FileEDS emptyFile']"
                :auto-upload="false"
                :on-change="handleChangeFileEDS"
                :file-list="FileEDS"
                action="#"
                accept=".eds"
                
                name=0>
                <div class="newOSupload">
                  <div v-if="FileEDS.length>0" >
                    <img :src="imageUrl" class="el-icon-folder-opened"/><div class="newOSAdd newOSHaveFile">{{FileEDS[0].name}}</div>
                  </div>
                  <i v-else class="el-icon-plus newOS-upload-icon">
                    <div class="newOSAdd">{{$t('bizConfig.EDSFile')}}</div>
                  </i>
                </div>
              </el-upload>
            </div>
            <div class="uploadFile">
              <el-upload ref="FileOS123"
                list-type="text" 
                :class="[FileOS123Flag?'newOS-upload FileOS123' : 'newOS-upload FileOS123 emptyFile']"
                  :auto-upload="false"
                  :on-change="handleChangeFileOS123"
                  :file-list="FileOS123"          
                  action="#"
                  accept=".csv"
                  
                >
                <div class="newOSupload">
                  <div v-if="FileOS123.length>0" >
                    <img :src="imageUrl" class="el-icon-folder-opened"/><div class="newOSAdd newOSHaveFile">{{FileOS123[0].name}}</div>
                  </div>
                  <i v-else class="el-icon-plus newOS-upload-icon">
                    <div class="newOSAdd">{{$t('bizConfig.FileOS123')}}</div>
                  </i>
                </div>
              </el-upload>
            </div>
            <div class="uploadFile">
              <el-upload ref="FileFault"
                list-type="text"
                :class="[FileFaultFlag?'newOS-upload FileFault' : 'newOS-upload FileFault emptyFile']"
                  :auto-upload="false"
                  :on-change="handleChangeFileFault"
                  :file-list="FileFault"          
                  action="#"
                  accept=".csv"
                  
                >
                <div class="newOSupload">
                  <div v-if="FileFault.length>0" >
                    <img :src="imageUrl" class="el-icon-folder-opened"/><div class="newOSAdd newOSHaveFile">{{FileFault[0].name}}</div>
                  </div>
                  <i v-else class="el-icon-plus newOS-upload-icon">
                    <div class="newOSAdd">{{$t('bizConfig.FaultFile')}}</div>
                  </i>
                </div>
              </el-upload>      
            </div>
            <div class="uploadFile">
              <el-upload ref="FileCanObjectAddr"
              list-type="text"
              :class="[FileCanObjectAddrFlag?'newOS-upload FileCanObjectAddr' : 'newOS-upload FileCanObjectAddr emptyFile']"
                :auto-upload="false"
                :on-change="handleChangeFileCanObjectAddr"
                :file-list="FileCanObjectAddr"          
                action="#"
                accept=".csv"
                
              >
                <div class="newOSupload">
                  <div v-if="FileCanObjectAddr.length>0" >
                    <img :src="imageUrl" class="el-icon-folder-opened"/><div class="newOSAdd newOSHaveFile">{{FileCanObjectAddr[0].name}}</div>
                  </div>
                  <i v-else class="el-icon-plus newOS-upload-icon">
                    <div class="newOSAdd">{{$t('bizConfig.CanObjectAddrFile')}}</div>
                  </i>
                </div>
              </el-upload>  
            </div>
          </el-form-item>
        </div>
        <el-form-item :label="$t('bizConfig.Remark')+':'" prop="remark"  class="marginTop35">
          <el-input type="textarea" :rows="3" class="marginTop-20 newOSRemark" :placeholder="$t('bizConfig.RemarkInformation')" v-model="newOSFrom.remark">{remark}</el-input>
          <span class="dialog-footer buttonLeft">
            <el-button @click="closeNewOS" class="defaultButton">{{$t('bizConfig.cancle')}}</el-button>
            <el-button type="primary" @click="checkOSVersionISExits" class="defaultButton primaryButton">{{$t('bizConfig.save')}}</el-button>
          </span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
  
<script>
import deviceApi from "@/api/Device";
import { ifGrantedTheOperationForThisFunction} from '@/utils';
export default {
  name: "ESEDevicePackage",
  data() {
    /*自定义验证OSVersion是否正确，必须为小数*/
    var checkOSVersion = (rule, value, callback) => {
      var re=/^(([0-9]+)(\.\d+)+)$/;
      if (!re.test(value)) {
        //callback(new Error('请输入正确的OS版本号'));
        callback(new Error(this.$t('bizConfig.OSVerifyMessage')));
      } else {
        callback();
      }
    };
    /*Device页面OSVersion是否选择*/
    var checkDOSVersion=(rule, value, callback) => {
      if(this.form.OSVersion==""){
        callback(new Error(this.$t('bizConfig.OSVersion')+this.$t('common.required')));
      }else {
        callback();
      }
    };
    /*Device页面FileMenu文件是否上传*/
    var checkFileMenu=(rule, value, callback) => {
      var flag = true;
      if(this.DeviceMenuFile.length<=0){
        this.DeviceMenuFileFlag = false;
        flag = false;
        callback(new Error(this.$t('bizConfig.OnlyDeviceMenuFile')));
      }
      if(this.VCLCodeFile.length<=0){
        this.VCLCodeFileFlag = false;
        flag = false;
        callback(new Error(this.$t('bizConfig.OnlyVCLCodFile')));
      }
      if(this.OSBSLFile.length<=0){
        this.OSBSLFileFlag = false;
        flag = false;
        callback(new Error(this.$t('bizConfig.OnlyOSBSLFile')));
      }
      if(this.VCLBSLFile.length<=0){
        this.VCLBSLFileFlag = false;
        flag = false;
        callback(new Error(this.$t('bizConfig.OnlyVCLBSLFile')));
      }
      if(flag) {
        this.DeviceMenuFileFlag = true;
        this.VCLCodeFileFlag = true;
        this.OSBSLFileFlag = true;
        this.VCLBSLFileFlag = true;
        callback();
      }
    };
    /*newOS页面FileEDS文件是否上传*/
    var checkFileEDS=(rule, value, callback) => {
      var flag = true;
      if(this.FileEDS.length<=0){
        this.FileEDSFlag = false;
        flag = false;
        callback(new Error(this.$t('bizConfig.OnlyFileEDS')));
      }
      if(this.FileOS123.length<=0){
        this.FileOS123Flag = false;
        flag = false;
        callback(new Error(this.$t('bizConfig.OnlyFileOS123')));
      }
      if(this.FileFault.length<=0){
        this.FileFaultFlag = false;
        flag = false;
        callback(new Error(this.$t('bizConfig.OnlyFileFault')));
      }
      if(this.FileCanObjectAddr.length<=0){
        this.FileCanObjectAddrFlag = false;
        flag = false;
        callback(new Error(this.$t('bizConfig.OnlyFileCanObjectAddr')));
      }
      if(flag){
        this.FileEDSFlag = true;
        this.FileOS123Flag = true;
        this.FileFaultFlag = true;
        this.FileCanObjectAddrFlag = true;
        callback();
      }
    };
    return {
      //设备文件表单
      form:{
        DeviceName:"",
        OSVersion:"",
        FileMenu:[],
        FileVCLCode:[],
        FileSoftwareParimaryOSBSL:[],
        FileSoftwareVCLBSL:[],
        DeviceType:0,
        Remark:"",
        IsUpdated:false
      },
      //New OS表单
      newOSFrom:{
        OSVersion:"",
        FileEDS:[],
        FileOS123:[],
        FileFault:[],
        FileCanObjectAddr:[],
        Remark:"",
        IsUpdated:false
      },
      options: [],
      //上传文件成功后图片路径
      imageUrl: require('@/assets/image/ESEDevicePackage_file_icon.png'),
      DeviceMenuFile:[],
      VCLCodeFile:[],
      OSBSLFile:[],
      VCLBSLFile:[],
      FileEDS:[],
      FileOS123:[],
      FileFault:[],
      FileCanObjectAddr:[],
      DeviceMenuFileFlag:true,
      VCLCodeFileFlag:true,
      OSBSLFileFlag:true,
      VCLBSLFileFlag:true,
      FileEDSFlag:true,
      FileOS123Flag:true,
      FileFaultFlag:true,
      FileCanObjectAddrFlag:true,
      //New OS弹窗是否打开
      dialogVisible: false,
      disabled: false,
      disabledFlag:false,
      rules:{
        DeviceName:[{required:true,message:this.$t('bizConfig.DeviceName')+this.$t('common.required'),trigger:'blur'}],
        DOSVersion:[{ required: true, validator: checkDOSVersion, trigger: 'blur'}],
        FileMenu:[{required:true,validator: checkFileMenu,trigger:'blur'}],
        OSVersion:[{required:true,message:this.$t('bizConfig.OSVersion')+this.$t('common.required'),trigger:'blur'},{validator:checkOSVersion,trigger:'blur'}],
        FileEDS:[{required:true,validator: checkFileEDS,trigger:'blur'}],
      },
      privilege: {
                View: false,
                Add: false,
                NewOS: false,
            }
    };
  },
  created() {
    this.getOSVersionList();
    this.initialFunctionOperationPrivilege();
  },
  methods: {
    ifGrantedTheOperationForThisFunction,
    initialFunctionOperationPrivilege()
    {
            let self = this;
            self.privilege.View = ifGrantedTheOperationForThisFunction("View");
            self.privilege.Add = ifGrantedTheOperationForThisFunction("Add");
            self.privilege.NewOS = ifGrantedTheOperationForThisFunction("NewOS");
    },
    //打开New OS弹窗 
    openDialog(){
      this.dialogVisible=true;
    },
    //获取OSVersion列表
    getOSVersionList()
    {
      var  result = [];
      deviceApi.getOSList({}).then((resp) => {
        console.log("GetOSVersionList:", resp);
        if (resp.IsSuccess) {
          var data=resp.Data.OSVersionList;
          for (var i = 0; i < data.length; i++) {
            var label=data[i].substring(0,data[i].indexOf("."));
            var flag=true;
            for(var j=0;j<result.length;j++){
              if(label==result[j].label){
                result[j].value.push(data[i]);
                flag=false;
              }
            }
            if(flag){
              var item={
                label:label,
                value:[data[i]],
              };
              result.push(item);
            }
          }
          console.log("result:", result);
        }else{
          this.$message.error(resp.ErrorMsg);
        }
      })
      .catch((err) => {
        this.$message.error(err);
      });
      this.options = result;
    },

    //判断OSVersion是否存在
    checkOSVersionISExits(){
      this.newOSFrom.FileEDS=this.FileEDS;
      this.newOSFrom.FileOS123=this.FileOS123;
      this.newOSFrom.FileFault=this.FileFault;
      this.newOSFrom.FileCanObjectAddr=this.FileCanObjectAddr;
      this.$refs["newOSFrom"].validate((valid) => {
        if (valid) {
          var data={
            osVersion:this.newOSFrom.OSVersion,
            remark:this.newOSFrom.Remark,
          };
          deviceApi.checkIfOSExists(data).then(res=>{
            if (res.Data) {
              const Url = require("@/assets/image/warning1.5x.png");
              this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("bizConfig.OSVerifyMessage2")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                iconClass:'warningTipIcon',
                customClass:"warningTip",
                center: true,
                showClose:false
              }).then(() => {
                this.form.IsUpdated=true;
                this.AddDevice();
              }).catch(() => {
                
              });
            }else{
              this.saveNewOS();
            }
          });
        } else {
          return false;
        }
      });
    },

    //判断Decive是否存在
    checkDeciveISExits(){
      this.form.FileMenu=this.DeviceMenuFile;
      this.form.FileVCLCode=this.VCLCodeFile;
      this.form.FileSoftwareParimaryOSBSL=this.OSBSLFile;
      this.form.FileSoftwareVCLBSL=this.VCLBSLFile;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          fd.append("DeviceName", this.form.DeviceName);
          fd.append("OSVersion", this.form.OSVersion);
          fd.append("FileMenu", this.DeviceMenuFile[0].raw);
          fd.append("DeviceType", 0);
          deviceApi.checkIfDeviceExists(fd).then(res=>{
            if (res.Data) {
              const Url = require("@/assets/image/warning1.5x.png");
              this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("bizConfig.DeviceVerifyMessage")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                iconClass:'warningTipIcon',
                customClass:"warningTip",
                center: true,
                showClose:false
              }).then(() => {
                this.form.IsUpdated=true;
                this.AddDevice();
              }).catch(() => {
                
              });
            }else{
              this.AddDevice();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    //打包设备文件
    AddDevice()
    {
      var self = this;
      let fd = new FormData();
      fd.append("DeviceName", this.form.DeviceName);
      fd.append("OSVersion", this.form.OSVersion);
      fd.append("FileMenu", this.form.FileMenu[0].raw);
      fd.append("FileVCLCode", this.form.FileVCLCode[0].raw);
      fd.append("FileSoftwareParimaryOSBSL", this.form.FileSoftwareParimaryOSBSL[0].raw);
      fd.append("FileSoftwareVCLBSL", this.form.FileSoftwareVCLBSL[0].raw);
      fd.append("Remark", this.form.Remark);
      fd.append("DeviceType", this.form.DeviceType);
      fd.append("IsUpdated", this.form.IsUpdated);
      deviceApi.SaveAndUpdateDeviceDefinitionAndFiles(fd).then(res=>
      {
        console.log("res"+res);
        if(res.IsSuccess)
        {
          if(res.Data.ParamtersContainEmptyCanAddress){
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("bizConfig.DeviceParameterNullMessage")+"</p>", '', {
              dangerouslyUseHTMLString: true,
              confirmButtonText: this.$t("common.confirm"),
              cancelButtonText: this.$t("common.cancel"),
              iconClass:'warningTipIcon',
              customClass:"warningTip",
              center: true,
              showClose:false
            }).then(()=>{
              self.downLoadFile(res.Data.RDEVFilePath);
              setTimeout(() => {
                self.downLoadFile(res.Data.EmptyCANAddressParameterListPath);
              }, 1000);
            }).catch(action=>{
              self.downLoadFile(res.Data.RDEVFilePath);
            });
          }else{
            self.downLoadFile(res.Data.RDEVFilePath);
          }
        }else{
          this.$message.error(res.ErrorMsg); 
        }
      });
    },
    // 下载文件
    downLoadFile(filePath){
      let FileName=deviceApi.getFileName(filePath);
      deviceApi
      .downLoadFile(filePath)
      .then(resContent=>
      { 
        if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a');
            elink.download = FileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(resContent);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else { // IE10+下载
            navigator.msSaveBlob(resContent, FileName);
          }
      });
      this.$message.success(this.$t('common.success')); 
    },
    //保存New OS
    saveNewOS(){
      var that=this;
      const Url = require("@/assets/image/package.png");
      this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("bizConfig.PackageGenerateMessage")+" </p>", '', {
        dangerouslyUseHTMLString: true,
        showCancelButton: false,
        showConfirmButton: false,
        iconClass:'warningTipIcon',
        customClass:"warningTip",
        center: true,
        showClose:false
      });
      let fd = new FormData();
      fd.append("OSVersion", that.newOSFrom.OSVersion);
      fd.append("FileEDS", that.newOSFrom.FileEDS[0].raw);
      fd.append("FileOS123", that.newOSFrom.FileOS123[0].raw);
      fd.append("FileFault", that.newOSFrom.FileFault[0].raw);
      fd.append("FileCanObjectAddr", that.newOSFrom.FileCanObjectAddr[0].raw);
      fd.append("Remark", that.newOSFrom.Remark);
      fd.append("IsUpdated", that.newOSFrom.IsUpdated);
      deviceApi.saveAndUpdateOSFiles(fd).then(res=>{
        if(res.IsSuccess){
          that.dialogVisible=false;
          const Url = require("@/assets/image/success.png");
          that.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("bizConfig.OSVerifyMessage3")+"</p>", '', {
            dangerouslyUseHTMLString: true,
            showCancelButton: false,
            showConfirmButton: false,
            iconClass:'warningTipIcon',
            customClass:"warningTip",
            center: true,
            showClose:false
          });
          that.getOSVersionList();
        }else{
          const Url = require("@/assets/image/error_76.png");
          that.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("bizConfig.OSResultMessage")+"</p>", '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            iconClass:'warningTipIcon',
            customClass:"warningTip",
            center: true,
            showClose:false
          });
        }
      }).catch(()=>{
        const Url = require("@/assets/image/error_76.png");
        that.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("bizConfig.OSResultMessage")+"</p>", '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t("addMachine.Delete"),
          cancelButtonText: this.$t("common.cancel"),
          iconClass:'warningTipIcon',
          customClass:"warningTip",
          center: true,
          showClose:false
        });
      });
    },
    //清理设备文件表单数据
    clearDevice(){
      this.form={
        DeviceName:"",
        OSVersion:"",
        FileMenu:[],
        FileVCLCode:[],
        FileSoftwareParimaryOSBSL:[],
        FileSoftwareVCLBSL:[],
        DeviceType:0,
        Remark:"",
        IsUpdated:false
      };
      this.DeviceMenuFile=[];
      this.VCLCodeFile=[];
      this.OSBSLFile=[];
      this.VCLBSLFile=[];
    },
    //关闭New OS弹窗
    closeNewOS(){
      this.newOSFrom={
        OSVersion:"",
        FileEDS:[],
        FileOS123:[],
        FileFault:[],
        FileCanObjectAddr:[],
        Remark:"",
        IsUpdated:false
      };
      this.FileEDS=[];
      this.FileOS123=[];
      this.FileFault=[];
      this.FileCanObjectAddr=[];
      this.dialogVisible=false;
    },
    //选择OSVersion
    setOSVersion(data){
      this.form.OSVersion=data;
    },

    //文件校验
    handleChangeFileEDS(file,fileList){
      if(deviceApi
      .beforeAvatarUpload(file))
      {
        fileList=[file];
        this.FileEDS=fileList;
        this.FileEDSFlag = true;
        this.disabledFlag=true;
      }else{
        this.$message.error(this.$t('Error.FileTypeError'));
        this.FileEDSFlag = false;
      }
    },
    handleChangeFileOS123(file,fileList){
      if(deviceApi
      .beforeAvatarUpload(file))
      {
        fileList=[file];
        this.FileOS123=fileList;
        this.disabledFlag=true;
        this.FileOS123Flag = true;
      }else{
        this.$message.error(this.$t('Error.FileTypeError'));
        this.FileOS123Flag = false;
      }
    },
    handleChangeFileFault(file,fileList){
      if(deviceApi
      .beforeAvatarUpload(file))
      {
        fileList=[file];
        this.FileFault=fileList;
        this.FileFaultFlag = true;
        this.disabledFlag=true;
      }else{
        this.$message.error(this.$t('Error.FileTypeError'));
        this.FileFaultFlag = false;
      }
    },
    handleChangeFileCanObjectAddr(file,fileList){
      if(deviceApi
      .beforeAvatarUpload(file))
      {
        fileList=[file];
        this.FileCanObjectAddr=fileList;
        this.FileCanObjectAddrFlag = true;
        this.disabledFlag=true;
      }else{
        this.$message.error(this.$t('Error.FileTypeError'));
        this.FileCanObjectAddrFlag = false;
      }
    },
    handleChangeDeviceMenuFile(file,fileList)
    {
      if(deviceApi
      .beforeAvatarUpload(file))
      {
        fileList=[file];
        this.DeviceMenuFile=fileList;
        this.disabledFlag=true;
        this.DeviceMenuFileFlag = true;
      }else{
        this.$message.error(this.$t('Error.FileTypeError'));
        this.DeviceMenuFileFlag = false;
      }
    },     
    handleChangeVCLCodeFile(file,fileList)
    {
      if(deviceApi
      .beforeAvatarUpload(file))
      {
        fileList=[file];
        this.VCLCodeFile=fileList;
        this.VCLCodeFileFlag = true;
        this.disabledFlag=true;
      }else{
        this.$message.error(this.$t('Error.FileTypeError'));
        this.VCLCodeFileFlag = false;
      }
    },
    handleChangeOSBSLFile(file,fileList)
    {
      if(deviceApi
      .beforeAvatarUpload(file))
      {
        fileList=[file];
        this.OSBSLFile=fileList;
        this.OSBSLFileFlag = true;
        this.disabledFlag=true;
      }else{
        this.$message.error(this.$t('Error.FileTypeError'));
        this.OSBSLFileFlag = false;
      }
    },
    handleChangeVCLBSLFile(file,fileList)
    {
      if(deviceApi
      .beforeAvatarUpload(file))
      {
        fileList=[file];
        this.VCLBSLFile=fileList;
        this.VCLBSLFileFlag = true;
        this.disabledFlag=true;
      }else{
        this.$message.error(this.$t('Error.FileTypeError'));
        this.VCLBSLFileFlag = false;
      }
    },
    
  },
};
</script>
  
<style lang="less">
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 4vw;
    padding: 30% 0 20% 0;
  }
  .emptyFile .el-upload{
    border: 1px dashed #ff0000 !important;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 24%;
    height: 100%;
    display: inline-block;
  }
  /*OSversionTabs组件标题*/
  .OSVersion-menu {
    width: 57%;
    height: 252px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 3px;
  }
  /*OSversionTabs组件内容*/
  .OSVersion-content{
    height: 160px;
    width: 97%;
    padding-left: 10px;
    padding-top: 7px;
    text-align: left;
  }
  .OSVersion-content .el-tabs__item{
    padding: 0 5px !important;
  }
  .OSVersion-content-title{
    padding: 0 10px !important;
    font-size: 16px !important;
    font-weight: 400;
  }
  .is-active .OSVersion-content-title{
    font-weight: 700 !important;
  }
  .SplitLine{
    width: 100%;
    height: 1px;
    background: #eeeeee;
    float: bottom;
  }
  .AddOS-Button{
    color: #000000 !important;
    border-radius: 3px;
    float: left;
    margin-top: 20px!important;
    margin-left: 24px!important;
    margin-bottom: 18px !important;
    border: 1px solid #eeeeee !important;
  }
  .AddOS-Button:hover{
    border: 1px solid #0079c1 !important;
    color: #0079c1 !important;
    background-color: transparent !important;
  }
  .formCard .upload{
    width: 100%;
    font-family: Arial, Arial-Regular;
    font-weight: 400;
    text-align: center;
    color: #555555;
  }
  .Add{
    width: 100%;
    line-height: 20px;
    font-size: 13px;
    font-family: Arial, Arial-Regular;
    font-weight: 400;
    text-align: center;
    color: #555555;
    margin-top:5% ;
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }
  .BottomButton{
    float: left;
    margin-top: 20px;
    margin-left: 335px;
  }
  /*Clear按钮样式*/
  .ClearButton{
    width: 110px;
    height: 38px;
    border: 1px solid #0079c1;
    border: 1px solid #cbcbcd;
    border-radius: 3px;
    margin-right: 20px;
  }
  /*顶部边距50px*/
  .marginTop50{
    margin-top: 50px;
  }
  /*顶部边距35px*/
  .marginTop35{
    margin-top: 50px;
  }
  /*顶部边距30px*/
  .marginTop30{
    margin-top: 30px;
  }
  /*顶部边距60px*/
  .marginTop60{
    margin-top: 60px;
  }
  /*顶部边距-20px*/
  .marginTop-20{
    margin-top: -20px;
  }
  .GenerateButton:hover{
    background: #0369A6 !important;
  }
  /*隐藏上传文件列表*/
  .el-upload-list{
    display: none;
  }
  /*调整表单label右边距*/
  .el-form-item__label{
    padding-right: 19px !important;
  }
  /*备注组件样式*/
  .formCard .remark textarea,.newOSRemark textarea{
    font-size: 14px;
    font-family: Arial, Arial-Regular;
    padding:30px;
    height: 116px;
  }
  /*Generate、Clear、Save、Cancel按钮大小*/
  .defaultButton{
    width: 110px;
    height: 38px;
  }
  /*Generate按钮样式*/
  .primaryButton{
    margin-left: 20px !important;
  }
  /*OSVersion Tabs组件中按钮大小*/
  .OSVersion-content button{
    width:61px !important;
    height:36px !important;
    padding: 0px !important;
  }
  /*按钮左对齐*/
  .buttonLeft{
    text-align: left;
    margin-top: 30px;
    display: inline-block;
  }
  /*上传组件中有文件时图片位置*/
  .el-icon-folder-opened{
    padding: 2.5px 0;
  }
  /*上传组件中有文件时文件名位置*/
  .haveFile{
    padding-top: 3px;
  }
  /*Device页面上传组件总宽度*/
  .width760{
    width: 58%;
    margin-top: -20px;
    line-height: 0px;
  }
  /*上传组件样式*/
  .uploadFile{
    display: inline-block;
    width: 23%;
    min-width: 80px;
    margin-right: 2%;
  }
  /*Device页面备注框样式*/
  .formCard .remark{
    width: 57% !important;
  }
  /*Device页面表单项样式*/
  .formItem{
    width: 100%;
    text-align: left;
  }
  /*DeviceName样式*/
  .DeviceName{
    width:33% !important;
  }
  .DeviceName:hover,.OSVersion:hover{
    box-shadow: 0px 0px 8px 0px rgb(51 153 255 / 80%);
    border:1px solid #3399FF !important;
    border-radius: 3px;
  }
  .DeviceName .el-input__inner{
    padding-left: 30px !important;
  }
  /*New OS弹窗页面OSVersion样式*/
  .OSVersion{
    width:428px !important;
  }
  .OSVersion .el-input__inner{
    padding-left: 30px !important;
  }
  /*dialog关闭按钮位置*/
  .el-dialog__headerbtn{
    top:23px !important;
    /*right: 40px  !important;*/
  }
  /*dialog标题样式*/
  .el-dialog__header{
    padding:23px 40px 23px 41px !important;
    background-color:#f2f2f2;
    height: 14px;
  }
  .el-tabs__nav-wrap::after{
    background-color: #eeeeee !important;
    height: 1px !important;
  }
  button{
    border-radius: 3px !important;
  }
  .tabButton:hover{
    background-color: transparent !important;
  }
  .formCard{
    margin-top:16px;
  }
  .newOSRemark{
    width: 760px !important;
  }
  .newOS-upload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
  }
  .newOS-upload .el-upload:hover {
    border-color: #409EFF;
  }
  .newOS-upload-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .newOSAdd{
    width: 100%;
    line-height: 20px;
    font-size: 13px;
    font-family: Arial, Arial-Regular;
    font-weight: 400;
    text-align: center;
    color: #555555;
    margin-top:23px ;
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }
  .newOSupload{
    width: 100%;
    height: 63px;
    font-size: 48px;
    font-family: Arial, Arial-Regular;
    font-weight: 400;
    text-align: center;
    color: #555555;
    margin-top: 58px;
  }
  /*上传组件中有文件时文件名位置*/
  .newOSHaveFile{
    margin-top:10px !important;
  }
  /*上传组件中有文件时图片位置*/
  .newOSupload .el-icon-folder-opened{
    vertical-align: top !important;
    margin-top:5px;
    padding: 0px !important;
  }
</style>