<template>
  <div class="NavMenu">
    <div class="company-logo" v-show="!isMenuCollapse">
      <img class="company-logo-img1" :src="logoImg" />
      <!--div class="company-logo-title">{{ logoTitle }}</div-->
    </div>
    <div class="company-logo-title-simple" v-show="isMenuCollapse">
      <img class="company-logo-img2" :src="logoTitleSimple" />
    </div>
    <div class="menu">
      <el-menu
        default-active="1"
        :unique-opened="true"
        class="el-menu-vertical-demo"
        @open="handleOpenOrClose"
        @close="handleOpenOrClose"
        :collapse="isMenuCollapse"
      >
        <el-submenu
          :class="{
            noChildrenMenu: m.children == undefined,
            activeMenu: m.menuId == activeMenuId,
          }"
          v-for="m in menus"
          :key="m.menuId"
          :index="m.menuId"
        >
          <template slot="title">
            <i class="nav-menu-icon" :class="m.icon" @click="menuClick(m)"></i>
            <span slot="title" class="menuItem menuName" @click="menuClick(m)"
              >{{ m.menuName }}
            </span>
          </template>
          <el-menu-item
            v-for="sub in m.children"
            :key="sub.menuId"
            :index="sub.menuId"
            @click="subMenuClick(sub)"
            :class="{
              activeMenu: sub.menuId == activeMenuId,
            }"
          >
            <div class="submenu menuName">
              {{ sub.menuName }}
            </div></el-menu-item
          >
        </el-submenu>
      </el-menu>
      <div class="switch-menu" v-if="false">
        <div class="switch-button">
          <i
            v-show="isMenuCollapse == false"
            class="el-icon-s-fold"
            @click="isMenuCollapse = !isMenuCollapse"
          ></i>
          <i
            v-show="isMenuCollapse"
            class="el-icon-s-unfold"
            @click="isMenuCollapse = !isMenuCollapse"
          ></i>
        </div>
      </div>
    </div>
    <!--div class="menu-bottom-copyright" v-show="!isMenuCollapse">
      <div class="text">Curtis Technology (Suzhou) Co., LTD</div>
      <div class="text">Copyright @ 19064680 all Rights reserved</div>
      <div class="text">Su public network security 32059002003120</div>
    </div-->
  </div>
</template>

<script>
export default {
  name: "NavMenu",
  props: {
    companyLogo: Object,
    companyName: String,
    companyNameSimple: String,
    menus: [],
    isMenuCollapse: Boolean,
  },
  computed: {
    logoTitle() {
      return this.companyName || "Curtis";
    },
    logoTitleSimple() {
      return (
        this.companyNameSimple ||
        require("./../../assets/image/Curtis.png")
      );
    },
    logoImg() {
      return (
        this.companyLogo || require("./../../assets/image/Curtis_Horz_white.png")
      );
    },
  },
  data() {
    return {
      // isMenuCollapse: false,
      activeMenuId: null,
    };
  },
  created() {
    if (this.menus) {
      this.activeMenuId = this.menus[0].menuId;
    }
  },
  methods: {
    handleOpenOrClose(key, keyPath) {
      console.log("handleOpenOrClose:", key, keyPath);
      this.menuClick({ menuId: keyPath, key: key });
    },

    menuClick(m) {
      console.log("menuClick:", m);
      let menuId = m.menuId;
      if (menuId) {
        for (let index = 0; index < this.menus.length; index++) {
          const m = this.menus[index];
          if (m.menuId == menuId) {
            if (m.link) {
              this.activeMenuId = m.menuId;
              this.$emit("menu-click", m);
              break;
            }
          }
        }
      }
    },
    subMenuClick(m) {
      this.activeMenuId = m.menuId;
      this.$emit("menu-click", m);
    },
  },
};
</script>

<style>
.noChildrenMenu .el-submenu__icon-arrow.el-icon-arrow-down {
  display: none;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.NavMenu {
  display: flex;
  height: 100%;
  flex-direction: column;
  background: #04223a;
  box-shadow: 0px 0px 6px 0px undefined;
}
.el-menu-vertical-demo {
  flex-grow: 1;
  background: #04223a;
}
.el-menu-vertical-demo .el-menu-item {
  padding-left: 50px !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 237px;
  text-align: left;
}
.switch-menu {
  flex-grow: 0;
  height: 100px;
  line-height: 100px;
}
.switch-button {
  font-size: 28px;
  cursor: pointer;
  width: 28px;
}

.company-logo {
  height: 70px;
  background: #04223a;
  border-bottom: #00b194 1px solid;
}
.company-logo-img1 {
  height: 45px; 
  width: 160px; 
  margin: 15px 0px 0px 25px;
  float: left;
}
.company-logo-title {
  font-size: 21px;
  font-family: Arial, Arial-Regular;
  margin-top: 25px;
  text-align: center;
  float: left;
  margin-left: 13px;
  color: #ffffff;
}
.company-logo-title-simple {
  height: 70px;
  background: #04223a;
  border-bottom: #00b194 1px solid;
}
.company-logo-img2 {
  height: 31px;
  width: 31px;
  margin-left: 27px;
  margin-right: 43px;
  margin-top: 20px;
}

.menu {
  margin-top: 24px;
  // margin-bottom: 100px;
  /*overflow-y: auto;*/
}
.el-submenu .el-menu-item {
  line-height: initial;
  min-height: 50px;
  padding-top: 15px;
}
.el-menu-item .submenu {
  white-space: pre-line;
}

.activeMenu {
  border-left: solid 2px #00b194;
  background: linear-gradient(to right, #034b54 0%, #04223a 100%);

  .menuName {
    color: #0079c1;
  }
}

/deep/ .el-submenu__title:hover {
  background: linear-gradient(to right, #034b54 0%, #04223a 100%);
}
/deep/ .el-menu-item:focus,
.el-menu-item:hover {
  background: linear-gradient(to right, #034b54 0%, #04223a 100%);
}

/deep/ .el-menu {
  border-right: 0;
}
/deep/ .el-submenu__title {
  padding-left: 33px !important;
  padding-right: 50px;
}
.menu-bottom-copyright {
  position: absolute;
  bottom: 40px;
  left: 10px;
  .text {
    letter-spacing: -0.5px;
    font-size: 9px;
    font-family: Arial, Arial-Regular;
    font-weight: 400;
    text-align: center;
    color: #006699;
  }
}
.menuItem {
  font-size: 14px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin-left: 13px;
}
.submenu {
  color: #ffffff;
}
.el-menu-item {
  background-color: #04223a;
}
</style>
