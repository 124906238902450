import request from "@/utils/request";

//获取NodeId列表
function getNodeIdList(data) {
  return request({
    url: "v2/SystemConfig/GetNodeIdList",
    method: "post",
    data
  });
}
              
            

export default {
  getNodeIdList
};