<template>
    <div class="fault-box">
        <el-row class="fault-top">
            <el-col :span="4">
                <div class="title">
                    <span>{{$t('faultManage.faultList')}}</span>
                </div>
            </el-col>
            <el-col :span="14">
                <div class="selectMachine">
                    <el-select v-model="rdmno" filterable clearable multiple :placeholder="$t('common.chooseMachine')" @change="getFaultList(0)">
                        <el-option
                        v-for="(item,key) in machineList"
                        :key="key"
                        :label="item.MachineSN"
                        :value="item.RDMNO">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="datepicker">
                    <el-date-picker
                        v-model="daterange"
                        type="daterange"
                        range-separator="~"
                        :start-placeholder="$t('common.startdate')"
                        :end-placeholder="$t('common.enddate')"
                        @change="getFaultList(0)"
                    >
                    </el-date-picker>
                </div>
            </el-col>
            <!--el-col :span="4">
                <div class="search">
                    <el-input
                        :placeholder="$t('addMachine.MachineSN')+'/'+$t('addMachine.RDMNO')"
                        suffix-icon="el-icon-search"
                        v-model="searchContent"
                    >
                    </el-input>
                </div>
            </el-col-->
        </el-row>
        <el-row class="fault-content">
            <el-col :span="24">
                <div class="fault-content-top">
                    <div class="tab">
                        <span :class="{active: includeAllFaults}" 
                            @click="chooseTab(0)">
                            {{$t("faultManage.allfault")}} ({{pageParams.TotalCount_All}})
                        </span>
                        <span :class="{active: includeSystemFaults}" 
                            @click="chooseTab(1)">
                            {{$t("faultManage.systemfault")}} ({{pageParams.TotalCount_SystemFault}})
                        </span>
                        <span :class="{active: includeCustomFaults}" 
                            @click="chooseTab(2)">
                            {{$t("faultManage.userfault")}} ({{pageParams.TotalCount_CustomFault}})
                        </span>
                        <span :class="{active: includeRealtimeFaults}" 
                            @click="chooseTab(3)">
                            {{$t("faultManage.realfault")}} ({{pageParams.TotalCount_RealtimeFault}})
                        </span>
                    </div>
                    <div class="button">
                        <el-button type="warning" @click="openFaultRecord(true)" v-if="privilege.Add">{{$t('faultManage.addFaultRecord')}}</el-button>
                    </div>
                </div>
            </el-col>
            <el-col :span="24">
                <div class="fault-list">
                    <div class="fault-list-row" v-for="(item,index) in currentList" :key="index">
                        <el-row >
                            <div class="fault-title">
                                <el-col :span="12" @click.native="expand(index)" style="cursor: pointer;">
                                        <div :class="item.resolved==ConstDefinition.faultStatus.active ? 'fault-active':'fault-resolved'">
                                            <span>{{checkExistData(faultStatus,item.resolved, 'value').data.text}}</span>
                                        </div>
                                        <div class="fault-code">
                                            <span >{{item.machineSN}}</span>
                                        </div>
                                        <div class="fault-name">
                                            <span >{{item.faultName}}</span>
                                        </div>
                                        <div class="fault-name">
                                            <span v-if="item.displayName!=null&&item.displayName!=''">-{{item.displayName}}</span>
                                        </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="fault-eidt" v-if="privilege.Edit" >
                                        <img src="../../assets/image/edit.png" v-if="item.source!='realtime'" @click="openFaultRecord(false,item)">
                                    </div>
                                    <div class="fault-date">
                                        {{formatTimestampUTC(new Date(item.startTime_TimestampUTC),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}
                                    </div>
                                </el-col>
                            </div>
                        </el-row>
                        <el-row class="fault-dec" v-if="selectRow.includes(index)">
                            <el-col :span="12">
                                <div class="fault-info">
                                    <el-row v-if="item.source ==='system'||item.source ==='realtime'"> 
                                        <el-col :span="6">
                                            <div class="info-title">
                                                <span>{{$t('addMachine.Device')}}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <div class="info-result" >
                                                <span>
                                                    {{item.DeviceName}}({{item.nodeId}})</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="6">
                                            <div class="info-title">
                                                <span>{{$t('addMachine.RDMNO')}}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <div class="info-result">
                                                <span>{{item.rdmno}}</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="6">
                                            <div class="info-title">
                                                <span>{{$t('FactoryProduct.MachineType')}}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <div class="info-result">
                                                <span>{{item.TypeName}}</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="6">
                                            <div class="info-title">
                                                <span>{{$t('common.faultCode')}}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <div class="info-result">
                                                <span>{{item.faultCode}}</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="6">
                                            <div class="info-title">
                                                <span>{{$t('addMachine.faultDisplayName')}}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <div class="info-result">
                                                <span>{{item.displayName}}</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="6">
                                            <div class="info-title">
                                                <span>{{$t('faultManage.faultCycle')}}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <div class="info-result">
                                                <span v-if="item.endTime_TimestampUTC>0">
                                                    {{formatTimestampUTC(new Date(item.startTime_TimestampUTC),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}~
                                                    {{formatTimestampUTC(new Date(item.endTime_TimestampUTC),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}
                                                </span>
                                                <span v-else>
                                                    {{formatTimestampUTC(new Date(item.startTime_TimestampUTC),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}
                                                </span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="6">
                                            <div class="info-title">
                                                <span>{{$t('faultManage.record_user')}}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <div class="info-result">
                                                <span>{{item.record_user}}</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="6">
                                            <div class="info-title">
                                                <span>{{$t('faultManage.resolved_user')}}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="18">
                                            <div class="info-result">
                                                <span>{{item.resolved_user}}</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="row-right"> 
                                    <el-row>
                                        <div class="row-right-title">
                                            {{$t('bizConfig.Remark')}}:
                                        </div>
                                    </el-row>
                                    <el-row>
                                        <div class="row-right-remark">
                                            {{item.remark}}
                                        </div>
                                    </el-row>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="more" v-if="pageParams.pageIndex*userInfo.PageSize <= pageParams.TotalCount" 
                    @click="getFaultList(1)">{{$t('common.loadingMore')}}</div>
                </div>
            </el-col>
        </el-row>
        <el-dialog
            :title="faulttitle"
            :visible.sync="faultRecordShow"
            width="950px"  class="el-dialog-custom"
        >
            <el-form
                ref="faultRecordForm" 
                :model="faultRecordForm" 
                label-width="230px" 
                :rules="isAdd ? addrules:editrules" 
                v-loading="faultLoading" 
            >
                <el-form-item
                    v-if="isAdd"
                    :label="$t('addMachine.MachineSN')+':'"
                    prop="machineId"
                >
                    <el-select v-model="faultRecordForm.machineId" filterable clearable :placeholder="$t('common.chooseMachine')">
                        <el-option
                        v-for="(item,key) in machineList"
                        :key="key"
                        :label="item.MachineSN"
                        :value="item.MachineId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="isAdd"
                    :label="$t('common.faultCode')+':'"
                    prop="faultCode"
                >
                    <el-input v-model="faultRecordForm.faultCode" 
                    :placeholder="$t('common.faultCode')" 
                    class="from-input"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('addMachine.faultName')+':'"
                    prop="faultName"
                >
                    <el-input v-model="faultRecordForm.faultName" 
                    :placeholder="$t('addMachine.faultName')" 
                    class="from-input"
                    :disabled='!isAdd'
                    ></el-input>
                </el-form-item>
                <el-form-item 
                    :label="isAdd ? $t('faultManage.record_user'): $t('faultManage.resolved_user')+':'"
                    prop="recordUser"
                >
                    <el-input v-model="faultRecordForm.recordUser" 
                    :placeholder="isAdd ? $t('faultManage.record_user'): $t('faultManage.resolved_user')" 
                    class="from-input"></el-input>
                </el-form-item>
                 <el-form-item
                    v-if="isAdd"
                    :label="$t('faultManage.faulttime')+':'"
                    prop="faultTime"
                >
                    <el-date-picker
                        v-model="faultRecordForm.faultTime"
                        type="datetime"
                        :placeholder="$t('common.chooseDate')"
                        >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    v-if="!isAdd"
                    :label="$t('faultManage.faultStartTime')+':'"
                    prop="startTime_TimestampUTC"
                >
                    <el-date-picker
                        v-model="faultRecordForm.startTime_TimestampUTC"
                        type="datetime"
                        :placeholder="$t('common.chooseDate')"
                        >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    v-if="!isAdd"
                    :label="$t('faultManage.faultEndTime')+':'"
                    prop="endTime_TimestampUTC"
                >
                    <el-date-picker
                        v-model="faultRecordForm.endTime_TimestampUTC"
                        type="datetime"
                        :placeholder="$t('common.chooseDate')"
                        >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    :label="$t('faultManage.faultStatus')+':'"
                    prop="faultStatus"
                >
                    <el-select v-model="faultRecordForm.faultStatus" filterable clearable :placeholder="$t('common.pleaseChoose')">
                        <el-option
                        v-for="(item,key) in faultStatus"
                        :key="key"
                        :label="item.text"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    :label="$t('addMachine.remark')+':'"
                    prop="Remark"
                >
                    <el-input type="textarea"
                    :rows="5"
                     v-model="faultRecordForm.remark" :placeholder="$t('bizConfig.RemarkInformation')"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogCancel" :disabled="typeDisabled">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="saveUserCustomFault" :disabled="typeDisabled">{{$t('addMachine.Submit')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import faultApi from '@/api/autogen/v2/fault';
import orgnizationApi from "@/api/autogen/v2/orgnization";
import { formatTimestampUTC,checkExistData ,formatTimeStr,ifGrantedTheOperationForThisFunction} from '@/utils';
import { ConstDefinition } from "@/utils/constDefinition";
export default {
    data() {
        var checkfaultTime = (rule, value, callback) => {
            if(value == '' || value == null){
                 callback();
            }
            let time1 = new Date(value);
            let time2 = new Date(this.faultRecordForm.startTime_TimestampUTC);
            if(time1< time2){
                callback(
                    new Error(
                        this.$t("faultManage.greaterthan_starttime")
                    )
                );
            }else{
                callback();
            }

        };
        return {
            faulttitle:'',
            isAdd:true,
            faultRecordShow:false,
            searchContent:'',
            includeAllFaults: true,
            includeRealtimeFaults: false,
            includeSystemFaults: false,
            includeCustomFaults: false,
            machineList:[],
            ConstDefinition:ConstDefinition,
            faultStatus:[
                {
                    text : this.$t("faultManage.active"),
                    value : ConstDefinition.faultStatus.active
                },
                {
                    text : this.$t("faultManage.resolved"),
                    value : ConstDefinition.faultStatus.resolved
                }
            ],
            machineDeviceList:[],
            rdmno:[],
            daterange:'',
            faultList:[],
            pageParams:{
                pageIndex: 1,
                TotalCount:0,
                TotalCount_All : 0,
                TotalCount_SystemFault: 0,
                TotalCount_CustomFault: 0,
                TotalCount_RealtimeFault: 0
            },
            selectRow:[],
            faultRecordForm:{
                recordUser:'',
                machineId:'',
                faultTime:'',
                faultCode:'',
                faultName:'',
                faultStatus:'',
                startTime_TimestampUTC:'',
                endTime_TimestampUTC:'',
                remark:'',
                id:'',
                source:''
            },
            addrules:{
                recordUser: [
                    {
                        required: true,
                        message:
                        this.$t("faultManage.record_user") + this.$t("common.required"),
                        trigger: "blur",
                    }
                ],
                machineId: [
                    {
                        required: true,
                        message:
                        this.$t("addMachine.MachineSN") + this.$t("common.required"),
                        trigger: "change",
                    }
                ],
                faultTime: [
                    {
                        required: true,
                        message:
                        this.$t("faultManage.faulttime") + this.$t("common.required"),
                        trigger: "blur",
                    }
                ],
                faultCode: [
                    {
                        required: true,
                        message:
                        this.$t("common.faultCode") + this.$t("common.required"),
                        trigger: "blur",
                    }
                ],
                faultName: [
                    {
                        required: true,
                        message:
                        this.$t("addMachine.faultName") + this.$t("common.required"),
                        trigger: "blur",
                    }
                ],
                faultStatus: [
                    {
                        required: true,
                        message:
                        this.$t("faultManage.faultStatus") + this.$t("common.required"),
                        trigger: "change",
                    }
                ],
            },
            editrules:{
                startTime_TimestampUTC:[
                    {
                        required: true,
                        message:
                        this.$t("faultManage.faultStartTime") + this.$t("common.required"),
                        trigger: "blur",
                    }
                ],
                endTime_TimestampUTC: [
                    {
                        validator: checkfaultTime,
                        trigger:'blur'
                    }
                ],
                faultStatus: [
                    {
                        required: true,
                        message:
                        this.$t("faultManage.faultStatus") + this.$t("common.required"),
                        trigger: "change",
                    }
                ],
                recordUser: [
                    {
                        required: true,
                        message:
                        this.$t("faultManage.record_user") + this.$t("common.required"),
                        trigger: "blur",
                    }
                ],
            },
            faultLoading:false,
            typeDisabled:false,
            tabActive:'',
            selectCompany:{},
            privilege: {
                View: false,
                Add: false,
                Edit: false,
            }
        };
    },
    computed: {
        currentCompany(){
            return this.$store.state.companyEntity;
        },
        userInfo() {
            return this.$store.state.userEntity;
        },
        selectIndex:{
            get:'',
            set: function(val){
                if(this.selectRow.includes(val)){
                    this.selectRow.forEach((item, index) => {
                        if(item == val){
                            this.$delete(this.selectRow,index);
                        }
                    });
                }else{
                    this.selectRow.push(val); 
                }
            }
        },
        sysMachineSN(){
            let array = [];
            /*if (this.faultList) {
                this.faultList.forEach((v) => {
                    if(!array.includes(v.machineSN)){
                        array.push(v.machineSN);
                    }
                });
            }*/
            if (this.machineList) {
                this.machineList.forEach((v) => {
                    if(!array.includes(v.MachineSN)){
                        if(this.rdmno!="")
                        {
                           let index = this.rdmno.indexOf(v.RDMNO);
                           let isContains = index !== -1;
                            if(isContains)
                            {
                                array.push(v.MachineSN);
                            }

                        }else{
                            array.push(v.MachineSN);
                        }
                        
                    }
                });
            }            
            return array;
        },
        currentList(){
            let list = [];
            if (this.faultList) {
                console.log("currentList faultList===");
                console.log(this.faultList);
                console.log("currentList this.machineDeviceList===");
                console.log(this.machineDeviceList);
                this.faultList.forEach((v) => {
                    //list.push(v);
                    let countMachine=0;
                    if(this.machineDeviceList.length>0){
                        let arry = {};
                        this.machineDeviceList.forEach((item)=>{
                            if(v.machineSN ==item.MachineSN && v.nodeId == item.NodeId){
                                arry ={
                                        ...v,
                                        DeviceName:item.DeviceName,
                                        TypeName:item.TypeName

                                    };
                                    //let index = checkExistData(list,v.id,'id').index
                                    //this.$delete(list,index)
                                    list.push(arry);   
                                    if(v.source=="realtime")
                                        console.log("realtime arry",arry);
                                return;                    
                            }
                            else
                            {
                                countMachine++;
                            }                                
                        });
                    }
                    if(countMachine==this.machineDeviceList.length)
                    {
                            list.push(v);
                    }
                });
            }
            console.log("list===");
            console.log(list);
            return list;
        }
    },
    created() {
        let self = this;
        self.$bus.on("company-switch", self.companySwitch);//监听公司变化
        self.getMachineList();
        self.getFaultList(0);
        self.initialFunctionOperationPrivilege();
    },
    methods: {
        formatTimestampUTC,
        checkExistData,
        ifGrantedTheOperationForThisFunction,
        initialFunctionOperationPrivilege()
        {
                let self = this;
                self.privilege.View = ifGrantedTheOperationForThisFunction("View");
                self.privilege.Add = ifGrantedTheOperationForThisFunction("Add");
                self.privilege.Edit = ifGrantedTheOperationForThisFunction("Edit");
        },
         //监听公司变化
        companySwitch(evtPayload){
            this.selectCompany = evtPayload;
            this.typeName = '';
            this.faultList =[];
            this.pageParams.TotalCount=0;
            this.pageParams.TotalCount_All=0;
            this.TotalCount_SystemFault=0;
            this.TotalCount_CustomFault=0;
            this.TotalCount_RealtimeFault=0;
            this.getMachineList();
            this.getFaultList(0);
        },

         

        //打开故障记录dialog
        openFaultRecord(type,item){
            this.isAdd = type;
            this.faultRecordShow = true;
            this.$nextTick(() => {
                this.$refs['faultRecordForm'].resetFields();
                this.faultRecordForm = {
                    recordUser:'',
                    machineId:'',
                    faultTime:'',
                    startTime_TimestampUTC:'',
                    endTime_TimestampUTC:'',
                    faultCode:'',
                    faultName:'',
                    faultStatus:'',
                    remark:''
                };
                if(type){
                    this.faulttitle = this.$t('faultManage.addFaultRecord');
                }else{
                    this.faultRecordForm.recordUser = item.resolved_user;
                    this.faultRecordForm.source = item.source;
                    this.faultRecordForm.faultName = item.faultName;
                    this.faultRecordForm.id = item.id;
                    this.faultRecordForm.startTime_TimestampUTC = new Date(item.startTime_TimestampUTC);
                    this.faultRecordForm.endTime_TimestampUTC = new Date(item.endTime_TimestampUTC);
                    this.faultRecordForm.faultStatus = item.resolved;
                    this.faultRecordForm.remark = item.remark;
                    this.faulttitle = this.$t('faultManage.editFaultRecord');
                }
            });
            
            
            
        },
        //关闭弹窗
        dialogCancel(){
            this.faultRecordShow = false;  
        },
        saveUserCustomFault(){
            if(this.isAdd){
                this.createUserCustomFault();
            }else{
                this.updateUserCustomFault();
            }
        },

        //更新用户自定义故障
        updateUserCustomFault(){
            let self = this; 
            self.$refs["faultRecordForm"].validate((valid) => {
                if(valid){
                    self.faultLoading = true;
                    self.typeDisabled = true;
                    let starttime  = '';
                    let endtime = '';
                    starttime = new Date(self.faultRecordForm.startTime_TimestampUTC).getTime();
                    endtime = new Date(self.faultRecordForm.endTime_TimestampUTC).getTime();
                    let resolved =true;
                    if(self.faultRecordForm.faultStatus === ConstDefinition.faultStatus.active){
                        resolved =false;
                    }
                    let param = {
                        id: self.faultRecordForm.id,
                        startTime_TimestampUTC: starttime,
                        endTime_TimestampUTC: endtime,
                        resolved: resolved,
                        resolvedUser: self.faultRecordForm.recordUser,
                        remark: self.faultRecordForm.remark
                    };
                    if(self.faultRecordForm.source === 'system'){
                        faultApi.UpdateSystemFault(param).then((res)=>{
                            let content = '';
                            let Url = '';
                            if(res&&res.IsSuccess && res.Data){
                                Url = require("@/assets/image/success.png");
                                content= self.$t("common.operationSuccess");
                                self.getFaultList(0);
                            }else{
                                Url = require("@/assets/image/error_76.png");
                                content= self.$t("common.operationFailure");
                            }
                            self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: self.$t("common.confirm"),
                                center: true,
                                showClose:false
                            });
                            self.faultLoading = false;
                            self.typeDisabled = false;
                            self.faultRecordShow = false;
                        });
                    }else{
                        faultApi.UpdateUserCustomFault(param).then((res)=>{
                        let content = '';
                        let Url = '';
                        if(res&&res.IsSuccess && res.Data){
                            Url = require("@/assets/image/success.png");
                            content= self.$t("common.operationSuccess");
                            self.getFaultList(0);
                        }else{
                            Url = require("@/assets/image/error_76.png");
                            content= self.$t("common.operationFailure");
                        }
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.faultLoading = false;
                        self.typeDisabled = false;
                        self.faultRecordShow = false;
                    });
                    }
                    
                }
            });
        },


        //增加用户自定义故障
        createUserCustomFault(){
            let self = this; 
            self.$refs["faultRecordForm"].validate((valid) => {
                if(valid){
                    self.faultLoading = true;
                    self.typeDisabled = true;
                    self.faultRecordForm.faultTime = formatTimeStr(self.faultRecordForm.faultTime);
                    let param = self.faultRecordForm;
                    faultApi.CreateUserCustomFault(param).then((res)=>{
                        let content = '';
                        let Url = '';
                        if(res&&res.IsSuccess && res.Data){
                            Url = require("@/assets/image/success.png");
                            content= self.$t("common.operationSuccess");
                            self.getFaultList(0);
                        }else{
                            Url = require("@/assets/image/error_76.png");
                            content= self.$t("common.operationFailure");

                        }
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.faultLoading = false;
                        self.typeDisabled = false;
                        self.faultRecordShow = false;
                    });
                }
            });
        },
        
        //展开
        expand(data){
            this.selectIndex = data;
            
        },

        //tab切换
        chooseTab(flag){
            if(flag == 0){
                this.includeAllFaults = true;
                this.includeSystemFaults = false;
                this.includeCustomFaults = false;
                this.includeRealtimeFaults = false;
            }else if(flag == 1){
                this.includeAllFaults = false;
                this.includeSystemFaults = true;
                this.includeCustomFaults = false;
                this.includeRealtimeFaults = false;
            }else if(flag == 2){
                this.includeAllFaults = false;
                this.includeSystemFaults = false;
                this.includeCustomFaults = true;
                this.includeRealtimeFaults = false;
            }else{
                this.includeAllFaults = false;
                this.includeSystemFaults = false;
                this.includeCustomFaults = false;
                this.includeRealtimeFaults = true;
            }
            this.getFaultList(0);
        },

        //获取车辆列表
        getMachineList(){
            this.machineList=[];
            this.rdmno=[];
            let param = {
                companyId :this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId,
            };
            faultApi.GetMachineBasicInfoListByCompanyId(param).then((res)=>{
                if(res){
                    this.machineList = res;
                }
            });
        },
        
        //获取错误列表
        getFaultList(type){      
            console.log("type==");      
            console.log(type);      
            if(type == 0){
                this.pageParams.pageIndex = 1;
            }else{
                this.pageParams.pageIndex = this.pageParams.pageIndex + 1;
            }
            let starttime  = '';
            let endtime = '';
            if(this.daterange&&this.daterange.length>0){
                starttime = formatTimeStr(this.daterange[0]); 
                if(this.daterange.length>1){
                    endtime = formatTimeStr(this.daterange[1]);
                }
            }
            let param = {};
            if(this.includeAllFaults){
                param = {
                    pageSize : this.userInfo.PageSize,
                    pageIndex : this.pageParams.pageIndex,
                    rdmnoList: this.rdmno,
                    companyId :this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId,
                    includeRealtimeFaults: true,
                    includeSystemFaults: true,
                    includeCustomFaults: true,
                    startTime: starttime,
                    endTime: endtime
                }; 
            }else{
                param = {
                    pageSize : this.userInfo.PageSize,
                    pageIndex : this.pageParams.pageIndex,
                    rdmnoList: this.rdmno,
                    companyId :this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId,
                    includeRealtimeFaults: this.includeRealtimeFaults,
                    includeSystemFaults: this.includeSystemFaults,
                    includeCustomFaults: this.includeCustomFaults,
                    startTime: starttime,
                    endTime: endtime
                }; 
            }
            
            return faultApi.GetFaults(param).then((res)=>{
               
                if(res&&res.IsSuccess&&res.Data){                    
                    console.log("res.Data.Faults==");
                    console.log(res.Data.Faults);
                    console.log("this.faultList==");
                    console.log(this.faultList);
                    if(type == 0){
                        this.faultList = res.Data.Faults;
                    }else{
                        this.faultList = this.faultList.concat(res.Data.Faults);
                    }
                    this.pageParams.TotalCount = res.Data.TotalCount;
                    if(this.includeAllFaults){
                        this.pageParams.TotalCount_All = res.Data.TotalCount_SystemFault + res.Data.TotalCount_CustomFault + res.Data.TotalCount_RealtimeFault;
                        this.pageParams.TotalCount_SystemFault = res.Data.TotalCount_SystemFault;
                        this.pageParams.TotalCount_CustomFault = res.Data.TotalCount_CustomFault;
                        this.pageParams.TotalCount_RealtimeFault = res.Data.TotalCount_RealtimeFault;
                    }else if(this.includeSystemFaults){
                        this.pageParams.TotalCount_SystemFault = res.Data.TotalCount_SystemFault;
                        this.pageParams.TotalCount_All = this.pageParams.TotalCount_SystemFault + this.pageParams.TotalCount_CustomFault + this.pageParams.TotalCount_RealtimeFault;
                    }else if(this.includeCustomFaults){
                        this.pageParams.TotalCount_CustomFault = res.Data.TotalCount_CustomFault;
                        this.pageParams.TotalCount_All = this.pageParams.TotalCount_SystemFault + this.pageParams.TotalCount_CustomFault + this.pageParams.TotalCount_RealtimeFault;
                    }else if(this.includeRealtimeFaults){
                        this.pageParams.TotalCount_RealtimeFault = res.Data.TotalCount_RealtimeFault;
                        this.pageParams.TotalCount_All = this.pageParams.TotalCount_SystemFault + this.pageParams.TotalCount_CustomFault + this.pageParams.TotalCount_RealtimeFault;
                    }
                    this.getMachineDeviceList(); 
                }
            });
        },

        //获取车辆的设备基本信息
        getMachineDeviceList(){
            let param = {
                machineSNList:this.sysMachineSN
            };
            console.log("this.sysMachineSN==");
            console.log(this.sysMachineSN);
            faultApi.GetMachineDevices(param).then((res)=>{
                 if(res.IsSuccess&&res.Data){
                     this.machineDeviceList = res.Data;
                     console.log("this.machineDeviceList==");
                     console.log(this.machineDeviceList);
                 }

            });
        }
    },
    mounted() {
    },
    beforeDestroy() {
        this.$bus.off("company-switch", this.companySwitch);
    },
};
</script>

<style lang="less" scoped>
    .el-dialog-custom{
        text-align: left;
        font-size: 14px;
        /deep/.el-dialog__title{
            font-size: 14px;
            font-weight: 700;
        }
        /deep/.el-input{
            width: 300px;
        }
        /deep/.el-textarea{
            width: 555px;
        }
         button {
            width: 110px;
            height: 38px;
        }
        /deep/ .el-dialog__footer{
            text-align: center;
        }
    }
    
    .fault-box{
        min-width: 883px;
        margin-top : 10px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
        .fault-top{
            border-bottom: 1px solid #e5e5e5;
            background: #fafafa;
            border-radius: 2px;
            padding: 0 18px;
            .title{
                font-size: 16px;
                font-weight: 700;
                line-height: 70px;
                float: left;
                margin-left:26px ;
            }
            .search{
                height: 48px;
                line-height: 70px;
            }
            .datepicker{
                height: 48px;
                line-height: 70px;
                /deep/ .el-date-editor{
                    width: 100%;
                }
                margin-right:10px ;
            }
            .selectMachine{
                line-height: 70px;
                height: 48px;
                margin-right:10px ;
                .el-select{
                    width: 100%;
                }
            }
        }
        .fault-content{
            padding: 20px;
            .fault-content-top{
                height: 58px;
                border-bottom: 1px solid #e5e5e5;
                position:relative;
                .tab{
                    position: absolute;
                    float: left;
                    bottom: 1px;
                    span{
                        width: 160px;
                        height: 38px;
                        background: #f2f2f2;
                        border-radius: 2px 2px 0px 0px;
                        font-size: 14px;
                        cursor: pointer;
                        display: inline-block;
                        line-height: 38px;
                        margin: 0 1.5px;
                        &.active {
                            color: #fff;
                            background: #0079C1;
                        }
                        &:hover {
                            color: #fff;
                            background: #0079C1;
                        }
                    }
                }
                .button{
                    float: right;
                    .el-button{
                        height: 38px;
                    }
                }
            }
            .fault-list{
                margin-top:20px ;
                .fault-list-row{
                    margin-bottom: 10px;
                    .fault-title{
                        min-height: 56px;
                        background: #fafafa;
                        border: 1px solid #dddddd;
                        border-radius: 2px 2px 0px 0px;
                        &:hover {
                            box-shadow: 0 0 15px #409EFF;
                        }
                        .fault-active{
                            margin-left: 23px;
                            margin-top: 13px;
                            width: 93px;
                            height: 30px;
                            background:rgba(255,0,0,0.1);
                            border-radius: 15px;
                            color: #FF0000;
                            font-size: 14px;
                            line-height: 30px;
                            float: left;
                        }
                        .fault-resolved{
                            margin-left: 23px;
                            margin-top: 13px;
                            width: 93px;
                            height: 30px;
                            background: rgba(0,177,148,0.1);
                            border-radius: 15px;
                            color: #00B194;
                            font-size: 14px;
                            line-height: 30px;
                            float: left;
                        }
                        .fault-code{
                            font-size: 16px;
                            font-weight: 700;
                            color: #333333;
                            float: left;
                            margin-left: 21px;
                        }
                        .fault-name{
                            font-size: 16px;
                            margin-left: 43px;
                            float: left;
                        }
                        .fault-date{
                            font-size: 16px;
                            color: #999999;
                            float: right;
                            margin-right: 23px;
                        }
                        .fault-eidt{
                            padding-top: 3px;
                            height: 19px;
                            width: 19px;
                            
                            float: right;
                            margin-right: 36px;
                            img{
                                cursor: pointer;
                            }
                        }
                        line-height: 56px;
                    }
                    .fault-dec{
                        border: 1px solid #dddddd;
                        border-top: 0;
                        border-radius: 0px 0px 2px 2px;
                        .fault-info{
                            padding: 25px 32px 25px 25px;
                            font-size: 14px;
                            border-right: 1px solid #dddddd;
                            .el-row{
                                margin-bottom: 2px;
                            }
                            .info-title{
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                background: #e5f1f9;
                                border-radius: 12px 0px 0px 12px;
                                color: #0079c1;
                                float: right;
                                margin-right:3px ;
                            }
                            .info-result{
                                padding-left:  9px;
                                width: 100%;
                                line-height: 30px;
                                height: 30px;
                                background: #fafafa;
                                float: left;
                                text-align: left;
                            }
                        }
                        .row-right{
                            padding: 25px 32px 25px 25px;
                            .row-right-title{
                                font-size: 14px;
                                font-weight: 700;
                                text-align: left;
                            }
                            .row-right-remark{
                                word-wrap:break-word;
                                font-size: 14px;
                                text-align: left;
                            }
                        }
                    }
                }
                .more{
                    height: 60px;
                    background: #fafafa;
                    border: 1px solid #dddddd;
                    border-radius: 2px;
                    text-align: center;
                    font-size: 16px;
                    line-height: 60px;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0 0 15px #409EFF;
                    }
                }
                
            }
        }
        
    }
</style>