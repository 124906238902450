<template>
    <div class="type-box">
        <el-row class="top">
            <el-col :span="12">
                <div class="top-title">
                    <span>{{ $t("FactoryProduct.manageMachineType") }}:</span>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="top-search">
                    <el-input
                        :placeholder="$t('FactoryProduct.machineTypeName')"
                        suffix-icon="el-icon-search"
                         @change="getMachineTypelist(0)"
                        v-model="typeName"
                    >
                    </el-input>
                </div>
            </el-col>
        </el-row>
        <el-row class="content" v-loading="loading" v-if="IsHaveType">
            <el-card class="boxcard" body-style="width:100%;height:100%;box-sizing:border-box;" 
            v-for="(item, index) in typeList" :key="index">
                <el-row class="info">
                    <el-col :xs="11" :sm="9" :md="7" :lg="5" :xl="3">
                        <div class="imgDiv">
                            <img :src="item.ImageBase64 ? item.ImageBase64 : require('../../assets/image/emptycar.png')" 
                            :class="item.ImageBase64 ? 'typeimg':'emptyimg'" 
                            :alt="item.ImageName"/>
                        </div>
                    </el-col>
                    <el-col :xs="13" :sm="15" :md="17" :lg="19" :xl="21">
                        <el-row class="type-div">
                            <el-col :span="20">
                                <div class="name">
                                    {{item.TypeName}}
                                </div>
                                <div class="type-dec">
                                    {{$t('common.version')}}:{{item.TypeVersion}}
                                </div>
                                <div class="version">
                                    <el-popover
                                        placement="bottom"
                                        trigger="hover"
                                        popper-class="popover-type-box"
                                        v-for="(item1, index) in item.DeviceList" :key="index"
                                    >
                                        <span>  {{item1.Remark}}</span>
                                        <div class="version-tag" slot="reference" >
                                            <span class="version-text"> {{item1.DeviceName}} ({{item1.SystemDisplayVersion}})</span>
                                        </div>
                                    </el-popover>
                                    <div class="voltage-tag">
                                       <span class="voltage"> {{$t('common.battery')}}:{{item.VoltMin}} V~{{item.VoltMax}} V</span>
                                    </div>
                                </div>
                                <div class="type-dec">
                                    {{item.Remark}}
                                </div>
                            </el-col>
                            <el-col :span="4">
                                 <el-dropdown trigger="click" class="operation" @command="handleCommand" v-if="privilege.Delete||privilege.Edit">
                                    <img :src="require('../../assets/image/more default.png')" />
                                    <el-dropdown-menu slot="dropdown" >
                                        <el-dropdown-item :command="beforeHandleCommand(item,'edit')" v-if="privilege.Edit">{{$t("FactoryProduct.editMachineType")}}</el-dropdown-item>
                                        <el-dropdown-item :command="beforeHandleCommand(item,'delete')" v-if="privilege.Delete">{{$t("FactoryProduct.deleteMachineType")}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-card>
            <div class="more" v-if="pageParams.pageIndex < pageParams.total" @click="getMachineTypelist(1)">{{$t('common.loadingMore')}}</div>
        </el-row>
        <el-row v-else>
            <div class="type-empty">
                <img src="../../assets/image/big_car.png" />
                <div class="type-empty-text">
                    <span>{{$t("FactoryProduct.emptyText")}}</span>
                </div>
                <div class="type-empty-button" >
                    {{$t("common.Tip")}}:{{$t("FactoryProduct.goTo")}}<span 
                    class="type-empty-link" @click="addMachineType"> {{$t("menu.MyVehicle")}} </span>{{$t('myvehicle.createMachineType')}}
                    <!-- <el-button type="primary">{{$t("FactoryProduct.addMachineType")}}</el-button> -->
                </div>
            </div>
        </el-row>

        <el-dialog
          :title="$t('FactoryProduct.editMachineType')"
          :visible.sync="machineTypeShow"
          width="950px"  class="el-dialog-custom"
        >
            <el-form ref="machineTypeForm" :model="machineTypeForm" label-width="230px" :rules="rules" v-loading="typeLoading" :element-loading-text="loadingText">
                <el-form-item
                    :label="$t('myvehicle.machineType_Name')+':'"
                    prop="TypeName"
                >
                    <el-input v-model="machineTypeForm.TypeName" 
                    :placeholder="$t('myvehicle.machineType_Name')" 
                    class="from-input"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('myvehicle.TypeQRCode')+':'"
                    prop="QRCode"
                >
                    <vue-qr  :text="QRCode" :size="200" v-if="QRCode != ''"></vue-qr>
                </el-form-item>
                <el-form-item
                    :label="$t('addMachine.remark')+':'"
                    prop="Remark"
                >
                    <el-input type="textarea"
                    :rows="5"
                     v-model="machineTypeForm.Remark" :placeholder="$t('bizConfig.RemarkInformation')"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('addMachine.picture') + ':'"
                    prop="picture"
                    >
                        <el-upload
                        action="#"
                        ref="picture"
                        :class="[machineTypeForm.ImageFile.length > 0 ? 'hide' : '']"
                        list-type="picture-card"
                        :file-list="machineTypeForm.ImageFile"
                        :auto-upload="false"
                        :on-change="handleChangePicture"
                        :on-remove="handleRemovePicture"
                        :on-preview="handlePreviewPicture"
                        accept=".jpg, .jpeg, .png, .gif"
                        >
                        <i
                            :class="isUploading ? 'el-icon-loading' : 'el-icon-plus'"
                        ></i>
                        <div class="upload">
                            {{ isUploading ? "Uploading" : "Upload" }}
                        </div>
                        </el-upload>
                    
                </el-form-item>
                 <el-dialog
                :title="$t('addMachine.uploadPicture')"
                width="508px"
                :visible.sync="dialogVisible"
                append-to-body
              >
                <el-upload
                  class="Rebulid"
                  action="#"
                  multiple
                  :file-list="cropPictureList"
                  :auto-upload="false"
                  :on-change="handleRebuildPicture"
                  :on-remove="handleRemovePicture"
                  accept=".jpg, .jpeg, .png, .gif"
                >
                  <el-button>{{ $t("addMachine.Rebulid") }}</el-button>
                  <div class="fileName" v-if="machineTypeForm.ImageFile.length > 0">
                    {{ $t("addMachine.support") }}
                  </div>
                </el-upload>
                <div class="cropper-content">
                  <div class="cropper-box">
                    <div class="cropper">
                      <!-- 裁剪图片组件 -->
                      <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.outputSize"
                        :outputType="option.outputType"
                        :info="option.info"
                        :canScale="option.canScale"
                        :autoCrop="option.autoCrop"
                        :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight"
                        :fixed="option.fixed"
                        :fixedNumber="option.fixedNumber"
                        :full="option.full"
                        :fixedBox="option.fixedBox"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :centerBox="option.centerBox"
                        :height="option.height"
                        :infoTrue="option.infoTrue"
                        :maxImgSize="option.maxImgSize"
                        :enlarge="option.enlarge"
                        :mode="option.mode"
                        @realTime="realTime"
                      >
                      </vue-cropper>
                    </div>
                  </div>
                  <!--预览效果图-->
                  <div class="show-preview">
                    <div class="previewsTitle">
                      {{ $t("addMachine.Preview") }}
                    </div>
                    <div class="preview">
                      <img :src="url" style="height: 100px; width: 100px" />
                    </div>
                  </div>
                </div>
                <div class="tip">
                  {{ $t("addMachine.tip") }}
                </div>
                <div class="footerButton">
                  <el-button @click="dialogVisible = false">{{
                    $t("common.cancel")
                  }}</el-button>
                  <el-button @click="cropPicture" type="primary">{{
                    $t("addMachine.Submit")
                  }}</el-button>
                </div>
              </el-dialog>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogCancel" :disabled="typeDisabled">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="editMachineType" :disabled="typeDisabled">{{$t('addMachine.Submit')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import orgnizationApi from "@/api/autogen/v2/orgnization";
import carTypeApi from '@/api/autogen/v2/cartype';
import carApi from "@/api/autogen/v2/carList";
import vueQr from 'vue-qr';
import { checkExistData,ifGrantedTheOperationForThisFunction } from '@/utils';
export default {
    data() {
         var checkTypeName =  (rule, value, callback) => {
            let data={
                typeName:value,
                companyId: this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId,
                Id:this.machineTypeForm.Id
            };
            if(value === this.originTypeName){
                callback();
            }
            carApi.CheckIfMachineTypeNameDuplicate(data).then(res=>{
                if(res.IsSuccess&&!res.Data){
                    callback();
                }else {
                    callback(
                        new Error(
                            this.$t("myvehicle.TypeNameExited")
                        )
                    );

                }
            });
        };
        return {
            typeList: [
            ],
            pageParams: {
                pageIndex: 1,
                total: 0,
            },
            search: '',
            loading: false,
            selectCompany:{},
            typeName:'',
            machineTypeShow:false,
            //裁剪图片对象
            option: {
                img: "", //裁剪图片的地址
                outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
                outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
                info: true, //图片大小信息
                canScale: true, //图片是否允许滚轮缩放
                autoCrop: true, //是否默认生成截图框
                autoCropWidth: 105, //默认生成截图框宽度
                autoCropHeight: 145, //默认生成截图框高度
                fixed: true, //是否开启截图框宽高固定比例
                fixedBox: false, // 固定截图框大小 不允许改变
                fixedNumber: [1.05, 1.45], //截图框的宽高比例
                canMove: true, //上传图片是否可以移动
                canMoveBox: true, //截图框能否拖动
                original: false, //上传图片按照原始比例渲染
                centerBox: true, //截图框是否被限制在图片里面
                height: true, //是否按照设备的dpr 输出等比例图片
                infoTrue: true, //true为展示真实输出图片宽高，false展示看到的截图框宽高
                maxImgSize: 3000, //限制图片最大宽度和高度
                enlarge: 1, //图片根据截图框输出比例倍数
                full: true, // 输出原图比例截图 props名full
                // mode: '290px 290px'  //图片默认渲染方式
            },
            //实时预览的路径
            url: "",
            //是否上传中
            isUploading: false,
            cropPictureList: [],
            machineTypeForm:{
                Id : null,
                TypeName:'',
                Remark:'',
                ImageFile:[],
                TypeId:null,
                TypeVersion:null
            },
            dialogVisible:false,
            rules: {
                TypeName: [
                    {
                        required: true,
                        message:
                        this.$t("myvehicle.machineType_Name") + this.$t("common.required"),
                        trigger: "blur",
                    },
                    {
                        validator: checkTypeName,
                        trigger:'blur'
                    },
                ],
            },
            typeLoading : false,
            typeDisabled :false,
            loadingText: this.$t('FactoryProduct.loading_editMachineType'),
            originTypeName:'',
            IsHaveType:true,
            privilege: {
                View: false,
                Delete: false,
                Edit: false,
            }
        };
    },
    components: {
        vueQr
    },
    computed: {
        currentCompany(){
            return this.$store.state.companyEntity;
        },
        userInfo() {
            return this.$store.state.userEntity;
        },
        QRCode(){
            let code = '';
            if(this.machineTypeForm.TypeName.length>0){
                //code = this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId + '_' + this.machineTypeForm.TypeName; 
                code = this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId + '_' + this.machineTypeForm.TypeName+ '_' + this.machineTypeForm.TypeId+ '_' + this.machineTypeForm.TypeVersion; 
            }
            return  code;
        }
    },
    created() {
        let self = this;
        self.$bus.on("company-switch", self.companySwitch);//监听公司变化
        self.getMachineTypelist(0);
        self.initialFunctionOperationPrivilege();
    },
    methods: {
        ifGrantedTheOperationForThisFunction,
        //监听公司变化
        companySwitch(evtPayload){
            this.selectCompany = evtPayload;
            this.typeName = '';
            this.getMachineTypelist(0);
        },
        initialFunctionOperationPrivilege()
        {
            let self = this;
            self.privilege.View = ifGrantedTheOperationForThisFunction("View");
            self.privilege.Delete = ifGrantedTheOperationForThisFunction("Delete");
            self.privilege.Edit = ifGrantedTheOperationForThisFunction('Edit');
        },
        
        //获取车型列表
        getMachineTypelist(type){
            this.loading =true;
            let pageIndex = 0;
            if(type === 0){
                pageIndex = 1;
            }else if(type === 1){
                this.pageParams.pageIndex = this.pageParams.pageIndex + 1;
                pageIndex = this.pageParams.pageIndex;
            }
            let params = {
                pageSize: this.userInfo.PageSize,
                pageIndex: pageIndex,
                companyId:this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId,
                typeName: this.typeName
            };
            carTypeApi.GetPublishTypeList(params).then((res)=>{
                if(res.IsSuccess){
                    if(type === 0){
                        this.typeList = res.Data.PublishMachineTypeViewModelsList;
                    }else{
                        this.typeList = this.typeList.concat(res.Data.PublishMachineTypeViewModelsList); 
                    }
                    
                    this.pageParams.total = res.Data.TotalPage;
                    
                }
                if(this.typeList.length>0){
                        this.IsHaveType = true;
                    }else{
                        this.IsHaveType = false;
                    }
                this.loading = false;
            });
        },

        //列表下来菜单返回值
        beforeHandleCommand(item, command){
            return {
                'item': item,
                'command':command
            };
        },

        //下拉菜单event
        handleCommand(command) {
            switch (command.command) {
                case 'edit' :
                    this.openMachineTypeShow(command.item);
                    break;
                case 'delete' :
                    this.deleteMachineType(command.item);
                    break;
            }

        },

        //实时预览函数
        realTime() {
            var self = this;
            this.$refs.cropper.getCropData(async (data) => {
                self.url = data;
            });
        },
        //打开图片剪切弹窗
        handlePreviewPicture(file) {
            this.dialogVisible = true;
        },
        //选择图片
        handleChangePicture(file, fileList) {
            if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
                this.$message({
                message: this.$t('common.PictureRequire'),
                type: "error",
                });
                this.machineTypeForm.ImageFile = [];
            } else {
                this.machineTypeForm.ImageFile = [];
                this.isUploading = true;
                setTimeout(() => {
                console.log(fileList);
                fileList = [file];
                var img = URL.createObjectURL(file.raw); // 获取URL
                this.machineTypeForm.ImageFile = fileList;
                this.cropPictureList = fileList;
                this.option.img = img;
                this.isUploading = false;
                }, 1000);
            }
        },
        //重新选择图片
        handleRebuildPicture(file, fileList) {
            if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
                this.$message({
                message: this.$t('common.PictureRequire'),
                type: "error",
                });
            } else {
                this.machineTypeForm.ImageFile = [];
                this.machineTypeForm.ImageFile[0] = file;
                let fileName = file.name;
                let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
                if (regex.test(fileName.toLowerCase())) {
                var img = URL.createObjectURL(file.raw); // 获取URL
                this.option.img = img;
                this.machineTypeForm.ImageFile[0].url = img;
                this.cropPictureList = [file];
                } else {
                this.$message.error(this.$t('common.ToSelectImage'));
                }
            }
        },
        //剪切图片
        cropPicture() {
            var self = this;
            //获取截图的blob数据
            this.$refs.cropper.getCropData(async (data) => {
                var file = this.dataURLtoFile(data, self.machineTypeForm.ImageFile[0].name);
                file.uid = self.machineTypeForm.ImageFile[0].uid;
                self.machineTypeForm.ImageFile[0].raw = file;
                self.machineTypeForm.ImageFile[0].url = data;
                self.machineTypeForm.ImageFile[0].size = file.size;
                self.dialogVisible = false;
            });
        },
        //删除图片
        handleRemovePicture() {
            setTimeout(() => {
                this.machineTypeForm.ImageFile = [];
                this.cropPictureList = [];
            }, 1000);
        },


        //打开编辑车型
        openMachineTypeShow(item){
            if (item.ImageBase64){

                this.machineTypeForm.ImageFile =[];
                let blobFile=this.dataURLtoFile(item.ImageBase64,"record.jpg");
                let img =URL.createObjectURL(blobFile);
                this.option.img = img;
                console.log(img);
                let file ={
                    raw: blobFile,
                    status:"ready",
                    url:item.ImageBase64
                };
                this.machineTypeForm.ImageFile.push(file);
                this.cropPictureList = [file];
            }else {
                this.machineTypeForm.ImageFile =[];
            }
            
            this.machineTypeForm = {
                 ...this.machineTypeForm,
                Id : item.Id,
                TypeName : item.TypeName,
                Remark : item.Remark,
                TypeId:item.TypeId,
                TypeVersion:item.TypeVersion
            };
            this.originTypeName = item.TypeName;
            this.machineTypeShow = true;
        },

        dialogCancel(){
            this.machineTypeShow =false;
        },
        //编辑车型
        editMachineType(){
            let self = this;
            self.$refs["machineTypeForm"].validate((valid) => {
                if (valid) {
                    self.typeLoading =true;
                    self.typeDisabled = true;
                    let fd = new FormData();
                    fd.append("Id", self.machineTypeForm.Id);
                    fd.append("TypeName", self.machineTypeForm.TypeName);
                    fd.append("QRCode", self.QRCode);
                    if(self.machineTypeForm.ImageFile.length>0){
                        fd.append("ImageFile", self.machineTypeForm.ImageFile[0].raw);
                    }
                    fd.append("Remark", self.machineTypeForm.Remark);
                    carTypeApi.EditPublishedMachineType(fd).then(res=>{
                        let content = '';
                        let Url = '';
                        if(res.IsSuccess && res.Data){
                            Url = require("@/assets/image/success.png");
                            content= self.$t("common.operationSuccess");
                            let IsmachineType = checkExistData(self.typeList,self.machineTypeForm.Id,'Id');
                            if(IsmachineType.isExist){
                                let machineType = IsmachineType.data;
                                // let Base64 = self.imgUrlToBase64(self.machineTypeForm.ImageFile[0].url)
                                self.$set(machineType,'TypeName',self.machineTypeForm.TypeName);
                                self.$set(machineType,'Remark',self.machineTypeForm.Remark);
                                self.$set(machineType,'QRCode',self.QRCode);
                                if(self.machineTypeForm.ImageFile.length>0)
                                    self.$set(machineType,'ImageBase64',self.machineTypeForm.ImageFile[0].url);
                                self.$set(self.typeList,IsmachineType.index,machineType);
                            }
                        }else{                           
                            Url = require("@/assets/image/error_76.png");
                            content= self.$t("common.operationFailure");

                        }
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.typeLoading =false;
                        self.typeDisabled = false;
                        self.machineTypeShow =false;
                        // this.getMachineTypelist(2)
                    }).catch((e) => {
                        console.log("exception",e);
                        let Url = require("@/assets/image/error_76.png");
                        let content= self.$t("common.operationFailure");
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.typeLoading =false;
                        self.typeDisabled = false;
                        self.machineTypeShow =false;
                    });
                }
            });
        },

        //删除车型
        deleteMachineType(item){
            let array = [];
            array.push(item.Id);
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("common.deleteComfirm")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                carTypeApi.DeletePublishedMachineType(array).then((res)=>{
                    if(!res.IsSuccess || !res.Data){
                        this.$message.error(this.$t("common.operationFailure"));
                        return; 
                    }
                    this.$message.success(this.$t("common.operationSuccess"));
                    let IsmachineType = checkExistData(this.typeList,item.Id,'Id');
                    if(IsmachineType.isExist){
                        this.$delete(this.typeList,IsmachineType.index);
                    }
                });
            }).catch(() => {       
            });

        },
        //将base64转换为file
        dataURLtoFile: function (dataurl, filename) {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        //跳转车辆列表发布车型
        addMachineType(){
            /*this.$router.push({
                path: '/VehicleList/'+this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId,
            })*/
           
            let id= this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId;
            this.$router.push({
                path: '/VehicleList/'+id,
            });
        },
    },
    watch:{
        machineTypeShow(val){
            !val&&setTimeout(() => {
                this.$refs['machineTypeForm'].resetFields();
            }, 0);
        }
    },
    mounted() {
    },
    beforeDestroy() {
        this.$bus.off("company-switch", this.companySwitch);
    },
};
</script>

<style lang="less" scoped>
    /*重新选择图片上传组件样式 */
    .Rebulid {
        width: 100%;
        padding: 0px 14px 13px 14px;
        .el-upload {
        text-align: left;
        width: 100%;
        }
        .el-button {
        height: 34px;
        background: #ffffff;
        border: 1px solid #eaeef0;
        border-radius: 2px !important;
        margin-right: 11px;
        color: #0079c1;
        }
        .fileName {
        display: inline-block;
        color: #7a7a7a;
        }
    }
    /*裁剪框样式 */
    .cropper-content {
        .cropper-box {
        display: inline-block;
        width: 290px;
        .cropper {
            width: auto;
            height: 294px;
            .cropper-box {
            background: #898989;
            }
        }
        }
        /*预览区域样式 */
        .show-preview {
        height: 292px;
        width: 150px;
        text-align: center;
        border: 1px solid #eaeef0;
        display: inline-block;
        vertical-align: top;
        .previewsTitle {
            margin-top: 15px;
            color: #696969;
        }
        .preview {
            overflow: hidden;
            width: 100px !important;
            height: 100px !important;
            border-radius: 50px;
            border: 1px solid #0079c1;
            margin: 45px auto;
            display: inline-block;
        }
        }
    }
    .tip {
        padding: 0px 14px 13px 14px;
        width: 100%;
        text-align: left;
        color: #fd990d;
    }

    /*弹窗底部按钮样式 */
    .footerButton {
        text-align: center;
    }
    .el-dialog-custom {
        text-align: left;
        font-size: 14px;
        /deep/ .el-dialog__title{
            font-weight: 700;
            font-size: 16px;
        }
        .el-input {
            width: 300px;
        }
        .el-textarea{
            width: 555px;
        }
        /*上传组件样式 */
        /deep/ .el-upload--picture-card {
            width: 102px;
            height: 102px;
            line-height: 102px;
        }
        /deep/ .el-upload-list__item {
            width: 102px !important;
            height: 102px !important;
        }
        /deep/ .upload {
            display: inline-block;
            top: 0;
            position: absolute;
            left: 17px;
            padding-top: 20px;
            color: #555555;
            width: 70px;
        }
        /deep/ .el-icon-plus {
            color: #555555;
            vertical-align: top;
            margin-top: 20px;
            font-size: 20px;
            line-height: 40px;
        }
        /deep/ .hide .el-upload {
            display: none;
        }
        button {
            width: 110px;
            height: 38px;
        }
        /deep/ .el-dialog__footer{
            text-align: center;
        }
    }
    .type-box{
        width: 100%;
        position: relative;
        .top{
            height: 40px;
            margin: 10px 0 20px 0px;
            .top-title{
                text-align: left;
                font-weight: 700;
                font-size: 16px;
                margin-right:62px ;
                line-height: 40px;
                margin-left: 46px;
            }
            .top-search{
                width: 368px;
                line-height: 40px;
                float: right;
            }
        }
        .content{
            box-sizing: border-box;
            .boxcard{
                margin-bottom: 10px;
                transition: all 0.4s;
                border-radius: 2px;
                width: 100%;
                &:hover {
                    box-shadow: 0 0 15px #409EFF;
                }
                
                .info{
                    box-sizing:border-box;
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: relative;
                    .imgDiv{
                        width: 160px;
                        height: 124px;
                        background: #f2f2f2;
                        border-radius: 2px;
                        margin-top: 8px;
                        margin-left: 8px;
                        .emptyimg {
                            display: block;
                            position: absolute;
                            margin-top: 31px;
                            margin-left: 37px;
                        }
                        .typeimg{
                            width: 160px;
                            height: 124px;
                        }
                        
                    }
                    .type-div{
                        margin-top: 8px;
                        .name{
                            font-size: 18px;
                            font-weight: 700;
                            text-align: left;
                            color: #333333;
                            text-align: left;
                            letter-spacing: 0.18px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .version{
                            width: 100%;
                            margin-top:12px ;
                            .version-tag{
                                cursor: pointer;
                                &:hover {
                                    border-color: #3399FF;
                                }
                                height: 25px;
                                width: 271px;
                                border: 1px solid #999999;
                                margin-right:10px ;
                                margin-bottom: 10px;
                                float: left;
                                text-align: center;
                                .version-text{
                                    height: 25px;
                                    line-height: 25px;
                                    font-size: 14px;
                                    
                                }
                                
                            }
                            .voltage-tag{
                                line-height: 25px;
                                height: 25px;
                                width: 271px;
                                border: 1px solid #999999;
                                margin-right:10px ;
                                margin-bottom: 10px;
                                float: left;
                                text-align: center;
                                .voltage{
                                    color: #00B194;
                                }
                            }
                        }
                        .type-dec{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            color: #333333;
                            width: 100%;
                        }
                        .operation{
                            float: right;
                            margin-right: 14px;
                            img{
                                cursor: pointer;
                            }
                        }
                    }
                    
                }
            }
            .more{
                height: 60px;
                line-height: 60px;
                text-align: center;
                background: #fff;
                font-size: 16px;
                color: #333;
                transition: all 0.4s;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 0 15px #409EFF;
                }
            }
        }
        .type-empty{
            position: absolute;
            transform:translateY(30%);
            width: 100%;
            .type-empty-text{
                font-size: 30px;
                font-family: Adobe Heiti Std, Adobe Heiti Std-R;
                font-weight: R;
                text-align: center;
                color: #999999;
                margin-top: 44px;
            }
            .type-empty-button{
                margin-top: 29px;
                font-size: 16px;
                .type-empty-link{
                    color: #3394cd;
                    cursor: pointer;
                }
            }
        }
    }
    
</style>