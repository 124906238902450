
import request from "@/utils/request";

// 我的车辆列表
function GetMachineBasicInfoListByCompanyId(data) {
    return request({
      url: "/v2/Car/GetMachineBasicInfoListByCompanyId",
      method: "post",
      data
    });
}

// 故障列表获取
function GetFaults(data) {
    return request({
      url: "/v2/FaultAnalysis/GetFaults",
      method: "post",
      data
    });
}

// 增加用户自定义故障
function CreateUserCustomFault(data) {
  return request({
    url: "/v2/FaultAnalysis/CreateUserCustomFault",
    method: "post",
    data
  });
}

// 更新用户自定义故障
function UpdateUserCustomFault(data) {
  return request({
    url: "/v2/FaultAnalysis/UpdateUserCustomFault",
    method: "post",
    data
  });
}

// 更新系统故障
function UpdateSystemFault(data) {
  return request({
    url: "/v2/FaultAnalysis/UpdateSystemFault",
    method: "post",
    data
  });
}

//获取车辆的设备基本信息
function GetMachineDevices(data) {
  return request({
    url: "/v2/CarDevices/GetMachineDevices",
    method: "post",
    data
  });
}

//故障影响时长的整体分析
function AnalysisTotalFaultDuration(data) {
  return request({
    url: "/v2/FaultAnalysis/AnalysisTotalFaultDuration",
    method: "post",
    data
  });
}

//故障影响时长的按天分析
function AnalysisDailyFaultDuration(data) {
  return request({
    url: "/v2/FaultAnalysis/AnalysisDailyFaultDuration",
    method: "post",
    data
  });
}

//故障影响次数的整体分析
function AnalysisTotalFaultFrequency(data) {
  return request({
    url: "/v2/FaultAnalysis/AnalysisTotalFaultFrequency",
    method: "post",
    data
  });
}

//故障影响次数的按天分析
function AnalysisDailyFaultFrequency(data) {
  return request({
    url: "/v2/FaultAnalysis/AnalysisDailyFaultFrequency",
    method: "post",
    data
  });
}

//故障发生次数的按天统计, 按照车辆分开
function AnalysisDailyFaultDetailByMachine(data) {
  return request({
    url: "/v2/FaultAnalysis/AnalysisDailyFaultDetailByMachine",
    method: "post",
    data
  });
}

//生成excel
function DownLoadAnalysisDailyFaultDetail(data) {
  return request({
    url: "/v2/FaultAnalysis/DownLoadAnalysisDailyFaultDetail",
    method: "post",
    data
  });
}

export default {
    GetMachineBasicInfoListByCompanyId,
    GetFaults,
    CreateUserCustomFault,
    UpdateUserCustomFault,
    UpdateSystemFault,
    GetMachineDevices,
    AnalysisTotalFaultDuration,
    AnalysisDailyFaultDuration,
    AnalysisTotalFaultFrequency,
    AnalysisDailyFaultFrequency,
    AnalysisDailyFaultDetailByMachine,
    DownLoadAnalysisDailyFaultDetail
};