import request from "@/utils/request";

              //创建/编辑车辆--创建车型，我的车辆使用
//
////页面故障配置点击下一页时调用
              function createMachineType(data) {
                return request({
                  url: "v2/CarType/CreateMachineType",
                  method: "post",
                  data
                });
              }
              
              //创建/编辑车辆--更新车辆的故障描述，我的车辆使用
//
////页面故障配置点击下一页时调用
              function updateFault(data) {
                return request({
                  url: "v2/CarType/UpdateFault",
                  method: "post",
                  data
                });
              }
              
              //编辑车辆--检查车型是否需要更改，我的车辆使用
//
////页面故障配置点击下一页时调用
              function checkIfNewMachineType(data) {
                return request({
                  url: "v2/CarType/CheckIfNewMachineType",
                  method: "post",
                  data
                });
              }
              
              //创建/编辑车辆--编辑车型，我的车辆使用
//
////页面点击增加设备下一步后调用
              function editMachineType(data) {
                return request({
                  url: "v2/CarType/EditMachineType",
                  method: "post",
                  data
                });
              }

              //获取公司发布的车型列表
              function GetPublishTypeList(data){
                return request({
                  url: "v2/CarType/GetPublishTypeList",
                  method: "post",
                  data
                });
              }
              
              //删除车型
              function DeletePublishedMachineType(data){
                return request({
                  url: "v2/CarType/DeletePublishedMachineType",
                  method: "post",
                  data
                });
              }

              //编辑发布车型
              function EditPublishedMachineType(data) {
                return request({
                  url: "v2/CarType/EditPublishedMachineType",
                  method: "post",
                  data
                });
              }
            

            export default {
                createMachineType,
updateFault,
checkIfNewMachineType,
editMachineType,
GetPublishTypeList,
DeletePublishedMachineType,
EditPublishedMachineType
            };