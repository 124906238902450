import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueBus from 'vue-bus';
import './assets/css/global.css';

Vue.use(VueBus); //全局的消息机制

import './assets/curtis-theme/theme/index.css'; // 自定义的主题样式
import ElementUI from 'element-ui';
Vue.use(ElementUI);

import VueCropper from 'vue-cropper';
Vue.use(VueCropper);


import 'font-awesome/css/font-awesome.css'; //使用font-awesone字体的样式
//import 'material-design-icons-iconfont/dist/material-design-icons.css'; //使用google material design iconfont的样式


import * as filters from './filters'; // global filters  
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;

import moment from 'moment';
Vue.prototype.$moment = moment; //时间处理库
import * as utils from './utils';
Vue.prototype.$utils = utils;

//多语言配置 
var language = sessionStorage.getItem("language");
if (language == null || language == undefined) {
  language = 'ch';
  sessionStorage.setItem("language", language);
}
console.log("sessionStorage language:", language);

import locale from 'element-ui/lib/locale';
import VueI18n from 'vue-i18n';
import messages from "./language/index";
// console.log('i18n_languages:', messages);
Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: language, // 语言标识
  messages,
});
locale.i18n((key, value) => i18n.t(key, value)); //重点：为了实现element插件的多语言切换

const app = new Vue({
  i18n: i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

