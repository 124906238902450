import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/Main.vue';
import HomePage from '../views/pages/HomePage.vue';
import ComponentDemo from '../views/pages/ComponentDemo.vue';
import NotificationManage from '../views/pages/NotificationManage.vue';
import Login from '../views/Login.vue';
import ESEDevicePackage from '../views/BizConfiguration/ESEDevicePackage.vue';
import AddMachineBasicInfo from '../views/AddMachine/AddMachineBasicInfo.vue';
import AddMachineDevicesConfig from '../views/AddMachine/AddMachineDevicesConfig.vue';
import AddMachineFaultConfig from '../views/AddMachine/AddMachineFaultConfig.vue';
import AddMachineMonitorConfig from '../views/AddMachine/AddMachineMonitorConfig.vue';
import CompanyList from '../views/MyVehicle/CompanyListForMyVehicle.vue';
import VehicleList from '../views/MyVehicle/VehicleList.vue';
import MachineDetails from '../views/MyVehicle/MachineDetails';
import MachineTypeManage from '../views/FactoryProduction/MachineTypeManage';
import FactoryProduct from '../views/FactoryProduction/FactoryProduct';
import CreateMachineFactory from '../views/FactoryProduction/CreateMachineFactory';
import FaultList from '../views/FaultManage/FaultList';
import FaultAnalysis from '../views/FaultManage/FaultAnalysis';
import FaultDetail from '../views/FaultManage/FaultDetail';
import RDMManage from '../views/RDM/RDMManage';
import SoftwareManage from '../views/RDM/SoftwareManage';
import RDMAssign from '../views/RDM/RDMAssign';
import RDMAssignByCompany from '../views/RDM/RDMAssignByCompany';
import AddRDM from '../views/RDM/AddRDM';
import RDMDetails from '../views/RDM/RDMDetails';
import PNManage from '../views/RDM/RDMPNManage';
import AddPN from '../views/RDM/AddPN';
Vue.use(VueRouter);

//解决VUE中路由遇到Error: Avoided redundant navigation to current location:报错显示是路由重复
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const routes = [
  {
    path: '/Login',
    name: 'Login',
    components: {
      default: Login
    }
  },
  {
    path: '/',
    name: 'Main',
    components: {
      default: Main
    },
    children: [
      {
        path: 'HomePage',
        name: 'HomePage',
        components: {
          main: HomePage
        }
      },

      {
        path: 'ComponentDemo',
        name: 'ComponentDemo',
        components: {
          main: ComponentDemo
        }
      },
      {
        path: 'NotificationManage',
        name: 'NotificationManage',
        components: {
          main: NotificationManage
        }
      },
      {
        path: 'ESEDevicePackage',
        name: 'ESEDevicePackage',
        components: {
          main: ESEDevicePackage
        }
      },
      {
        path: 'AddMachineBasicInfo',
        name: 'AddMachineBasicInfo',
        components: {
          main: AddMachineBasicInfo
        },
      },
      {
        path: 'AddMachineDevicesConfig',
        name: 'AddMachineDevicesConfig',
        components: {
          main: AddMachineDevicesConfig
        },
      },
      {
        path: 'AddMachineFaultConfig',
        name: 'AddMachineFaultConfig',
        components: {
          main: AddMachineFaultConfig
        },
      },
      {
        path: 'AddMachineMonitorConfig',
        name: 'AddMachineMonitorConfig',
        components: {
          main: AddMachineMonitorConfig
        },
      },
      {
        path: 'CompanyList',
        name: 'CompanyList',
        components: {
          main: CompanyList
        }
      },
      {
        path: '/VehicleList/:companyId',
        name: 'VehicleList',
        components: {
          main: VehicleList
        }
      },
      {
        path: '/MachineDetails/:companyId/:machineId',
        name: 'MachineDetails',
        components: {
          main: MachineDetails
        }
      },
      {
        path: '/MachineTypeManage',
        name: 'MachineTypeManage',
        components: {
          main: MachineTypeManage
        }
      },
      {
        path: 'FactoryProduct',
        name: 'FactoryProduct',
        components: {
          main: FactoryProduct
        }
      },
      {
        path: 'CreateMachineFactory',
        name: 'CreateMachineFactory',
        components: {
          main: CreateMachineFactory
        },
      },
      {
        path: 'FaultList',
        name: 'FaultList',
        components: {
          main: FaultList
        },
      },
      {
        path: 'FaultAnalysis',
        name: 'FaultAnalysis',
        components: {
          main: FaultAnalysis
        },
      },
      {
        path: 'FaultDetail',
        name: 'FaultDetail',
        components: {
          main: FaultDetail
        },
      },
      {
        path: 'RDMManage',
        name: 'RDMManage',
        components: {
          main: RDMManage
        },
      },
      {
        path: 'SoftwareManage',
        name: 'SoftwareManage',
        components: {
          main: SoftwareManage
        },
      },
      {
        path: 'RDMAssign',
        name: 'RDMAssign',
        components: {
          main: RDMAssign
        },
      },
      {
        path: 'RDMAssignByCompany',
        name: 'RDMAssignByCompany',
        components: {
          main: RDMAssignByCompany
        },
      },
      {
        path: 'AddRDM',
        name: 'AddRDM',
        components: {
          main: AddRDM
        },
      },
      {
        path: '/RDMDetails/:companyId/:RDMNo',
        name: 'RDMDetails',
        components: {
          main: RDMDetails
        },
      },
      {
        path: 'PNManage',
        name: 'PNManage',
        components: {
          main: PNManage
        },
      },
      {
        path: 'AddPN',
        name: 'AddPN',
        components: {
          main: AddPN
        },
      },
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
