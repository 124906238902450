<!--
 * @Description: vehicle clone pop
 * @Author: dtc
 * @Date: 21/12/01
-->
<template>
    <el-dialog
        class="pop-box"
        :title="$t('common.clone')"
        :visible.sync="dialogVisible"
        @close="close"
        align="left"
        width="30%">
        <div>
            <el-form ref="formName" :model="form" label-width="auto" :rules="rules">
                <el-form-item :label="$t('addMachine.MachineSN')" prop="machineSN">
                    <el-input v-model="form.machineSN" :placeholder="$t('myvehicle.check_machinesn')"
                    @change="checkMachineSN"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('addMachine.RDMNO')" prop="rdmno">
                    <el-select v-model="form.rdmno" :placeholder="$t('myvehicle.select_rdmno')">
                        <el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{$t('common.cancel')}}</el-button>
            <el-button type="primary" @click="submit" :disabled="loading" v-loading="loading">{{$t('addMachine.Submit')}}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import carApi from "@/api/autogen/v2/carList";
import vehicleApi from "@/api/autogen/v2/car";
export default {
    props: {
        editData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialogVisible: true,
            form: {},
            options: [],
             rules: {
                machineSN: [
                    { required: true, message:  this.$t("myvehicle.check_machinesn"), trigger: 'change' }
                ],
                rdmno: [
                    { required: true, message: this.$t("myvehicle.select_rdmno"), trigger: 'change' }
                ],
            },
        };
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            this.getRdmList();
        },
        submit() {
            this.$refs.formName.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.form,
                        machineId: this.editData.MachineId,
                        companyId: this.editData.CompanyId,
                    };
                    this.$emit('submit', params);
                }
            });
        },
        close() {
            this.$emit('close', false);
        },
        checkMachineSN(){
            let params = {
                machineSN: this.form.machineSN,
                companyId: this.editData.CompanyId,
            };
            vehicleApi.checkIfMachineSNExisted(params).then(res => {
                if(res.Data){
                    this.form.machineSN = '';
                    const Url = require("@/assets/image/warning1.5x.png");
                    this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.MachineSNExist")+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
            });

        },
        getRdmList() {
            let params = {
                companyId: this.editData.CompanyId,
            };

            carApi.getRomList(params).then(res => {
                console.log(res.Data);
                this.options = res.Data;
            });
        }
    },
    mounted() {},
};
</script>

<style lang="less" scoped>
.pop-box {
    margin-top: 16vh;
    /deep/.el-dialog__footer {
        text-align: center;
    }

    /deep/.el-select {
        width: 100%;
    }
}
</style>