import request from "@/utils/request";

//创建/编辑车辆--创建车型监控模板
//
////页面点击保存监控模板下一步后调用
function createMonitorTemplate(data) {
    return request({
        url: "v2/MonitorTemplateConfig/CreateMonitorTemplate",
        method: "post",
        data
    });
}

//获取系统监控模板列表
//<para></para>
function getMonitorTemplateList(data) {
    return request({
        url: "v2/MonitorTemplateConfig/GetMonitorTemplateList",
        method: "post",
        data
    });
}

//获取默认系统监控模板列表
function getSystemDefaultMonitorList(data) {
    return request({
        url: "v2/MonitorTemplateConfig/GetSystemDefaultMonitorList",
        method: "post",
        data
    });
}

//编辑车辆--检查车型是否需要更改，我的车辆使用。只有在监控模板以前作为UsedAsTemplate=true时，需要先检查是否需要创建新模板。否则直接调用EditMachineMonitorList
//
////页面故障配置点击下一页时调用
function checkIfNewMonitorTemplate(data) {
    return request({
        url: "v2/MonitorTemplateConfig/CheckIfNewMonitorTemplate",
        method: "post",
        data
    });
}

//创建/编辑车辆--编辑监控模板,只有在监控以前作为非模板，则调用更新此处更新。否则调用新增模板
//
////页面点击保存车辆，先保存监控模板，再保存车辆
function editMachineMonitorList(data) {
    return request({
        url: "v2/MonitorTemplateConfig/EditMachineMonitorList",
        method: "post",
        data
    });
}

////页面点击保存车辆，先保存监控模板，再保存车辆
function checkTemplateNameExisted(data) {
    return request({
        url: "v2/MonitorTemplateConfig/CheckIfMonitorTemplateNameExisted",
        method: "post",
        data
    });
}

////获取模板监控参数和目前车辆配置设备的检测关系 <br>选择模板导入时调用
function CheckImportMonitorTemplate(data) {
    return request({
        url: "/v2/MonitorTemplateConfig/CheckImportMonitorTemplate",
        method: "post",
        data
    });
}

//通知设备开始上传参数
function resetRDM(data) {
    return request({
      url: "/v2/RDSLambda/S2C0x1A_CommandToResetRDM",
      method: "post",
      data
    });
}

export default {
    createMonitorTemplate,
    getMonitorTemplateList,
    checkIfNewMonitorTemplate,
    editMachineMonitorList,
    getSystemDefaultMonitorList,
    checkTemplateNameExisted,
    CheckImportMonitorTemplate,
    resetRDM
};
