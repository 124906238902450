import request from "@/utils/request";

//获取RDM列表
//<para></para>
function getRDMList(data) {
  return request({
    url: "v2/RDM/GetRDMList",
    method: "post",
    data
  });
}
//获取RDM列表
function GetRDMList_v2(data) {
  return request({
    url: "v2/RDM/GetRDMList_v2",
    method: "post",
    data
  });
}

//获取软件列表
function GetRDMSoftwareList(data) {
  return request({
    url: "v2/RDM/GetRDMSoftwareList",
    method: "post",
    data
  });
}

//设置默认RDM软件版本
function SetDefaultRDMSoftware(data) {
  return request({
    url: "v2/RDM/SetDefaultRDMSoftware",
    method: "post",
    data
  });
}


//删除软件
function DeleteRDMSoftware(data) {
  return request({
    url: "v2/RDM/DeleteRDMSoftware",
    method: "post",
    data
  });
}

//验证软件是否存在
function CheckIfRDMDefinitionExists(data) {
  return request({
    url: "v2/RDM/CheckIfRDMDefinitionExists",
    method: "post",
    data
  });
} 

//保存软件
function AddOrEditRDMDefinition(data) {
  return request({
    url: "v2/RDM/AddOrEditRDMDefinition",
    method: "post",
    data
  });
}  


//更改RDM Sim卡状态
function ChangeSIMCardStatus(data) {
  return request({
    url: "v2/SIMCardCMCC/ChangeSIMCardStatus",
    method: "post",
    data
  });
}  

//RDM assign
function AssginRDM(data) {
  return request({
    url: "v2/RDM/AssginRDM",
    method: "post",
    data
  });
}  

//删除某个公司的RDM信息
function DeleteRDM(data) {
  return request({
    url: "/v2/RDM/DeleteRDM",
    method: "post",
    data
  });
}

//检查RDM是否寻找
function CheckIfRDMNoExisted(data) {
  return request({
    url: "/v2/RDM/CheckIfRDMNoExisted",
    method: "post",
    data
  });
}

//新增某个公司的RDM信息
function CreateRDM(data) {
  return request({
    url: "/v2/RDM/CreateRDM",
    method: "post",
    data
  });
}

//获取RDM详情
function GetRDMDetail_v2(data) {
  return request({
    url: "v2/RDM/GetRDMDetail_v2",
    method: "post",
    data
  });
}

//编辑某个公司的RDM信息
function EditRDM(data) {
  return request({
    url: "/v2/RDM/EditRDM",
    method: "post",
    data
  });
}


//判断设备是否被其他使用
function getRDMLockStatus(data) {
  return request({
    url: "/v2/RDM/GetRDMLockStatus",
    method: "post",
    data
  });
}

//获取菜单参数
function getMenuAndParameter(data) {
  return request({
    url: "/v2/RDM/GetRDMChildrenMenuAndParameterList",
    method: "post",
    data
  });
}

//提交上传参数
function submitData(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x0B_CommandToDoUpdateParameterValueForRDM",
    method: "post",
    data
  });
}

//通知设备开始上传参数
function startUploadData(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x08_CommandToDoPFUForRDM",
    method: "post",
    data
  });
}

//停止设备开始上传参数
function stopUploadData(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x0A_CommandToStopRDM",
    method: "post",
    data
  });
}

//实时上传参数
function getRDMParameterValueList(data) {
  return request({
    url: "/v2/RDM/GetRDMParameterValueList",
    method: "post",
    data
  });
}

//获取软件列表
function getRDMTotalSoftwareList(data) {
  return request({
    url: "v2/RDM/GetRDMTotalSoftwareList",
    method: "post",
    data
  });
}


//添加
function RDMUpgrade(data) {
  return request({
    url: "/v2/RDM/RDMUpgradeFromExistedSoftware",
    method: "post",
    data
  });
}

//获取RDM软件版本
function getRDMSoftwareVersion(data) {
  return request({
    url: "/v2/RDSLambda/S2C0x0C_CommandToGetDeviceSoftwareVersionForRDM",
    method: "post",
    data
  });
}

export default {
  getRDMList,
  GetRDMList_v2,
  GetRDMSoftwareList,
  SetDefaultRDMSoftware,
  DeleteRDMSoftware,
  CheckIfRDMDefinitionExists,
  AddOrEditRDMDefinition,
  ChangeSIMCardStatus,
  AssginRDM,
  DeleteRDM,
  CheckIfRDMNoExisted,
  CreateRDM,
  GetRDMDetail_v2,
  EditRDM,
  getRDMLockStatus,
  getMenuAndParameter,
  submitData,
  startUploadData,
  stopUploadData,
  getRDMParameterValueList,
  getRDMTotalSoftwareList,
  RDMUpgrade,
  getRDMSoftwareVersion
};