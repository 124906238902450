<template>
  <div class="notification">
    <el-popover
      ref="notification_popover"
      placement="bottom"
      title=""
      width="582px"
      trigger="click"
    >
      <div class="notice-content-main">
        <div class="header">
          <div class="header-tip1">{{ $t("notification.myInformation") }}</div>
          <div class="header-tip2" @click="markAllUnRead()" v-if="privilege.MarkAllRead">
            {{ $t("notification.markAllAsRead") }}
          </div>
        </div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1"
            ><div class="custom-icon-error"></div>

            {{ $t("notification.type_error") }}({{
              errorMsgList.length
            }})</el-menu-item
          >
          <el-menu-item index="2"
            ><div class="custom-icon-warning"></div>
            {{ $t("notification.type_warning") }} ({{
              warningMsgList.length
            }})</el-menu-item
          >
          <el-menu-item index="3"
            ><div class="custom-icon-notice"></div>
            {{ $t("notification.type_notice") }} ({{
              noticeMsgList.length
            }})</el-menu-item
          >
        </el-menu>

        <div class="contents">
          <div
            class="content-item"
            v-for="item in errorMsgList"
            :key="item.id"
            v-show="showError"
          >
            <div class="content-item-read" @click="markAsRead(item)" v-if="privilege.MarkRead"></div>
            <div class="content-item-text-error content-item-text">
              {{ item.content }}
            </div>
            <div class="content-item-time">
              {{ item.time | notificationTimeFormat(userInfo.TimezoneSetting,i18nt) }}
            </div>
          </div>

          <div
            class="content-item"
            v-for="item in warningMsgList"
            :key="item.id"
            v-show="showWarning"
          >
            <div class="content-item-read" @click="markAsRead(item)" v-if="privilege.MarkRead"></div>
            <div class="content-item-text-warning content-item-text">
              {{ item.content }}
            </div>
            <div class="content-item-time">
              {{ item.time | notificationTimeFormat(userInfo.TimezoneSetting,i18nt)}}
            </div>
          </div>

          <div
            class="content-item"
            v-for="item in noticeMsgList"
            :key="item.id"
            v-show="showNotice"
          >
            <div class="content-item-read" @click="markAsRead(item)" v-if="privilege.MarkRead"></div>
            <div class="content-item-text-notice content-item-text">
              {{ item.content }}
            </div>
            <div class="content-item-time">
              {{ item.time | notificationTimeFormat(userInfo.TimezoneSetting,i18nt) }}
            </div>
          </div>
        </div>
        <div class="footer">
          <el-button type="primary" @click="viewMore()">
            {{ $t("notification.toMessageCenter") }}
          </el-button>
        </div>
      </div>
    </el-popover>

    <el-badge
      v-popover:notification_popover
      :value="notificationCount"
      :hidden="notificationCount == 0"
      :max="9"
    >
      <i class="el-icon-message-solid"></i>
    </el-badge>
  </div>
</template>

<script>
export default {
  name: "NotificationIcon",
  props: {
    notificationCount: Number,
    data: [],
    errorMsgList: [],
    warningMsgList: [],
    noticeMsgList: [],
    privilege:[],
  },
  data() {
    return {
      Data: this.$props.data1,
      errorData: [],
      warningData: [],
      noticeData: [],
      activeIndex: "1",
      showError: false,
      showWarning: false,
      showNotice: false,
      isInit: false
    };
  },
  mounted() {
    this.showErrorMsg();
  },
  computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
  },
  methods: {
    handleSelect(key) {
      if (key == 1) {
        this.showErrorMsg();
      }
      if (key == 2) {
        this.showWarningMsg();
      }
      if (key == 3) {
        this.showNoticeMsg();
      }
    },
    showErrorMsg() {
      this.$data.data;
      this.$data.showError = true;
      this.$data.showWarning = false;
      this.$data.showNotice = false;
    },
    showWarningMsg() {
      this.$data.showError = false;
      this.$data.showWarning = true;
      this.$data.showNotice = false;
    },
    showNoticeMsg() {
      this.$data.showError = false;
      this.$data.showWarning = false;
      this.$data.showNotice = true;
    },
    markAllUnRead() {
      this.$emit("notification-event", {
        type: "markAllUnRead",
        payload: {},
      });
      this.$refs.notification_popover.showPopper = false;
    },
    markAsRead(d) {
      this.$emit("notification-event", {
        type: "markAsRead",
        payload: d,
      });
    },
    viewMore() {
      this.$emit("notification-event", {
        type: "viewMore",
        payload: {},
      });
      this.$refs.notification_popover.showPopper = false;
    },
    i18nt(...params){
      return this.$t.apply(this,params)
   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.notification {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 50px;
}
.notification i {
  color: #565d6a;
  font-size: 17px;
}
.notification i:hover {
  color: #0079c1;
}
.el-icon-message-solid {
  margin-right: 5px;
}
.header {
  // margin-top: 34px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
.contents {
  overflow-y: auto;
  overflow-x: hidden;
  width: 524px;
  height: 436px;
  margin-top: 2px;
  margin-bottom: 3px;
}

.footer {
  text-align: center;
  .more {
    cursor: pointer;
    color: #fff;
  }
}

.notificationType-info {
  color: rgb(255, 255, 255);
}
.notificationType-warn {
  color: orange;
}
.notificationType-error {
  color: red;
}
.notice-content-main {
  margin: 12px 12px;
  width: 524px;
  height: 563px;
}
.el-menu-item {
  width: 174px;
  height: 18px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #333333;
}
.header-tip1 {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #04223a;
  line-height: 16px;
}
.header-tip2 {
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  text-align: left;
  color: #0079c1;
}
.header-tip2:hover {
  cursor: pointer;
}
.custom-icon-error {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 13px;
  background-image: url("../../assets/image/error.png");
}
.custom-icon-warning {
  display: inline-block;
  width: 16px;
  height: 15px;
  margin-right: 11px;
  background-image: url("../../assets/image/warning.png");
}
.custom-icon-notice {
  display: inline-block;
  width: 16px;
  height: 14px;
  margin-right: 10px;
  background-image: url("../../assets/image/notice.png");
}
.el-button {
  width: 524px;
  height: 48px;
  background: #0079c1;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #f2f6fc;
}

.content-item {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px 0px 12px 24px;
}
.content-item-text {
  width: 430px;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  color: #333333;
}
.content-item-text-error:hover {
  text-decoration: underline;
  color: #f34863;
  cursor: pointer;
}
.content-item-text-warning:hover {
  text-decoration: underline;
  color: #f6bd16;
  cursor: pointer;
}
.content-item-text-notice:hover {
  text-decoration: underline;
  color: #52c41a;
  cursor: pointer;
}
.content-item-time {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-top: 12px;

  color: #666666;
}
.content-item-read {
  margin-top: 12px;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  float: right;
  background-image: url("../../assets/image/unread.png");
}

.content-item-read:hover {
  cursor: pointer;
  background-image: url("../../assets/image/unread-hover.png");
}
</style>
