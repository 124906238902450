<template>
  <div>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        {{ $t("common.switch_language")
        }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="d in languageItems" :key="d.name" :command="d">
          {{ d.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  name: "LanguageDropdown",
  props: {
    languageItems: Array,
  },
  methods: {
    handleCommand(command) {
      this.$emit("language-dropdown-item-click", command);
    },
  },
};
</script>
<style lang="less" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #565d6a;
  padding-right: 19px;
  padding-left: 55px;
  padding-bottom: 29px;
  padding-top: 36px;
}
.el-dropdown-link:hover {
  color: #0079c1;
}
.el-icon-arrow-down {
  font-size: 14px;
}
</style>