<template>
    <div class="fault-box">
        <div class="arrow right" @click="DownLoadAnalysisDailyFaultDetail()"></div>
        <el-row class="fault-top">
            <el-col :span="3">
                <div class="title">
                    <span>{{$t('menu.FaultAnalysis')}}</span>
                </div>
            </el-col>
            <el-col :span="16">
                <div class="selectMachine">
                    <el-select v-model="machines" filterable clearable multiple 
                    :placeholder="$t('common.chooseMachine')" @change="refreshData()">
                        <el-option
                        v-for="(item,key) in machineList"
                        :key="key"
                        :label="item.MachineSN"
                        :value="item.MachineSN">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="5">
                <div class="datepicker">
                    <el-date-picker
                        v-model="daterange"
                        type="daterange"
                        range-separator="~"
                        :start-placeholder="$t('common.startdate')"
                        :end-placeholder="$t('common.enddate')"
                        value-format="yyyy-MM-dd"
                        @change="refreshData()"
                    >
                    </el-date-picker>
                </div>
            </el-col>
        </el-row>
        <el-row class="fault-content">
            <Card :title="$t('faultManage.durationAnalysis')" >
                <div class="infofrmation-fault-box" slot="content">
                    <el-row>
                        <div class="tab">
                            <span :class="{active: item.label == durationTabActive}" 
                                v-for="(item,key) in durationTab" 
                                :key="key"
                                @click="chooseDurationTab(item.label)">
                                {{item.text}}
                            </span>
                        </div>
                    </el-row>
                    <el-row class="chart">
                        <el-col :span="8">
                            <div class="infofrmation-fault-pie-box" ref="pie-chart-box"></div>
                        </el-col>
                        <el-col :span="16">
                            <div class="infofrmation-fault-bar-box" ref="bar-chart-box"></div>
                        </el-col>
                    </el-row>
                </div>
            </Card>
        </el-row>
        <el-row class="fault-content">
             <Card :title="$t('faultManage.FaultFrequencyAnalysis')" class="marginBottom">
                 <div class="infofrmation-fault-box" slot="content">
                    <el-row>
                        <div class="tab">
                            <span :class="{active: item.label == frequencyTabActive}" 
                                v-for="(item,key) in frequencyTab" 
                                :key="key"
                                @click="chooseFrequencyTab(item.label)">
                                {{item.text}}
                            </span>
                        </div>
                    </el-row>
                    <el-row class="chart">
                        <el-col :span="8">
                            <div class="infofrmation-fault-pie-box" ref="pie-chart-fre"></div>
                        </el-col>
                        <el-col :span="16">
                            <div class="infofrmation-fault-bar-box" ref="bar-chart-fre"></div>
                        </el-col>
                    </el-row>
                 </div>
             </Card>
        </el-row>
        <el-row class="fault-content">
             <Card :title="$t('faultManage.Faultdetaillist')" class="marginBottom">
                  <div class="fault-list"  slot="content">
                    <div class="fault-list-row"  v-for="(item,index) in machineArray" :key="index">
                        <el-row >
                            <div class="fault-title" @click="expand(index)">
                                <span class="fault-vehicle">
                                    {{item}}
                                </span>
                                
                                <el-button class="fault-button" @click="getFaultDetail(item)" v-if="privilege.Edit">{{$t('faultManage.maintenance_record')}}</el-button>
                            </div>
                            <div class="fault-table" v-if="selectRow.includes(index)">
                                <el-table :data="tableData(item)" 
                                style="width: 100%;" max-height="700"  >
                                    <el-table-column
                                        type="index"
                                        align="center"
                                        :label="$t('common.SEQ')"
                                        width="50"
                                        >
                                    </el-table-column>
                                    <el-table-column
                                        prop="StatisticDay"
                                        align="center"
                                        :label="$t('faultManage.faultDate')"
                                        width="200">
                                        <template slot-scope="scope">
                                            <span style="margin-left: 10px">{{formatTimeStr(scope.row.StatisticDay,'YYYY-MM-DD')}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="Fault"
                                        align="center"
                                        :label="$t('addMachine.faultName')">
                                    </el-table-column>
                                    <el-table-column
                                        prop="Frequency"
                                        :label="$t('faultManage.faultFrequency')"
                                        width="150">
                                    </el-table-column>
                                    <el-table-column
                                        prop="EffectTimeDuration"
                                        :label="$t('faultManage.faulttime') + '(s)'"
                                        width="150">
                                        <template slot-scope="scope">
                                            <span style="margin-left: 10px">{{scope.row.EffectTimeDuration/1000}}</span>
                                        </template>
                                    </el-table-column>
                                    
                                </el-table>
                            </div>
                        </el-row>
                    </div>
                  </div>
             </Card>
        </el-row>
    </div>
</template>
<script>
import Card from "@/components/ui/Card.vue";
import faultApi from '@/api/autogen/v2/fault';
import orgnizationApi from "@/api/autogen/v2/orgnization";
import { formatTimestampUTC,checkExistData ,formatTimeStr,ifGrantedTheOperationForThisFunction} from '@/utils';
import deviceApi from "@/api/Device";
export default {
    data() {
        return {
            daterange:'',
            machineList:[],
            machines:[],
            durationTab:[
                {
                    label:'total',
                    text:this.$t("faultManage.tab_total_duration"),
                },
                {
                    label:'daily',
                    text:this.$t("faultManage.tab_daily_duration"),
                },
            ],
            durationTabActive:'total',
            frequencyTab:[
                {
                    label:'total',
                    text:this.$t("faultManage.tab_total_frequency"),
                },
                {
                    label:'daily',
                    text:this.$t("faultManage.tab_daily_frequency"),
                },
            ],
            frequencyTabActive:'total',
            faultParam:{
                    startDay: "",
                    endDay: "",
                    filterMachineSNList: [],
                    faultTopN: 5
            },
            AnalysisTotalFaultDurationList:[],
            AnalysisDailyFaultDurationList:[],
            DurationClickDate:'',
            AnalysisTotalFaultFrequencyList:[],
            AnalysisDailyFaultFrequencyList:[],
            FrequencyClickDate:'',
            AnalysisDailyFaultDetailByMachineList:[],
            selectRow:[],
            selectCompany:{},
            colors: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de", "#3ba272","#fc8452","#9a60b4","	#ea7ccc","#800080"],
            filePath:'',
            privilege: {
                View: false,
                Edit: false,
            },
        };
    },

    components: {
        Card,
    },
    computed: {
        currentCompany(){
            return this.$store.state.companyEntity;
        },
        userInfo() {
            return this.$store.state.userEntity;
        },
        TotalFaultFrequencyChart(){
            let xDataList = [];
            let yDataList = [];
            let data = [];
            if(this.AnalysisTotalFaultFrequencyList){
                this.AnalysisTotalFaultFrequencyList.forEach(item => {
                    xDataList.push(item.Frequency);
                    yDataList.push(item.Fault);
                    let array = {
                        name : item.Fault,
                        value : item.Frequency
                    };
                    data.push(array);
                });
            }
            let totalData = {
                xDataList : xDataList,
                yDataList : yDataList,
                data : data
            };
            return totalData;
        },
        machineArray(){
            let array = [];
            if(this.AnalysisDailyFaultDetailByMachineList){
                this.AnalysisDailyFaultDetailByMachineList.forEach((item)=>{
                    if(!array.includes(item.MachineSN)){
                        array.push(item.MachineSN);
                    }

                });
                
            }
            return array;
        },
        MachineSNS(){
            let array = [];
            this.machineList.forEach((item)=>{
                array.push(item.MachineSN);
            });
            return array;
        },

        tableData(){
             return function(MachineSN){
                let list =[];
                if(this.AnalysisDailyFaultDetailByMachineList){
                    this.AnalysisDailyFaultDetailByMachineList.forEach((item)=>{
                        if(item.MachineSN === MachineSN){
                            list.push(item);
                        }

                    });
                    
                }
                return list;         
            };   
        },
        TotalFaultDurationChart(){
            let xDataList = [];
            let yDataList = [];
            let data = [];
            if(this.AnalysisTotalFaultDurationList){
                this.AnalysisTotalFaultDurationList.forEach(item => {
                    let effctHour = item.EffectTimeDuration/1000/60/60;
                    xDataList.push(effctHour);
                    yDataList.push(item.Fault);
                    let array = {
                        name : item.Fault,
                        value : effctHour
                    };
                    data.push(array);
                });
            }
            let totalData = {
                xDataList : xDataList,
                yDataList : yDataList,
                data : data
            };
            return totalData;
        },
        
        DailyFaultDurationChart(){
            let source = [],dimensions=[],data = [],series=[];
            let date = [];
            if(this.AnalysisDailyFaultDurationList){
                dimensions.push('Fault');
                this.AnalysisDailyFaultDurationList.forEach(item => {
                    let effctHour = item.EffectTimeDuration/1000/60/60;
                    
                    

                    if(!dimensions.includes(item.Fault)){                       
                        dimensions.push(item.Fault);
                        let serie = { type: 'bar' };
                        series.push(serie);
                    }
                    if(this.DurationClickDate === formatTimeStr(item.StatisticDay,'YYYY-MM-DD')){
                            let array = {
                                    name : item.Fault,
                                    value : effctHour
                                };
                            data.push(array);
                            
                        }else{
                             let array = {
                                    name : item.Fault,
                                    value : 0
                                };
                            data.push(array);
                        }
                     if(!date.includes(item.StatisticDay)){
                        date.push(item.StatisticDay);
                        let ob = {};
                        
                        ob['Fault'] = formatTimeStr(item.StatisticDay,'YYYY-MM-DD');
                        ob[item.Fault] = effctHour;
                        this.AnalysisDailyFaultDurationList.forEach((item1)=>{
                            if(item.StatisticDay === item1.StatisticDay){
                                let effctHour1 = item1.EffectTimeDuration/1000/60/60;
                                ob[item1.Fault] = effctHour1;
                            }
                        });
                        source.push(ob);
                     }
                });
            }
            let totalData ={
                source : source,
                dimensions: dimensions,
                data : data,
                series : series
            };
            return totalData;
        },
        DailyFaultFrequencyChart(){
            let source = [],dimensions=[],data = [],series=[];
            let date = [];
            if(this.AnalysisDailyFaultFrequencyList){
                dimensions.push('Fault');
                this.AnalysisDailyFaultFrequencyList.forEach(item => {
                    if(!dimensions.includes(item.Fault)){                       
                        dimensions.push(item.Fault);
                        let serie = { type: 'bar' };
                        series.push(serie);
                    }
                    if(this.FrequencyClickDate === formatTimeStr(item.StatisticDay,'YYYY-MM-DD')){
                            let array = {
                                    name : item.Fault,
                                    value : item.Frequency
                                };
                            data.push(array);
                        
                        }else{
                            let array = {
                                    name : item.Fault,
                                    value : 0
                                };
                            data.push(array);
                        }
                    if(!date.includes(item.StatisticDay)){
                        date.push(item.StatisticDay);
                        let ob = {};
                        ob['Fault'] = formatTimeStr(item.StatisticDay,'YYYY-MM-DD');
                        this.AnalysisDailyFaultFrequencyList.forEach((item1)=>{
                            if(item.StatisticDay === item1.StatisticDay){
                                ob[item1.Fault] = item1.Frequency;
                            }
                        });
                        source.push(ob);
                    }
                    
                });
            }
            let totalData ={
                source : source,
                dimensions: dimensions,
                data : data,
                series : series
            };
            return totalData;
        },
        selectIndex:{
            get:'',
            set: function(val){
                if(this.selectRow.includes(val)){
                    this.selectRow.forEach((item, index) => {
                        if(item == val){
                            this.$delete(this.selectRow,index);
                        }
                    });
                }else{
                    this.selectRow.push(val); 
                }
            }
        },
    },
    created() {
        let self = this;
        self.$bus.on("company-switch", self.companySwitch);//监听公司变化
        self.getMachineList().then(val=>{
            self.refreshData();
        });
        self.initialFunctionOperationPrivilege();
    },
    methods: {
        formatTimeStr,
        ifGrantedTheOperationForThisFunction,
        initialFunctionOperationPrivilege()
        {
                let self = this;
                self.privilege.View = ifGrantedTheOperationForThisFunction("View");
                self.privilege.Edit = ifGrantedTheOperationForThisFunction("Edit");
        },
        //导出故障分析文件
        DownLoadAnalysisDailyFaultDetail(){
            let self = this;
            let starttime  = '';
            let endtime = '';
            if(self.daterange&&self.daterange.length>0){
                starttime = self.daterange[0];
                if(self.daterange.length>1){
                    endtime = self.daterange[1];
                }
            } 
            self.faultParam.startDay = starttime;
            self.faultParam.endDay =endtime;
            if(self.machines.length<=0){
                self.faultParam.filterMachineSNList = self.MachineSNS;
            }else{
                self.faultParam.filterMachineSNList = self.machines;
            }
            faultApi.DownLoadAnalysisDailyFaultDetail(this.faultParam).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    let filePath = res.Data;
                    let FileName=deviceApi.getFileName(filePath);
                    deviceApi
                    .downLoadFile(filePath)
                    .then(resContent=>
                    { 
                        if ('download' in document.createElement('a')) { // 非IE下载
                            const elink = document.createElement('a');
                            elink.download = FileName;
                            elink.style.display = 'none';
                            elink.href = URL.createObjectURL(resContent);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        } else { // IE10+下载
                            navigator.msSaveBlob(resContent, FileName);
                        }
                    });
                }
            });
        },

        //监听公司变化
        companySwitch(evtPayload){
            let self = this;
            self.machines=[];
            self.daterange=[];
            self.machineList=[];
            self.selectCompany = evtPayload;
            self.getMachineList().then(val=>{
                self.refreshData();
            });
        },


        //展开
        expand(data){
            this.selectIndex = data;
            
        },

        //获取车辆列表
        getMachineList(){
            let param = {
                companyId :this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId
            };
            return faultApi.GetMachineBasicInfoListByCompanyId(param).then((res)=>{
                if(res){
                    this.machineList = res;
                }
            });
        },

        //数据查询
        refreshData(){
            let self = this;
            let starttime  = '';
            let endtime = '';
            if(self.daterange&&self.daterange.length>0){
                starttime = self.daterange[0];
                if(self.daterange.length>1){
                    endtime = self.daterange[1];
                }
            } 
            self.faultParam.startDay = starttime;
            self.faultParam.endDay =endtime;
            if(self.machines.length<=0){
                self.faultParam.filterMachineSNList = self.MachineSNS;
            }else{
                self.faultParam.filterMachineSNList = self.machines;
            }
            //console.log("self.faultParam.filterMachineSNList==",self.faultParam.filterMachineSNList);
            self.refreshDurationData();
            self.refreshFrequencyData();
            self.getAnalysisDailyFaultDetailByMachine();
        },

        //跳转维护记录
        getFaultDetail(MachineSN){
            let RDMNO = '';
            let array = checkExistData(this.machineList,MachineSN,'MachineSN').data;
            RDMNO = array.RDMNO;
            this.$router.push({
                name:"FaultDetail",
                params:{MachineSN:MachineSN,RDMNO:RDMNO,CompanyId:this.selectCompany?  this.currentCompany.CompanyId: this.selectCompany.CompanyId,MachineId:array.MachineId}

            });
        },

        //更新时长数据
        refreshDurationData(){
            let self = this;
            let P1 = '';
            if(self.durationTabActive == 'total'){
                P1 = self.getAnalysisTotalFaultDuration();
            }else{
                P1 = self.getAnalysisDailyFaultDuration();
            }
            //console.log("P1=====",P1);
            Promise.all([P1]).then(function (resp) {
                if(self.durationTabActive == 'total'){
                    self.initTotalFaultDurationPie();
                    self.initTotalFaultDurationBar();
                }else{
                    self.initDailyFaultDurationBar();
                    self.initDailyFaultDurationPie();
                }
            });
            
        },

        //更新次数数据
        refreshFrequencyData(){
            let self = this;
            let P1 = '';
            if(self.frequencyTabActive == 'total'){
                P1 = self.getAnalysisTotalFaultFrequency();
            }else{
                P1 = self.getAnalysisDailyFaultFrequency();
            }
            Promise.all([P1]).then(function (resp) {
                if(self.frequencyTabActive == 'total'){
                    self.initTotalFaultFrequencyPie();
                    self.initTotalFaultFrequencyBar();
                }else{
                    self.initDailyFaultFrequencyBar();
                    self.initDailyFaultFrequencyPie();
                }
            });
            
        },

        //切换时长tab
        chooseDurationTab(label){
            this.durationTabActive = label;
            this.refreshDurationData();
        },

        //切换次数tab
        chooseFrequencyTab(label){
            this.frequencyTabActive = label;
            this.refreshFrequencyData();
        },
        
        //获取故障整体影响时长
        getAnalysisTotalFaultDuration(){
            this.AnalysisTotalFaultDurationList =[];
            if(this.faultParam.filterMachineSNList!=null && this.faultParam.filterMachineSNList.length>0)
            {
                return faultApi.AnalysisTotalFaultDuration(this.faultParam).then((res)=>{
                    console.log("this.faultParam==",this.faultParam);
                    if(res&&res.IsSuccess&&res.Data){
                        this.AnalysisTotalFaultDurationList = res.Data.AnalysisTotalFaultDurationList;
                        console.log("AnalysisTotalFaultDurationList value=",this.AnalysisTotalFaultDurationList);
                    }
                });
            }
        },

        //获取故障按日影响时长
        getAnalysisDailyFaultDuration(){
            this.AnalysisDailyFaultDurationList =[];
            if(this.faultParam.filterMachineSNList!=null && this.faultParam.filterMachineSNList.length>0)
            {
                return faultApi.AnalysisDailyFaultDuration(this.faultParam).then((res)=>{
                    if(res&&res.IsSuccess&&res.Data){
                        this.AnalysisDailyFaultDurationList = res.Data.AnalysisDailyFaultDurationList;
                        if(res.Data.AnalysisDailyFaultDurationList.length>0){
                            this.DurationClickDate =  formatTimeStr(this.AnalysisDailyFaultDurationList[0].StatisticDay,'YYYY-MM-DD');
                        }
                    }
                });
            }
        },

        //获取故障整体影响次数
        getAnalysisTotalFaultFrequency(){
            this.AnalysisTotalFaultFrequencyList =[];
            if(this.faultParam.filterMachineSNList!=null && this.faultParam.filterMachineSNList.length>0)
            {
                return faultApi.AnalysisTotalFaultFrequency(this.faultParam).then((res)=>{
                    if(res&&res.IsSuccess&&res.Data){
                        this.AnalysisTotalFaultFrequencyList = res.Data.AnalysisTotalFaultFrequencyList;
                    }
                });
            }
        },

        //获取故障按日影响次数
        getAnalysisDailyFaultFrequency(){
            this.AnalysisDailyFaultFrequencyList =[];
            if(this.faultParam.filterMachineSNList!=null && this.faultParam.filterMachineSNList.length>0)
            {
                return faultApi.AnalysisDailyFaultFrequency(this.faultParam).then((res)=>{
                    if(res&&res.IsSuccess&&res.Data){
                        this.AnalysisDailyFaultFrequencyList = res.Data.AnalysisDailyFaultFrequencyList;
                        if(res.Data.AnalysisDailyFaultFrequencyList.length>0){
                            this.FrequencyClickDate =  formatTimeStr(this.AnalysisDailyFaultFrequencyList[0].StatisticDay,'YYYY-MM-DD');
                        }
                    }
                });
            }
        },

        //故障详细列表数据获取
        getAnalysisDailyFaultDetailByMachine(){
            this.AnalysisDailyFaultDetailByMachineList=[];
            if(this.faultParam.filterMachineSNList!=null && this.faultParam.filterMachineSNList.length>0)
            {
                faultApi.AnalysisDailyFaultDetailByMachine(this.faultParam).then((res)=>{
                    if(res&&res.IsSuccess&&res.Data){
                        this.AnalysisDailyFaultDetailByMachineList = res.Data.AnalysisDailyFaultDetailByMachineList;
                    }
                });
            }
        },
        
        formatPieLegendOrXYSeriesLabel(param,length)
        {
            
            let newParamName="";
            let paramLength=param.length;
            let provideNumber=length;
            let rowNumber=Math.ceil(paramLength/provideNumber);
            if(paramLength>provideNumber)
            {
                for(var i=0;i<rowNumber;i++){  
                    let TmpStr="";
                    let start=i*provideNumber;
                    let end=start+provideNumber;
                    if(i==rowNumber)
                        TmpStr=param.substring(start,paramLength);
                    else
                        TmpStr=param.substring(start,end)+"\n";
                    newParamName+=TmpStr;
                }

            }
             else
                newParamName=param;
            return newParamName;                    
                        
        },
        //故障整体影响时长Pie
        initTotalFaultDurationPie(){
            let self=this;
            console.log("AnalysisTotalFaultDurationList==",this.AnalysisTotalFaultDurationList);
            if(this.AnalysisTotalFaultDurationList){
                let doms = this.$refs['pie-chart-box']; 
                let unit=this.$t("common.Hour");
                let  option = {
                    tooltip: {
                        trigger: 'item',
                        //formatter: '{b} : {c} '+this.$t("common.Hour") +'({d}%)'
                        formatter: function(a)
                        {
                            //console.log("a",a);
                            return(
                                "<font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":<br/>"+a.value.toFixed(2)+" "+unit +" ("+a.percent.toFixed(2) +"%)"
                            );
                        },
                    },
                    legend: {
                        left: 'left',
                        top: 'center',
                        orient: 'vertical',
                        icon: 'circle',
                        formatter:function(param)
                        {
                           return self.formatPieLegendOrXYSeriesLabel(param,10);
                        },
                    },                   
                    color:this.colors,
                    series: [
                        {
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ["60%", "53%"],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: this.TotalFaultDurationChart.data
                        }
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
        //故障整体影响时长Bar
        initTotalFaultDurationBar(){
            let self = this;
            let unit=this.$t("common.Hour");
            if(self.AnalysisTotalFaultDurationList){
                let doms = self.$refs['bar-chart-box'] ;
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        //formatter: '{b} : {c}.toFixed(2) h'
                        formatter: function (param){
                            let forstr="";
                            for(var i=0, h=param.length;i<h;i++){   
                                forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+ param[i].name+": "+param[i].value.toFixed(2)+" "+unit+"";
                            }
                            return forstr;
                        },
                    },
                    grid: {
                        top: '5%',
                        left: '0%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        name: unit,
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                    yAxis: {
                        type: 'category',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: true,
                        },
                        axisLabel:{
                            //rotate:15,
                            formatter:function(param)
                            {
                                return self.formatPieLegendOrXYSeriesLabel(param,4);                                
                            }
                        },
                        data: self.TotalFaultDurationChart.yDataList
                    },
                    series: [
                        {
                            itemStyle: {
                                normal: {
                                    color: function(params) {
                                        var colorList = self.colors; 
                                        return colorList[params.dataIndex]; 
                                    }
                                }
                            },
                            name: '',
                            type: 'bar',
                            barWidth: 6,
                            data: self.TotalFaultDurationChart.xDataList
                        },
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
        //故障按天影响时长Bar
        initDailyFaultDurationBar(){
            let self = this;
            if(self.AnalysisDailyFaultDurationList){
                let unit=this.$t("common.Hour");
                let xSeries=this.$t("common.Day");
                let doms = self.$refs['bar-chart-box'] ;
                let option = {
                    legend: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (param){
                            //console.log("param",param);
                            //console.log("a",a);
                            let forstr=param[0].name+":</br>";
                            for(var i=0, h=param.length;i<h;i++){   
                                let seriesName=param[i].name;
                                let paramvalue=param[i].value[param[i].seriesName];
                                if(paramvalue==undefined)
                                    paramvalue=0;
                                forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+param[i].seriesName+":"+paramvalue.toFixed(2)+" "+unit+"</br>";
                            }
                            return forstr;
                        },
                    },
                    dataset: {
                        dimensions: self.DailyFaultDurationChart.dimensions,
                        source: self.DailyFaultDurationChart.source
                    },
                    xAxis: { type: 'category',
                            name:xSeries,
                            axisLabel:{
                                    //rotate:15,
                                    interval:0,
                                    formatter:function(param)
                                    {
                                        return self.formatPieLegendOrXYSeriesLabel(param,10);                                
                                    }
                            },
                    },
                    yAxis: {name:unit},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: self.DailyFaultDurationChart.series
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
                myCharts.on('click', function (params) {
                    self.DurationClickDate =  params.data.Fault;
                    self.initDailyFaultDurationPie();
                });
            }
        },

        //故障按天影响时长Pie
        initDailyFaultDurationPie(){
            if(this.AnalysisDailyFaultDurationList){
                let self=this;
                let unit=this.$t("common.Hour");
                let doms = this.$refs['pie-chart-box']; 
                let  option = {
                    tooltip: {
                        trigger: 'item',
                        //formatter: '{b} : {c} h ({d}%)'
                        formatter: function(a)
                        {
                            
                            return(
                                "<font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":<br/>"+a.value.toFixed(2)+" "+unit +" ("+a.percent.toFixed(2) +"%)"
                            );
                        },
                    },
                    legend: {
                        left: 'left',
                        top: 'center',
                        orient: 'vertical',
                        icon: 'circle',
                        formatter:function(param)
                        {
                           return self.formatPieLegendOrXYSeriesLabel(param,10);
                        },
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ["60%", "53%"],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: this.DailyFaultDurationChart.data
                        }
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },

        //故障整体影响次数Pie
        initTotalFaultFrequencyPie(){
            if(this.AnalysisTotalFaultFrequencyList){
                let self=this;
                let doms = this.$refs['pie-chart-fre']; 
                let unit=this.$t("common.Frequency");
                let  option = {
                    tooltip: {
                        trigger: 'item',
                        //formatter: '{b} : {c} c ({d}%)'
                        formatter: function(a)
                        {
                            
                            return(
                                "<font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":<br/>"+a.value+" "+unit +" ("+a.percent.toFixed(2) +"%)"
                            );
                        },
                    },
                    legend: {
                        left: 'left',
                        top: 'center',
                        orient: 'vertical',
                        icon: 'circle',
                        formatter:function(param)
                        {
                           return self.formatPieLegendOrXYSeriesLabel(param,10);
                        },
                    },
                    color:this.colors,
                    series: [
                        {
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ["60%", "53%"],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2,
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: this.TotalFaultFrequencyChart.data
                        }
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
        //故障整体影响次数Bar
        initTotalFaultFrequencyBar(){
            let self = this;
            if(self.AnalysisTotalFaultFrequencyList){
                let unit=this.$t("common.Frequency");
                let doms = self.$refs['bar-chart-fre'] ;
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        //formatter: '{b} : {c} c'
                        formatter: function (param){
                            let forstr="";
                            for(var i=0, h=param.length;i<h;i++){   
                                forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+ param[i].name+": "+param[i].value+" "+unit+"";
                            }
                            return forstr;
                        },
                    },
                    grid: {
                        top: '5%',
                        left: '0%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        name:unit,
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                    yAxis: {
                        type: 'category',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: true,
                        },
                        axisLabel:{
                            //rotate:15,
                            formatter:function(param)
                            {
                                return self.formatPieLegendOrXYSeriesLabel(param,4);                                
                            }
                        },
                        data: self.TotalFaultFrequencyChart.yDataList
                    },
                    series: [
                        {
                            itemStyle: {
                                normal: {
                                    color: function(params) {
                                        var colorList = self.colors; 
                                        return colorList[params.dataIndex]; 
                                    }
                                }
                            },

                            name: '',
                            type: 'bar',
                            barWidth: 6,
                            data: self.TotalFaultFrequencyChart.xDataList
                        },
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },

        //故障按天影响次数Bar
        initDailyFaultFrequencyBar(){
            let self = this;
            if(self.AnalysisDailyFaultFrequencyList){
                let unit=this.$t("common.Frequency");
                let xSeries=this.$t("common.Day");
                let doms = self.$refs['bar-chart-fre'] ;
                let option = {
                    legend: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (param){
                            let forstr=param[0].name+":</br>";
                            for(var i=0, h=param.length;i<h;i++){   
                                let seriesName=param[i].name;
                                let paramvalue=param[i].value[param[i].seriesName];
                                if(paramvalue==undefined)
                                    paramvalue=0;
                                forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+param[i].seriesName+":"+paramvalue+" "+unit+"</br>";
                            }
                            return forstr;
                        },
                    },
                    dataset: {
                        dimensions: self.DailyFaultFrequencyChart.dimensions,
                        source: self.DailyFaultFrequencyChart.source
                    },
                    xAxis: { 
                        type: 'category',
                        name:xSeries,
                        axisLabel:{
                            //rotate:15,
                            formatter:function(param)
                            {
                                return self.formatPieLegendOrXYSeriesLabel(param,10);                                
                            }
                        },
                    },
                    yAxis: {name:unit},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: self.DailyFaultFrequencyChart.series
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
                myCharts.on('click', function (params) {
                    self.FrequencyClickDate =  params.data.Fault;
                    self.initDailyFaultFrequencyPie();
                });
            }
        },

        //故障按天影响次数Pie
        initDailyFaultFrequencyPie(){
            if(this.AnalysisDailyFaultFrequencyList){
                let self=this;
                let doms = this.$refs['pie-chart-fre']; 
                let unit=this.$t("common.Frequency");
                let  option = {
                    tooltip: {
                        trigger: 'item',
                        //formatter: '{b} : {c} c ({d}%)'
                        formatter: function(a)
                        {
                            
                            return(
                                "<font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":<br/>"+a.value+" "+unit +" ("+a.percent.toFixed(2) +"%)"
                            );
                        },
                    },
                    legend: {
                        left: 'left',
                        top: 'center',
                        orient: 'vertical',
                        icon: 'circle',
                        formatter:function(param)
                        {
                           return self.formatPieLegendOrXYSeriesLabel(param,10);
                        },
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ["60%", "53%"],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: this.DailyFaultFrequencyChart.data
                        }
                    ]
                },
                myCharts = echarts.getInstanceByDom(doms);

                if (typeof myCharts !== "undefined") {
                    myCharts.clear();
                }
                myCharts = echarts.init(doms);
                myCharts.setOption(option);
            }
        },
    },
    beforeDestroy() {
        this.$bus.off("company-switch", this.companySwitch);
    },
};
</script>
<style lang="less" scoped>
    .fault-box{
        min-width: 1042px;
        margin-top : 10px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
        position: relative;
        .arrow{
            background:url("../../assets/image/download1.png") no-repeat 0px 0px;
            cursor: pointer;
            width: 47px;
            height: 100px;
            position:fixed;
            top: 50%;
            z-index: 10;
            transform: translateY(-50%);
            &:hover {
                background:url("../../assets/image/download2.png") no-repeat 0px 0px;
            }
        }
        .right{
            right: 1px;
        }
        .marginBottom {
            margin-bottom: 20px;
        }
        .tab{
            float: left;
            text-align: center;
            span{
                width: 244px;
                height: 38px;
                background: #f2f2f2;
                border-radius: 2px 2px 0px 0px;
                font-size: 14px;
                cursor: pointer;
                display: inline-block;
                line-height: 38px;
                margin: 0 1.5px;
                &.active {
                    color: #fff;
                    background: #0079C1;
                }
                &:hover {
                    color: #fff;
                    background: #0079C1;
                }
            }
        }
        .fault-top{
            border-bottom: 1px solid #e5e5e5;
            background: #fafafa;
            border-radius: 2px;
            padding: 0 18px;
            .title{
                font-size: 16px;
                font-weight: 700;
                line-height: 70px;
                float: left;
                margin-left:26px ;
            }
            .datepicker{
                height: 48px;
                line-height: 70px;
                /deep/ .el-date-editor{
                    width: 100%;
                }
            }
            .selectMachine{
                height: 48px;
                margin-right:10px ;
                line-height: 70px;
                .el-select{
                    width: 100%;
                }
            }
        }
        .fault-content{
            padding: 20px 20px 0 20px;
            .infofrmation-fault-box {
                height: 420px;
                .chart{
                    padding: 38px;
                    .infofrmation-fault-pie-box {
                        width: 100%;
                        height: 340px;
                        float: right;
                    }
                    .infofrmation-fault-bar-box {
                        width: 100%;
                        height: 340px;
                        float: left;
                    }
                }
                
            }
            .fault-list{
                margin-top:20px ;
                .fault-list-row{
                    margin-bottom: 10px;
                    .fault-title{
                        cursor: pointer;
                        display: flex;
                        height: 86px;
                        justify-content:space-between;
                        background: #fafafa;
                        border: 1px solid #dddddd;
                        border-radius: 2px 2px 0px 0px;
                        &:hover {
                            box-shadow: 0 0 15px #409EFF;
                        }
                        .fault-vehicle{
                            height: 12px;
                            font-size: 16px;
                            font-weight: 700;
                            color: #333333;
                            margin-left: 35px;
                        }
                        .fault-button{
                            margin: 24px 31px 0 0;
                            width: 164px;
                            height: 38px;
                            background: #00b194;
                            border-radius: 2px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #ffffff;
                        }
                        line-height: 86px;
                    }
                    .fault-table{
                        padding: 24px 34px 0 34px;
                        /deep/ .el-table__header tr th {
                            background: #FAFAFA;
                        }
                        /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
                            border-bottom: 1px solid #ebeef5;
                        }
                    }
                }
            }
        }
    }
</style>