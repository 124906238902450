<template>
  <div class="login">
    <img src="../assets/image/bg.png" />
    <!-- 内容主体 -->
    <div class="main">
      <div class=login_header>
        <div class="change">
          <!-- <button @click="change" :style="$i18n.locale == 'ch'?'font-size:10px':''">{{ $t("login.language") }}</button> -->
          <span  @click="change">{{ $t("login.language") }}</span>
        </div>
        <!-- 图标 -->
        <div class="login_logo">
          <img src="../assets/image/Curtis_Horz_black.png" />
        </div>
        <!-- 内容的头部 -->
        <div class="login_header_up">{{ $t("login.title1") }}</div>
        <div class="login_header_down">{{ $t("login.title2") }}</div>
      </div>     
      <!-- 登陆的主要内容-->
      <div class="form_Form">
        <el-form ref="loginFormRef" :rules="rules" :model="loginModel">
          <div class="form_username">
            <el-form-item prop="account">
              <el-input
                :placeholder="$t('login.input_username')"
                v-model="loginModel.account"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form_password">
            <el-form-item prop="password">
              <el-input
                :placeholder="$t('login.input_password')"
                show-password
                v-model="loginModel.password"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form_option">
            <el-checkbox v-model="loginModel.rememberMe">
              <div class="form_option_main">{{ $t("login.remember") }}</div>
            </el-checkbox>
          </div>
          <div class="form_login">
             
              <el-button type="primary" @click="login">{{
                $t("login.login")
              }}</el-button>
            
          </div>
        </el-form>
      </div>
      <div class="login_footer">
        ©{{NowYear}} {{ $t("login.CompanyLTD") }}{{ $t("login.AllRight") }}   
        <br/>
        <span class="link-style" @click="viewBeian">{{ $t("login.SuICP") }}19064680{{ $t("login.SuUnit") }}</span>&nbsp;|
        <span class="link-style" @click="viewGongan">{{ $t("login.PublicNetWorkSecurity") }}32059002003120{{ $t("login.SuUnit") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import authApi from "@/api/autogen/v2/login";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Main",
  components: {},
  data() {
    return {
      loginModel: {
        password: "",
        account: "",
        rememberMe: false,
      },
      rules: {
        account: [
          {
            required: true,
            message: this.$t("login.check_username"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.check_password1"),
            trigger: "blur",
          },
          {
            min: 6,
            message: this.$t("login.check_password2"),
            trigger: "blur",
          },
        ],
      },
      NowYear:"2022"
    };
  },
  computed: {
    ...mapGetters(["language"]),
  },
  created() {
    this.getLocalStorage();
    let nowDate= new Date();
    let nowYear=nowDate.getFullYear().toString();
    this.NowYear="2022~"+nowYear;
  },
  mounted() {},
  methods: {
    ...mapActions(["setLanguage", "setUserEntity"]),
    languageMapping(languageValue) {
      if (languageValue == "1") {
        return "en";
      } else {
        return "ch";
      }
    },
    // 语言切换
    change() {
      if (this.$i18n.locale == "ch") {
        this.$i18n.locale = "en";
        this.setLanguage("en");
      } else {
        this.$i18n.locale = "ch";
        this.setLanguage("ch");
      }
      location.reload();
    },
    getLocalStorage() {
      const username = localStorage.getItem("username");
      console.log(username);
      const password = localStorage.getItem("password");
      const rememberMe = localStorage.getItem("rememberMe");
      this.loginModel = {
        account: username === undefined ? this.loginModel.account : username,
        password: password === undefined ? this.loginModel.password : password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    login() {
      let self = this;
      self.$refs.loginFormRef.validate((valid) => {
        if (!valid) {
          return;
        }

        if (self.loginModel.rememberMe) {
          localStorage.setItem("username", this.loginModel.account, {
            expires: 30,
          });
          localStorage.setItem("password", this.loginModel.password, {
            expires: 30,
          });
          localStorage.setItem("rememberMe", this.loginModel.rememberMe, {
            expires: 30,
          });
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }
        authApi
          .login(self.loginModel)
          .then((resp) => {
            if (resp) {
              console.log(resp);
              self.$message.success(this.$t("login.message1"));

              //登录成功,将账户信息存入sessionStorage
              let e = resp;

              var languge = self.languageMapping(e.LanguageSetting);
              var userEntity = {
                ...e,
                RoleId: e.RoleIdList,
                LanguageSetting: languge,
              };

              self.setUserEntity(userEntity);
              self.setLanguage(languge);
              this.$i18n.locale=languge;
              console.log("i18n=====",this.$i18n.locale);
              var auth_token = "Bearer " + e.Token;
              sessionStorage.setItem("auth_token", auth_token);

              //跳转至主页面
              setTimeout(() => {
                self.$router.push({
                  name: "Main",
                });
              }, 200);
            } else {
              self.$message.error(this.$t("login.message2"));
            }
          })
          .catch((err) => {
            self.$message.error(err);
          });
      });
    },
    viewBeian()
    {
      window.open( "https://beian.miit.gov.cn/#/","_blank");
    },
    viewGongan()
    {
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003120","_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  display: flex;
  height: 100%;
  width: 100%;
  min-width:1232px ;
  min-height: 770px;
  background-color: #04223a;
}
@media screen and (min-width: 1920px) {
  .main {
    position: absolute;
    transform: translate(180%, 0);
    margin-left: 12%;
  }
}
@media screen and (max-width: 1919px) {
  .main {
    position: absolute;
    transform: translate(100%, 0);
    margin-left: 28%;
  }
}
.main {
  height: 100%;
  min-width: 533px;
  min-height: 770px;
  background-color: #f4f9fe;
}
.login_header {
  height: 200px;
  width: 100%;
  position: absolute;
  /*transform: translate(83px, 360px);*/
}
.change {
  cursor: pointer;
  width: 40px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  float:right;
  margin: 22% 5%;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
}
.login_logo {
  height: 80px;
  width: 300px;
  float:left;
  margin: 20% 7% 5% 6.5%;
}
img {
  height: 100%;
  width: 100%;
}
.login_header_up {
  width: 287px;
  height: 26px;
  font-size: 26px;
  font-family: Arial, Arial-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  position: absolute;
  /*transform: translate(83px, 250px);*/
  margin: 38% 0 0 7%;
}
.login_header_down {
  width: 227px;
  height: 17px;
  font-size: 17px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  position: absolute;
  /*transform: translate(84px, 300px);*/
  margin: 47% 0 0 7%;
}
.form_Form {
  /*height: 315px;*/
  min-width: 350px;
  position: absolute;
  transform: translate(9.4%, 115%);
}
.form_username {
  min-width: 428px;
  float: inline-start;
}
.form_password {
  min-width: 428px;
  float: inline-start;
}
.form_option {
  height: 18px;
  width: 18px;
  margin-top: 5%;
}
.form_option_main {
  width: 82px;
  height: 13px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
}
.form_login {
  margin-top: 8%;
}
.el-button {
  min-width: 450px;
  height: 64px;
  border-radius: 3px;
  float: inline-start;
  margin-top: 8%;
}
.login_footer {
  width: 432px;
  height: 55px;
  font-size: 13px;
  font-family: Arial, Arial-Regular;
  font-weight: 400;
  text-align: center;
  color: #999999;
  line-height: 24px;
  position: absolute;
  /*transform: translate(22%, 2000%);*/
  bottom: 3%;
  left: 11%;
}
/deep/ .el-input__inner {
  height: 64px;
}
.link-style {
        font-size: 12px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
}
</style>