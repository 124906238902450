<template>
    <div class="factory-box" >
        <div class="factory-box-top">
            <span class="title">{{$t('FactoryProduct.MachineType')}}: {{params.typeName}}</span>
        </div>
        <div class="factory-box-content">
            <el-form 
                ref="machineForm" 
                :model="machineForm" 
                label-width="40%" 
                class="factory-box-form"
                :rules="rules"
                v-loading="loading"
                :element-loading-text="$t('FactoryProduct.loading_CreateMachine')"
                >
                <el-form-item
                    :label="$t('addMachine.MachineSN')+':'"
                    prop="machineSN"
                >
                    <el-input v-model="machineForm.machineSN" 
                    :placeholder="$t('addMachine.MachineSN')" 
                    class="from-input" style="width: 60%;"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('addMachine.RDMNO')+':'"
                    prop="rdmno"
                >
                     <el-select v-model="machineForm.rdmno" filterable  :placeholder="$t('myvehicle.select_rdmno')"  style="width: 60%;">
                        <el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    :label="$t('addMachine.remark')+':'"
                    prop="remark"
                >
                    <el-input type="textarea"  style="width: 60%;"
                    :rows="3"
                     v-model="machineForm.remark" :placeholder="$t('bizConfig.RemarkInformation')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="backLast">{{$t('addMachine.Return')}}</el-button>
                    <el-button @click="resetForm('machineForm')">{{$t('common.reset')}}</el-button>
                    <el-button type="primary" @click="createMachineFactory" v-if="privilege.Add">{{$t('addMachine.Submit')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import carApi from "@/api/autogen/v2/car";
import carListApi from "@/api/autogen/v2/carList";
import {ifGrantedTheOperationForThisFunction} from '@/utils';
export default {
    data() {
        var checkMachineSN =  (rule, value, callback) => {
            let data={
                machineSN:value,
                companyId: this.params.companyId
            };
            if(this.isEdit){
                callback();
            }
            carApi.checkIfMachineSNExisted(data).then(res=>{
                if(res.IsSuccess&&!res.Data){
                callback();
            }else {
                callback(
                    new Error(
                        this.$t("addMachine.MachineSNExited")
                    )
                );

                }
            });
        };
        return {
            params : this.$route.params,
            machineForm:{
                companyId: '',
                typeId : '',
                typeVersion: '',
                monitorTemplateId:'',
                machineSN: "",
                rdmno: "",
                remark:''
            },
            loading:false,
            options: [],
            rules: {
                machineSN: [
                {
                    required: true,
                    message:
                    this.$t("addMachine.MachineSN") + this.$t("common.required"),
                    trigger: "blur",
                },
                {
                    validator: checkMachineSN,
                    trigger:'blur'
                },
                ],
                rdmno: [
                {
                    required: true,
                    message: this.$t("addMachine.RDMNO") + this.$t("common.required"),
                    trigger: "blur",
                },
                ],
            },
            privilege: {
                View: false,
                Delete: false,
                Add: false,
            }
        };
    },
    computed: {
    },
    created() {
        this.initData();
        this.initialFunctionOperationPrivilege();
    },
    methods: {
        ifGrantedTheOperationForThisFunction,
        initialFunctionOperationPrivilege()
        {
                let self = this;
                self.privilege.View = ifGrantedTheOperationForThisFunction("View");
                self.privilege.Add = ifGrantedTheOperationForThisFunction("Add");
                self.privilege.Delete = ifGrantedTheOperationForThisFunction("Delete");
        },
        //页面初始化
        initData(){
            this.machineForm ={
                ...this.machineForm,
                companyId: this.params.companyId,
                typeId : this.params.typeId,
                typeVersion: this.params.typeVersion,
                monitorTemplateId:this.params.monitorTemplateId,
            };
            this.getRdmList(); 
        },
        
        //获取RDM列表
        getRdmList() {
            let params = {
                companyId: this.params.companyId,
            };

            carListApi.getRomList(params).then(res => {
                console.log(res.Data);
                this.options = res.Data;
            });
        },

        backLast(){
            this.$router.push({path: '/FactoryProduct'});
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.machineForm ={ 
                machineSN: "",
                rdmno: "",
                remark:'',
                companyId: this.params.companyId,
                typeId : this.params.typeId,
                typeVersion: this.params.typeVersion,
                monitorTemplateId:this.params.monitorTemplateId,
            };
        },
        createMachineFactory(){
            let self = this;
            self.$refs["machineForm"].validate((valid) => {
                if (valid) {
                    self.loading = true;
                    let param = self.machineForm;
                    carApi.CreateMachine_Factory(param).then(res=>{
                        let content = '';
                        let Url = '';
                        if(res.IsSuccess && res.Data){
                            Url = require("@/assets/image/success.png");
                            content= self.$t("common.operationSuccess");
                            this.resetForm('machineForm');
                        }else{
                            Url = require("@/assets/image/error_76.png");
                            content= self.$t("common.operationFailure");

                        }
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.loading =false;
                    }).catch((e) => {
                        let Url = require("@/assets/image/error_76.png");
                        let content= self.$t("common.operationFailure");
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.loading =false;
                    });
                }
            });
        }

        
    },
    mounted() {
    },
    beforeDestroy() {
    },
};
</script>

<style lang="less" scoped>
.factory-box{
    margin: 5% 10% 0;
    min-width: 758px;
    .factory-box-top{
        width: 100%;
        height: 101px;
        line-height: 101px;
        background: #0079c1;
        border-bottom: 4px solid #00B194;
        text-align: left;
        .title{
            margin-left:42px ;
            font-size: 30px;
            font-weight: 700;
            text-align: left;
            color: #ffffff;
        }
    }
    .factory-box-content{
        padding: 50px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
        text-align: left;
        font-size: 36px;
        .factory-box-form{
            /deep/ .el-form-item{
                margin-bottom: 40px;
            }
            /deep/ .el-form-item__label{
                font-size: 36px;
                height: 64px;
                line-height: 64px;
            }
            /deep/ .el-input__inner{
                font-size: 36px;
                height: 64px;
                line-height: 64px;
            }
            /deep/ .el-textarea__inner{
                font-size: 36px;
                font-weight: 700;
            }
            .el-button{
                font-size: 30px;
                height: 64px;
            }
            .el-button + .el-button {
                margin-left: 18px;
            }
        }
    }
}
</style>