export const menusDefinition = [
    // {
    //     menuId: "9999",
    //     menuName: "组件演示",
    //     link: "ComponentDemo",
    //     type: "route",
    //     icon: "el-icon-eleme",
    // },
    {
        menuId: "0000",
        menuName: "首页",
        menuNameKey: "menu.HomePage",
        link: "HomePage",
        type: "route",
        //icon: "icon-home",
        icon: "el-icon-house",
        //icon: "el-icon-s-home",
        //icon: "icon_main_32",
    },
    {
        menuId: "0101",
        menuName: "消息中心",
        menuNameKey: "menu.Notification",

        link: "NotificationManage",
        type: "route",
        //icon: "el-icon-message-solid",
        icon: "el-icon-bell",
        //icon: "icon_note",
    },
    {

        menuId: "0201",
        menuName: "我的车辆",
        icon: "icon-machine",
        menuNameKey: "menu.MyVehicle",
        link: "CompanyList",
        type: "route",

    },
    {
        menuId: "0301",
        menuName: "车型管理",
        menuNameKey: "menu.MachineTypeManage",

        link: "MachineTypeManage",
        type: "route",
        icon: "icon-machine-type",
    },    
    {
        menuId: "0401",
        menuName: "控制器打包",
        menuNameKey: "menu.ESEDevicePackage",

        link: "ESEDevicePackage",
        type: "route",
        icon: "icon-package",

    },
    {
        menuId: "0501",
        menuName: "故障管理",
        menuNameKey: "menu.FaultManage",

        icon: "icon-fault",
        children: [
            {
                menuId: "0502",
                menuName: "故障管理",
                menuNameKey: "menu.FaultManage",

                link: "FaultList",
                type: "route",
                icon: "",
            },
            {
                menuId: "0503",
                menuName: "故障分析",
                menuNameKey: "menu.FaultAnalysis",

                link: "FaultAnalysis",
                type: "route",
                icon: "",
            },
        ],
    },


    {
        menuId: "0601",
        menuName: "RDM管理",
        menuNameKey: "menu.RDMManage",

        icon: "icon-rdm-manage",
        children: [

            {

                menuId: "0602",
                menuName: "RDM管理",
                icon: "",
                menuNameKey: "menu.RDMManage",

                link: "RDMManage",
                type: "route",

            },
            {

                menuId: "0603",
                menuName: "RDM分配",
                icon: "",
                menuNameKey: "menu.RDMAssign",

                link: "RDMAssign",
                type: "route",

            }, {

                menuId: "0604",
                menuName: "软件管理",
                icon: "",
                menuNameKey: "RDMManage.SoftwareManage",

                link: "SoftwareManage",
                type: "route",

            },
            {

                menuId: "0605",
                menuName: "品号管理",
                icon: "",
                menuNameKey: "RDMManage.RDMPNManage",

                link: "PNManage",
                type: "route",

            },
        ]
    },
    {
        menuId: "0701",
        menuName: "授权管理",
        menuNameKey: "menu.UserManagement",
        //icon: "el-icon-user-solid",
         icon: "el-icon-user",
        //icon: "icon_user_32",
        children: [
            {
                menuId: "0702",
                menuName: "客户管理",
                menuNameKey: "menu.UserManagement_Org",
                type: "iframe",
            },
            {
                menuId: "0703",
                menuName: "用户管理", 
                menuNameKey: "menu.UserManagement_User",
                type: "iframe",
            },
            {
                menuId: "0704",
                menuName: "用户组管理", 
                menuNameKey: "menu.UserManagement_UserGroup",
                type: "iframe",
            },
            {
                menuId: "0705",
                menuName: "角色管理", 
                menuNameKey: "menu.UserManagement_Role",
                type: "iframe",
            },
            {
                menuId: "0706",
                menuName: "车辆分组管理", 
                menuNameKey: "menu.UserManagement_VehicleGroup",
                type: "iframe",
            },
        ],
    },
    {
        menuId: "0801",
        menuName: "电子围栏",
        icon: "icon-geofence",
        menuNameKey: "menu.GeoFence",
        children: [
            {
                menuId: "0802",
                menuName: "围栏设置",
                menuNameKey: "menu.GeoFenceManagement",
                type: "iframe",
            },
            {
                menuId: "0803",
                menuName: "事件看板",
                menuNameKey: "menu.GeoFenceEvent",
                type: "iframe",
            },
        ],
    },
    {
        menuId: "0901",
        menuName: "工厂生产",
        menuNameKey: "menu.FactoryProduct",

        link: "FactoryProduct",
        type: "route",
        icon: "icon-factory",
    },
    /*
     {
         menuId: "1001",
         menuName: "基础数据",
         icon: "am-icon-table",
         menuNameKey: "menu.BasicDataManage",

        children: [
            {
                 menuId: "1002",
                menuName: "品号管理",
                menuNameKey: "menu.PNManagetage",
                type: "iframe",
             },
             {
               menuId: "1003",
               menuName: "参数管理",
               menuNameKey: "menu.ParameterManage",
               type: "iframe",
            },
         ],
     },*/
// {
    //     menuId: "0401",
    //     menuName: "数据分析",
    //     icon: "main_icon_32",
    //     menuNameKey: "menu.DataAnalysis",

    //     children: [
    //         {
    //             menuId: "0402",
    //             menuName: "电池分析",
    //             menuNameKey: "menu.VoltageAnalysis",
    //         },
    //         {
    //             menuId: "0403",
    //             menuName: "车辆分析",
    //             menuNameKey: "menu.VehicleAnalysis",
    //         },
    //         {
    //             menuId: "0404",
    //             menuName: "效率分析",
    //             menuNameKey: "menu.EfficencyAnalysis",
    //         },
    //     ],
    // },
];