<template>
    <div class="PN-box" v-loading="loading">
        <el-row class="PN-top">
            <el-col :span="16">
                <div class="title">
                    <span> 
                        {{$t('RDMManage.RDMPNManage')}}
                    </span>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="search">
                    <el-input
                        :placeholder="$t('RDMManage.ProductNo')"
                        suffix-icon="el-icon-search"
                        v-model="searchContent"
                        @change="getPNList"
                    >
                    </el-input>
                </div>
            </el-col>
        </el-row>
        <el-row class="PN-content">
            <el-col :span="24">
                <div class="PN-content-top">
                    <el-button type="primary" icon="el-icon-plus" @click="AddPN" v-if="privilege.Add">{{$t('RDMManage.Add')}}</el-button>
                    <el-button type="danger" icon="el-icon-delete" @click="deleteData(2)" v-if="privilege.Delete">{{$t('RDMManage.Delete')}}</el-button>
                </div>
                <div class="companyHeaderDropdown">
                    <el-dropdown @command="companyChange">
                      <span class="el-dropdown-link">
                        {{companyName}}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="data in companyList"
                          :key="data.CompanyId"
                          :command="data"
                          >{{ data.CompanyName }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
            </el-col>
            <el-col :span="24">
                <div class="PN-table">
                    <div class="table-box">
                        <el-table :data="tableData" :row-class-name="TableRowClassName"
                         style="width: 100%;" 
                        @selection-change="handleSelectionChange"
                         :row-style="rowClass" >
                            <el-table-column
                                type="selection"
                                align="center"
                                width="55">
                            </el-table-column>
                            <el-table-column
                                type="index"
                                align="center"
                                :label="$t('common.SEQ')"
                                width="50"
                                sortable
                                :index="indexMethod"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="CompanyId"
                                align="center"
                                :label="$t('common.company')"
                                width="250">
                                <template slot-scope="scope">
                                    <span  v-for="item in companyList" :key="item.CompanyId">
                                        <p v-if="scope.row.CompanyId==item.CompanyId">{{item.CompanyName}}</p>                                        
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="ProductNumber"
                                align="center"
                                :label="$t('RDMManage.ProductNo')"
                                width="250">
                            </el-table-column>
                            <el-table-column
                                    prop="ProductType"
                                    align="center"
                                    :label="$t('RDMManage.ProductType')"
                                    width="200"
                                    >
                                    <template slot-scope="scope">
                                        <p v-if="scope.row.ProductType==0">
                                            {{$t('RDMManage.ProductType_General')}}
                                        </p>
                                        <p v-else> {{$t('RDMManage.ProductType_Particular')}}</p>
                                    </template>
                                </el-table-column> 
                                <el-table-column
                                prop="IsCloud"
                                align="center"
                                :label="$t('RDMManage.ThridCloudSupport')"
                                >
                                <template slot-scope="scope">
                                    <p v-if="scope.row.IsCloud==0">
                                        {{$t('RDMManage.ThridCloudSupport_No')}}
                                    </p>
                                    <p v-else> {{$t('RDMManage.ThridCloudSupport_Yes')}}</p>
                                </template>
                            </el-table-column>                                                                                                         
                            <el-table-column
                                :label="$t('common.operation')"
                                align="center"
                                fixed="right"
                                width="250">
                                <template slot-scope="scope">
                                    <div class="table-operation">
                                        <i class="el-icon-edit" @click="editPN(scope.row)" :title="$t('common.edit')" v-if="privilege.Edit"></i>                                                             
                                        <i class="el-icon-delete" @click="deleteData(1,scope.row.ID)" :title="$t('common.delete')" v-if="privilege.Delete"></i>
                                    </div>                                    
                                </template>                              
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="pagination">
                        <div
                            v-if="pnSearch.total > userInfo.PageSize"
                            class="paginationCount"
                        >
                            {{ (pnSearch.page - 1) * userInfo.PageSize + 1 }}-{{
                            pnSearch.page * userInfo.PageSize
                            }}
                            {{ $t("common.Page_of") }} {{ pnSearch.total }} {{ $t("common.Pages") }}
                        </div>
                        <div class="paginationDiv">
                            <el-pagination
                            background
                            :page-size="userInfo.PageSize"
                            @current-change="handleCurrentChange"
                            :hide-on-single-page="true"
                            layout="prev, pager, next"
                            :total="pnSearch.total"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import pnApi from "@/api/autogen/v2/product";
import companyApi from "@/api/autogen/v2/orgnization";
import {ifGrantedTheOperationForThisFunction} from '@/utils';
export default {
    data() {
        return {
            companyList:[],
            searchContent:'',
            pnSearch: {
                page: 1,
                total: 0,
            },
            tableData: [],
            muitipleData: [],
            selectRow: [],
            selectCompany:{
                CompanyId:-1                
            },
            processingColor: '#00b194',
            loading:false,
            privilege: {
                View: false,
                Add: false,
                Delete: false,
                Edit: false,
            },
            companyName:  this.$t("common.company"),
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
        currentCompany(){
            
            return this.$store.state.companyEntity;
        }
    },
    watch: {
        muitipleData(data) {  //存储选中的row
            this.selectRow = [];
            if (data.length > 0) {
                data.forEach((item, index) => {
                    this.selectRow.push(this.tableData.indexOf(item));
                });
            }
        }
    },
    created(){
        let self = this;
        self.buildCompanyItems();     
        self.getPNList();
        self.initialFunctionOperationPrivilege();
    },
    methods: {
        ifGrantedTheOperationForThisFunction,
        initialFunctionOperationPrivilege()
        {
                let self = this;
                self.privilege.View = ifGrantedTheOperationForThisFunction("View");
                self.privilege.Add = ifGrantedTheOperationForThisFunction("Add");
                self.privilege.Delete = ifGrantedTheOperationForThisFunction("Delete");
                self.privilege.Edit = ifGrantedTheOperationForThisFunction("Edit");
        },
        buildCompanyItems() {
            let self = this;
            companyApi.getCompanies().then((resp) => {
                let companyItems = resp.Data;
                for (var i = 0; i < companyItems.length; i++) {
                    self.companyList.push({
                        CompanyName: companyItems[i].CompanyName,
                        CompanyId: companyItems[i].CompanyId,
                    });
                }
                //console.log(companyList);
            });
        },  
        //监听公司变化
        companyChange(command) {
            this.pnSearch.pageIndex = 1;
            this.companyName = command.CompanyName;
            this.selectCompany.CompanyId = command.CompanyId;
            this.getPNList();
        },
        //分页序号连续
        indexMethod(index) {
            index = (index + 1) + (this.pnSearch.page - 1) * this.userInfo.PageSize;
            return index;
        },

        /**
         * @desc 分页
         */
        handleCurrentChange(val) {
            this.pnSearch.page = val;
            this.getPNList();
        },


        //获取PN列表
        getPNList(){
            this.loading=true;
            let param = {
                pageSize: this.userInfo.PageSize,
                pageIndex: this.pnSearch.page,
                CompanyId: this.selectCompany.CompanyId,
                ProductNumber: this.searchContent
            };
            pnApi.GetProductNumberList(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.tableData = res.Data;
                    this.pnSearch.total = res.Data.TotalCount;
                }
                this.loading=false;
            });
        },
        /**
         * @desc 自定义斑马纹
         */
        TableRowClassName({row, rowIndex}) {
            if (rowIndex%2==0) {
                 return '';
            } else {
                return 'even-row';
            }
        },

        handleSelectionChange(val) {
            this.muitipleData = val;
        },

        //选中行变色
        rowClass({ row, rowIndex }) {
            if (this.selectRow.includes(rowIndex)) {
                return { "background-color": "#E6F7FF" };
            }
        },
        /**
         * @desc 添加PN
         */
         AddPN(){
          this.$store.commit("setIsEdit",false);
            var param = {  
                CompanyId: this.selectCompany.CompanyId?  this.selectCompany.CompanyId:this.currentCompany.CompanyId ,             
            };
            this.$router.push({
                name: "AddPN",
                params: param,
            });
        },        
        /**
         * @desc 编辑PN
         */
         editPN(row){
          this.$store.commit("setIsEdit",true);
            var param={
                ID:row.ID,
                CompanyId: this.selectCompany.CompanyId?  this.selectCompany.CompanyId:this.currentCompany.CompanyId ,   
            };
            this.$router.push({
                name: "AddPN",
                params: param,
            });
        },
        /**
         * @desc 删除二次确认
         */
         deleteData(type, data) {
            this.loading=true;
            let array = [];

            if(type == 1) {
                array.push(data);
            } else {
                array = this.muitipleData.map(item => {
                    return item.ID;
                });
            }
            if(array.length <= 0) {
                const Url = require("@/assets/image/warning1.5x.png");
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("common.selectPN")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("common.deleteComfirm")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                this.DeletePN(array);
            }).catch(() => {     
                this.loading=false;  
            });
        },
        /**
         * @desc 删除PN信息
         */
         DeletePN(arr) {
            let params = {
                IDList: arr,
            };

            pnApi.DeleteProductNumber(params).then(res => {
                if(!res) {
                    this.$message.error(this.$t("common.operationFailure"));
                    return; 
                }
                this.$message.success(this.$t("common.operationSuccess"));

                this.getPNList();
                this.loading=false;
            });
        },       
    },
    beforeDestroy() {
        this.$bus.off("company-switch", this.companySwitch);
    },
};
</script>

<style lang="less" scoped>
     /*筛选条件下拉框布局*/
    .companyHeaderDropdownList {
        width: 50%;
        display: inline-block;
        text-align: right;
    }
    .companyHeaderDropdown {
        display: inline-block;
        margin-left: 30px;
        float: right;
    }
    .PN-box{
        margin-top : 10px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
        .PN-top{
            border-bottom: 1px solid #e5e5e5;
            background: #fafafa;
            border-radius: 2px;
            padding: 0 18px;
            height: 79px;
            .title{
                font-size: 16px;
                font-weight: 700;
                line-height: 79px;
                float: left;
                margin-left:26px ;
            }
            .search{
                height: 48px;
                line-height: 79px;
            }
        }
        .PN-content{
            padding: 20px;
            .PN-content-top{
                float: left;
            }
            .PN-table{
                margin-top: 21px;
                .table-box {
                    border: 1px solid #F2F2F2;

                    /deep/ .el-table__header tr th {
                        background: #FAFAFA;
                    }

                    .table-operation {
                        display: flex;
                        justify-content: space-around;
                        color: #0079C1;

                        i {
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            &:last-child {
                                color: #FF4D4F;
                            }
                        }
                    }
                }
                .pagination {
                    margin-top: 20px;
                    text-align: right;
                    padding-bottom: 40px;
                    .paginationCount {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;
                    }
                    .paginationDiv {
                        display: inline-block;
                    }
                }
                .point{
                    cursor: pointer;
                }
                .gray{
                        font-size: 21px;
                        color: #999999;
                    }

                .green{
                        font-size: 21px;
                        color: #00b194;
                    }
                .red{
                        font-size: 21px;
                        color: #fe686a;
                    }                
            }
        }
    }
</style>