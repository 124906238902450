<template>  
    <div v-loading="loadingCar">
    <div class="updgrade-device-box">
        <Card :title="$t('myvehicle.machineDevice')" class="marginBottom">
            <div class="updgrade-basic-box" slot="content">
                <div
                    class="updgrade-basic-list-box"
                    v-if="
                        carDetails.MachineComponents &&
                        carDetails.MachineComponents.DeviceList
                    "
                >
                    <div     
                        class="updgrade-basic-list"                
                        v-for="(item, key) in carDetails.MachineComponents
                            .DeviceList"
                        :key="key"
                    >
                        <div class="updgrade-basic-list-icon">
                            <img src="../../assets/image/icon_controller.png" />
                        </div>
                        <div class="updgrade-basic-list-content">
                            <div class="updgrade-basic-list-title">
                                {{ item.DeviceName }}({{ item.NodeId }}),{{item.Remark}}                                
                            </div>
                            <div class="updgrade-basic-list-desc">
                                <p>
                                    <span>{{ $t("myvehicle.systemVersion") }}：</span>
                                    <span>{{ item.SystemDisplayVersion }}</span>
                                </p>
                                <p>
                                    <span>{{ $t("myvehicle.fieldVersion") }}：</span>
                                    <span>{{ item.FeildDisplayVersion }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    </div>
    <div class="machine-upgrade-box">           
        <div class="machine-upgrade-header failed" v-if="upgradeFail">{{this.$t('myvehicle.latestUpgradeFailed')}}:{{UpgradeError}}</div>
        <span class="card-header-title">{{ $t("myvehicle.selectSoftwareVersion") }}:</span><div class="machine-upgrade-header"></div>
        <div class="machine-upgrade-body" v-loading="loading">
            <div class="machine-upgrade-body-content">
                <div class="machine-body-header">
                    {{ $t('myvehicle.totalVersion',{total:tableData.length}) }}
                    <span class="machine-body-header-btn" @click="addNewSoft" v-if ="this.privilege.GrantSoftwareUpgrade"
                        >{{ $t("myvehicle.addNewSoftware") }}</span
                    >
                </div>
                <div class="machine-body-content" >
                    <div v-if="tableData.length > 0">
                        <el-radio-group v-model="version">
                            <el-radio
                                v-for="(item, key) in tableData"
                                :label="item.TypeId + '/' + item.TypeVersion"
                                :key="key"
                            >                              
                              {{ typeNameDec[key]}}
                                <el-popover
                                    placement="right-start"
                                    width="468"
                                    trigger="hover"
                                    popper-class="popover-content-box"
                                >
                                    <div>
                                        <span>{{ $t("myvehicle.Device_Information") }}</span>
                                    </div>
                                    <div >
                                        <p v-for="(value,index) in item.DeviceList" :key="index">{{value.DeviceName}}({{value.NodeId}}):{{value.SystemDisplayVersion}}</p>
                                    </div>
                                    <i
                                        slot="reference"
                                        class="el-icon-question" style="color:#ffc529"
                                    ></i>
                                </el-popover>
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div class="none-data-box" v-else>{{ $t("common.empty") }}</div>
                </div>
            </div>
            <div class="machine-upgrade-body-footer">
                <el-button @click="cancel">{{ $t("bizConfig.clear") }}</el-button>
                <el-button type="primary" :disabled="!this.privilege.GrantSoftwareUpgrade? true: false"  @click="Submit">{{ $t("bizConfig.save") }}</el-button>
                <!-- <span @click="Submit">{{ $t("bizConfig.save") }}</span> -->
            </div>
            <PopBox v-if="popShow" :details="popDetails"></PopBox>
        </div>

        <el-dialog
            :visible.sync="addShow"
            :title="$t('myvehicle.addNewSoftware')"
            width="960px"
            class="add-soft-box"
        >
            <el-form
                :model="basicForm"
                ref="basicForm"
                label-width="auto"
                :rules="rules"
            >
                <el-form-item :label="$t('myvehicle.Uploaddevicefiles')+':'" prop="DeviceFile">
                    <el-col :span="8" :key="key" v-for="(item,key) in DeviceList">
                        <el-upload ref="DeviceFile"
                            :accept="getAccept(item.DeviceType)"
                            list-type="picture-card"
                            :show-file-list="false"
                            :auto-upload="false"
                            :on-change="(file, fileList) => {handleChangeDeviceFile(file, fileList, key,item)}"
                            :file-list="devicesFile"
                            :disabled ="item.IsCanBeUpdate == 0 ? true : fileName[key].disabled"
                            action="#">
                            <div v-if=" fileName[key].name !=''" >
                            <div class="uploading"  v-loading="true" v-if=" fileName[key].loading"></div>
                            <i class="el-icon-folder-opened" v-else></i>
                            <div class="haveFile">{{fileName[key].name}}</div>
                            </div>
                            <div  v-else>
                            <i class="el-icon-plus"></i>
                            <div class="upload">{{ $t("common.Upload") }}</div>
                            </div>
                            <div class="upload-text-box">
                                 {{item.DeviceName}}({{item.NodeId}})
                            </div>
                        </el-upload>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('bizConfig.Remark')+':'">
                    <el-input
                        type="textarea"
                        :rows="5"
                        class="remark"
                        v-model="basicForm.remark"
                        :placeholder="$t('bizConfig.RemarkInformation')"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="add-soft-footer">
                <el-button @click="cancelUpdate"> {{ $t("bizConfig.cancle") }} </el-button>
                <el-button type="primary" @click="submitMachineType" :disabled="!this.privilege.GrantSoftwareUpgrade||loading? true: false" v-loading="loading"> {{ $t("bizConfig.save") }} </el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('myvehicle.secondaryVerification')"
          :visible.sync="dialogVisible"
          width="430px" style="margin-top: 16vh">
          <el-form ref="secondVerify" :model="secondPassword" label-width="120px" :rules="rules">
              <el-form-item
                :label="$t('common.input_password')"
                prop="password"
              >
               <el-input
                    v-model="secondPassword.password" show-password
                    :placeholder="$t('common.check_password')"
                ></el-input>
              </el-form-item>
           
              <el-form-item>
                <el-col class="dialogFooter">
                  <el-button @click="dialogCancel">{{$t('common.cancel')}}</el-button>
                  <el-button type="primary" @click="submitVerify" >{{$t('addMachine.Submit')}}</el-button>
                </el-col>
              </el-form-item>
          </el-form>
        </el-dialog>
    </div>
    </div>
</template>
<script>
import Card from "@/components/ui/Card.vue";
import carApi from "@/api/autogen/v2/carList";
import deviceApi from "@/api/autogen/v2/cardevices";
import carTypeApi from "@/api/autogen/v2/cartype";
import PopBox from "./components/PopBox.vue";
import { ConstDefinition } from "@/utils/constDefinition";
import { checkExistData,ifGrantedTheOperationForMyMachineFunction } from "@/utils";
export default {
    name: "",
    props: {
        companyId: {
            type: String,
            default: '',
        },
        machineId: {
            type: String,
            default: '',
        },
    },
    components: {
        Card,
        PopBox},
    data() {
        var validateFlie = (rule, value, callback) => {
            let files =  this.devicesFile;
            if(files.length == 0){
                callback(new Error(this.$t("myvehicle.fileUploadnotnull")));
            }else {
                callback();
            }
        };
        return {
            //列表数据
            tableData: [],
            //选择的数据
            version: "",
            //弹框是否显示
            addShow: false,
            basicForm: {
                software: [],
                remark: '',
            },
            DeviceList:[],
            loading: false,
            loadingCar:false,
            carDetails: {},
            devicesFile:[],
            fileName:[],
              //弹出框内容
            popDetails: {
                title: '',
                desc: '',
                detail:'',
                type: 0
            },
            popShow: false,
            upgradeFail : false,
            dialogVisible:false,
            secondPassword :{
                password:''
            },
            timer: null,
            rules: {
                DeviceFile: [
                    {
                        required:true,
                        validator: validateFlie,
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: this.$t('login.check_password1'), trigger: 'blur' },
                ]
            },
            params:{},
            submitType : 0,
            UpgradeError:'',
            softwreUpgradeStatus:[
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Failed'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Success'),
                    value: ConstDefinition.softwreUpgradeStatus.Success,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Processing'),
                    value: ConstDefinition.softwreUpgradeStatus.Processing,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_TimeOut'),
                    value: ConstDefinition.softwreUpgradeStatus.TimeOut,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_SCU_Fail'),
                    value: ConstDefinition.softwreUpgradeStatus.SCU_Fail,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_RDMRefuse'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_RDMRefuse_0x0E,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_File_Summary'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_File_Summary_0x0F,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Transfer_File'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_Transfer_File_0x10_0x12,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_RDMRefuseToUpagrade'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_RDMRefuseToUpagrade_0x13,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Upgrade_Interrupt'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_Upgrade_Interrupt_0x14,
                }
            ],
            privilege: {
                GrantSoftwareUpgrade:false,
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
       typeNameDec() {
           return this.tableData.map((item) =>
           {
               let strTypeNameDec="";
               let autoDec=this.companyId+"_AutoCreated_MachineType_";
               if(item.TypeName.indexOf(autoDec)!=-1)
               {
                    strTypeNameDec="Basic Machine Type"+'_';

               }else{
                    strTypeNameDec=item.TypeName +'_';
               }   
                    
                if(item.Remark ==null||item.Remark =="null")
                {
                    strTypeNameDec=strTypeNameDec +this.$t('common.version')+"(" +item.TypeVersion+")";      
                    //return  item.TypeName +'_' + item.TypeVersion;
                }else{
                    strTypeNameDec=strTypeNameDec +this.$t('common.version')+"(" + item.TypeVersion +")"+ '_'+item.Remark;
                    //return  item.TypeName +'_' + item.TypeVersion + '_'+item.Remark;
                }
                return strTypeNameDec;
           });
           
       },
    },
    created() {
        this.getMachineLockStatus();
        this.initialFunctionOperationPrivilege();
    },
    methods: {
        ifGrantedTheOperationForMyMachineFunction,
        initialFunctionOperationPrivilege()
        {
            this.privilege.GrantSoftwareUpgrade = ifGrantedTheOperationForMyMachineFunction("SoftwareUpgrade");
        },
        //车辆锁定&升级
        getMachineLockStatus() {
            let params = {
                machineId: this.machineId,
            };
            carApi.getMachineLockStatus(params).then((res) => {
                if (res.IsSuccess) {
                    let { IsLocked, IsLockedBySoftwreUpgrade,SoftwreUpgradeStatus } = res.Data;
                    
                    if (IsLocked && !IsLockedBySoftwreUpgrade) {
                        //车辆进行参数修改过程中
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("myvehicle.Machine_locked"),
                            desc: this.$t("myvehicle.waiting"),
                            detail:this.$t("myvehicle.lockby",{username:res.Data.LockedUserName}),
                            type: 1,
                        };
                         this.initTime();
                    } else if(IsLocked && IsLockedBySoftwreUpgrade){
                        //车辆进行,升级过程中
                        if(SoftwreUpgradeStatus != 1 &&  SoftwreUpgradeStatus != 2){
                         //车辆如果升级失败，则显示失败原因
                            this.upgradeFail = true;
                            this.UpgradeError = checkExistData(this.softwreUpgradeStatus,SoftwreUpgradeStatus,'value').data.text;
                        }else{
                            //车辆进行,升级过程中，显示waiting lock页面
                            this.popShow = true;
                            this.popDetails = {
                                title: this.$t("myvehicle.Machine_upgraded"),
                                desc: this.$t("myvehicle.waiting"),
                                detail: '',
                                type: 2
                            };
                        }
                         this.initTime();
                    } else {
                        //车辆的升级和参数修改结果已经完成
                        if(SoftwreUpgradeStatus != 1 &&  SoftwreUpgradeStatus != 2){
                            //车辆如果升级失败，则显示失败原因
                            this.upgradeFail = true;
                            this.UpgradeError = checkExistData(this.softwreUpgradeStatus,SoftwreUpgradeStatus,'value').data.text;
                        }
                        console.log("getMachineLockStatus timer：");
                        console.log(this.timer);    
                        console.log("upgrade getMachineLockStatus clearTime======");
                        this.clearTime();                        
                        this.getMachineControlllerSoftwareVersion();                        
                    }
                }
            });
        },
        //预升级后获取升级状态
        getMachineUpgradeStatus(){
            let params = {
                machineId: this.machineId,
            };
            console.log("getMachineUpgradeStatus....");
            carApi.getMachineLockStatus(params).then((res) => {
                if (res.IsSuccess) {
                    let { IsLocked, IsLockedBySoftwreUpgrade,SoftwreUpgradeStatus } = res.Data;
                    if(IsLocked && IsLockedBySoftwreUpgrade){
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("myvehicle.Machine_upgraded"),
                            desc: this.$t("myvehicle.waiting"),
                            detail: '',
                            type: 2
                        };
                    }else{
                        this.popShow = false;
                        if(SoftwreUpgradeStatus != 2){
                            let content = '';
                            let Url = '';
                            if(SoftwreUpgradeStatus == 1){
                                this.upgradeFail = false;
                                Url = require("@/assets/image/success.png");
                                content= this.$t("common.operationSuccess");
                            }else{
                                this.upgradeFail = true;
                                Url = require("@/assets/image/error_76.png");
                                content= checkExistData(this.softwreUpgradeStatus,SoftwreUpgradeStatus,'value').data.text;
                                this.UpgradeError = content;
                            }               
                            this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: this.$t("common.confirm"),
                                center: true,
                                showClose:false
                            });
                            console.log("getMachineUpgradeStatus timer：");
                            console.log(this.timer);    
                            console.log("upgrade getMachineUpgradeStatus clearTime======");
                            this.clearTime();                           
                            //this.getCarList();
                            this.getMachineControlllerSoftwareVersion(); 
                        }
                    }
                }
            });
        },

        /**
         * @desc 获取车辆列表详情
         */
        getCarDetails() {
            let params = {
                companyId: this.companyId,
                machineId: this.machineId,
            };

            carApi.getCarDetails(params).then((res) => {
                this.carDetails = res.Data;
                //RDM离线
                if(this.carDetails.CarRDMStatus == ConstDefinition.CarStatus.Offline){
                    this.popShow = true;
                    this.popDetails = {
                        title: this.$t("myvehicle.RDM_offline"),
                        desc: this.$t("myvehicle.waiting"),
                        detail:'',
                        type: 1,
                    };
                    return;
                }
                //ksi断电
                if(this.carDetails.CarKSIStatus == ConstDefinition.KSIStatus.ElectricOff){
                    this.popShow = true;
                    this.popDetails = {
                        title: this.$t("myvehicle.KSI_ElectricOff"),
                        desc: this.$t("myvehicle.waiting"),
                        detail:'',
                        type: 1,
                    };
                    return;
                }

                this.fileName=[];
                this.basicForm.software=[];
                if(res.Data.MachineComponents.DeviceList.length>0){
                    res.Data.MachineComponents.DeviceList.forEach((item)=>{
                        let filename = {
                            name :'',
                            loading : false,
                            disabled : false
                        };
                        let device = {
                            DeviceId: item.DeviceId,
                            NodeId: item.NodeId,
                            MenuDefId: item.MenuDefId,
                            FaultDefId: item.FaultDefId,
                            FileDefId: item.FileDefId,
                        };
                        this.basicForm.software.push(device);
                        console.log("getCarDetails==");
                        console.log(this.basicForm.software);
                        this.fileName.push(filename);
                        console.log(this.fileName);
                    });
                }
                this.DeviceList = res.Data.MachineComponents.DeviceList;
                this.$emit('getHeaderData', this.carDetails);               
            });
        },

        getAccept(val){
            let accept = '';
            if (val===0){
                accept='.edev';
            }else {
                accept='.fdev';
            }
            return accept;
        },
        /*
        // //选择文件
        handleChangeSoftware(file, fileList, index) {
            let software = [],
            devicesConfig = this.basicForm.software[index];

        
            fileList = [file];
            var img = URL.createObjectURL(file.raw); // 获取URL
            software = fileList;
            this.$set(this.basicForm.software, index, {...devicesConfig, software});
            this.uploadFile(index,file);
        },*/
        // //删除图片
        // handleRemoveSoftware(key) {debugger
        //     this.$set(this.basicForm.software, key, {...this.basicForm.software[key], software: []})
        // },
        //上传文件
        handleChangeDeviceFile(file, fileList,index,item){
            let filename = {
                name : file.name,
                loading : true,
                disabled : true
            };
            if (item.DeviceType ===0) {
                    if (!/\.(edev)$/.test(file.name)) {
                    this.$message({
                        message: this.$t("addMachine.OnlyEDEVFile"),
                        type: "error",
                    });
                    return;
                }else {
                    this.$set(this.fileName,index,filename);
                    this.uploadFile(item, index,file);
                }
            } else if (item.DeviceType ===1){
                if (!/\.(fdev)$/.test(file.name)) {
                    this.$message({
                        message: this.$t("addMachine.OnlyFDEVFile"),
                        type: "error",
                    });
                    return;
                } else {

                   console.log("handleChangeDeviceFile this.fileName,index,filename,item, file"+index +" "+filename);
                   console.log(this.fileName);
                   console.log(item);
                   console.log(file);

                   this.$set(this.fileName,index,filename);    
                   
                   console.log("after set this.fileName==");
                   console.log(this.fileName);

                   this.uploadFile(item, index,file);

                  
                }

            }
        },
        /**
         * @desc 提交
         */
        uploadFile(item, index,file) {
            this.devicesFile.push(file);
            let filename = {
                name : file.name,
                loading : false,
                disabled : false
            };
            let fd = new FormData();
                
            fd.append("DeviceType", item.DeviceType);
            fd.append("DeviceNodeId", item.NodeId);
            fd.append("DeviceFile", file.raw);
            deviceApi.addDevice(fd).then(res=> {
              
                if(res.IsSuccess) {
                    let device = {
                        DeviceId: res.Data.DeviceId,
                        NodeId: res.Data.NodeId,
                        MenuDefId: res.Data.MenuDefId,
                        FaultDefId: res.Data.FaultDefId,
                        FileDefId: res.Data.FileDefId,
                    };
                    this.$set(this.basicForm.software,index,device);
                    console.log("uploadFile==index=="+index+";;filename="+filename);
                    console.log(this.basicForm.software);
                    this.$set(this.fileName,index,filename);
                    console.log(this.fileName);
                   
                }else{
                    this.$message.error(res.ErrorMsg);
                }
            })
            .catch(() => {
                this.$set(this.fileName,index,filename);
            });
        },
        // 新增软件保存
        submitMachineType(){
            if(this.loading) return;
            this.$refs["basicForm"].validate((valid) => {
                if (valid) {
                    this.submitType = 1;
                    const Url = require("@/assets/image/warning1.5x.png");
                    this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.safeCheck")+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        cancelButtonText: this.$t("common.cancel"),
                        center: true,
                    }).then(() => {
                        //this.addShow=false;
                        this.dialogVisible = true;
                    }).catch(() => {       
                    });
                }
            });
        },

        cancelUpdate(){
            this.addShow = false;
            this.basicForm.software=[];
            this.fileName=[];
            this.getCarDetails();            
        },
        // 编辑车型
        editMachineType(){
            console.log("editMachineType with new device...begin");
            this.loading = true;
            var date = new Date();
            var typeName = this.carDetails.CompanyId+"_AutoCreated_MachineType_"+date.getFullYear()+(date.getMonth()+1)+date.getDate()+date.getHours()+date.getMinutes()+date.getSeconds()+date.getMilliseconds()+Math.floor(Math.random() * 10);
            var fd = new FormData();
            fd.append("MachineId",this.carDetails.MachineId);
            fd.append("RDMNO",this.carDetails.RDMNO);
            fd.append("TypeId",this.carDetails.TypeId);
            fd.append("TypeVersion",this.carDetails.TypeVersion);
            fd.append("TypeName",this.carDetails.TypeName);
            fd.append("QRCode",typeName);
            fd.append("CompanyId",this.carDetails.CompanyId);
            fd.append("VoltMin",this.carDetails.VoltMin);
            fd.append("VoltMax",this.carDetails.VoltMax);
            fd.append("Remark",this.basicForm.remark);
            fd.append("DevicesJson",JSON.stringify(this.basicForm.software));
            fd.append("IsNewMachineType", false);
            fd.append("IsNewMachineTypeVersion", true);
            fd.append("IsPublishedType",this.carDetails.MachineComponents.MachineType.IsPublishedType);
            carTypeApi.editMachineType(fd).then(res=>{
                //this.loading = false;
                console.log("editMachineType with new device...done and OK");
                if(res.IsSuccess){
                    //this.fileName=[];
                    //this.basicForm.software=[];
                    this.getCarDetails();
                    this.params = {
                        companyId: this.companyId,
                        machineId: this.machineId,
                        typeId: res.Data.TypeId,
                        typeVersion: res.Data.TypeVersion
                    };
                    this.addShow = false;
                    //console.log("addShow pop form close...");
                    this.addMachineUpgradeData();                   
                    
                }else{
                    console.log("editMachineType with new device...done and failed");
                    this.loading = false;
                    this.$message.error(res.ErrorMsg);
                }
            }).catch((e) => {
                this.addShow = false;
                console.log("editMachineType with new device...done and error");
                console.log(e);
                this.$message.error(e);
                this.loading = false;
            });
        },
        /**
         * @desc 获取车辆列表详情
         */
        getCarList() {
            this.loading = true;
            let params = {
                companyId: this.companyId,
                machineId: this.machineId,
            };

            carApi.getUpgradeMachineTypeList(params).then((res) => {
                this.tableData = res.Data;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.version = '';
        },
        //添加提交版本
        Submit() {
            if(this.loading) return;
            
            if(!this.version) {
                this.$message.warning(this.$t("myvehicle.selectSoftwareVersion"));
                return;
            }
            
            let arr = this.version.split('/');

            this.params = {
                companyId: this.companyId,
                machineId: this.machineId,
                typeId: arr[0],
                typeVersion: arr[1]
            };
            this.submitType = 0;
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.safeCheck")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                this.dialogVisible = true;
            }).catch(() => {       
            });
            
        },

        addMachineUpgradeData(){
            carApi.addMachineUpgradeData(this.params).then((res) => {
                let content = '';
                let Url = '';
                if (res.Data) {
                    this.loading = false;
                    //console.log("this.loading = false...");
                    this.popShow = true;
                    this.popDetails = {
                                title: this.$t("myvehicle.Machine_upgraded"),
                                desc: this.$t("myvehicle.waiting"),
                                detail: '',
                                type: 2
                    };
                    //console.log("this.loading = false...");
                    this.initTime();
                    //this.$nextTick(function(){this.$forceUpdate();})
                    //console.log("forceUpdate...");
                    //console.log("call initTime...");
                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("myvehicle.MachineUpgrade_fail");
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                    this.loading = false;
                }
                
            })
            .catch(() => {
                this.loading = false;
            });
            this.basicForm.software=[];
            //this.fileName=[];
        },

        //初始化定时器
        initTime() {           
            this.timer = window.setInterval(() => {
                this.getMachineUpgradeStatus();
            }, 10000);
           
        },

        //清除定时器
        clearTime() {
            window.clearInterval(this.timer);
            this.timer = null;
        },

        //二次验证
        submitVerify(){
            this.$refs["secondVerify"].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    let params = {
                        userId: this.userInfo.UserId,
                        userAccount: this.userInfo.UserAccount,
                        password: this.secondPassword.password,
                    };
                    //this.dialogVisible = false;  
                    //this.addShow=false;
                    carApi.SecondPasswordCheckForModify(params).then((res) => {
                        if(res.Data){
                            //console.log("SecondPasswordVerify OK...");
                            this.dialogVisible = false;  
                            //this.addShow=false;
                            this.safecheck();
                        }else{
                            const Url = require("@/assets/image/warning1.5x.png");
                            this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.secondaryVerificationFail")+"</p>", '', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: this.$t("common.confirm"),
                                center: true,
                                showClose:false
                            });
                            this.loading = false;
                        }
                        
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            });
        },

        //安全检查
        safecheck(){
            let params = {
                machineId: this.machineId,
                rdmno: this.carDetails.RDMNO,
                machineSN: this.carDetails.MachineSN,
                companyId: this.companyId,
            };
            carApi.CommandToGetSCUStatus(params).then((res) => {
                if(res.Data){
                    
                    //console.log("CommandToGetSCUStatus OK and SCU is true...");
                    if(this.submitType == 0){                                                                                    
                        //console.log("update machine from choose template list...begin");
                        this.addMachineUpgradeData();                       
                    }else{                       
                        //console.log("update machine with new fdev file...begin");
                        this.editMachineType();
                    }

                    this.$message({
                        message: this.$t("myvehicle.scuOK"),
                        type: "success",
                    });

                }else{
                    //console.log("CommandToGetSCUStatus OK and SCU is False...");
                    const Url = require("@/assets/image/warning1.5x.png");
                    this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.scuFail")+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                    this.loading = false;
                }              
            });
        },

        //弹框展示
        addNewSoft() {
            if(this.fileName.length>0){
                this.fileName.forEach((item)=>{
                    this.$set(item,'name','');
                    this.$set(item,'loading',false);
                    this.$set(item,'disabled',false);
                });
            }
            this.addShow = true;
            
            this.basicForm = {
                ...this.basicForm,
                remark: '',
            };
        },

        
        dialogCancel(){
            this.clearForm();
            this.dialogVisible = false;
        },
        clearForm(){
            this.secondPassword ={
                password :''
            };
        },
        /**
        * @desc 获取车辆控制器软件版本
        */
        getMachineControlllerSoftwareVersion() {
            this.loadingCar = true;
            let params = {
                MachineId: this.machineId,
            };

            carApi.getMachineControlllerSoftwareVersion(params).then((res) => {
                this.getCarDetails();
                this.getCarList();
                this.loadingCar = false;
            })
            .catch(() => {
                this.loadingCar = false;
            });
        },
    },
    beforeDestroy() {
        console.log("timer：");
        console.log(this.timer);
        console.log("upgrade beforeDestroy clearTime======");
        this.clearTime();        
    },    
};
</script>
<style lang="less" scoped>
.machine-upgrade-box {
    position: relative;
    text-align: left;
    font-size: 14px;

    .machine-upgrade-header {
        font-weight: bold;
        margin-bottom: 20px;
        
    }
    .failed{
        color:#F34863 ;
    }
    .machine-upgrade-body {
        border: 1px solid #ccc;
        border-radius: 2px;
        .machine-upgrade-body-content {
            padding: 0 20px;

            .machine-body-header {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                .machine-body-header-btn {
                    background: #00b194;
                    border-radius: 2px;
                    width: 160px;
                    height: 32px;
                    display: inline-block;
                    color: #fff;
                    line-height: 32px;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .machine-body-content {
                height: 400px;
                padding: 20px 0;
                overflow-y: auto;
                /deep/.el-radio {
                    display: block;
                    margin: 10px auto;
                }
            }
        }
        .machine-upgrade-body-footer {
            height: 78px;
            background: #ffffff;
            border-top: 1px solid #cccccc;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                // display: inline-block;
                width: 110px;
                height: 38px;
                // background: #ffffff;
                // border: 1px solid #cbcbcd;
                // border-radius: 4px;
                // cursor: pointer;
                // text-align: center;
                // line-height: 38px;

                // &:last-child {
                //     height: 38px;
                //     background: #047bc2;
                //     border-radius: 4px;
                //     color: #fff;
                //     margin-left: 20px;
                // }

                // &:hover {
                //     opacity: 0.8;
                // }
            }
        }
    }

    .add-soft-box {
        /deep/ .el-dialog__body {
            padding: 30px 85px;
        }

        /deep/ .el-dialog__footer {
            padding: 0;
        }
        .uploading{
            height: 39px;
        }
        .upload {
            display: inline-block;
            top: 0;
            position: absolute;
            left: 35px;
            padding-top: 92px;
            color: #555555;
            width: 110px;
        }

        .add-soft-footer {
            text-align: center;
            border-top: 1px solid rgba(204, 204, 204, 0.4);
            padding: 20px 0;
        }
    }

    .upload-text-box {
        text-align: center;
        height: 30px;
        background: #f2f2f2;
        border-radius: 3px;
        line-height: 30px;
        color: #666666;
        width: 177px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        position: absolute;
        bottom: 1px;
        left: 1px;
        border-top: 1px dashed #c0ccda;
    }

    /deep/.el-upload--picture-card {
        position: relative;
        width: 180px;
        height: 180px;
        border: 1px solid #ccc;
        background-color: #fff;
        padding: 27% 0;
        line-height: inherit;
        i{
        font-weight: 1000;
        color: #555555;
        }
        .haveFile{
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        padding: 0 10%;
        }
    }

    .hide /deep/.el-upload {
        display: none;
    }
}
.updgrade-device-box {
    position: relative;
    text-align: left;

    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }

    .updgrade-basic-list-box {
        margin-bottom: 30px;
    }
    .updgrade-basic-box {
        .updgrade-basic-list {
            margin-bottom: 9px;
            height: 68px;
            border: 1px solid #cccccc;
            border-radius: 0px 2px 2px 0px;

            .updgrade-basic-list-icon {
                width: 146px;
                box-sizing: border-box;
                height: 100%;
                background: #f1f9fe;
                border-right: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;

                img {
                    width: 59px;
                    height: 60px;
                }
            }

            .updgrade-basic-list-content {
                padding-left: 47px;
                float: left;
                font-size: 14px;
                color: #333;
                margin-top: 15px;
                .updgrade-basic-list-title {
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .updgrade-basic-list-desc {
                    p {
                        float: left;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
}
.card-header-title {
            font-size: 16px;
            color: #333333;
            padding-left: 30px;
            font-weight: bold;
            background: url('../../assets/image/icon.png') left center no-repeat;
        }
</style>