export const ConstDefinition = {
    VoltageState: {
        Normal: 0, //正常
        Warning: 1, //警告
        Danger: 2 //危险
    },
    CarStatus: {
        Offline: 0,//下线
        Online: 1,//在线
        Error: 2//故障
    },

    NotificationType: {
        Notice: 1,//消息
        Warning: 2,//警告
        Error: 3//错误
    },

    KSIStatus: {
        ElectricOn:  1, //上电
        ElectricOff: 0 //断电
    },

    RDMSIMStatus: {
        ShutDown:  0, //停机
        Normal: 1 //正常
    },
    ParamClass: {
        Class1: 'Class1', //测量类
        Class2: 'Class2', //物理类
        Class3: 'Class3', //时间类
        Class4: 'Class4', //器件类
        Class5: 'Class5', //开关
        Class6: 'Class6', //其他
    },
    FenceTrigger:{
        enter : 1, //进入
        leave : 2, //离开
    },
    CarLockStatus:{
        lock : 1, //锁车
        unlock : 2, //未锁车
    },
    CarLockResult:{
        Success : 1, //成功
        Failed : 0, //失败
        Processing:2,//操作中
    },
    CarLimitedStatus:{
        nolimit : 3, //未限速
        //high : 4, //高
        low : 4, //低
        middle :5, //中
        //low : 6,//低
        high : 6,//高
    },
    CarLimitedResult:{
        Success : 1, //成功
        Failed : 0, //失败
        Processing:2,//操作中
    },
    CarRemoteEnableStatus:{
        Enabled : 1, //未开启远程
        Disabled : 0, //开启远程
    },
    CarRemoteEnableResult:{
        Success : 1, //成功
        Failed : 0, //失败
        Processing:2,//操作中
    },
    CarRaiseUpStatus:{
        Enabled : 1, //禁用起升
        Disabled : 0, //开启起升
    },
    CarRaiseUpResult:{
        Success : 1, //成功
        Failed : 0, //失败
        Processing:2,//操作中
    },
    softwreUpgradeStatus:{
        Failed : 0 ,
        Success : 1,
        Processing : 2,
        TimeOut : 3,
        SCU_Fail : 4,
        Failed_RDMRefuse_0x0E : 5,
        Failed_File_Summary_0x0F : 6,
        Failed_Transfer_File_0x10_0x12 : 7,
        Failed_RDMRefuseToUpagrade_0x13 : 8,
        Failed_Upgrade_Interrupt_0x14 : 9,
    },
    faultStatus:{
        active:0,
        resolved:1
    },
    SoftwareConfigStatus: {
        No: 0, //已配置
        Yes: 1, //未配置
    },
    NetWorkStatus: {
        Disabled: 0, //已关闭
        Normal: 1, //正常
        NoSIMCard: 2, //无网络
    },
    MachineBindStatus: {
        No: 0, //已配置
        Yes: 1, //未配置
    },
    RDMStatus: {
        Offline: 0,//下线
        Online: 1,//在线
        Error: 2//故障
    },
    NetWorkStatus_Provider: {
        CMCC: 0, //移动
        CTCC: 1, //电信
        CUCC: 2, //联通
    },
};