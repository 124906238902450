<template>
  <div class="page">
    <div class="page-title">
      <div class="title-en">{{ $t("notification.MessageCenter") }}</div>
      <span
        class="time"
        v-if="!(tableData.length == 0 && pickTimeValue == null)"
      >
        <el-date-picker
          style="width: 228px; height: 26px; line-height: 20px !important"
          v-model="pickTimeValue"
          @change="selectDate()"
          :editable="false"
          type="daterange"
          range-separator="~"
          :start-placeholder="$t('common.startTime')"
          :end-placeholder="$t('common.endTime')"
          value-format="yyyy-MM-dd"
        />
      </span>
    </div>
    <div class="page-contents" v-if="tableData.length !== 0">
      <div class="main">
        <div class="header">
          <div class="tab">
            <div
              :class="ischeckTab == 0 ? 'tab_item isChecked' : 'tab_item'"
              @click="checkTab(0)"
            >
              {{ $t("notification.type_All") }} ({{ AllCount }})
            </div>
            <div
              :class="ischeckTab == 1 ? 'tab_item isChecked' : 'tab_item'"
              @click="checkTab(1)"
            >
              {{ $t("notification.type_error") }} ({{ ErrorCount }})
            </div>
            <div
              :class="ischeckTab == 2 ? 'tab_item isChecked' : 'tab_item'"
              @click="checkTab(2)"
            >
              {{ $t("notification.type_warning") }} ({{ WarningCount }})
            </div>
            <div
              :class="ischeckTab == 3 ? 'tab_item isChecked' : 'tab_item'"
              @click="checkTab(3)"
            >
              {{ $t("notification.type_notice") }} ({{ NoticeCount }})
            </div>
          </div>
          <div class="btu">
            <span class="msg-btu" v-if="privilege.MarkAllRead">
              <span class="mask-icon"></span>
              <span class="btu_mask_all" @click="markAllUnRead()">{{
                $t("notification.markAllAsRead")
              }}</span>
            </span>
            <span class="msg-btu" v-if="privilege.BatchDelete">
              <span class="delete-icon"></span>
              <span class="btu_delete_all" @click="deleteAll()">{{
                $t("notification.deleteAll")
              }}</span>
            </span>
          </div>
        </div>
        <div class="contents">
          <el-timeline v-if="MsgShow">
            <el-timeline-item v-for="(item, index) in currentData" :key="index">
              <div :class="item.read_flag ? 'serial read' : 'serial'">
                {{ index + 1 }}
              </div>
              <el-card>
                <span
                  v-if="item.notificationType == 1"
                  class="msg-icon info"
                ></span>
                <span
                  v-else-if="item.notificationType == 2"
                  class="msg-icon warning"
                ></span>
                <span v-else class="msg-icon error"></span>
                <div class="msg-main">
                  <div class="msg-contents">{{ item.content }}</div>
                  <div class="msg-time">
                    {{ item.time | notificationTimeFormat(userInfo.TimezoneSetting,i18nt) }}
                  </div>
                </div>
                <div class="msg-mask">
                  <div
                    v-if="privilege.MarkRead && !item.read_flag"
                    v
                    class="msg-mask-true"
                    @click="markAsRead(item)"
                  ></div>
                  <div
                    class="msg-mask-delete"
                    @click="markAsDeleted(item)"
                    v-if="privilege.Delete"
                  ></div>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <div class="no-msg" v-if="!MsgShow">
            <div class="icon"></div>
            <span class="tip" v-if="ischeckTab === 1"
              >{{ $t("notification.Noerrorinformation") }}!</span
            >
            <span class="tip" v-if="ischeckTab === 2"
              >{{ $t("notification.Nowarninginformation") }}!</span
            >
            <span class="tip" v-if="ischeckTab === 3"
              >{{ $t("notification.Nonoticeinformation") }}!</span
            >
          </div>
          <div class="more" v-if="ifShow" @click="moreMsg">
            {{ $t("common.loadingMore") }}
          </div>
        </div>

        <el-dialog
          title="Notification"
          :visible.sync="notificationDialogVisible"
          width="40%"
        >
          <div>
            <div>{{ notification.time }}</div>
            <div>{{ notification.notificationType }}</div>
            <div>{{ notification.source }}</div>
            <div>{{ notification.content }}</div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="markAsRead(notification)">{{
              $t("notification.markAsRead")
            }}</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <div class="no-msg" v-else>
      <div class="icon"></div>
      <span class="tip">{{ $t("notification.Noinformation") }}!</span>
    </div>
  </div>
</template>

<script>
import notificationApi from "@/api/autogen/v2/notification";
import { ifGrantedTheOperationForNotificationFunction } from "@/utils";
import { mapGetters } from "vuex";
export default {
  name: "NotificationManage",
  data() {
    return {
      AllCount: 0,
      ErrorCount: 0,
      WarningCount: 0,
      NoticeCount: 0,
      errorList: [],
      warningList: [],
      noticeList: [],
      pickTimeValue: [],
      ischeckTab: 0,
      vueInstance: this,
      totalCount: 0,
      tableData: [],
      query: {
        CompanyId: 0,
        PageIndex: 1,
        PageSize: 0,
        NotificationTypeFilters: [],
        OrderBy: [{ name: "id", OrderBy: "Desc" }],
        startTime: "",
        endTime: "",
      },
      notificationDialogVisible: false,
      notification: {},
      privilege: {
        View: false,
        Delete: false,
        MarkRead: false,
        MarkAllRead: false,
        BatchDelete: false,
      },
      ifShow: true,
    };
  },

  computed: {
    userInfo() {
            return this.$store.state.userEntity;
        },
    ...mapGetters(["companyId"]),
    MsgShow() {
      if (this.ischeckTab === 1) {
        return this.errorList.length !== 0;
      } else if (this.ischeckTab === 2) {
        return this.warningList.length !== 0;
      } else if (this.ischeckTab === 3) {
        return this.noticeList.length !== 0;
      } else {
        return this.tableData.length !== 0;
      }
    },
    currentData() {
      if (this.ischeckTab === 1) {
        return this.errorList;
      } else if (this.ischeckTab === 2) {
        return this.warningList;
      } else if (this.ischeckTab === 3) {
        return this.noticeList;
      } else {
        return this.tableData;
      }
    },
  },
  created() {
    this.refreshCount();
    this.refreshList();
    this.initialFunctionOperationPrivilege();
  },
  mounted: {},
  methods: {
    ifGrantedTheOperationForNotificationFunction,
    initialFunctionOperationPrivilege() {
      this.privilege.View =
        ifGrantedTheOperationForNotificationFunction("View");
      this.privilege.Delete =
        ifGrantedTheOperationForNotificationFunction("Delete");
      this.privilege.MarkRead =
        ifGrantedTheOperationForNotificationFunction("MarkRead");
      this.privilege.MarkAllRead =
        ifGrantedTheOperationForNotificationFunction("MarkAllRead");
      this.privilege.BatchDelete =
        ifGrantedTheOperationForNotificationFunction("BatchDelete");
    },
    refreshCount() {
      let self = this;
      this.query.CompanyId = this.companyId;
      this.query.PageSize = this.$store.getters.userEntity.PageSize;
      let param = { ...this.query };
      param.NotificationTypeFilters = [];
      notificationApi
        .queryNotificationList(param)
        .then((resp) => {
          self.AllCount = resp.TotalCount;
        })
        .catch((err) => {
          self.$message.error(err);
        });

      param.NotificationTypeFilters = [1];
      notificationApi
        .queryNotificationList(param)
        .then((resp) => {
          self.NoticeCount = resp.TotalCount;
        })
        .catch((err) => {
          self.$message.error(err);
        });

      param.NotificationTypeFilters = [2];
      notificationApi
        .queryNotificationList(param)
        .then((resp) => {
          self.WarningCount = resp.TotalCount;
        })
        .catch((err) => {
          self.$message.error(err);
        });

      param.NotificationTypeFilters = [3];
      notificationApi
        .queryNotificationList(param)
        .then((resp) => {
          self.ErrorCount = resp.TotalCount;
        })
        .catch((err) => {
          self.$message.error(err);
        });
    },
    checkTab(data) {
      let minPageSize = this.$store.getters.userEntity.PageSize;
      let self = this;
      this.ischeckTab = data;
      this.ifShow = true;
      let param = { ...this.query };
      if (data === 0) {
        console.log("all");
        if (
          self.tableData.length === 0 ||
          self.tableData.length <= minPageSize
        ) {
          param.PageSize = minPageSize;
        } else {
          param.PageSize = self.tableData.length;
        }
        param.NotificationTypeFilters = [];
        notificationApi
          .queryNotificationList(param)
          .then((resp) => {
            self.AllCount = resp.TotalCount;
            self.tableData = resp.Data;
          })
          .catch((err) => {
            self.$message.error(err);
          });
      } else if (data === 3) {
        console.log("notice");
        if (
          self.noticeList.length === 0 ||
          self.noticeList.length <= minPageSize
        ) {
          param.PageSize = this.$store.getters.userEntity.PageSize;
        } else {
          param.PageSize = self.noticeList.length;
        }
        param.NotificationTypeFilters = [1];
        notificationApi
          .queryNotificationList(param)
          .then((resp) => {
            self.NoticeCount = resp.TotalCount;
            self.noticeList = resp.Data;
          })
          .catch((err) => {
            self.$message.error(err);
          });
      } else if (data == 2) {
        console.log("warning");
        if (
          self.warningList.length === 0 ||
          self.warningList.length <= minPageSize
        ) {
          param.PageSize = minPageSize;
        } else {
          param.PageSize = self.warningList.length;
        }
        param.NotificationTypeFilters = [2];
        notificationApi
          .queryNotificationList(param)
          .then((resp) => {
            self.WarningCount = resp.TotalCount;
            self.warningList = resp.Data;
          })
          .catch((err) => {
            self.$message.error(err);
          });
      } else {
        console.log("error");
        if (
          self.errorList.length === 0 ||
          self.errorList.length <= minPageSize
        ) {
          param.PageSize = minPageSize;
        } else {
          param.PageSize = self.errorList.length;
        }
        param.NotificationTypeFilters = [3];
        notificationApi
          .queryNotificationList(param)
          .then((resp) => {
            self.ErrorCount = resp.TotalCount;
            self.errorList = resp.Data;
          })
          .catch((err) => {
            self.$message.error(err);
          });
      }
    },
    selectDate() {
      console.log("选择了时间", this.pickTimeValue);
      if (this.pickTimeValue != null) {
        this.query.startTime = new Date(this.pickTimeValue[0]).toISOString();
        this.query.endTime = new Date(this.pickTimeValue[1]).toISOString();
        this.refreshList();
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
        this.refreshList();
      }
    },
    refreshList() {
      this.refreshCount();
      var self = this;
      var param = { ...this.query };
      notificationApi
        .queryNotificationList(param)
        .then((resp) => {
          self.tableData = resp.Data;
        })
        .catch((err) => {
          self.$message.error(err);
        });
      param.NotificationTypeFilters = [1];
      notificationApi
        .queryNotificationList(param)
        .then((resp) => {
          self.noticeList = resp.Data;
        })
        .catch((err) => {
          self.$message.error(err);
        });
      param.NotificationTypeFilters = [2];
      notificationApi
        .queryNotificationList(param)
        .then((resp) => {
          self.warningList = resp.Data;
        })
        .catch((err) => {
          self.$message.error(err);
        });
      param.NotificationTypeFilters = [3];
      notificationApi
        .queryNotificationList(param)
        .then((resp) => {
          self.errorList = resp.Data;
        })
        .catch((err) => {
          self.$message.error(err);
        });
    },
    markAsRead(d) {
      let self = this;
      console.log(d);
      notificationApi
        .markAsRead({
          NotificationIds: [d.id],
        })
        .then((resp) => {
          if (self.ischeckTab === 0) {
            this.tableData.forEach((item, index) => {
              if (item === d) {
                this.tableData[index].read_flag = true;
              }
            });
          } else if (self.ischeckTab === 1) {
            this.errorList.forEach((item, index) => {
              if (item === d) {
                this.errorList[index].read_flag = true;
              }
            });
          } else if (self.ischeckTab === 2) {
            this.warningList.forEach((item, index) => {
              if (item === d) {
                this.warningList[index].read_flag = true;
              }
            });
          } else {
            this.noticeList.forEach((item, index) => {
              if (item === d) {
                this.noticeList[index].read_flag = true;
              }
            });
          }
        })
        .catch((err) => {
          self.$message.error(err);
        });
    },
    markAsDeleted(d) {
      let self = this;
      notificationApi
        .markAsDeleted({
          NotificationIds: [d.id],
        })
        .then((resp) => {
          this.tableData = this.tableData.filter((item) => {
            return item !== d;
          });
          this.errorList = this.errorList.filter((item) => {
            return item !== d;
          });
          this.warningList = this.warningList.filter((item) => {
            return item !== d;
          });
          this.noticeList = this.noticeList.filter((item) => {
            return item !== d;
          });
          self.query.PageSize = -1;
          this.refreshCount();
        })
        .catch((err) => {
          self.$message.error(err);
        });
    },

    markAllUnRead() {
      notificationApi
        .clearAllUnReadNotifications({
          CompanyId: this.query.CompanyId,
        })
        .then((resp) => {
          this.refreshList();
        })
        .catch((err) => {
          self.$message.error(err);
        });
    },
    deleteAll() {
      let ids = [];
      if (this.ischeckTab == 0) {
        this.tableData.forEach((item) => {
          ids.push(item.id);
        });
      } else if (this.ischeckTab == 1) {
        this.errorList.forEach((item) => {
          ids.push(item.id);
        });
      } else if (this.ischeckTab == 2) {
        this.warningList.forEach((item) => {
          ids.push(item.id);
        });
      } else {
        this.noticeList.forEach((item) => {
          ids.push(item.id);
        });
      }

      let self = this;
      const Url = require("@/assets/image/warning1.5x.png");
      self
        .$confirm(
          "<img src=" +
            Url +
            "><p class='text'>" +
            this.$t("common.deleteComfirm") +
            "</p>",
          "",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            center: true,
          }
        )
        .then(() => {
          notificationApi
            .markAsDeleted({
              NotificationIds: ids,
            })
            .then((resp) => {
              self.$message.success(self.$t("common.operationSuccess"));
              if (this.ischeckTab == 0) {
                this.tableData = [];
              } else if (this.ischeckTab == 1) {
                this.errorList = [];
              } else if (this.ischeckTab == 2) {
                this.warningList = [];
              } else {
                this.noticeList = [];
              }
              this.refreshCount();
              this.refreshList();
            })
            .catch((err) => {
              self.$message.error(err);
            });
        })
        .catch(() => {});
    },
    showNotification(d) {
      this.notification = d;
      this.notificationDialogVisible = true;
    },

    moreMsg() {
      let self = this;
      let addNum = this.$store.getters.userEntity.PageSize;
      let data = this.ischeckTab;
      let param = { ...this.query };
      if (data == 0) {
        console.log("all");
        param.PageSize = self.tableData.length + addNum;
        param.NotificationTypeFilters = [];
        notificationApi
          .queryNotificationList(param)
          .then((resp) => {
            self.AllCount = resp.TotalCount;
            self.tableData = resp.Data;
            if (param.PageSize >= resp.TotalCount) this.ifShow = false;
          })
          .catch((err) => {
            self.$message.error(err);
          });
      } else if (data == 3) {
        console.log("notice");
        param.PageSize = self.noticeList.length + addNum;
        param.NotificationTypeFilters = [1];
        notificationApi
          .queryNotificationList(param)
          .then((resp) => {
            self.NoticeCount = resp.TotalCount;
            self.noticeList = resp.Data;
            if (param.PageSize >= resp.TotalCount) this.ifShow = false;
          })
          .catch((err) => {
            self.$message.error(err);
          });
      } else if (data == 2) {
        console.log("warning");
        param.PageSize = self.warningList.length + addNum;
        param.NotificationTypeFilters = [2];
        notificationApi
          .queryNotificationList(param)
          .then((resp) => {
            self.WarningCount = resp.TotalCount;
            self.warningList = resp.Data;
            if (param.PageSize >= resp.TotalCount) this.ifShow = false;
          })
          .catch((err) => {
            self.$message.error(err);
          });
      } else {
        console.log("error");
        param.PageSize = self.errorList.length + addNum;
        param.NotificationTypeFilters = [3];
        notificationApi
          .queryNotificationList(param)
          .then((resp) => {
            self.ErrorCount = resp.TotalCount;
            self.errorList = resp.Data;
            f(param.PageSize >= resp.TotalCount);
            this.ifShow = false;
          })
          .catch((err) => {
            self.$message.error(err);
          });
      }
    },
    i18nt(...params){
      return this.$t.apply(this,params)
   }
  },
};
</script>

<style lang="less" scoped>
.main {
  margin: 28px 32px 40px 41px;
}
.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e5e5e5;
}
.page {
  background-color: #ffffff;
  min-width: 1013px;
}
.tab {
  display: flex;
}
.tab_item {
  display: flex;
  border-radius: 2px 2px 0px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 38px;
  margin-right: 4px;
  background-color: #f2f2f2;
  color: #666666;
  cursor: pointer;
}
.tab_item:hover {
  color: #fff;
  background: #0079c1;
}
.isChecked {
  background-color: #0079c1;
  color: #ffffff;
}
.btu {
  display: flex;
  .msg-btu {
    align-items: center;
    display: flex;
    margin-left: 47px;
    background-repeat: no-repeat;
    background-size: contain;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    .mask-icon {
      display: flex;
      min-width: 15.5px;
      min-height: 15.5px;
      margin-right: 6.5px;
      background-image: url("../../assets/image/mark all@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
    .btu_mask_all {
      display: flex;
      flex: 1;
      width: 130px;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #0079c1;
      line-height: 39px;
      cursor: pointer;
    }
    .btu_mask_all:hover {
      text-decoration: underline;
    }
    .btu_delete_all {
      width: 80px;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #ff0000;
      line-height: 39px;
      cursor: pointer;
    }
    .btu_delete_all:hover {
      text-decoration: underline;
    }
    .delete-icon {
      display: flex;
      min-width: 15.5px;
      min-height: 15.5px;
      margin-right: 5px;
      background-image: url("../../assets/image/detele@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
/deep/ is-active {
  background-color: #d50c66;
}

.page-title {
  height: 70px;
  display: flex;
  align-items: center;
  .title-ch {
    display: flex;
    width: 90px;
    height: 22px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-left: 44px;
  }
  .title-en {
    display: flex;
    width: 160px;
    height: 22px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-left: 44px;
  }
  .time {
    .el-range-editor.el-input__inner {
      border-radius: 25px;
    }
    /deep/ .el-date-editor .el-range__icon {
      margin-left: 3px;
      line-height: 20px;
    }
    /deep/ .el-date-editor .el-range__close-icon {
      line-height: 20px;
    }
    /deep/ .el-date-editor .el-range-separator {
      line-height: 20px;
    }
  }
}
.contents {
  margin-top: 20px;
  margin-left: 25px;
  min-height: 540px;
  .serial {
    position: absolute;
    left: -25px;
    top: 40px;
    height: 32px;
    width: 32px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #0079c1;
    line-height: 32px;
  }
  .read {
    color: #999999;
  }
  .el-card {
    min-height: 94px;
    background-color: #f2f2f2;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    .msg-icon {
      display: flex;
      align-self: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .info {
      display: flex;
      width: 45px;
      height: 38px;
      background-image: url("../../assets/image/notice@2x.png");
    }
    .warning {
      display: flex;
      width: 47px;
      height: 44px;
      background-image: url("../../assets/image/warning@2x.png");
    }
    .error {
      display: flex;
      width: 45px;
      height: 45px;
      background-image: url("../../assets/image/error@2x.png");
    }
    .msg-main {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 16px;
      .msg-contents {
        display: flex;
        max-width: 885px;
        //height: 13px;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        color: #333333;
      }
      .msg-time {
        margin-top: 16px;
        display: flex;
        width: 122px;
        font-size: 16px;
        font-weight: 400;
        text-align: right;
        color: #666666;
      }
    }
    .msg-mask {
      left: 28px;
      width: 98%;
      height: 100%;
      position: absolute;
      top: 0px;
      display: none;
      background-color: rgba(0, 121, 193, 0.8);
      color: #eeeeee;
      font-size: 20px;
      .msg-mask-true {
        width: 64px;
        height: 64px;
        background-image: url("../../assets/image/mark  big@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 36px;
      }
      .msg-mask-delete {
        width: 64px;
        height: 64px;
        background-image: url("../../assets/image/detele big@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .el-card__body:hover .msg-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .more {
    margin-left: 70px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
  }
  .more:hover {
    cursor: pointer;
  }
  .no-msg {
    height: 540px;
  }
}
.no-msg {
  display: flex;
  flex: 1;
  height: 670px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;

  .icon {
    display: flex;
    height: 125px;
    width: 135px;
    background-image: url("../../assets/image/message all@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .tip {
    display: flex;
    //justify-self: flex-end;
    height: 28px;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    color: #999999;
  }
}
/deep/ .el-card__body {
  display: flex;
  flex: 1;
}

/deep/ .el-timeline-item__node {
  background-color: #e5f1f9;
}
/deep/ .el-timeline-item__node--normal {
  height: 32px;
  left: -25px;
  width: 32px;
  top: 37px;
}
/deep/ .el-timeline-item__tail {
  left: -10px;
  border-left: 2px solid #e5e5e5;
  height: 180%;
}
</style>
