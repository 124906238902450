import request from "@/utils/request";

              //创建车辆,保存设备信息，我的车辆使用
//
////页面点击确定导入设备增加后调用
              function addDevice(data) {
                return request({
                  url: "v2/CarDevices/AddDevice",
                  method: "post",
                  data
                });
              }
              
              //创建/编辑页面，设备配置故障Step调用
//
////DeviceId，设备ID。新增设备时回传的ID
              function getDeviceFaultsList(data) {
                return request({
                  url: "v2/CarDevices/GetDeviceFaultsList",
                  method: "post",
                  data
                });
              }
              
              //获取某个设备用户有权限的菜单的子节点和菜单
//<paramref>DeviceId，设备ID。新增设备时回传的ID。</paramref><paramref>ParIndex，菜单节点Index，默认为-1，取顶级菜单</paramref>
              function getDeviceChildrenMenuAndParameterList(data) {
                return request({
                  url: "v2/CarDevices/GetDeviceChildrenMenuAndParameterList",
                  method: "post",
                  data
                });
              }
              
              //获取某个设备用户有权限的菜单的子节点和菜单
//<paramref>DeviceId，设备ID。新增设备时回传的ID。</paramref>
              function getDeviceTotalMenuAndParameterList(data) {
                return request({
                  url: "v2/CarDevices/GetDeviceTotalMenuAndParameterList",
                  method: "post",
                  data
                });
              }
              
//删除设备
function deleteDevice(data) {
  return request({
    url: "v2/CarDevices/DeleteDevice",
    method: "post",
    data
  });
}

            

            export default {
                addDevice,
getDeviceFaultsList,
getDeviceChildrenMenuAndParameterList,
getDeviceTotalMenuAndParameterList,
deleteDevice
            };