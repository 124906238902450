<template>
  <div class="page">
    <div class="page-title">
      <div>Components</div>
    </div>
    <div class="page-contents">
      <div>
        <i class="fa fa-address-card"> </i>
        <h1>ComponentDemo</h1>
      </div>
      <div class="component-test">
        <el-button type="primary" @click="open">primary</el-button>
        <el-button type="success" @click="open">success</el-button
        ><el-button type="warning" @click="open">warning</el-button
        ><el-button type="info" @click="open">info</el-button>
        <el-button type="danger" @click="open">danger</el-button>
      </div>
      <div class="component-test">
        <el-input v-model="input" placeholder="请输入内容"></el-input>
      </div>

      <div class="component-test">
        <el-radio v-model="radio" label="1">备选项</el-radio>
        <el-radio v-model="radio" label="2">备选项</el-radio>
        <el-checkbox v-model="checked1">备选项1</el-checkbox>
        <el-checkbox v-model="checked2">备选项</el-checkbox>
      </div>

      <div class="component-test">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="component-test">
        <el-switch v-model="value"> </el-switch>
      </div>

      <div class="component-test">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="date" label="日期" sortable width="180">
          </el-table-column>
          <el-table-column prop="name" label="姓名" sortable width="180">
          </el-table-column>
          <el-table-column prop="address" label="地址"> </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="50"> </el-pagination>
      </div>
      <div class="component-test">
        <el-button :plain="true" @click="showMessage">打开消息提示</el-button>
      </div>

      <div class="component-test">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
          <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
          <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
          <el-tab-pane label="定时任务补偿" name="fourth"
            >定时任务补偿</el-tab-pane
          >
        </el-tabs>
      </div>

      <div class="component-test">
        <el-dropdown>
          <span class="el-dropdown-link">
            下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>黄金糕</el-dropdown-item>
            <el-dropdown-item>狮子头</el-dropdown-item>
            <el-dropdown-item>螺蛳粉</el-dropdown-item>
            <el-dropdown-item disabled>双皮奶</el-dropdown-item>
            <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown>
          <el-button type="primary">
            更多菜单<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>黄金糕</el-dropdown-item>
            <el-dropdown-item>狮子头</el-dropdown-item>
            <el-dropdown-item>螺蛳粉</el-dropdown-item>
            <el-dropdown-item>双皮奶</el-dropdown-item>
            <el-dropdown-item>蚵仔煎</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "component-demo",
  data() {
    return {
      input: "",
      radio: "",
      checked1: "",
      checked2: "",
      value: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],

      activeName: "first",
    };
  },
  methods: {
    showMessage() {
      this.$message({
        message: "这是一条消息提示",
        duration: 5000,
        type: "success",
      });
    },
    open() {
      let content = this.$t("common.deleteComfirm");
      this.$alert(content, "", {
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
    handleClick() {},
  },
};
</script>

<style lang="less" scoped>
.component-demo {
  padding: 20px 20px;
}
.component-test {
  margin: 10px 0px;
}
</style>