<template>
    <div class="parameter-data-box" v-loading="loading" :element-loading-text="loadingText">        
        <Card :title="$t('myvehicle.parameterList')" v-loading="loadingQuery" :element-loading-text="loadingQueryText">
            <div class="parameter-data-list-box" slot="content">
                <div class="parameter-data-list-left">
                    <div class="parameter-data-list-left-title">
                        {{ $t("RDMManage.RDMMenu") }}
                    </div>
                    <div class="parameter-data-list-left-input">
                        <el-input
                            :placeholder="$t('common.SearchKeyWord')"
                            suffix-icon="el-icon-search"
                            @change="filterConditionChange"
                            v-model="filterCondition"
                        >
                        </el-input>
                    </div>
                    <el-tree
                        v-if="openPanel"
                        :data="menuTreeData"
                        :props="defaultProp"
                        node-key="ParIndex"
                        :default-expanded-keys="defaultkey"
                        @node-click="handleNodeClick"
                        :render-content="renderContent"
                        @node-expand = "hanleNodeExpand"
                        @node-collapse = "hanleNodeCollapse"
                    ></el-tree>
                </div>
                <div class="parameter-data-list-right">
                    <el-table
                        :data="tableData"
                        height="590"
                        style="width: 100%"
                        :row-style="rowClass"
                        :row-class-name="TableRowClassName"
                    >
                        <el-table-column
                            prop="MenuName"
                            align="center"
                            :label="$t('myvehicle.parameterName')"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            align="center"
                            :label="$t('addMachine.displayName')"
                        >
                        </el-table-column>
                        <el-table-column
                            align="center"
                            :label="$t('myvehicle.lable_range')"
                        >
                        <template slot-scope="scope">
                            <span>{{scope.row.ShowMin}}~{{scope.row.ShowMax}} {{scope.row.Unit}}</span>
                        </template>
                        </el-table-column>
                        <el-table-column
                            prop="Value"
                            align="center"
                            :label="$t('myvehicle.paramvalue')"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="ReadOnly"
                            align="center"
                            :label="$t('common.edit')"
                        >
                            <template
                                slot-scope="scope"
                                v-if="scope.row.ReadOnly == '0'"
                            >
                                <el-input
                                    type="number"
                                    v-model="scope.row.setDataValue"
                                    :disabled="editEnable"
                                    @change="getEditParam(scope.row,$event,scope.$index)"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="parameter-data-list-footer" slot="footer">
                <span  v-if ="this.privilege.GrantRDMParameterModify"  @click="cleanData">{{ $t("bizConfig.clear") }}</span>
                <span  v-if ="this.privilege.GrantRDMParameterModify"  @click="submitData">{{ $t("bizConfig.save") }}&nbsp;&nbsp;{{paramData.length>0 ? '('+paramData.length+$t("myvehicle.parameters")+')' :''}}</span>
            </div>
        </Card>
        <PopBox v-if="popShow" :details="popDetails"></PopBox>
        <el-dialog :title="$t('myvehicle.secondaryVerification')"
          :visible.sync="dialogVisible"
          width="430px" style="margin-top: 16vh">
          <el-form ref="secondVerify" :model="secondPassword" label-width="120px" :rules="rules"> 
              <el-form-item
                :label="$t('common.input_password')"
                prop="password"
              >
               <el-input
                    v-model="secondPassword.password" show-password
                    :placeholder="$t('common.check_password')"
                ></el-input>
              </el-form-item>
           
              <el-form-item>
                <el-col class="dialogFooter">
                  <el-button @click="dialogCancel">{{$t('common.cancel')}}</el-button>
                  <el-button type="primary" @click="submitVerify" >{{$t('addMachine.Submit')}}</el-button>
                </el-col>
              </el-form-item>
          </el-form>
        </el-dialog>
    </div>
</template>
<script>
import Card from "@/components/ui/Card.vue";
import { checkExistData,getListData,ifGrantedTheOperationForRDMManageFunction } from "@/utils";
import { ConstDefinition } from "@/utils/constDefinition";
import PopBox from "../MyVehicle/components/PopBox.vue";
import carApi from "@/api/autogen/v2/carList";
import rdmApi from "@/api/autogen/v2/rdm";
export default {
    name: "",
    components: {
        Card,
        PopBox,
    },
    props: {
        companyId: {
            type: String,
            default: "",
        },
        RDMNo: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            rdmDetails: {},
            IsLocked: false,
            IsLockedBySoftwreUpgrade: false,
            IsLockedByRDMSoftwreUpgrade: false,
            IsBindMachineBind:false,
            popShow: false,
            RDMDefId: "",
            nodeId:'',
            filterCondition: "",
            menuData: [],
            PFUQuery:false,
            menuTreeData :[],
            tableData :[],
            ParamValueData :[],
            treeData: [],
            selectRow: [],
            defaultProp:{
                label: 'MenuName',
                id:'ParIndex',
                pid:'ParentIndex'
            },
            defaultkey:[],
            timer: null,
            loading: false,
            loadingQuery: false,
            parentIndex: -1,
            paramData:[],
            selected:[],
            selectedTree:[],
            openPanel:true,
            searchType:0,
            searchParentIndex:-1,
            //弹出框内容
            popDetails: {
                title: '',
                desc: '',
                detail:'',
                type: 0
            },
            dialogVisible:false,
            secondPassword :{
                password:''
            },
            rules: {
                password: [
                    { required: true, message: this.$t('login.check_password1'), trigger: 'blur' },
                ]
            },
            treeNode:{},
            loadingText : this.$t("myvehicle.loading_editParam"),
            loadingQueryText : this.$t("myvehicle.loading_queryParam"),
            privilege: {
                GrantRDMParameterQuery:false,
                GrantRDMParameterModify:false,
            },
            editEnable:false,
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
    },
    watch:{
       menuData(val, oldval){     
            if(val!=oldval)
            {
                let data=val;
                let array = [],
                    tableData =[];
                if (data) {
                    data.forEach((item) => {
                        if (item.MenuLevel != 7) { 
                            if(!checkExistData(this.treeData,item.ParIndex,'ParIndex').isExist){
                                array.push(item);
                            }
                        }else{
                            tableData.push(item);
                        }
                    });
                }

                if(this.searchType == 0){
                    //初始化后正常点击后显示
                    if (this.parentIndex == -1) {
                        //初始化展示
                        this.treeData = array;
                        array = getListData(array,-1,this.defaultProp);
                    
                    }else{
                        //正常点击展示
                        this.treeData = this.treeData.concat(array);
                        array = getListData(this.treeData,-1,this.defaultProp);
                    }
                }
                else{
                    if(this.parentIndex ==  -2){
                        //搜索后初始化
                        this.treeData = [];
                    }else{
                        if(this.treeData.length>0){
                            //正常点击展示
                            this.treeData = this.treeData.concat(array);
                            array = getListData(this.treeData,this.searchParentIndex,this.defaultProp);
                        }else{
                            if(this.treeNode){
                                this.searchParentIndex = this.treeNode.ParentIndex;
                                array.push(data);
                                //搜索后点击展示
                                this.treeData = array;
                                array = getListData(this.treeData,data.ParentIndex,this.defaultProp);
                            }
                        }
                    }
                }
                this.menuTreeData= array;

                this.defaultkey.push(this.parentIndex);
                if(this.paramData.length>0){
                    tableData.forEach((item)=> {
                        let editParam = checkExistData(this.paramData,item.ParIndex,'parameterIndex');
                        if(editParam.isExist){
                            this.$set(item, "setDataValue", editParam.data.setDataValue);
                        }
                        
                    });
                }
                
                this.tableData = tableData;
                if (tableData.length > 0 && this.PFUQuery && this.privilege.GrantRDMParameterQuery) {
                    this.startUploadData();
                    this.PFUQuery=false;
                }
            }
        },
        ParamValueData(data){
            if(data){
                this.tableData.forEach((item) => {

                    data.ListParameterCanAddressValue.forEach((item1)=>{
                        if(item1.BitSelect == item.BitSelect && item1.CANAddress == item.Address){
                            this.$set(item, "Value", item1.Value);
                        }
                    });
                });
            }
        }
    },
    created() {
        let self = this;
        self.getRDMLockStatus().then(val => { 
            if(val){
                let rdmDetails = self.getRDMDetail();
                Promise.all([rdmDetails]).then(function (resp) {
                    self.getMenuAndParameter();
                });
            }
            
        });
        self.initialFunctionOperationPrivilege();
    },
    methods: {
        checkExistData,
        getListData,
        ifGrantedTheOperationForRDMManageFunction,
        initialFunctionOperationPrivilege()
        {
            this.privilege.GrantRDMParameterQuery = ifGrantedTheOperationForRDMManageFunction("RDMParameterQuery");   
            this.privilege.GrantRDMParameterModify = ifGrantedTheOperationForRDMManageFunction("RDMParameterModify");   
            //console.log("call method ifGrantedTheOperationForRDMManageFunction...");  
            if(!this.privilege.GrantRDMParameterQuery ){
                this.popShow = true;
                this.popDetails = {
                    title: '',
                    desc: this.$t("myvehicle.DisableParameterQuery"),
                    detail: '',
                    type: 1
                };      
            } 
            if(!this.privilege.GrantRDMParameterModify)
                this.editEnable=true;
             //this.editEnable=false;    
            console.log("editEnable"+this.editEnable);    
        },
        //车辆锁定&升级
        async getRDMLockStatus() {
            let flag = false;
            let params = {
                RDMNo: this.RDMNo,
            };
            await rdmApi.getRDMLockStatus(params).then((res) => {
                if (res&&res.IsSuccess) {
                    let { IsLocked, IsLockedBySoftwreUpgrade ,IsLockedByRDMSoftwreUpgrade} = res.Data;
                    
                    if (IsLocked && !IsLockedBySoftwreUpgrade && !IsLockedByRDMSoftwreUpgrade) {
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("RDMManage.RDM_locked"),
                            desc: this.$t("myvehicle.waiting"),
                            detail:this.$t("myvehicle.lockby",{username:res.Data.LockedUserName}),
                            type: 1,
                        };
                    } else if(IsLocked && IsLockedBySoftwreUpgrade){
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("myvehicle.Machine_upgraded"),
                            desc: this.$t("myvehicle.waiting"),
                            detail: '',
                            type: 2
                        };
                    }
                    else if(IsLocked && IsLockedByRDMSoftwreUpgrade){
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("RDMManage.RDM_upgraded"),
                            desc: this.$t("myvehicle.waiting"),
                            detail: '',
                            type: 2
                        };
                    }else{
                        flag = true;
                    }
                }
                
            });
            return flag;
        },

        
        //编辑行变色
        rowClass({ row, rowIndex }) {
            if(checkExistData(this.paramData,row.ParIndex,'parameterIndex').isExist){
                return { "background-color": "#E6F7FF" };
            }
        },

        /**
         * @desc 自定义斑马纹
         */
        TableRowClassName({row, rowIndex}) {
            if (rowIndex%2==0) {
                 return '';
            } else {
                return 'even-row';
            }
        },

        //树的修改数量显示
        renderContent(h, { node, data, store }) {
            let selectedText = '';
            if(checkExistData(this.selectedTree,data.ParIndex,'ParentIndex').isExist){
                selectedText ="("+this.$t('myvehicle.Selected')+" "+checkExistData(this.selectedTree,data.ParIndex,'ParentIndex').data.Value+")";
            }
            
            return (
            <span class="custom-tree-node">
                <span>{node.label}&nbsp;</span>
                <span style="color:#0066CC">{selectedText}</span>
            </span>);
        },

        //获取编辑的参数
        getEditParam(row,e,index){
            this.openPanel = false;
            if(e<row.ShowMin || e>row.ShowMax){
                this.tableData[index].setDataValue = '';
                const Url = require("@/assets/image/warning1.5x.png");
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.notRange")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                let selectData = checkExistData(this.selected,row.ParIndex,'ParIndex');
                let paramDatas = checkExistData(this.paramData,row.ParIndex,'parameterIndex');
                if(selectData.isExist){
                    this.$delete(this.selected,selectData.index);
                    if(checkExistData(this.selectedTree,row.ParentIndex,'ParentIndex').isExist){
                        this.selectedTree.forEach((item)=>{
                            if(item.ParentIndex === row.ParentIndex){
                                this.$set(item, "Value", item.Value - 1);
                            }
                        });
                    }
                }
                if(paramDatas.isExist){
                    this.$delete(this.paramData,paramDatas.index);
                }
                this.openPanel = true;
                return;

            }
            let ListParameterIndexDetailQuery ={
                parameterIndex: row.ParIndex,
                bitSelect: row.BitSelect,
                setDataValue: e,
            };
            

            if(!checkExistData(this.selected,row.ParIndex,'ParIndex').isExist){
               
                if(!checkExistData(this.selectedTree,row.ParentIndex,'ParentIndex').isExist){
                    let selectedTree ={
                        ParentIndex : row.ParentIndex,
                        Value : 1
                    };
                    this.selectedTree.push(selectedTree);
                }else{
                    this.selectedTree.forEach((item)=>{
                        if(item.ParentIndex === row.ParentIndex){
                            this.$set(item, "Value", item.Value + 1);
                        }
                     });
                }
                let selected ={
                    ParentIndex : row.ParentIndex,
                    ParIndex : row.ParIndex
                };
                this.selected.push(selected);
            }
            
            this.openPanel = true;
            if(!checkExistData(this.paramData,row.ParIndex,'parameterIndex').isExist){
                this.paramData.push(ListParameterIndexDetailQuery);
            }
            
        },

        //提交操作
        submitData() {
            if (this.loading || this.paramData.length <= 0) {
                const Url = require("@/assets/image/warning1.5x.png");
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.noParam")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }
            const Url = require("@/assets/image/warning1.5x.png");
            this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.safeCheck")+"</p>", '', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: this.$t("common.confirm"),
                cancelButtonText: this.$t("common.cancel"),
                center: true,
            }).then(() => {
                this.dialogVisible = true;
            }).catch(() => {       
            });
           
        },

        //参数值修改
        editParamValue(){
            let params = {
                RDMNo: this.RDMNo,
                RDMDefId: this.RDMDefId,
                updateParameterDetailList: this.paramData,
            };

            rdmApi
                .submitData(params)
                .then((res) => {
                    this.loading = false;
                    let content = '';
                    let Url = '';
                    if (res.Data) {
                        this.cleanData();
                        //console.log("cleanData");
                        Url = require("@/assets/image/success.png");
                        content= this.$t("common.operationSuccess");
                        
                    }else{
                        Url = require("@/assets/image/error_76.png");
                        content= this.$t("common.operationFailure");

                    }
                    
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                })
                .catch(() => {
                    this.loading = false;
                    let content = '';
                    let Url = '';
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.operationFailure");
                     this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                });
        },
        //开始上传参数
        startUploadData() {

            let params = {
                RDMNo: this.RDMNo,
                RDMDefId: this.RDMDefId,
                ListParameterIndexDetailQuery: [],
            };

            this.tableData.forEach((item) => {
                params.ListParameterIndexDetailQuery.push({
                    parameterIndex: item.ParIndex,
                    bitSelect: item.BitSelect,
                });
            });

            rdmApi.startUploadData(params).then((res) => {
                if(res.Data == 1){
                    if(this.timer){
                        this.clearTime();
                    }
                    this.initTime();
                }else{
                    const Url = require("@/assets/image/warning1.5x.png");
                    //const errMessage = this.$t("RDMMessage.uploadFailed") + res.ErrorMsg;
                    const errMessage = this.$t("RDMMessage.uploadFailed");
                    this.$alert("<img src=" + Url + "><p class='text'>"+ errMessage +"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
            });

            
        },
        //实时获取参数
        getDeviceParameterValueList() {
            let params = {
                RDMNo: this.RDMNo,
                RDMDefId: this.RDMDefId,
                ListParameterIndexDetailQuery: [],
            };

            this.tableData.forEach((item) => {
                params.ListParameterIndexDetailQuery.push({
                    parameterIndex: item.ParIndex,
                    bitSelect: item.BitSelect,
                });
            });

            rdmApi.getRDMParameterValueList(params).then((res) => {
                if (res.Data) {
                    this.ParamValueData = res.Data;
                }
            });
        },
        //停止上传参数
        stopUploadData() {
            let params = {
                RDMNo: this.RDMNo,
            };

            rdmApi.stopUploadData(params).then((res) => {});
        },
        //初始化定时器
        initTime() {
            this.timer = window.setInterval(() => {
                this.getDeviceParameterValueList();
            }, 1000);
        },
        //清除定时器
        clearTime() {
            window.clearInterval(this.timer);
            this.timer = null;
        },
        getMenuAndParameter() {
            let params = {
                RDMDefId: this.RDMDefId,
                parentIndex: this.parentIndex,
                filterCondition: this.treeNode&& this.treeNode.length>0 ? "": this.filterCondition ,
            };
            rdmApi.getMenuAndParameter(params).then((res) => {
                this.menuData = res.Data;
                this.PFUQuery=true;
                this.loadingQuery = false;
            }).catch(() => {
                            this.loadingQuery = false;
                        });
        },
        getRDMDetail() {
            let params = {
                RDMNo: this.RDMNo
            };
            return rdmApi.GetRDMDetail_v2(params).then((res) => {
                if (res.Data) {
                   
                   
                    this.rdmDetails = res.Data;
                    this.RDMDefId =
                        this.rdmDetails.RDMSoftware &&
                        this.rdmDetails.RDMSoftware.RDMDefId;
                    this.nodeId =
                        this.rdmDetails.RDM &&
                        this.rdmDetails.RDM.NodeId;
                    if(this.rdmDetails.machine)
                    {
                        this.IsBindMachineBind=true;
                        if(this.rdmDetails.machine.RDMStatus == ConstDefinition.RDMStatus.Offline){
                            this.popShow = true;
                            this.popDetails = {
                                title: this.$t("myvehicle.RDM_offline"),
                                desc: this.$t("myvehicle.waiting"),
                                detail:'',
                                type: 1,
                            };
                            return;
                        }
                    }
                    //alert(this.IsBindMachineBind);

                }
            });
        },
        handleNodeClick(data) {
            this.loadingQuery = true;
            this.parentIndex = data.ParIndex;
            this.treeNode = data;
            this.getMenuAndParameter();
        },

        hanleNodeExpand(data){
            // 保存当前展开的节点
            let flag = false;
            this.defaultkey.some(item => {
                if (item === data.ParIndex) { // 判断当前节点是否存在， 存在不做处理
                    flag = true;
                    return true;
                }
            });
            if (!flag) { // 不存在则存到数组里
                this.defaultkey.push(data.ParIndex);
            }
        },

        hanleNodeCollapse(data){
            this.defaultkey.some((item, i) => {
                if (item === data.ParIndex) {
                    // 删除关闭节点
                    this.defaultkey.length = i;
                }
            });
        },
        filterConditionChange() {
            if(this.filterCondition == ""){
                this.searchType = 0;
                this.parentIndex = -1;
            }else{
                this.searchType = 1;
                this.parentIndex = -2;
            }
            this.getMenuAndParameter();
        },
        //清楚修改值
        cleanData(){
            this.openPanel = false;
            this.selected = [];
            this.paramData = [];
            this.selectedTree = [];
            this.tableData.forEach((item)=>{
                this.$set(item,'setDataValue','');

            });
            this.openPanel = true;
        },
        //二次验证
        submitVerify(){
            if(this.IsBindMachineBind)
            {
                this.$refs["secondVerify"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        let params = {
                            userId: this.userInfo.UserId,
                            userAccount: this.userInfo.UserAccount,
                            password: this.secondPassword.password,
                        };
                        carApi.SecondPasswordCheckForModify(params).then((res) => {
                            if(res.Data){
                                this.safecheck();
                            }else{
                                const Url = require("@/assets/image/warning1.5x.png");
                                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.secondaryVerificationFail")+"</p>", '', {
                                    dangerouslyUseHTMLString: true,
                                    confirmButtonText: this.$t("common.confirm"),
                                    center: true,
                                    showClose:false
                                });
                                this.loading = false;
                            }
                            
                        }).catch(() => {
                            this.loading = false;
                        });
                    }
                });
            }else{
                this.editParamValue();
            }
        },
        //安全检查
        safecheck(){
            let params = {
                machineId: this.rdmDetails.machine.MachineId,
                rdmno: this.RDMNo,
                machineSN: this.rdmDetails.machine.MachineSN,
                companyId: this.rdmDetails.machine.CompanyId,
            };
            carApi.CommandToGetSCUStatus(params).then((res) => {
                if(res.Data){
                    this.dialogVisible = false;
                    this.editParamValue();
                }else{
                    const Url = require("@/assets/image/warning1.5x.png");
                    this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.scuFail")+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                    this.loading = false;
                }
               
            });
        },

        dialogCancel(){
            this.clearForm();
            this.dialogVisible = false;
        },
        clearForm(){
            this.secondPassword ={
                password :''
            };
        },
    },
    mounted() {},
    beforeDestroy() {
        this.clearTime();
        this.stopUploadData();
    },
};
</script>
<style lang="less">
.el-tree {
    height: 486px;
    overflow: auto;
}
.parameter-data-box {
    position: relative;
    text-align: left;

    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }

    .parameter-basic-list-box {
        margin-bottom: 30px;
    }
    .parameter-basic-box {
        .parameter-basic-list {
            margin-bottom: 9px;
            height: 68px;
            border: 1px solid #cccccc;
            border-radius: 0px 2px 2px 0px;
            
            &:hover,
            &.isSelected {
                border-color: #3399FF;
                box-shadow: 0px 0px 6px 0px #3399FF;
                background-color: #F1F9FE;
            }

            .parameter-basic-list-icon {
                width: 146px;
                box-sizing: border-box;
                height: 100%;
                background: #f1f9fe;
                border-right: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;

                img {
                    width: 59px;
                    height: 60px;
                }
            }

            .parameter-basic-list-content {
                padding-left: 47px;
                float: left;
                font-size: 14px;
                color: #333;
                margin-top: 15px;
                cursor:pointer;
                .parameter-basic-list-title {
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .parameter-basic-list-desc {
                    p {
                        float: left;
                        margin-right: 40px;
                    }
                }
            }
        }
    }

    .parameter-data-list-box {
        &::after {
            content: "";
            display: block;
            overflow: hidden;
            width: 0;
            height: 0;
            clear: both;
        }
        .parameter-data-list-left {
            width: 30%;
            height: 600px;
            border: 1px solid #f2f2f2;
            float: left;

            .parameter-data-list-left-title {
                height: 48px;
                line-height: 48px;
                padding-left: 14px;
                background: #fafafa;
                border-bottom: 1px solid #f2f2f2;
            }

            .parameter-data-list-left-input {
                margin: 12px;
            }
        }

        .parameter-data-list-right {
            width: 68%;
            height: 600px;
            border: 1px solid #f2f2f2;
            float: right;

            /deep/.el-table th {
                background: #fafafa;
                border-bottom: 1px solid #f2f2f2;
            }
            /deep/.el-table input {
                width: 59px;
            }
        }
    }

    .parameter-data-list-footer {
        margin-top: 20px;
        height: 78px;
        background: #ffffff;
        border-top: 1px solid #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: inline-block;
            width: 110px;
            height: 38px;
            background: #ffffff;
            border: 1px solid #cbcbcd;
            border-radius: 4px;
            cursor: pointer;
            text-align: center;
            line-height: 38px;

            &:last-child {
                width: 250px;
                height: 38px;
                background: #047bc2;
                border-radius: 4px;
                color: #fff;
                margin-left: 20px;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
</style>
