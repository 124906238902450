<template>
  <div class="home">
    <div class="nav-left">
    <NavMenu
          :menus="permissionmenus"
          v-on:menu-click="onMenuClick"
          :isMenuCollapse.sync="isMenuCollapse"
      ></NavMenu>
      <div class="menu-bottom-copyright" >
        <div class="text">©{{ $t("login.CompanyLTD") }}{{ $t("login.AllRight") }}</div>
        <div class="text">{{ $t("login.SuICP") }}19064680{{ $t("login.SuUnit") }}&nbsp;&nbsp;</div>
        <div class="text">{{ $t("login.PublicNetWorkSecurity") }}32059002003120{{ $t("login.SuUnit") }}    </div>
        <div class="text">V2.0.003</div>
      </div>
    </div>
    <div class="nav-right">
      <div class="nav-header">
        <NavMenuSwitch
          v-on:switch-menu-collapse="switchMenuCollapse"
        ></NavMenuSwitch>

        <div style="flex-grow: 1; text-align: left; margin-left: 40px">
          <CompanyDropdown
            v-if="showCompanySwitch"
            :companyItems="companyItems"
          ></CompanyDropdown>
          <span v-else class="nav-header-title">{{menuTitle}}</span>
        </div>

        <NotificationIcon
          :notificationCount="notificationCount"
          :errorMsgList="errorMsgList"
          :warningMsgList="warningMsgList"
          :noticeMsgList="noticeMsgList"
          :privilege="notificationPrivilege"
          v-on:notification-event="notificationEventCallback"
        ></NotificationIcon>
        <UserDropdown
          :userName="userName"
          :dropdownItems="dropdownItems"
          v-on:dropdown-item-click="dropDownItemClick"
        ></UserDropdown>
        <LanguageDropdown
          :languageItems="languageItems"
          v-on:language-dropdown-item-click="languageDropDownItemClick"
        ></LanguageDropdown>
      </div>
      <div class="nav-content">
        <router-view name="main" v-show="!isIframe"></router-view>
        <iframe
          ref="mainIframe"
          :src="iframeSrc"
          frameborder="0"
          width="100%"
          height="100%"
          scrolling="auto"
          v-show="isIframe"
        ></iframe>
        <template>
        <el-backtop target=".nav-content" :bottom="100" :right="5">
          <div  class="scrolltop">
          </div>
        </el-backtop>
        </template>
      </div>
      <!-- <div class="nav-bottom">
        <CopyRight></CopyRight>
      </div> -->
    </div>

    <el-dialog
      title="Notification"
      :visible.sync="notificationDialogVisible"
      width="40%"
    >
      <div>
        <div>{{ notification.time }}</div>
        <div>{{ notification.notificationType }}</div>
        <div>{{ notification.source }}</div>
        <div>{{ notification.content }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="markAsRead(notification)" v-if="notificationPrivilege.MarkRead"
          >{{$t('notification.markAsRead')}}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="changePasswordDialogVisible" width="400px" :title="$t('common.password')" style="text-align:left">
      <el-form :model="passwordModel" ref="passwordRef" style="text-align:center">
        <el-form-item
          :label="$t('common.input_password')"
          :label-width="formLabelWidth"
          prop="password"
        >
          <el-input
            v-model="passwordModel.password"
            :placeholder="$t('common.check_password')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('common.input_new_password')"
          :label-width="formLabelWidth"
          prop="newPassword"
        >
          <el-input
            v-model="passwordModel.newPassword"
            :placeholder="$t('common.check_password')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changePassword">{{
            $t("common.confirm")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="changeSecondPasswordDialogVisible" width="400px" :title="$t('common.secondpassword')" style="text-align:left">
      <el-form :model="secondPasswordModel" ref="secondPasswordRef"  style="text-align:center">
        <el-form-item
          :label="$t('common.input_password')"
          :label-width="formLabelWidth"
          prop="password"
        >
          <el-input
            v-model="secondPasswordModel.password"
            :placeholder="$t('common.check_password')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('common.input_new_password')"
          :label-width="formLabelWidth"
          prop="newPassword"
        >
          <el-input
            v-model="secondPasswordModel.newPassword"
            :placeholder="$t('common.check_password')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changeSecondPassword">{{
            $t("common.confirm")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="changeTimezoneDialogVisible" width="500px"  :title="$t('common.timezone')" style="text-align:left">
      <el-form :model="timezoneModel"  style="text-align:center">
        <el-form-item
          :label="$t('common.select_timezone')"
          :label-width="formLabelWidth"
          prop="timezone"
        >
          <el-select
            style="width: 350px"
            v-model="timezoneModel.code"
            placeholder=""
          >
            <el-option
              v-for="item in timezoneOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="changeUserTimezone">{{
            $t("common.confirm")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import NavMenu from "@/components/layout/NavMenu.vue";
// import CopyRight from "@/components/layout/CopyRight.vue";

import NavMenuSwitch from "@/components/layout/NavMenuSwitch.vue";
import NotificationIcon from "@/components/layout/NotificationIcon.vue";
import UserDropdown from "@/components/layout/UserDropdown.vue";
import LanguageDropdown from "@/components/layout/LanguageDropdown.vue";

import authApi from "@/api/autogen/v1/login";
import notificationApi from "@/api/autogen/v2/notification";
import userApi from "@/api/autogen/v2/user";
import { mapActions, mapGetters } from "vuex";

import { menusDefinition } from "./menus";
// console.log(menusDefinition);

import CompanyDropdown from "@/components/layout/CompanyDropdown.vue";
import companyApi from "@/api/autogen/v2/orgnization";
import { ifGrantedTheOperationForNotificationFunction} from '@/utils';
export default {
  name: "Main",
  components: {
    NavMenu,
    CompanyDropdown,
    NavMenuSwitch,
    NotificationIcon,
    UserDropdown,
    LanguageDropdown,
  },
  data() {
    return {
      changePasswordDialogVisible: false,
      changeTimezoneDialogVisible: false,
      changeSecondPasswordDialogVisible:false,
      isMenuCollapse: false,

      isIframe: false,
      iframeSrc: "",

      userName: "Curits Admin",

      dropdownItems: [],
      languageItems: [],
      TotalMenus: [],
      permissionmenus: [],
      notificationCount: 100,
      errorMsgList: [],
      warningMsgList: [],
      noticeMsgList: [],
      notificationDialogVisible: false,
      notification: {},

      passwordModel: {
        password: "",
        newPassword: "",
      },
      secondPasswordModel: {
        password: "",
        newPassword: "",
      },
      formLabelWidth: "120px",

      timezoneOptions: [],
      userTimezone: {},
      timezoneModel: {
        code: "",
      },

      companyItems: [],
      menuTitle:'',
      notificationPrivilege: {
                View: false,
                MarkRead: false,
                MarkAllRead: false,                
            }
    };
  },
  computed: {
    ...mapGetters([
      "companyId",
      "userEntity",
      "topNCountForNotification",
      "language",
    ]),

    showCompanySwitch() {
      if (this.userEntity) {
        let currentView = this.$route.name;
        let viewSupported = "";
        if((currentView === "HomePage" || currentView === "MachineTypeManage" || currentView === "FaultList"
        || currentView === "FaultAnalysis" || currentView==="RDMManage" || currentView==="RDMAssign") && this.userEntity.CompanyId == 0 && !this.isIframe){
          viewSupported = currentView;
        }
        return viewSupported;
      }
      return false;
    },
  },
  created() {
    if (!window.VERSION) {
      window.VERSION = "/V1";
    }
    if (this.userEntity) {
      this.userTimezone = this.userEntity.TimezoneSetting;
    }
    this.TotalMenus = menusDefinition;
    console.log("TotalMenus1:", this.TotalMenus);   
    this.buildDropdownItems();
    this.buildLanguageItems();
    this.buildMenus();

    this.buildCompanyItems();
    
  },
  mounted() {
    var self = this;
    window.addEventListener("iframe_message", this.handleIframeMessage);
    this.mainIframe = this.$refs.mainIframe;

    setTimeout(() => {
      self.$router.push({
        name: "HomePage",
      });
    }, 100);

    self.fetchNotifications();
    self.refreshInterval = setInterval(() => {
      self.fetchNotifications();
    }, 10000);
  },
  destroyed() {
    let self = this;
    if (self.refreshInterval) {
      clearInterval(self.refreshInterval);
    }
  },
  methods: {    
    ...mapActions(["setLanguage", "setUserEntity","setCurrentCompany"]),
    ifGrantedTheOperationForNotificationFunction,
    initialFunctionOperationNotificationPrivilege()
    {
      this.notificationPrivilege.View = ifGrantedTheOperationForNotificationFunction("View");
      this.notificationPrivilege.MarkRead = ifGrantedTheOperationForNotificationFunction("MarkRead");
      this.notificationPrivilege.MarkAllRead = ifGrantedTheOperationForNotificationFunction("MarkAllRead");
   },
    fetchNotifications() {
      var self = this;
      let param = {
        CompanyId: this.companyId,
        TopNCount: this.topNCountForNotification,
      };
      //fetch notification
      notificationApi
        .queryUnReadNotificationList(param)
        .then((resp) => {
          // console.log("QueryUnReadNotificationList:", resp);
          if (resp) {
            var arr = [];
            var errorArr = [];
            var warningArr = [];
            var noticeArr = [];
            self.notificationCount = resp.TotalCount;
            arr = resp.Data;
            for (var i = 0; i < arr.length; i++) {
              if (arr[i].notificationType == 1) {
                noticeArr.push(arr[i]);
              }
              if (arr[i].notificationType == 2) {
                warningArr.push(arr[i]);
              }
              if (arr[i].notificationType == 3) {
                errorArr.push(arr[i]);
              }
            }
            this.$data.noticeMsgList = noticeArr;
            this.$data.warningMsgList = warningArr;
            this.$data.errorMsgList = errorArr;
          }
        })
        .catch((err) => {
          self.$message.error(err);
        });
    },
    buildMenus() {
      let self = this;

      if (!self.userEntity) {
        self.logOut();
        return;
      }

      this.userName = self.userEntity.UserName;

      authApi
        .searchPermissionList({})
        .then((resp) => {
          console.log("SearchPermissionList:", resp);          
          if (resp.length > 0) {
            let language = this.language;
            console.log("current language:", language);
            //筛选有权限的菜单
            self.permissionmenus=[];
            self.TotalMenus.forEach((m) => {
              if(self.CheckPermision(m,resp))
              {                
                if (m.children && m.children.length>0) {
                  //console.log("children==:", m.children);
                  let childrenTmp=[];
                  m.children.forEach((subm) => {
                    if(self.CheckPermision(subm,resp))
                      childrenTmp.push(subm);
                  });
                  if(childrenTmp.length>0)
                    m.children=childrenTmp;
                }
                self.permissionmenus.push(m);
              }
               
            });
            console.log("permissionmenus:", self.permissionmenus);
            //菜单V1版本和vue版本的转换
            self.permissionmenus.forEach((m) => {
              self.matchMenuV1(m, resp, language);
              
              if (m.children) {
                m.children.forEach((subm) => {
                  self.matchMenuV1(subm, resp, language);
                });
              }              
            });

            console.log("TotalMenus:", self.TotalMenus);            
            //将功能点权限写入sessionStorage
            for (var i = 0; i < resp.length; i++) {
              sessionStorage.setItem(resp[i].MenuId, resp[i].FunctionalPoint);
            }
            this.initialFunctionOperationNotificationPrivilege();
          }
        })
        .catch((err) => {
          self.$message.error(err);
        });
    },
    CheckPermision(m, permissionMenus) {
      let findMenu = permissionMenus.find((item) => {
        return item.MenuId == m.menuId;
      });
      if (findMenu) 
        return true; 
      else if (m.menuId=="0000") //首页
        return true;      
      else
        return false;   
    },
    matchMenuV1(m,permissionMenus, language) {
      let findMenu = permissionMenus.find((item) => {
        return item.MenuId == m.menuId;
      });
      if (findMenu && m.type!="route") {
        if (findMenu.MenuLink) {
          m.link = window.VERSION + "/" + findMenu.MenuLink;
        } else {
          m.link = "";
        }
        m.type = "iframe";
        m.icon = findMenu.IconFile;
        m.menuName = language == "ch"? findMenu.MenuName : findMenu.Value;
      } else if (findMenu && m.type=="route"){
        let languageKey = this.$t(m.menuNameKey);
        if (languageKey) {
          m.menuName = languageKey;
        }
      }else if (m.menuId=="0000") {//首页
        console.log("m.menuNameKey",m.menuNameKey);
        let languageKey = this.$t(m.menuNameKey);
        console.log("languageKey",languageKey);
        if (languageKey) {
          m.menuName = languageKey;
        }
      }
      else{
        console.log("not find");
      }      
    },
    buildDropdownItems() {
      this.dropdownItems = [];
      this.dropdownItems.push({
        name: this.$t("common.password"),
        key: "password",
      });
      this.dropdownItems.push({
        name: this.$t("common.secondpassword"),
        key: "secondpassword",
      });
      this.dropdownItems.push({
        name: this.$t("common.timezone"),
        key: "timezone",
      });

      this.dropdownItems.push({
        name: this.$t("common.logout"),
        key: "logout",
      });
    },
    buildLanguageItems() {
      this.languageItems = [];

      let language = this.language;
      let targetLanguage = language == "en" ? "ch" : "en";
      console.log("buildLanguageItems - targetLanguage:",targetLanguage);
      this.languageItems.push({
        name: this.$t("common.switch_language_" + targetLanguage),
        key: "switchLanguage",
      });
    },

    handleIframeMessage(event) {
      const data = event.data;
      console.log("handleIframeMessage:", data);
    },

    onMenuClick(m) {
      console.log("onMenuClick:", m);
      if (m.link) {
        this.menuTitle = this.$t(m.menuNameKey);
        if (m.type == "iframe") {
          this.isIframe = true;
          let currentPath = this.mainIframe.contentWindow.location.pathname;
          if (currentPath != m.link) {
            console.log("new iframe page , reload to ", m.link);
            this.iframeSrc = m.link;

            this.mainIframe.contentWindow.location.reload();
          } else {
            console.log("same iframe page , stay here");
          }
        } else {
          this.isIframe = false;
          let currentRoute = this.$route;
          console.log("currentRoute:", currentRoute);
          let targetRoute = m.link;
          if (currentRoute.name != targetRoute) {
            console.log("new route , reload to ", m.link);
            this.$router.push({
              name: m.link,
            });
          } else {
            console.log("same route , stay here");
          }          
        }
         //此flag 配合控制权限页面的operation 按钮
         sessionStorage.setItem("LastMenuId", m.menuId);
      }
    },

    dropDownItemClick(d) {
      // console.log("dropDownItemClick:", d);
      switch (d.key) {
        case "password":
          this.changePasswordDialogVisible = true;
          break;
        case "secondpassword":
          this.changeSecondPasswordDialogVisible = true;
          break;
        case "timezone":
          this.changeTimezoneDialogVisible = true;
          this.loadTimezoneOptions();
          break;
        case "logout":
          this.logOut();
          break;

        default:
          break;
      }
    },
    languageDropDownItemClick(d) {
      if (d.key == "switchLanguage") {
        let language = this.language;
        let languageValue=0;
        if (language == "en") {
          language = "ch";
          languageValue=0;
        } else if (language == "ch") {
          language = "en";
          languageValue=1;
        }

        var changeLanguageRequest = {
          Language: languageValue,
        };
        userApi.changeUserLanguage(changeLanguageRequest).then((resp) => {
          if (!resp.IsSuccess) {
            this.$message.error(this.$t("common.change_language_error"));
          }else{
            this.setLanguage(language);

            window.location.reload();   
          }
        });
      }
    },
    switchMenuCollapse(d) {
      console.log("switchMenuCollapse:", d);
      this.isMenuCollapse = d;
    },

    notificationEventCallback(payload) {
      console.log("notificationEventCallback:", payload);
      if (payload) {
        if (payload.type === "markAllUnRead") {
          notificationApi
            .clearAllUnReadNotifications({
              CompanyId: this.companyId,
            })
            .then((resp) => {
              this.fetchNotifications();
            })
            .catch((err) => {
              self.$message.error(err);
            });
        }
        if (payload.type === "viewMore") {
          this.$router.push({
            name: "NotificationManage",
          });
        }
        if (payload.type === "viewNotification") {
          this.notification = payload.payload;
          this.notificationDialogVisible = true;
        }
        if (payload.type === "markAsRead") {
          this.markAsRead(payload.payload);
        }
      }
    },
    markAsRead(d) {
      let self = this;
      self.notificationDialogVisible = false;
      notificationApi
        .markAsRead({
          NotificationIds: [d.id],
        })
        .then((resp) => {
          self.fetchNotifications();
        })
        .catch((err) => {
          self.$message.error(err);
        });
    },

    logOut() {
      // debugger;
      this.setUserEntity({});
      sessionStorage.clear();
      this.$router.push({
        name: "Login",
      });
    },
    changePassword() {
      let self = this;
      if (!this.passwordModel.password) {
        this.$message.error(this.$t("common.check_password"));
        return;
      }
      if (!this.passwordModel.newPassword) {
        this.$message.error(this.$t("common.check_new_password"));
        return;
      }
      if (this.passwordModel.newPassword.length < 6) {
        this.$message.error(this.$t("common.check_password_length"));
        return;
      }

      userApi.changePassword(this.passwordModel).then((resp) => {
        if (resp.IsSuccess) {
          self.$message.success(self.$t("common.change_password_success"));
          self.changePasswordDialogVisible = false;
          self.passwordModel = {};
        } else {
          this.$message.error(this.$t("common.change_password_error"));
        }
      });
    },
   changeSecondPassword() {
      let self = this;
      if (!this.secondPasswordModel.password) {
        this.$message.error(this.$t("common.check_password"));
        return;
      }
      if (!this.secondPasswordModel.newPassword) {
        this.$message.error(this.$t("common.check_new_password"));
        return;
      }
      if (this.secondPasswordModel.newPassword.length < 6) {
        this.$message.error(this.$t("common.check_password_length"));
        return;
      }

      userApi.changeSecondPassword(this.secondPasswordModel).then((resp) => {
        if (resp.IsSuccess) {
          self.$message.success(self.$t("common.change_password_success"));
          self.changeSecondPasswordDialogVisible = false;
          self.secondPasswordModel = {};
        } else {
          this.$message.error(this.$t("common.change_password_error"));
        }
      });
    },
    loadTimezoneOptions() {
      let self = this;
      if (!self.timezoneOptions || self.timezoneOptions.length == 0) {
        userApi.getTimezone().then((resp) => {
          //console.log(resp);

          if (resp.IsSuccess) {
            self.timezoneOptions = [];
            resp.Data.forEach((d) => {
              self.timezoneOptions.push({
                value: d.Code,
                label: self.language == "ch"? d.CodeName : d.CodeNameEN,
              });
            });
          }
        });
      }

      self.timezoneModel.code = self.userTimezone;
    },
    changeUserTimezone() {
      let self = this;
      if (self.timezoneModel.code) {
        userApi.changeUserTimezone(self.timezoneModel).then((resp) => {
          if (resp.IsSuccess) {
            self.$message.success(self.$t("common.change_timezone_success"));
            self.changeTimezoneDialogVisible = false;

            self.userEntity.TimezoneSetting = self.timezoneModel.code;
            self.userTimezone = self.timezoneModel.code;
            self.setUserEntity(self.userEntity);
            // self.timezoneModel.code = "";
          } else {
            this.$message.error(this.$t("common.change_timezone_error"));
          }
        });
      }
    },

    buildCompanyItems() {
      let self = this;
      companyApi.getCompaniesByUserPrivilege().then((resp) => {
        let companyItems = resp.Data;
        for (var i = 0; i < companyItems.length; i++) {
          self.companyItems.push({
            CompanyName: companyItems[i].CompanyName,
            CompanyId: companyItems[i].CompanyId,
          });
        }
        self.setCurrentCompany(self.companyItems[0]);
      });
    }, 
    /**
    * @desc 打开车辆组管理
    */
    openMachineGroup(){
        this.isIframe = true;
        this.iframeSrc = "/V1/VehicleGroupAdmin/vehicleGroupList.html";

        //此flag 配合控制权限页面的operation 按钮
        sessionStorage.setItem("LastMenuId", "0306");
        this.mainIframe.contentWindow.location.reload();
    },   
  },
  provide()
  {
    return{
      openMachineGroup:this.openMachineGroup,
      //showCompanySwitch:this.showCompanySwitch
    };
  }
};
</script>
<style lang="less" scoped>
.scrolltop{
  background:url("../assets/image/zhiding01.png") no-repeat 0px 0px;
  cursor: pointer;
  width: 47px;
  height: 100px;
  &:hover {
      background:url("../assets/image/zhiding02.png") no-repeat 0px 0px;
  }
}
.home {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  height: 100vh;
}
.nav-left {
  position: relative;
}
.menu-bottom-copyright {
  position: absolute;
  left: 15px;
  bottom: 15px;
  .text {
    letter-spacing: -0.5px;
    font-size: 9px;
    font-family: Arial, Arial-Regular;
    font-weight: 400;
    text-align: center;
    color: #006699;
  }
}
.nav-right {
  background-color: #f2f2f2;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
.nav-header {
  height: 70px;
  min-height:70px ;
  max-height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: solid 2px #e5e5e5;
  flex-shrink: 0;
  .nav-header-title{
    font-size: 16px;
  }
}
.nav-content {
  background: #f2f2f2;
  flex-grow: 1;
  padding: 10px 10px 5px;
  margin: 0px 18px 16px 16px;
  border-radius: 0px;
  overflow: auto;
}
.nav-bottom {
  /* background: yellow; */
  height: 20px;
  padding: 10px 10px 5px;
  font-size: 12px;
  color: #000000;
}

</style>
