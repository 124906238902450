<template>
<div v-loading="loadingRDM">
    <div class="updgrade-device-box">
        <Card :title="$t('RDMManage.RDMSoftwareVersion')" class="marginBottom">           
            <div class="updgrade-basic-box" slot="content">
                <div
                    class="updgrade-basic-list-box"
                    v-if="
                       rdmDetails.RDM
                    "
                >
                    <div     
                        class="updgrade-basic-list"                
                    >
                        <div class="updgrade-basic-list-icon">
                            <img src="../../assets/image/icon_rdm.png">
                        </div>
                        <div class="updgrade-basic-list-content">
                            <div class="updgrade-basic-list-title">
                                {{rdmDetails.RDMNo}}({{rdmDetails.RDM.NodeId}})
                            </div>
                            <div class="updgrade-basic-list-desc">
                                <p>
                                    <span>{{ $t("myvehicle.latestVersion") }}：</span>
                                    <span>{{rdmDetails.LatestVersion}}</span>
                                </p>
                                <p>
                                    <span>{{ $t("myvehicle.fieldVersion") }}：</span>
                                    <span>{{rdmDetails.FeildDisplayVersion}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    </div>
    <div class="machine-upgrade-box">
        <div class="machine-upgrade-header failed" v-if="upgradeFail">{{this.$t('RDMManage.latestUpgradeFailed')}}:{{UpgradeError}}</div>
        <span class="card-header-title">{{ $t("RDMManage.selectSoftwareVersion") }}:</span><div class="machine-upgrade-header"></div>
        <div class="machine-upgrade-body"  v-loading="loading">
            <div class="machine-upgrade-body-content">
                <div class="machine-body-header">
                    {{ $t('RDMManage.totalVersionCount',{total:tableData.length}) }}
                    <span class="machine-body-header-btn" @click="addNewSoft" v-if ="this.privilege.GrantRDMSoftwareUpgrade"
                        >{{ $t("RDMManage.NewSoftware") }}</span
                    >
                </div>
                <div class="machine-body-content" >
                    <div v-if="tableData.length > 0">
                        <el-radio-group v-model="NewRDMDefId">
                           
                            <el-radio
                                v-for="(item, key) in tableData"
                                :label="item.RDMDefId"
                                :key="key"
                            >
                                {{ item.Version}}
                                <el-popover
                                    placement="right-start"
                                    width="468"
                                    trigger="hover"
                                    popper-class="popover-content-box"
                                >
                                    <div>
                                        <span>{{ $t("RDMManage.software_detail") }}:</span>
                                    </div>
                                    <div >
                                        <p>{{ $t("common.Description") }}:{{item.Remark}}</p>
                                        <p>{{ $t("RDMManage.UploadTime") }}:{{formatTimestampUTC(new Date(item.UTime),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}</p>
                                    </div>
                                    <i
                                        slot="reference"
                                        class="el-icon-question" style="color:#ffc529"
                                    ></i>
                                </el-popover>
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div class="none-data-box" v-else>{{ $t("common.empty") }}</div>
                </div>
            </div>
            <div class="machine-upgrade-body-footer">
                <el-button @click="clear">{{ $t("bizConfig.clear") }}</el-button>
                <el-button type="primary" :disabled="!this.privilege.GrantRDMSoftwareUpgrade? true: false"  @click="Submit">{{ $t("bizConfig.save") }}</el-button>
            </div>
            <PopBox v-if="popShow" :details="popDetails"></PopBox>
        </div>

        <el-dialog
            :title="$t('RDMManage.NewSoftware')"
            :visible.sync="AddNew"
            width="30%"
            class="el-dialog-custom"
            center
        >
            <div class="uploadFile">
                <el-upload ref="FileMenu"
                    list-type="text"
                    :class="[FileFlag?'avatar-uploader FileMenu' : 'avatar-uploader FileMenu emptyFile']"
                    :auto-upload="false"
                    :on-change="handleChangeSoftFile"
                    :file-list="SoftFile"
                    action="#"
                    accept=".fdev">
                    <div class="upload">
                    <div v-if="SoftFile.length>0" >
                        <img :src="imageUrl" class="el-icon-folder-opened"/><div class="Add haveFile">{{SoftFile[0].name}}</div>
                    </div>
                    <i v-else class="el-icon-plus avatar-uploader-icon">
                        <div class="Add">{{$t('RDMManage.NewSoftware')}}</div>
                    </i>
                    </div>
                </el-upload>
            </div>
            <div slot="footer" class="add-soft-footer">
                <el-button @click="AddNew = false"> {{ $t("bizConfig.cancle") }} </el-button>
                <el-button type="primary" @click="submitCheckParam" :disabled="!this.privilege.GrantRDMSoftwareUpgrade||loading? true: false" v-loading="loading"> {{ $t("bizConfig.save") }} </el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('myvehicle.secondaryVerification')"
          :visible.sync="dialogVisible"
          width="430px" style="margin-top: 16vh">
          <el-form ref="secondVerify" :model="secondPassword" label-width="120px" :rules="rules">
              <el-form-item
                :label="$t('common.input_password')"
                prop="password"
              >
               <el-input
                    v-model="secondPassword.password" show-password
                    :placeholder="$t('common.check_password')"
                ></el-input>
              </el-form-item>
           
              <el-form-item>
                <el-col class="dialogFooter">
                  <el-button @click="dialogCancel">{{$t('common.cancel')}}</el-button>
                  <el-button type="primary" @click="submitSCUVerify" >{{$t('addMachine.Submit')}}</el-button>
                </el-col>
              </el-form-item>
          </el-form>
        </el-dialog>
    </div>
</div>
</template>
<script>
import Card from "@/components/ui/Card.vue";
import carApi from "@/api/autogen/v2/carList";
import deviceApi from "@/api/autogen/v2/cardevices";
import carTypeApi from "@/api/autogen/v2/cartype";
import PopBox from "../MyVehicle/components/PopBox.vue";
import { ConstDefinition } from "@/utils/constDefinition";
import { checkExistData,formatTimestampUTC,ifGrantedTheOperationForRDMManageFunction } from "@/utils";
import rdmApi from "@/api/autogen/v2/rdm";
export default {
    name: "",
    props: {
        companyId: {
            type: String,
            default: '',
        },
        RDMNo: {
            type: String,
            default: '',
        },
    },
    components: {Card,PopBox},
    data() {
        return {
            //列表数据
            tableData: [],
            loading: false,
            rdmDetails: {},
            IsBindMachineBind:false,
            RDMDefId:"",
            NewRDMDefId:"",
              //弹出框内容
            popDetails: {
                title: '',
                desc: '',
                detail:'',
                type: 0
            },
            AddNew:false,
            popShow: false,
            upgradeFail : false,
            dialogVisible:false,
            secondPassword :{
                password:''
            },
            timer: null,
            rules: {
                password: [
                    { required: true, message: this.$t('login.check_password1'), trigger: 'blur' },
                ]
            },
            updateParams:{},
            submitType : 0,
            UpgradeError:'',
            SoftFile:[],
            FileFlag:true,
            //上传文件成功后图片路径
            imageUrl: require('@/assets/image/ESEDevicePackage_file_icon.png'),
            softwreUpgradeStatus:[
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Failed'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Success'),
                    value: ConstDefinition.softwreUpgradeStatus.Success,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Processing'),
                    value: ConstDefinition.softwreUpgradeStatus.Processing,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_TimeOut'),
                    value: ConstDefinition.softwreUpgradeStatus.TimeOut,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_SCU_Fail'),
                    value: ConstDefinition.softwreUpgradeStatus.SCU_Fail,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_RDMRefuse'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_RDMRefuse_0x0E,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_File_Summary'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_File_Summary_0x0F,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Transfer_File'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_Transfer_File_0x10_0x12,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_RDMRefuseToUpagrade'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_RDMRefuseToUpagrade_0x13,
                },
                {
                    text: this.$t('myvehicle.softwreUpgradeStatus_Upgrade_Interrupt'),
                    value: ConstDefinition.softwreUpgradeStatus.Failed_Upgrade_Interrupt_0x14,
                }
            ],
            privilege: {
                GrantRDMSoftwareUpgrade:false,
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
    },
    created() {
        this.getRDMLockStatus();
        this.initialFunctionOperationPrivilege();
    },
    methods: {
        formatTimestampUTC,
        ifGrantedTheOperationForRDMManageFunction,
        initialFunctionOperationPrivilege()
        {
            this.privilege.GrantRDMSoftwareUpgrade = ifGrantedTheOperationForRDMManageFunction("RDMSoftwareUpgrade");
            //console.log("call method ifGrantedTheOperationForRDMManageFunction...");  
        },
        //车辆锁定&升级
        getRDMLockStatus() {
            let flag = false;
            let params = {
                RDMNo: this.RDMNo,
            };
            rdmApi.getRDMLockStatus(params).then((res) => {
                if (res.IsSuccess) {
                    let {  IsLocked, IsLockedBySoftwreUpgrade , IsLockedByRDMSoftwreUpgrade,SoftwreUpgradeStatus } = res.Data;
                    
                    if (IsLocked && !IsLockedBySoftwreUpgrade&& !IsLockedByRDMSoftwreUpgrade) {
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("RDMManage.RDM_locked"),
                            desc: this.$t("myvehicle.waiting"),
                            detail:this.$t("myvehicle.lockby",{username:res.Data.LockedUserName}),
                            type: 1,
                        };
                    }
                    else if(IsLocked && IsLockedBySoftwreUpgrade){
                        if(SoftwreUpgradeStatus != 1 &&  SoftwreUpgradeStatus != 2){
                            this.upgradeFail = true;
                            this.UpgradeError = checkExistData(this.softwreUpgradeStatus,SoftwreUpgradeStatus,'value').data.text;
                        }else{
                            this.popShow = true;
                            this.popDetails = {
                                title: this.$t("myvehicle.Machine_upgraded"),
                                desc: this.$t("myvehicle.waiting"),
                                detail: '',
                                type: 2
                            };
                        }
                        
                    }
                     else if(IsLocked && IsLockedByRDMSoftwreUpgrade){
                        if(SoftwreUpgradeStatus != 1 &&  SoftwreUpgradeStatus != 2){
                            this.upgradeFail = true;
                            this.UpgradeError = checkExistData(this.softwreUpgradeStatus,SoftwreUpgradeStatus,'value').data.text;
                        }else{
                            this.popShow = true;
                            this.popDetails = {
                                title: this.$t("RDMManage.RDM_upgraded"),
                                desc: this.$t("myvehicle.waiting"),
                                detail: '',
                                type: 2
                            };
                        }
                        
                    } else {
                        if(SoftwreUpgradeStatus != 1 &&  SoftwreUpgradeStatus != 2){
                            this.upgradeFail = true;
                            this.UpgradeError = checkExistData(this.softwreUpgradeStatus,SoftwreUpgradeStatus,'value').data.text;
                        }
                        console.log("SoftwreUpgradeStatus"+SoftwreUpgradeStatus);                  
                        this.getRDMSoftwareVersion();
                        //this.GetRDMSoftwareList();
                    }
                }
            });
        },
        //预升级后获取升级状态
        getRDMUpgradeStatus(){
            let params = {
                 RDMNo: this.RDMNo,
            };
            rdmApi.getRDMLockStatus(params).then((res) => {
                if (res.IsSuccess) {
                    let { IsLocked ,IsLockedByRDMSoftwreUpgrade,SoftwreUpgradeStatus } = res.Data;
                    if(IsLocked && IsLockedByRDMSoftwreUpgrade){
                        this.popShow = true;
                        this.popDetails = {
                            title: this.$t("RDMManage.RDM_upgraded"),
                            desc: this.$t("myvehicle.waiting"),
                            detail: '',
                            type: 2
                        };
                    }
                    else{
                        this.popShow = false;
                        if(SoftwreUpgradeStatus != 2){                           
                            let content = '';
                            let Url = '';
                            if(SoftwreUpgradeStatus == 1){
                                this.upgradeFail = false;
                                Url = require("@/assets/image/success.png");
                                content= this.$t("common.operationSuccess");
                            }else{
                                this.upgradeFail = true;
                                Url = require("@/assets/image/error_76.png");
                                content= checkExistData(this.softwreUpgradeStatus,SoftwreUpgradeStatus,'value').data.text;
                                this.UpgradeError = content;
                            }               
                            this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: this.$t("common.confirm"),
                                center: true,
                                showClose:false
                            });
                            this.clearTime();
                            this.getRDMDetail();
                        }
                    }
                }
            });
        },
        getRDMDetail() {
            let params = {
                RDMNo: this.RDMNo
            };
            return rdmApi.GetRDMDetail_v2(params).then((res) => {
                if (res.Data) {
                   
                   
                    this.rdmDetails = res.Data;
                    this.RDMDefId =
                        this.rdmDetails.RDMSoftware &&
                        this.rdmDetails.RDMSoftware.RDMDefId;
                    if(this.rdmDetails.machine)
                    {
                        this.IsBindMachineBind=true;
                        if(this.rdmDetails.machine.RDMStatus == ConstDefinition.RDMStatus.Offline){
                            this.popShow = true;
                            this.popDetails = {
                                title: this.$t("myvehicle.RDM_offline"),
                                desc: this.$t("myvehicle.waiting"),
                                detail:'',
                                type: 1,
                            };
                            return;
                        }
                    }                    
                }
            });
        },
       //获取软件列表
        GetRDMSoftwareList(){
            rdmApi.getRDMTotalSoftwareList().then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.tableData=[];
                    for (var i = 0; i < res.Data.length; i++) {                      
                      if(res.Data[i].RDMDefId!=this.RDMDefId)
                      {
                        this.tableData.push(res.Data[i]);
                      }
                      //console.log(this.tableData);
                    }
                }
            });
        },
        //验证软件
        handleChangeSoftFile(file,fileList){
            if (!/\.(fdev)$/.test(file.name)) {
                this.$message({
                    message: this.$t("addMachine.OnlyFDEVFile"),
                    type: "error",
                });
                return;
            } 
            let fd = new FormData();
            let isDefault = 0; 
            fd.append("RDMFile", file.raw);
            fd.append("IsDefault", isDefault);
            rdmApi.CheckIfRDMDefinitionExists(fd).then((res)=>{
                if(res&&res.IsSuccess){
                    if(!res.Data){
                        fileList=[file];
                        this.SoftFile=fileList;
                        this.FileFlag = true;
                    }else{
                      this.$message.error(this.$t('RDMManage.IsSoftware'));  
                    }
                }else{
                    this.$message.error(res.ErrorMsg);
                }
            });           
        },              
        //保存软件
        saveSoftwareAndUpgrade(){
            if(this.SoftFile.length<=0){
                this.$message.error(this.$t("common.selectSoftware"));
                return;
            }
            let fd = new FormData();
            let isDefault = 0;
            fd.append("RDMFile", this.SoftFile[0].raw);
            fd.append("IsDefault", isDefault);
            rdmApi.AddOrEditRDMDefinition(fd).then((res)=>{
                let Url = '';
                let content = '';
                if(res&&res.IsSuccess&&res.Data){
                    //Url = require("@/assets/image/success.png");
                    //content= this.$t("common.operationSuccess");
                    this.NewRDMDefId=res.Data.RDMDefId;
                    this.dialogVisible = false;
                    this.AddNew = false;
                    //this.GetRDMSoftwareList()
                    this.updateParams = {
                        RDMNo: this.RDMNo,
                        RDMDefId: this.NewRDMDefId,
                    };
                    this.RDMUpgrade();
                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("common.operationFailure");
                
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
            });
        },
        // 新增软件保存
        submitCheckParam(){
            if(this.SoftFile.length<=0){
                this.$message.error(this.$t("common.selectSoftware"));
                return;
            }
            if(this.IsBindMachineBind)
            {
                if(this.loading) return;
                this.submitType = 1;
                const Url = require("@/assets/image/warning1.5x.png");
                this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.safeCheck")+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: this.$t("common.confirm"),
                            cancelButtonText: this.$t("common.cancel"),
                            center: true,
                }).then(() => {
                            this.dialogVisible = true;
                }).catch(() => {       
                });
                        
            }
            else{
                this.saveSoftwareAndUpgrade();
            }
        },
        clear() {
            this.NewRDMDefId = '';
        },
        //添加提交版本
        Submit() {
            if(this.loading) return;
            
            if(!this.NewRDMDefId) {
                this.$message.warning(this.$t("myvehicle.selectSoftwareVersion"));
                return;
            }
            this.updateParams = {
                RDMNo: this.RDMNo,
                RDMDefId: this.NewRDMDefId,
            };
            this.submitType = 0;
            if(this.IsBindMachineBind)
            {
                const Url = require("@/assets/image/warning1.5x.png");
                this.$confirm("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.safeCheck")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    cancelButtonText: this.$t("common.cancel"),
                    center: true,
                }).then(() => {
                    this.dialogVisible = true;
                }).catch(() => {       
                });
            }else{
                this.RDMUpgrade();
            }
        },

        RDMUpgrade(){
            rdmApi.RDMUpgrade(this.updateParams).then((res) => {
                let content = '';
                let Url = '';
                if (res.Data) {
                    this.popShow = true;
                    this.popDetails = {
                        title: this.$t("RDMManage.RDM_upgraded"),
                        desc: this.$t("myvehicle.waiting"),
                        detail: '',
                        type: 2
                    };
                    this.initTime();
                }else{
                    Url = require("@/assets/image/error_76.png");
                    content= this.$t("myvehicle.MachineUpgrade_fail");
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });

                }
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
        },

        //初始化定时器
        initTime() {
            this.timer = window.setInterval(() => {
                this.getRDMUpgradeStatus();
            }, 10000);
        },

        //清除定时器
        clearTime() {
            window.clearInterval(this.timer);
            this.timer = null;
        },

        //二次验证
        submitSCUVerify(){
            this.$refs["secondVerify"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        let params = {
                            userId: this.userInfo.UserId,
                            userAccount: this.userInfo.UserAccount,
                            password: this.secondPassword.password,
                        };
                        carApi.SecondPasswordCheckForModify(params).then((res) => {
                            if(res.Data){
                                this.safecheck();
                            }else{
                                const Url = require("@/assets/image/warning1.5x.png");
                                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.secondaryVerificationFail")+"</p>", '', {
                                    dangerouslyUseHTMLString: true,
                                    confirmButtonText: this.$t("common.confirm"),
                                    center: true,
                                    showClose:false
                                });
                                this.loading = false;
                            }
                            
                        }).catch(() => {
                            this.loading = false;
                        });
                    }
                });
        },

        //安全检查
        safecheck(){
            let params = {
                machineId: this.rdmDetails.machine.MachineId,
                rdmno: this.RDMNo,
                machineSN: this.rdmDetails.machine.MachineSN,
                companyId: this.rdmDetails.machine.CompanyId,
            };
            carApi.CommandToGetSCUStatus(params).then((res) => {
                if(res.Data){
                    this.dialogVisible = false;
                    if(this.submitType==1)
                        this.saveSoftwareAndUpgrade();
                    else
                        this.RDMUpgrade();                    
                }else{
                    const Url = require("@/assets/image/warning1.5x.png");
                    this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("myvehicle.scuFail")+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
                this.loading = false;
            });
        },

        //弹框展示
        addNewSoft() {
            this.AddNew = true;     
            this.FileFlag=true;   
        },

        
        dialogCancel(){
            this.clearForm();
            this.dialogVisible = false;
        },
        clearForm(){
            this.secondPassword ={
                password :''
            };
        },
        /**
        * @desc 获取RDM软件版本
        */
        getRDMSoftwareVersion() {
            this.loadingRDM = true;
            let params = {
                RDMNO: this.RDMNo,
            };

            rdmApi.getRDMSoftwareVersion(params).then((res) => {
                this.getRDMDetail();
                this.GetRDMSoftwareList();
                this.loadingRDM = false;
            })
            .catch(() => {
                this.loadingRDM = false;
            });
        },
    },
    beforeDestroy() {
        this.clearTime();
    },
    
};
</script>
<style lang="less" scoped>
.machine-upgrade-box {
    position: relative;
    text-align: left;
    font-size: 14px;

    .machine-upgrade-header {
        font-weight: bold;
        margin-bottom: 20px;
        
    }
    .failed{
        color:#F34863 ;
    }
    .machine-upgrade-body {
        border: 1px solid #ccc;
        border-radius: 2px;
        .machine-upgrade-body-content {
            padding: 0 20px;

            .machine-body-header {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                .machine-body-header-btn {
                    background: #00b194;
                    border-radius: 2px;
                    width: 160px;
                    height: 32px;
                    display: inline-block;
                    color: #fff;
                    line-height: 32px;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .machine-body-content {
                height: 400px;
                padding: 20px 0;
                overflow-y: auto;
                /deep/.el-radio {
                    display: block;
                    margin: 10px auto;
                }
            }
        }
        .machine-upgrade-body-footer {
            height: 78px;
            background: #ffffff;
            border-top: 1px solid #cccccc;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                // display: inline-block;
                width: 110px;
                height: 38px;
                // background: #ffffff;
                // border: 1px solid #cbcbcd;
                // border-radius: 4px;
                // cursor: pointer;
                // text-align: center;
                // line-height: 38px;

                // &:last-child {
                //     height: 38px;
                //     background: #047bc2;
                //     border-radius: 4px;
                //     color: #fff;
                //     margin-left: 20px;
                // }

                // &:hover {
                //     opacity: 0.8;
                // }
            }
        }
    }

    .add-soft-box {
        /deep/ .el-dialog__body {
            padding: 30px 85px;
        }

        /deep/ .el-dialog__footer {
            padding: 0;
        }
        .uploading{
            height: 39px;
        }
        .upload {
            display: inline-block;
            top: 0;
            position: absolute;
            left: 35px;
            padding-top: 92px;
            color: #555555;
            width: 110px;
        }

        .add-soft-footer {
            text-align: center;
            border-top: 1px solid rgba(204, 204, 204, 0.4);
            padding: 20px 0;
        }
    }

    .upload-text-box {
        text-align: center;
        height: 30px;
        background: #f2f2f2;
        border-radius: 3px;
        line-height: 30px;
        color: #666666;
        width: 177px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        position: absolute;
        bottom: 1px;
        left: 1px;
        border-top: 1px dashed #c0ccda;
    }

    /deep/.el-upload--picture-card {
        position: relative;
        width: 180px;
        height: 180px;
        border: 1px solid #ccc;
        background-color: #fff;
        padding: 27% 0;
        line-height: inherit;
        i{
        font-weight: 1000;
        color: #555555;
        }
        .haveFile{
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        padding: 0 10%;
        }
    }

    .hide /deep/.el-upload {
        display: none;
    }
}
.updgrade-device-box {
    position: relative;
    text-align: left;

    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }

    .updgrade-basic-list-box {
        margin-bottom: 30px;
    }
    .updgrade-basic-box {
        .updgrade-basic-list {
            margin-bottom: 9px;
            height: 68px;
            border: 1px solid #cccccc;
            border-radius: 0px 2px 2px 0px;

            .updgrade-basic-list-icon {
                width: 146px;
                box-sizing: border-box;
                height: 100%;
                background: #f1f9fe;
                border-right: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;

                img {
                    width: 59px;
                    height: 60px;
                }
            }

            .updgrade-basic-list-content {
                padding-left: 47px;
                float: left;
                font-size: 14px;
                color: #333;
                margin-top: 15px;
                .updgrade-basic-list-title {
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .updgrade-basic-list-desc {
                    p {
                        float: left;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
}
.card-header-title {
            font-size: 16px;
            color: #333333;
            padding-left: 30px;
            font-weight: bold;
            background: url('../../assets/image/icon.png') left center no-repeat;
        }
</style>