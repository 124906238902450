<template>
  <div class="page">
    <div class="page-contents" style="min-width: 1024px;">
      <div class="row1">
        <div class="online-state">
          <div class="carMain">
            <div class="carMainTop">
              <div class="carOnlineCount">
                <span :title="$t('common.CarOnlineCount')">
                  {{ carStatusStatisticInfo.CarOnlineCount }}
                </span>
              </div>
              /
              <div class="carOfflineCount">
                <span :title="$t('common.CarOfflineCount')">
                  {{ carStatusStatisticInfo.CarOfflineCount }}
                </span>
              </div>
              /
              <div class="carCount">
                <span :title="$t('common.CarTotalCount')">
                  {{ carStatusStatisticInfo.CarCount }}
                </span>
              </div>
            </div>
            <div class="carMainBottom">
              <div class="imgLogo">
                <img src="../../assets/image/icon-car.png" />
              </div>
              <span class="carMainText">{{$t('myvehicle.vehicleInformation')}}</span>
            </div>
          </div>
          <div class="progress">
            <el-progress-ext
              type="semicircle"
              :width="102"
              :percentage="carStatusStatisticPercentage"
              :color="progressColor"
              :backgroundColor="progressBackgroundColor"
            ></el-progress-ext>
          </div>
        </div>
        <div class="RDM-state">
          <div class="RDMMain">
            <div class="RDMMainTop">
              <div class="rdm">
                <span :title="$t('common.RDMConfigCount')">{{ this.rdmConfigInfo.ConfiguredCount }}</span>
              </div>
              /
              <div class="unRdm">
                <span :title="$t('common.RDMUnConfigCount')">{{ this.rdmConfigInfo.UnConfiguredCount }}</span>
              </div>
            </div>
            <div class="RDMMainBottom">
              <img src="../../assets/image/rdm.png" />
              <div class="RDMMainText">{{$t('homepage.RDMNew')}}</div>
            </div>
          </div>
          <div class="progress">
            <el-progress-ext
              type="semicircle"
              :width="102"
              :percentage="rdmStatisticPercentage"
              :color="progressColor"
              :backgroundColor="progressBackgroundColor"
            ></el-progress-ext>
          </div>
        </div>
        <div class="Battery">
          <div class="low">
            <el-dropdown 
              trigger="click">
              <div class="lowBatteryImg">
                <img src="../../assets/image/very low.png" />
                <div class="battery-warning-count" v-if="voltageWarningList&&voltageWarningList.length>0">
                  {{ voltageWarningList.length }}
                </div>
                <div class="battery-warning-count" v-else>
                  0
                </div>
                <el-dropdown-menu slot="dropdown" class="BatterySN" v-if="voltageWarningList&&voltageWarningList.length>0">  
                  <el-dropdown-item >{{$t('addMachine.MachineSN')}}</el-dropdown-item>
                  <el-dropdown-item divided v-for="item in voltageWarningList" :key="item">{{item.MachineSN}}</el-dropdown-item>
                </el-dropdown-menu>
              </div>
            </el-dropdown>
            <div class="lowText">{{$t('homepage.BatteryLevelLow')}}</div>
          </div>
          <div class="veryLow">
            <el-dropdown 
              trigger="click">
              <div class="veryLowBatteryImg">
                <img src="../../assets/image/low.png" />
                <div class="battery-danger-count" v-if="voltageDangerList&&voltageDangerList.length>0">
                  {{ voltageDangerList.length }}
                </div>
                <div class="battery-danger-count" v-else>
                  0
                </div>
              </div>
              <el-dropdown-menu slot="dropdown" class="BatterySN" v-if="voltageDangerList&&voltageDangerList.length>0">
                  <el-dropdown-item >{{$t('addMachine.MachineSN')}}</el-dropdown-item>
                  <el-dropdown-item divided v-for="item in voltageDangerList" :key="item">{{item.MachineSN}}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="veryLowText">{{$t('homepage.BatteryLevelVeryLow')}}</div>
          </div>
        </div>
        <div class="faultStatus">
          <div class="faultMain">
            <div class="faultImg" @click="openFaultManage()">
              <div class="faultCount">
                  {{faultSummary.Count}}
              </div>
            </div>
            <div class="faultBottom" :style="$i18n.locale == 'en'?'width:120px':''">
              <img src="../../assets/image/fault.png" />
              <div class="faultText" :title="$t('homepage.MachineFaultTips')">{{$t('homepage.MachineFault')}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row2">
        <div class="fault-statistic">
          <div class="faultTrendChart-main">
            <div class="faultTrendChart-header">
              <span>{{$t('homepage.VehicleFaultTrend')}}</span>
              <el-dropdown
                :hide-on-click="false"
                placement="bottom-start"
                trigger="click"
              >
                <span class="el-dropdown-link">
                  <i class="el-icon-setting"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-checkbox
                      v-model="isCheckAll.ischeck"
                      @change="selectLegend(isCheckAll)"
                      >{{$t('common.all')}}</el-checkbox
                    ></el-dropdown-item
                  >
                  <el-divider />
                  <el-dropdown-item
                    v-for="item in faultTrendChartLegend"
                    :key="item.label"
                    ><el-checkbox
                      v-model="item.ischeck"
                      @change="selectLegend(item)"
                    >
                      {{ item.label }}</el-checkbox
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <span class="faultTrendChart-time">
                <el-date-picker
                  style="
                    width: 228px;
                    height: 26px;
                    line-height: 20px !important;
                  "
                  v-model="value1"
                  @change="selectDate()"
                  :editable="false"
                  :picker-options="pickerBeginDateAfter"
                  type="daterange"
                  range-separator="~"
                  :start-placeholder="$t('common.startTime')"
                  :end-placeholder="$t('common.endTime')"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </span>
            </div>
            <div class="faultTrendChart" ref="faultTrendChart"></div>
          </div>
          <div class="faultCategoryChart-main">
            <div class="faultCategoryChart-header">
              <span>{{$t('homepage.FaultStatistic')}}</span>
              <span class="faultCategoryChart-time">
                <el-dropdown
                  placement="bottom-start"
                  @command="handleSelectCommand"
                >
                  <span>
                    {{ sta_dropdown_select.name
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      class="dropdownItem"
                      v-for="item in sta_dropdownItems"
                      :key="item.name"
                      :command="item"
                      style="text-align: center"
                    >
                      {{ item.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>
            <div class="faultCategoryChart" ref="faultCategoryChart"></div>
          </div>
        </div>
      </div>

      <div class="row3">
        <el-card class="VehicleOverview">
          <div class="car-list">
            <div class="carMapHeader">
              <div class="carMapHeaderTitle">{{$t('homepage.VehicleOverview')}}</div>
              <div class="carMapHeaderDropdownList">
                <div class="carMapHeaderDropdown">
                  <!-- <div>{{ currentCompany.CompanyName }}</div> -->
                  <!-- <el-dropdown @command="selectCompany">
                    <span class="el-dropdown-link">
                      {{ companyName
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="data in companyList"
                        :key="data.CompanyId"
                        :command="data"
                        >{{ data.CompanyName }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown> -->
                </div>
                <div class="carMapHeaderDropdown">
                  <el-dropdown @command="selectCarNo">
                    <span class="el-dropdown-link">
                      {{ carNo
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="data in carNoList"
                        :key="data"
                        :command="data"
                        >{{ data }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="carMapHeaderDropdown">
                  <el-dropdown @command="selectCarStatus">
                    <span class="el-dropdown-link">
                      {{ status
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="data in carStatusList"
                        :key="data.value"
                        :command="data"
                        >{{ data.label }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <div v-if="carList.length == 0" class="noVehicleView">
              <div><img :src="noVehicleView" /></div>
              <div>{{$t('homepage.NoMahcinesOnline')}}</div>
            </div>
            <div
              :class="[carList.length > 0 ? 'carMapAndCarList' : 'carListNone']"
            >
              <div id="map" class="carMap"></div>
              <div :class="[carList.length > 5 ? 'carList' : 'carListSmall']">
                <div class="carListInfo">
                  <div
                    v-for="c in carList"
                    :key="c.CarId"
                    :class="[
                      c.Fault != '' || c.CarBundleStatus == 2
                        ? 'car-item error'
                        : 'car-item',
                    ]"
                    @click="setAsCurrentCar(c)"
                  >
                    <div class="carItemInfo">
                      {{ c.RDMNO }}
                    </div>
                    <div class="carItemInfo">
                      {{ c.MachineSN }}
                    </div>
                    <div class="carItemInfo carItemInfoStatus">
                      <i
                        v-if="c.Fault != '' || c.CarBundleStatus == 2"
                        class="fa fa-exclamation-triangle warning"
                      ></i>
                      <div
                        v-if="c.CarBundleStatus == 1 && c.Fault == ''"
                        class="carItemInfoStatusIconOnline"
                      ></div>
                      <div v-else class="carItemInfoStatusIconOffline"></div>
                      <div
                        class="carItemInfoStatusTextOffline"
                        v-if="c.CarBundleStatus == 0"
                      >
                      {{ $t("common.carState_offline") }}
                      </div>
                      <div
                        :class="[
                          c.Fault == ''
                            ? 'carItemInfoStatusTextOnline'
                            : 'carItemInfoStatusTextOffline',
                        ]"
                        v-if="c.CarBundleStatus == 1"
                      >
                      {{ $t("common.carState_online") }}
                      </div>
                      <div
                        class="carItemInfoStatusTextOffline"
                        v-if="c.CarBundleStatus == 2"
                      >
                      {{ $t("common.carState_error") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination">
                  <div
                    v-if="carSearch.total > carSearch.pageSize"
                    class="paginationCount"
                  >
                    {{ (carSearch.pageIndex - 1) * carSearch.pageSize + 1 }}-{{
                      carSearch.pageIndex * carSearch.pageSize
                    }}
                    {{ $t("common.Page_of") }} {{ carSearch.total }} {{ $t("common.Pages") }}
                  </div>
                  <div class="paginationDiv">
                    <el-pagination
                      background
                      @current-change="handleCurrentChange"
                      :hide-on-single-page="true"
                      layout="prev, pager, next"
                      :total="carSearch.total"
                    >
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!--Dialog车辆详情弹窗-->
    <el-dialog
      :visible.sync="dialogVisible"
      :modal="false"
      width="466px"
      append-to-body
    >
      <div class="CarType">{{ CarType }}</div>
      <img class="image1" src="../../assets/image/icon-rdm.png" />
      <div class="RDM" v-if="carInfo.RDM">
        {{ carInfo.RDM.RDMNo }} <br />
        <div class="carInfoRDM">{{ carInfo.RDM.ModelNumber }}</div>
      </div>
      <div v-for="item in carInfo.DeviceList" :key="item.DeviceId">
        <img class="image3" src="../../assets/image/icon-controller.png" />
        <div class="Motor">
          {{ item.NodeId }}<br />
          <div class="carInfoRDM">{{ item.DeviceName }},{{ item.Remark }}</div>
        </div>
      </div>
      <div class="Voltage-page">
        <div class="Voltage-title">
          {{$t('homepage.Latest5minsVoltage')}}<!--i class="fa fa-gear gear"></i-->
        </div>
        <div ref="monitorLineChart" class="monitorLineChart"></div>
      </div>
      <div class="link">
        <!--router-link to="">View vehicle details</router-link
        ><i class="fa fa-question-circle-o question"></i-->
        <span class="qrcode"  @click="openMachineDetails(carInfo.RDMProperties.machine.CompanyId,carInfo.RDMProperties.machine.MachineId)" >
         {{$t('common.vehicleDetail')}}
        </span>   
        <br /> 
        <span v-if="privilege.GrantRDMFunction" class="qrcode"  @click="openRDMDetails(carInfo.RDMProperties.machine.CompanyId,carInfo.RDM.RDMNo)" >
          {{$t('RDMManage.RDM_Detail')}}
         </span>   
         <br /> 
        <!--router-link to="">Real-time monitoring</router-link
        ><i class="fa fa-question-circle-o question"></i><br />
        <router-link to="">Map location</router-link
        ><i class="fa fa-question-circle-o question"></i><br />
        <router-link to="">Lock vehicle</router-link
        ><i class="fa fa-question-circle-o question"></i-->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import homepageApi from "@/api/autogen/v2/homepage";
import carApi from "@/api/autogen/v2/car";
import geoFenceApi from "@/api/autogen/v2/geoFence";
import monitorApi from "@/api/autogen/v2/parametermonitor";
import geoApi from "@/api/autogen/v2/cargeo";
import orgnizationApi from "@/api/autogen/v2/orgnization";

import "@/assets/css/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import MapboxWordViewControl from "@/components/geo/mapboxWordViewControl";
import GeoFenceControl from "@/components/geo/geoFenceControl";

import { ConstDefinition } from "@/utils/constDefinition";
import { mapGetters } from "vuex";

import ElProgressExt from "@/components/ui/ElProgressExt.vue";
import {formatTimestampUTC,formatTimeStr,ifGrantedTheOperationForRDMManageFunction} from '@/utils';
export default {
  name: "Homepage",
  components: {
    ElProgressExt,
  },
  data() {
    let _vue = this;
    return {
      //公司列表
      companyList: [],
      //车辆状态列表
      carStatusList: [
        { label: "不限制", value: -1 },
        { label: "离线", value: 0 },
        { label: "在线", value: 1 },
        { label: "故障", value: 2 },
      ],

      currentCompany: {},
      carNo: this.$t("common.carNo"),
      status:  this.$t("myvehicle.carStatus"),
      //carNo列表
      carNoList: [],
      carStatus: 0,
      //车辆列表查询条件
      carSearch: {
        pageSize: 10,
        pageIndex: 1,
        total: 0,
        orderBy: [
          {
            name: "",
            orderBy: 0,
          },
        ],
        companyIdFilter: [],
        carBundleStatusFilter: -1,
        machineSNFilter: [],
      },

      defaultMapCenterPoint: [120.58586216387619, 31.203448584425516], //默认地图中心点的位置
      defaultMapShowLevel: 14, //默认地图显示级别
      mapInstance: null, //地图对象
      noVehicleView: require("@/assets/image/no-vehicle-view.png"),
      carAddress: null, //车辆位置标记

      dialogVisible: false, //车辆详情弹窗是否打开
      CarType: "",
      //车辆详情页面数据
      carInfo: {
        RDM: {},
        DeviceList: [],
      },
      monitorLineChart: null,
      flag_showGeoFenceAsMarkerOnMap: false, //是否在地图上显示电子围栏
      geoFencePolygonList: [], // 电子围栏polyline对象列表

      sta_dropdownItems: [],
      sta_dropdown_select: { name: this.$t("common.all"), key: "all" },
      isCheckAll: {
        ischeck: true,
        label: "all",
      },
      checked: false,
      faultTrendChartLegend: [],

      percentageProgressWidth: 80,
      carStatusStatisticInfo: {},
      rdmConfigInfo: {},
      faultSummary: {},
      voltageWarningInfo: [],
      faultStatistic: {},
      carList: [],
      currentCar: {},

      faultTrendChartInstance: null,
      faultCategoryChartInstance: null,

      refreshIntervalMilliseconds: 30 * 1000,

      value1: "",
      timeOptionRange: null, // 30天的参数控制
      pickerBeginDateAfter: {
        disabledDate(time) {
          let secondNum = 1000 * 60 * 60 * 24 * 30;
          return (
            time.getTime() < new Date("2000/08/01").getTime() ||
            time.getTime() < new Date(new Date() - secondNum).getTime() ||
            (_vue.timeOptionRange &&
              (time.getTime() > _vue.timeOptionRange.getTime() + secondNum ||
                time.getTime() < _vue.timeOptionRange.getTime() - secondNum))
          );
        },
        onPick(time) {
          if (time.minDate && !time.maxDate) {
            _vue.timeOptionRange = time.minDate;
          }
          if (time.maxDate) {
            _vue.timeOptionRange = null;
          }
        },
      },
      progressColor: "#0079C1",
      progressBackgroundColor: "#04223A",
      markConfig: {
                //正常
                normal: {
                    color: '#007AC2',
                    background: '#1286C4',
                    rectBack: '#6BB0A6',
                    text: this.$t("common.carState_online"),
                },
                //锁车
                passing: {
                    color: '#FF9A30',
                    background: '#FDA140',
                    rectBack: '#E5BC65',
                    text: this.$t("common.carLockStatus_Lock"),
                },
                //离线
                offline: {
                    color: '#9A9A9A',
                    background: '#747371',
                    rectBack: '#B1BC99',
                    text: this.$t("common.carState_offline"),
                },
                //故障
                fault: {
                    color: '#FF3030',
                    background: '#FD423F',
                    rectBack: '#E58965',
                    text: this.$t("common.carState_fault"),
                },
      },
      privilege: {
                GrantRDMFunction:false,
            },
    };
  },
  computed: {
    userInfo() {
            return this.$store.state.userEntity;
        },
    currentChooseCompany(){
            return this.$store.state.companyEntity;
    },
    rdmStatisticPercentage() {
      return this.calculatePercent(
        this.rdmConfigInfo.ConfiguredCount,
        this.rdmConfigInfo.AllCount,
        0
      );
    },
    carStatusStatisticPercentage() {
      return this.calculatePercent(
        this.carStatusStatisticInfo.CarOnlineCount,
        this.carStatusStatisticInfo.CarCount,
        0
      );
    },
    voltageWarningList() {
      var list = [];
      if (this.voltageWarningInfo) {
        this.voltageWarningInfo.forEach((v) => {
          if (v.VoltageState == ConstDefinition.VoltageState.Warning) {
            list.push(v);
          }
        });
      }
      return list;
    },
    voltageDangerList() {
      var list = [];
      if (this.voltageWarningInfo) {
        this.voltageWarningInfo.forEach((v) => {
          if (v.VoltageState == ConstDefinition.VoltageState.Danger) {
            list.push(v);
          }
        });
      }
      return list;
    },    
    ...mapGetters(["companyId", "userEntity", "language"]),
  },
  created() {
    let self = this;

    self.$bus.on("company-switch", self.companySwitch);
    //alert(this.currentChooseCompany.CompanyId);
    let p1 = self.getCompanyList();
    Promise.all([p1]).then(function (resp) {
      self.refreshData();
      self.refreshDataInterval = setInterval(
        self.refreshData,
        self.refreshIntervalMilliseconds
      );
    });

    self.build_Sta_DropdownItems();
    self.initialFunctionOperationPrivilege();
  },
  mounted() {
    let self = this;
    setTimeout(self.refreshFaultChart, 200);
    self.refreshFaultChartInterval = setInterval(
      self.refreshFaultChart,
      self.refreshIntervalMilliseconds
    );
    //加载地图， 地图加载完成后会触发加载电子围栏信息
    setTimeout(() => {
      self.initmap();
    }, 500);

    window.onresize = function () {
      self.faultTrendChartInstance.resize();
      self.faultCategoryChartInstance.resize();
    };
  },
  beforeDestroy() {
    this.$bus.off("company-switch", this.companySwitch);
  },
  destroyed() {
    console.log("destroyed");
    let self = this;
    console.log("refreshDataInterval");
    console.log(self.refreshDataInterval);  
    if (self.refreshDataInterval) {
      clearInterval(self.refreshDataInterval);
    }
    console.log("refreshFaultChartInterval");
    console.log(self.refreshFaultChartInterval);
    console.log("destroyed======");
    if (self.refreshFaultChartInterval) {
      clearInterval(self.refreshFaultChartInterval);
    }
  },
  methods: {
    formatTimeStr,
    ifGrantedTheOperationForRDMManageFunction,
    initialFunctionOperationPrivilege()
    {
            this.privilege.GrantRDMFunction = ifGrantedTheOperationForRDMManageFunction("View");
            console.log("this.privilege.GrantRDMFunction==");
            console.log(this.privilege.GrantRDMFunction);
    },
    //跳转故障管理
    openFaultManage(){
      this.$router.push({
        name: "FaultList"
      });
    },
    selectDate() {
      this.refreshFaultBarChart();
    },
    selectLegend(d) {
      const chartInstance = this.faultTrendChartInstance;
      if (d.label === "all" && d.ischeck) {
        this.faultTrendChartLegend.forEach(function (item) {
          item.ischeck = true;
        });
        chartInstance.dispatchAction({
          type: "legendAllSelect",
        });
      } else if (d.label === "all" && !d.ischeck) {
        chartInstance.dispatchAction({
          type: "legendAllSelect",
        });
        chartInstance.dispatchAction({
          type: "legendInverseSelect",
        });
        this.faultTrendChartLegend.forEach(function (item) {
          item.ischeck = false;
        });
      } else {
        chartInstance.dispatchAction({
          type: "legendToggleSelect",
          name: d.label,
        });

        var array = this.faultTrendChartLegend;
        var count1 = 0;
        var count2 = 0;
        for (let index = 1; index < array.length; index++) {
          const element = array[index];
          if (element.ischeck) {
            count1++;
          } else {
            count2++;
          }
        }
        if (count1 === array.length - 1) {
          this.isCheckAll.ischeck = true;
        }
        if (count2 === array.length - 1) {
          this.isCheckAll.ischeck = false;
        }
      }
    },
    companySwitch(evtPayload) {
      this.carSearch.companyIdFilter = [evtPayload.CompanyId];
      this.currentCompany = evtPayload;

      this.refreshData();
      this.selectCompany(evtPayload);
      this.showGeoFenceAsMarkerOnMap();
    },
    async  refreshFaultChart()
    {
      this.refreshFaultBarChart();
      this.refreshFaultPieChart();
    },
    async refreshFaultBarChart() {
      let self = this;
      if (self.faultTrendChartInstance === null) {
        self.faultTrendChartInstance = echarts.init(self.$refs.faultTrendChart);       
        self.faultTrendChartInstance.resize();
      }
      let faultTrendData = {};
      let faultTrend_xAxis = [];
      let faultCategoryData = {};
      self.faultTrendChartInstance.setOption({},true);
      console.log("self.value1[0]",self.value1[0]);
      let startDay=null;
      let endDay=null;
      if(self.value1[0]!=undefined)
        startDay=self.value1[0];
      if(self.value1[1]!=undefined)
        endDay=self.value1[1];
      let QueryParams={
        CompanyId:self.currentCompany.CompanyId,
        StartDay:startDay,
        EndDay:endDay,
        FaultTopN:5
      };
      //console.log("QueryParams",QueryParams);
      await homepageApi.getFaultStatistic(QueryParams).then((resp) => {
        if (resp && resp.IsSuccess) {
          self.faultStatistic = resp.Data;
          if (self.faultStatistic.DailyDataList) {
            var arr = [];
            self.faultStatistic.DailyDataList.forEach((d) => {
              var wd = d.WorkDay.substr(0, 10);
              //console.log("wd=======",wd);
              if (arr.includes(d.FaultType) === false) {
                arr.push(d.FaultType);
              }
              if (!this.value1) {
                if (faultTrend_xAxis.includes(wd) === false) {
                  faultTrend_xAxis.push(wd);
                }
                /*if (faultTrendData[d.FaultType] === undefined) {
                  faultTrendData[d.FaultType] = [];
                }
                faultTrendData[d.FaultType].push(d.Count);*/
              } else {
                if (wd >= this.value1[0] && wd <= this.value1[1]) {
                  if (faultTrend_xAxis.includes(wd) === false) {
                    faultTrend_xAxis.push(wd);
                  }
                  /*if (faultTrendData[d.FaultType] === undefined) {
                    faultTrendData[d.FaultType] = [];
                  }
                  faultTrendData[d.FaultType].push(d.Count);*/
                }
              }                         
            });

            console.log("faultTrend_xAxis==",faultTrend_xAxis);
            console.log("faultTrendData==",faultTrendData);
            console.log("arr==",arr);
            
            arr.forEach((ft)=> {              
              faultTrend_xAxis.forEach((wd) => {
                let count=0;
                self.faultStatistic.DailyDataList.forEach((d) => {
                    
                      if(wd==d.WorkDay.substr(0, 10) && d.FaultType==ft)
                      {
                        if (faultTrendData[d.FaultType] === undefined) {
                          faultTrendData[d.FaultType] = [];
                        }
                        faultTrendData[d.FaultType].push(d.Count);
                        return;
                      }else{
                        count++;
                      }

                });
                if(count==self.faultStatistic.DailyDataList.length)
                {
                  if (faultTrendData[ft] === undefined) {
                          faultTrendData[ft] = [];
                  }
                  faultTrendData[ft].push(0);
                  console.log("FaultType push 0==",ft);
                }
              });
            });
            this.setfaultTrendChartLegend(arr);
          }
        }
      });
      
      if (faultTrend_xAxis.length > 0) {
        let unit=this.$t("common.Frequency");
        let xSeries=this.$t("common.Day");
        let option = {
          legend: {
            show: false,
          },
          grid: {
            top: "10",
            left: "29",
            right: "0",
            bottom: "34",
            containLabel: true,
          },
          tooltip: {
            trigger: "item",
            position: "top",
            formatter: function (param){
              //console.log("param",param.length);
              return(
                    param.name+":</br>"+"<font color="+param.color+">&nbsp;●&nbsp;</font>"+param.seriesName+":"+param.value +" "+unit+"</br>"
              );
            },
          },
          dataset: {
            source: [],
          },
          xAxis: {
            type: "category",
            data: faultTrend_xAxis,
            name:unit
          },
          yAxis: {name:xSeries},
          // Declare several bar series, each will be mapped
          // to a column of dataset.source by default.
          series: [],
        };
        for (const key in faultTrendData) {
          option.series.push({
            name: key,
            data: faultTrendData[key],
            type: "bar",
            barWidth: "6",
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          });
        }
        //console.log("faultTrendChart option==",option);
        self.faultTrendChartInstance.setOption(option);
      }     
    },

    async refreshFaultPieChart() {
      let self = this;
      if (self.faultCategoryChartInstance === null) {
        self.faultCategoryChartInstance = echarts.init(
          self.$refs.faultCategoryChart
        );
        self.faultCategoryChartInstance.resize();
      }

      let faultTrendData = {};
      let faultTrend_xAxis = [];
      let faultCategoryData = {};
      self.faultCategoryChartInstance.setOption({},true);

      const todayTime = formatTimeStr(new Date(),'YYYY-MM-DD');
      let weekStartDate= new Date(
        new Date() - (new Date().getDay() - 1) * 86400000
      );
      const weekStart =formatTimeStr(weekStartDate,'YYYY-MM-DD');
        

      let startDay=null;
      let endDay=null;

      switch (this.sta_dropdown_select.key) {               
              case "today":
                startDay=todayTime;
                break;
              case "thisWeek":
                startDay=weekStart;
                break;
              case "thisMonth":
                startDay=todayTime.substr(0, 7)+"-01";
                break;
              case "thisYear":
                startDay=todayTime.substr(0, 4)+"-01-01";               
                break;
              default:
                  break;
      }
      endDay=todayTime;
      let QueryParams={
        CompanyId:self.currentCompany.CompanyId,
        StartDay:startDay,
        EndDay:endDay,
        FaultTopN:5
      };
    console.log("startDay",startDay);
    console.log("endDay",endDay);
    //console.log("QueryParams",QueryParams);
    await homepageApi.getFaultStatistic(QueryParams).then((resp) => {
        if (resp && resp.IsSuccess) {
          self.faultStatistic = resp.Data;
          if (self.faultStatistic.DailyDataList) {
            var arr = [];
            self.faultStatistic.DailyDataList.forEach((d) => {
              var wd = d.WorkDay.substr(0, 10);
                      
              switch (this.sta_dropdown_select.key) {               
                case "all":
                  this.setFaultCategoryData(faultCategoryData, d);                  
                  break;
                case "today":
                  if (wd === todayTime) {
                    this.setFaultCategoryData(faultCategoryData, d);
                  }
                  break;
                case "thisWeek":
                  if (wd > weekStart && wd <= todayTime) {
                    this.setFaultCategoryData(faultCategoryData, d);
                  }
                  break;
                case "thisMonth":
                  if (wd.substr(0, 7) === todayTime.substr(0, 7)) {
                    this.setFaultCategoryData(faultCategoryData, d);
                  }
                  break;
                case "thisYear":
                  if (wd.substr(0, 4) === todayTime.substr(0, 4)) {
                    this.setFaultCategoryData(faultCategoryData, d);
                  }
                  break;
                default:
                  break;
              }
            });
          }
        }
      });
      console.log("faultCategoryData Pie=",faultCategoryData);     
      if (faultCategoryData) {
        let unit=this.$t("common.Frequency");
        let option = {
          tooltip: {
            trigger: "item",
            position: "top",
            formatter: function(a)
            {
                            
              return(
                        "<font color="+a.color+">&nbsp;●&nbsp;</font>"+a.name+":<br/>"+a.value+" "+unit +" ("+a.percent.toFixed(2) +"%)"
              );
            },
          },
          legend: {
            top: "center",
            icon: "circle",
            align: "right",
            left: "left",
            orient: "vertical",
            formatter:function(param)
            {
                  return self.formatPieLegendOrXYSeriesLabel(param,15);
            },
          },

          series: [
            {
              type: "pie",
              left: "40%",
              width: "131px",
              radius: ["70%", "120%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderColor: "#fff",
                borderWidth: 6,
              },
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  //show: true,
                  fontSize: "14",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [],
            },
          ],
        };

        let data = option.series[0].data;
        for (const key in faultCategoryData) {
          data.push({
            name: key,
            value: faultCategoryData[key],
          });
        }
        self.faultCategoryChartInstance.setOption(option);
      }
    },
    setfaultTrendChartLegend(arr) {
      const self = this;
      let tempArr = [];
      if (arr) {
        arr.forEach(function (item) {
          let temp = {
            ischeck: true,
            label: item,
          };
          tempArr.push(temp);
        });
        self.$data.faultTrendChartLegend = tempArr;
      }
    },

    setFaultCategoryData(arr, data) {
      if (arr[data.FaultType] === undefined) {
        arr[data.FaultType] = 0;
      }
      arr[data.FaultType] += data.Count;
    },
    calculatePercent(val, total) {
      if (!total) return 0;

      let percent = ((val * 100) / total).toFixed(1);
      return parseFloat(percent);
    },
    refreshData() {
      let self = this;
      homepageApi
        .getCarStatusStatisticInfo(self.currentCompany)
        .then((resp) => {
          console.log(resp);
          if (resp && resp.IsSuccess) {
            self.carStatusStatisticInfo = resp.Data;
          }
        });

      homepageApi.getRDMConfigInfo(self.currentCompany).then((resp) => {
        if (resp && resp.IsSuccess) {
          self.rdmConfigInfo = resp.Data;
        }
      });

      homepageApi.getFaultInfo(self.currentCompany).then((resp) => {
        if (resp && resp.IsSuccess) {
          self.faultSummary = resp.Data;
        }
      });

      homepageApi.getVoltageStateInfo(self.currentCompany).then((resp) => {
        if (resp && resp.IsSuccess) {
          self.voltageWarningInfo = resp.Data;
        }
      });

      //重新加载echart图片
      this.refreshFaultChart();    
    },
    markConfigValue(CarBundleStatus,CarLockedStatus) {
            let value = 'normal',
                status = CarBundleStatus,
                lockedStatus = CarLockedStatus;
            console.log("lockedStatus==",lockedStatus);
            if(status == ConstDefinition.CarStatus.Error) {
                value = 'fault';
            } else if(status == ConstDefinition.CarStatus.Offline) {
                value = 'offline';
            } else if(status == ConstDefinition.CarStatus.Online) {
                if(lockedStatus == ConstDefinition.CarLockStatus.lock) {
                    value = 'passing';
                }
            }
            
            return value;
        },
    setAsCurrentCar(c) {
      let self = this;
      self.currentCar = {
        ...self.currentCar,
        ...c,
      };
      var device = {
        deviceSerialNumber: self.currentCar.RDMNO,
      };
      geoApi.getCarLocation(device).then((res) => {
        var local = res.Data;
        //清除标记
        if (self.carAddress != null) {
          self.carAddress.remove();
        }
        //添加标记
        self.carAddress = new mapboxgl.Marker({ color: this.markConfig[this.markConfigValue(c.CarBundleStatus,c.CarLockedStatus)].color })
          .setLngLat([local.LongitudeShowValue, local.LatitudeShowValue])
          // .setPopup(new mapboxgl.Popup({offset:25})
          //   .setHTML('<h3>111111</h3><p>222222222222222222</p>'))
          .addTo(self.mapInstance);
        //设置级别
        self.mapInstance.setZoom(this.defaultMapShowLevel);
        //设置中心点
        self.mapInstance.setCenter([
          local.LongitudeShowValue,
          local.LatitudeShowValue,
        ]);
      });
      this.CarType = c.MachineSN;
      //打开车辆详情弹窗页面
      self.dialogVisible = true;
      //获取车辆详情页面数据
      carApi.getCarControllers(device).then((res) => {
        self.carInfo = res.Data;
      });

      let param2 = {
        Device: {
          ...device,
        },
        StartTimeUtc: self.$utils.addTime(self.$utils.now(), "m", -5),
        EndTimeUtc: self.$utils.now(),
      };
      monitorApi.getCarVoltageMonitor(param2).then((resp) => {
        if (resp && resp.IsSuccess) {
          let xDataList = [];
          let yDataList = [];
          resp.Data.forEach((element) => {
            yDataList.push(element.Voltage);
            //xDataList.push(new Date(element.TimestampUtc).toLocaleString());
            xDataList.push(formatTimestampUTC(new Date(element.TimestampUtc),'HH:mm:ss',this.userInfo.TimezoneSetting));
          });
          //生成图表
          if (self.monitorLineChart == null) {
            self.monitorLineChart = echarts.init(self.$refs.monitorLineChart);
          }
          let unit=this.$t("common.Voltage");
          let xSeriesUnit=this.$t("common.Time");
          let SecondUnit=this.$t("common.Second");
          self.monitorLineChart.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (param){
                  console.log("param",param);
                  let forstr="";
                  for(var i=0, h=param.length;i<h;i++){   
                    forstr+="<font color="+param[i].color+">&nbsp;●&nbsp;</font>"+ param[i].name+SecondUnit+": "+param[i].value.toFixed(2)+" "+unit+"";
                    }
                  return forstr;
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: xDataList,
              name:xSeriesUnit
            },
            yAxis: {
              type: "value",
              name:unit
            },

            series: [
              {
                symbol: "circle", //设定为实心点
                symbolSize: 2, //设定实心点的大小
                data: yDataList,
                type: "line",
                smooth: true,
                itemStyle: {
                  normal: {
                    color: "#3397fb",
                    lineStyle: {
                      width: 2, //设置线条粗细
                    },
                  },
                },
                areaStyle: {
                  normal: {
                    color: {
                      type: "line",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#3397fb", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#3397fb00", // 100% 处的颜色
                        },
                      ],
                      global: false, // 缺省为 false
                    },
                  },
                },
              },
            ],
          });
        }
      });
    },

    //获取公司列表
    getCompanyList() {
      var self = this;
      return orgnizationApi.getCompaniesByUserPrivilege().then((resp) => {
        self.companyList = resp.Data;
        // self.company.unshift({
        //   CompanyId: -1,
        //   CompanyName: "Company",
        //   ImageName: null,
        // });

        if (self.companyList && self.companyList.length > 0) {
          let companyObj=this.currentChooseCompany?  this.currentChooseCompany: self.companyList[0];
          self.selectCompany(companyObj);
        }
      });
    },

    //选择公司
    selectCompany(command) {
      this.carSearch.pageIndex = 1;
      this.carSearch.companyIdFilter = [command.CompanyId];
      this.currentCompany = command;
      var companyFilter = {
        companyId: command.CompanyId,
      };
      carApi.getCarNoByCompanyId(companyFilter).then((resp) => {
        this.carNoList = resp;
        this.carNoList.unshift("CarNo");
      });
      this.getCarList();
    },
    //选择CarNo
    selectCarNo(command) {
      this.carSearch.pageIndex = 1;
      this.carSearch.machineSNFilter = [command];
      this.carNo = command;
      this.getCarList();
    },
    //选择车辆状态
    selectCarStatus(command) {
      this.carSearch.pageIndex = 1;
      this.carSearch.carBundleStatusFilter = command.value;
      this.status = command.label;
      this.getCarList();
    },

    //获取车辆列表
    getCarList() {
      var self = this;
      if (this.carSearch.machineSNFilter[0] === "CarNo") {
        this.carSearch.machineSNFilter = [];
      }
      if (this.carSearch.companyIdFilter[0] === -1) {
        this.carSearch.companyIdFilter = [];
      }
      carApi.getCarListForHomePage(this.carSearch).then((resp) => {
        if (resp && resp.IsSuccess) {
          self.carList = resp.Data;
          self.carSearch.total = resp.TotalCount;
          if (this.mapInstance != null) {
            setTimeout(() => {
              this.mapInstance.resize();
            }, 100);
          }
        }
      });
    },

    handleCurrentChange(val) {
      this.carSearch.pageIndex = val;
      this.getCarList();
    },

    initmap() {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiNjI2NjgwMTA4IiwiYSI6ImNrb3RyNjQwdDA1eXMzMW12Z2hvYm5leXIifQ.CegbhRdTZq4v4cRw-aCDdQ"; //这里请换成自己的token
      this.mapInstance = new mapboxgl.Map({
        container: "map", // container id 绑定的组件的id
        style: "mapbox://styles/mapbox/streets-v11", //地图样式，可以使用官网预定义的样式,也可以自定义
        center: this.defaultMapCenterPoint, // 初始坐标系，这个是南京建邺附近
        zoom: this.defaultMapShowLevel, // starting zoom 地图初始的拉伸比例
        pitch: 0, //地图的角度，不写默认是0，取值是0-60度，一般在3D中使用
        bearing: 0, //地图的初始方向，值是北的逆时针度数，默认是0，即是正北
        antialias: false, //抗锯齿，通过false关闭提升性能
      });

      // Add zoom and rotation controls to the map.
      this.mapInstance.addControl(new mapboxgl.NavigationControl());
      //添加定位的control
      /*this.mapInstance.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        })
      );*/

      //添加language控件
      var languageFlag = sessionStorage.getItem("language");
      if (languageFlag == "ch") {
        languageFlag = "zh-Hans";
      }
      if (mapboxgl.getRTLTextPluginStatus() !== "loaded") {
        mapboxgl.setRTLTextPlugin(
          "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.0/mapbox-gl-rtl-text.js"
        );
      }
      this.mapInstance.addControl(
        new MapboxLanguage({
          defaultLanguage: languageFlag,
        })
      );
      this.addWorldViewControl();
      //添加一个自定义的控件， 用于控制是否显示电子围栏
      this.addControl4ShowGeoFence();
    },

    addWorldViewControl() {
      var self = this;
      var availableWorldviews = [
        {
          code: "CN",
          name: "common.mapcode_China",
        },
        // {
        //   code: 'IN',
        //   name: 'common.mapcode_India'
        // },
        // {
        //   code: 'JP',
        //   name: 'common.mapcode_Japan'
        // },
        //{
        //  code: "US",
        //  name: "common.mapcode_USA",
        //},
      ];
      //var worldviewOnMapLoad = "US";
      var worldviewOnMapLoad = "CN";
      for (let index = 0; index < availableWorldviews.length; index++) {
        const element = availableWorldviews[index];
        if (element.code == worldviewOnMapLoad) {
          element.checked = true;
        } else {
          element.checked = false;
        }
        element.displayName = self.$t(element.name);
      }
      var MapboxWordViewCtl = new MapboxWordViewControl();
      self.mapInstance.addControl(MapboxWordViewCtl);
      var wordViewConfig = {
        id: "mapInstance",
        title: self.$t("common.mapcode_desc"),
        wordViewArray: availableWorldviews,
      };
      MapboxWordViewCtl.setWordView(wordViewConfig, function (evt) {
        // console.log(evt);
        if (evt.target.value) {
          self.switchWorldview(evt.target.value);
        }
      });

      // Wait for the map to finish loading.
      this.mapInstance.on("load", function () {
        self.filterLayers(self.mapInstance, worldviewOnMapLoad);
      });
    },

    switchWorldview(worldview) {
      this.filterLayers(this.mapInstance, worldview);
    },

    filterLayers(map, worldview) {
      // The "admin-0-boundary-disputed" layer shows boundaries
      // at this level that are known to be disputed.
      map.setFilter("admin-0-boundary-disputed", [
        "all",
        ["==", ["get", "disputed"], "true"],
        ["==", ["get", "admin_level"], 0],
        ["==", ["get", "maritime"], "false"],
        ["match", ["get", "worldview"], ["all", worldview], true, false],
      ]);
      // The "admin-0-boundary" layer shows all boundaries at
      // this level that are not disputed.
      map.setFilter("admin-0-boundary", [
        "all",
        ["==", ["get", "admin_level"], 0],
        ["==", ["get", "disputed"], "false"],
        ["==", ["get", "maritime"], "false"],
        ["match", ["get", "worldview"], ["all", worldview], true, false],
      ]);
      // The "admin-0-boundary-bg" layer helps features in both
      // "admin-0-boundary" and "admin-0-boundary-disputed" stand
      // out visually.
      map.setFilter("admin-0-boundary-bg", [
        "all",
        ["==", ["get", "admin_level"], 0],
        ["==", ["get", "maritime"], "false"],
        ["match", ["get", "worldview"], ["all", worldview], true, false],
      ]);
    },
    addControl4ShowGeoFence() {
      var self = this;

      var config = { title: self.$t("homepage.showGeoFenceOnMap") };
      //add to map
      var geoFenceCtr = new GeoFenceControl();
      self.mapInstance.addControl(geoFenceCtr);
      geoFenceCtr.setView(config, function (evt) {
        self.flag_showGeoFenceAsMarkerOnMap = evt.target.checked;
        self.showGeoFenceAsMarkerOnMap();
      });
    },
    showGeoFenceAsMarkerOnMap() {
      var self = this;

      // //清理geoFence polygon
      for (let index = 0; index < this.geoFencePolygonList.length; index++) {
        const item = this.geoFencePolygonList[index];
        this.mapInstance.removeLayer(item.polygon);
        this.mapInstance.removeSource(item.source);

        var layerId = item.polygon;
        self.mapInstance.off("click", layerId, self.showPopupForLayer);
      }
      this.geoFencePolygonList = [];

      if (self.flag_showGeoFenceAsMarkerOnMap == false) {
        return;
      }

      var param = {
        companyId: this.carSearch.companyIdFilter[0],
      };
      if (param.companyId == null) {
        return;
      }
      geoFenceApi
        .getFences(param)
        .then((res) => {
          console.log(res);
          var result = res;
          if (result.IsSuccess) {
            var geoFenceList = result.Data;
            for (let index = 0; index < geoFenceList.length; index++) {
              const geoFence = geoFenceList[index];

              if (geoFence.GeoPath) {
                var geoPath = JSON.parse(geoFence.GeoPath);
                var coordinates = [];
                for (let index = 0; index < geoPath.length; index++) {
                  const geoPoint = geoPath[index];
                  coordinates.push([geoPoint.Longitude, geoPoint.Latitude]);
                }
                var sourceId = "source" + geoFence.FenceId;
                var layerId = "polygon" + geoFence.FenceId;
                // Add a data source containing GeoJSON data.
                self.mapInstance.addSource(sourceId, {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    geometry: {
                      type: "Polygon",
                      // These coordinates outline Maine.
                      coordinates: [coordinates],
                    },
                    properties: {
                      description: geoFence.FenceName,
                    },
                  },
                });
                // Add a new layer to visualize the polygon.

                self.mapInstance.addLayer({
                  id: layerId,
                  type: "fill",
                  source: "source" + geoFence.FenceId, // reference the data source
                  layout: {},
                  paint: {
                    "fill-color": "#ff0000",
                    "fill-opacity": 0.3,
                  },
                });

                self.mapInstance.on("click", layerId, self.showPopupForLayer);

                this.geoFencePolygonList.push({
                  fenceId: geoFence.FenceId,
                  polygon: layerId,
                  source: sourceId,
                  coordinates: coordinates,
                });
              }
            }

            // zoom the map to the polygon
            if (this.geoFencePolygonList.length > 0) {
              self.mapInstance.fitBounds(
                this.geoFencePolygonList[0].coordinates
              );
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleSelectCommand(command) {      
      this.sta_dropdown_select = command;
      this.refreshFaultPieChart();
    },
    build_Sta_DropdownItems() {
      this.sta_dropdownItems = [];
      this.sta_dropdownItems.push({
        name: this.$t("common.all"),
        key: "all",
      });
      this.sta_dropdownItems.push({
        name: this.$t("common.today"),
        key: "today",
      });
      this.sta_dropdownItems.push({
        name: this.$t("common.thisWeek"),
        key: "thisWeek",
      });
      this.sta_dropdownItems.push({
        name: this.$t("common.thisMonth"),
        key: "thisMonth",
      });
      this.sta_dropdownItems.push({
        name: this.$t("common.thisYear"),
        key: "thisYear",
      });
    },
    /**
    * @desc 打开车辆详情
    */
    openMachineDetails(CompanyId,MachineId) {
        this.$router.push({path: '/MachineDetails/' + CompanyId + '/' +MachineId});
    },
    /**
    * @desc 打开RDM详情
    */
    openRDMDetails(CompanyId,RDMNO) {
        this.$router.push({path: '/RDMDetails/' +CompanyId+ '/' + RDMNO});
    },    
    formatPieLegendOrXYSeriesLabel(param,length)
    {
            
            let newParamName="";
            let paramLength=param.length;
            let provideNumber=length;
            let rowNumber=Math.ceil(paramLength/provideNumber);
            if(paramLength>provideNumber)
            {
                for(var i=0;i<rowNumber;i++){  
                    let TmpStr="";
                    let start=i*provideNumber;
                    let end=start+provideNumber;
                    if(i==rowNumber)
                        TmpStr=param.substring(start,paramLength);
                    else
                        TmpStr=param.substring(start,end)+"\n";
                    newParamName+=TmpStr;
                }

            }
             else
                newParamName=param;
            return newParamName;                    
                        
    },
  },
};
</script>

<style lang="less" scoped>
.BatterySN{
  max-height: 300px;
  overflow-y: auto;
}
.row1 {
  background-color: #e5e5e5;
  height: 130px;
  display: flex;
  justify-content: center;
  .online-state {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;
    .carMain {
      display: inline-block;
      .carMainTop {
        font-size: 30px;
        font-weight: 700;
        display: flex;
        flex-wrap: nowrap;

        .carOnlineCount {
          width: 90px;
          text-align: center;
          color: #0079c1;
        }
        .carOfflineCount {
          width: 90px;
          text-align: center;
          color: #f34863;
        }
        .carCount {
          width: 90px;
          text-align: center;
          color: #04223a;
        }
      }
      .carMainBottom {
        padding-top: 10px;
        height: 19px;
        .imgLogo {
          height: 19px;
          display: inline-block;
        }
        .carMainText {
          height: 19px;
          font-size: 14px;
          line-height: 19px;
          font-weight: 400;
          text-align: center;
          color: #666666;
          margin-left: 6px;
          display: inline-block;
        }
      }
    }
    .carChart {
      width: 145px;
      height: 91px;
    }
  }
  .RDM-state {
    border-left: #ffffff solid 1px;
    border-right: #ffffff solid 1px;
    border-color: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;
    .RDMMain {
      display: inline-block;
      .RDMMainTop {
        display: flex;
        flex-wrap: nowrap;
        font-size: 30px;
        font-weight: 700;
        .rdm {
          width: 90px;
          height: 34px;
          text-align: center;
          color: #0079c1;
        }
        .unRdm {
          width: 90px;
          height: 34px;
          text-align: center;
          color: #04223a;
        }
      }
      .RDMMainBottom {
        padding-top: 10px;
        .RDMMainText {
          height: 19px;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #666666;
          display: inline-block;
          margin-left: 9px;
        }
      }
    }
    .RDMChart {
      width: 145px;
      height: 91px;
    }
  }
  .Battery {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;
    border-right: #ffffff solid 1px;
    border-color: rgba(0, 0, 0, 0.1);
    .low {
      display: inline-block;
      .lowBatteryImg {
        display: inline-block;
        height: 33px;
        cursor: pointer;
        .battery-warning-count {
          display: inline-block;
          padding-left: 9px;
          text-align: justify;
          float: right;
          height: 30px;
          line-height: 30px;
          font-size: 30px;
          font-weight: 700;
          color: #ff9900;
        }
      }
      .lowText {
        height: 13px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #666666;
      }
    }
    .veryLow {
      display: inline-block;
      cursor: pointer;
      .veryLowBatteryImg {
        display: inline-block;
        height: 33px;
        .battery-danger-count {
          display: inline-block;
          padding-left: 9px;
          float: right;
          height: 30px;
          line-height: 30px;
          font-size: 30px;
          font-weight: 700;
          color: #f34863;
        }
      }
      .veryLowText {
        height: 13px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #666666;
      }
    }
  }

  .faultStatus{
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    .faultMain{
      display: inline-block;
      margin-top:14px ;
      cursor: pointer;
      text-align: left;
      .faultImg {
        height: 59px;
        width: 123px;
        background:url('../../assets/image/fault bg.png');
        .faultCount {
          width: 109px;
          height: 22px;
          font-size: 30px;
          font-weight: 700;
          text-align: center;
          color: #f2f2f2;
          line-height: 71px;
          cursor: pointer;
        }
        
      }
      .faultBottom {
          padding-top: 2px ;
          display: inline-block;
          width: 110px;
          text-align: center;
          .faultText {
            height: 19px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #666666;
            display: inline-block;
            margin-left: 9px;
          }
        
        }
    }
    
  }
  .fault-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #e94050;
    height: 100%;
    width: 250px;
    .value {
      font-size: 36px;
      font-weight: bold;
    }
    .desc {
      font-size: 14px;
    }
    .icon {
      margin-left: 30px;
      i {
        font-size: 4rem;
        color: #fff;
      }
    }
  }
}
.row2 {
  width: 100%;
  height: 301px;
  display: flex;
  margin: 16px 0px;
  justify-content: space-between;
  align-items: center;
  .fault-statistic {
    flex-grow: 1;
    height: 100%;
    .faultTrendChart-main {
      background: #ffffff;
      width: 68.3%;
      height: 100%;
      float: left;
      border-radius: 2px;
      box-shadow: 0px 0px 1px 0px #ffffff;
      .faultTrendChart-header {
        margin-bottom: 34px;
        font-size: 16px;
        margin-top: 35px;
        margin-left: 30px;
        font-weight: 700;
        text-align: left;
        color: #333333;
        i {
          margin-left: 18px;
          width: 12px;
          height: 14px;
        }
        .el-dropdown-link:hover {
          cursor: pointer;
        }
        .faultTrendChart-time {
          float: right;
          margin-right: 30px;
          .el-range-editor.el-input__inner {
            border-radius: 25px;
          }
          /deep/ .el-date-editor .el-range__icon {
            margin-left: 3px;
            line-height: 20px;
          }
          /deep/ .el-date-editor .el-range__close-icon {
            line-height: 20px;
          }
          /deep/ .el-date-editor .el-range-separator {
            line-height: 20px;
          }
        }
      }
      .faultTrendChart {
        background: #ffffff;
        width: 95%;
        height: 70%;
      }
    }

    .faultCategoryChart-main {
      background: #ffffff;
      margin-left: 1%;
      width: 30%;
      height: 100%;
      float: right;
      box-shadow: 0px 0px 1px 0px #ffffff;
      .faultCategoryChart-header {
        text-align: left;
        margin-bottom: 34px;
        font-size: 16px;
        margin-top: 35px;
        margin-left: 30px;
        font-weight: 700;
        color: #333333;

        .faultCategoryChart-time {
          float: right;
          margin-right: 30px;
          .el-dropdown {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            :hover {
              cursor: pointer;
            }
          }
        }
      }
      .faultCategoryChart {
        margin-left: 40px;
        width: 92%;
        height: 70%;
      }
    }
  }
}

.row3 {
  margin: 16px 0px;

  display: block;
  justify-content: space-around;

  .car-list {
    .car-item {
      padding: 10px 10px;
      border: solid 1px #ddd;
      cursor: pointer;
      width: 44.5%;
      display: inline-block;
      text-align: center;
      margin: 0px 0px 1.5% 1.5%;
    }
  }
  .car-detail {
    .rdm {
      padding: 10px 10px;
      border: solid 1px #ddd;
      cursor: pointer;
    }
    .controller {
      padding: 10px 10px;
      border: solid 1px #ddd;
      cursor: pointer;
    }
  }
  .el-pager li.active,
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #0079c1 !important;
    border-color: #0079c1 !important;
    border: 1px #0079c1 solid;
  }
  .el-pager li {
    border: 1px #f4f4f5 solid;
    background-color: #fff !important;
  }

  /*VehicleOverview部分布局*/
  .VehicleOverview {
    width: 98%;
    height: 388px;
    padding: 14px;
  }

  .carMapHeader {
    margin-bottom: 30px;
    padding-right: 5px;
  }
  /*VehicleOverview文本样式*/
  .carMapHeaderTitle {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    display: inline-block;
    width: 50%;
  }
  /*筛选条件下拉框布局*/
  .carMapHeaderDropdownList {
    width: 50%;
    display: inline-block;
    text-align: right;
  }
  .carMapHeaderDropdown {
    display: inline-block;
    margin-left: 30px;
  }
  /*map和CarList设置弹性布局*/
  .carMapAndCarList {
    display: flex;
  }
  /*地图布局*/
  .carMap {
    display: inline-block;
    flex: 1;
    height: 275px;
  }

  /*CarList布局*/
  .carList {
    text-align: right;
    width: 66%;
    display: inline-block;
  }
  .carListSmall {
    text-align: right;
    width: 33%;
    display: inline-block;
  }
  .carListSmall .car-item {
    width: 95% !important;
  }
  .carList .car-item {
    width: 44.5% !important;
  }
  .carItemInfo {
    width: 32%;
    display: inline-block;
  }
  /*Car状态布局*/
  .carItemInfoStatus {
    color: #ff6666;
    text-align: right;
    width: 36%;
  }
  .carItemInfoStatusIconOffline {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #ff6666;
    display: inline-block;
    margin-right: 10px;
  }
  .carItemInfoStatusIconOnline {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #00b194;
    display: inline-block;
    margin-right: 10px;
  }
  .carItemInfoStatusTextOffline {
    display: inline-block;
    margin-right: 21px;
    width: 50px;
    text-align: center;
  }
  .carItemInfoStatusTextOnline {
    display: inline-block;
    margin-right: 21px;
    color: #00b194;
    width: 50px;
    text-align: center;
  }
  /*分页布局*/
  .pagination {
    text-align: center;
    margin-top: 40px;
  }
  /*分页前总数文本信息*/
  .paginationCount {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #666666;
  }
  .paginationDiv {
    display: inline-block;
  }
  /*故障图标*/
  .warning {
    color: #faad14;
    margin-right: 5px;
    vertical-align: middle;
  }
  /*故障车辆*/
  .error {
    background: #ffffff;
    border: 1px solid #ffccc7 !important;
    border-radius: 2px;
  }
  .car-item:hover {
    background: #fef3f3;
    border: 1px solid #ffccc7;
    border-radius: 2px;
  }
  .noVehicleView {
    margin-top: 105px;
    font-size: 18px;
    font-weight: 400;
    color: #a6a6a6;
  }
  .noVehicleView img {
    margin-bottom: 30px;
  }
  .mapboxgl-popup {
    max-width: 200px;
  }
  .mapboxgl-popup-content {
    text-align: center;
  }
  .carListNone {
    display: none;
  }
  .carListInfo {
    height: 250px;
  }
}

.el-divider--horizontal {
  display: block;
  margin: 3px 0;
  width: 80%;
}
.el-divider {
  background-color: #f2f2f2;
}
/deep/.el-dialog {
  float: right;
  margin-top: 0 !important;
  margin-bottom: 0px;
  background: #04223a;
  .el-dialog__header {
    background: #04223a !important;
    padding-bottom: 15px !important;
  }
  ///*dialog关闭按钮位置*/
  .el-dialog__headerbtn {
    right: 28px !important;
  }
  .el-dialog__body {
    height: 100%;
    min-height: 94.6vh;
    width: 466px;
    padding: 0;
  }
}
.CarType {
  height: 15px;
  font-size: 17px;
  font-weight: 650;
  text-align: left;
  color: #f2f6fc;
  margin-left: 30px;
}
.image1 {
  width: 55px;
  height: 54px;
  margin-left: 39px;
  margin-top: 40px;
}
.RDM {
  margin-top: -50px;
  margin-left: 119px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1;
}
.image2 {
  width: 55px;
  height: 54px;
  margin-left: 39px;
  margin-top: 40px;
}
.Programmable {
  margin-top: -50px;
  margin-left: 119px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1;
}
.image3 {
  width: 55px;
  height: 54px;
  margin-left: 39px;
  margin-top: 40px;
}
.Motor {
  margin-top: -50px;
  margin-left: 119px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1;
}
.Voltage-page {
  width: 410px;
  height: 316px;
  background: #f2f6fc;
  border-radius: 2px;
  margin-top: 59px;
  margin-left: 30px;
}
.Voltage-title {
  height: 15px;
  font-size: 15px;
  font-weight: 650;
  text-align: left;
  color: #333333;
  padding-top: 30px;
  padding-left: 21px;
}
.link {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #0079c1 !important;
  line-height: 48px;
  margin-left: 30px;
  margin-top: 48px;
}
.link a {
  text-decoration: none;
  color: #0079c1 !important;
}
.link a:hover {
  text-decoration: underline;
}
.question {
  margin-left: 20px;
  height: 18px;
  width: 18px;
  opacity: 0.4;
}
.question:hover {
  opacity: 1;
}
.carInfoRDM {
  color: #f2f6fc;
}
.gear {
  margin-left: 1%;
}
.monitorLineChart {
  width: 105%;
  height: 105%;
  margin-top: -3%;
}
.qrcode {
        font-size: 14px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
    }
</style>
