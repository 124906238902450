import Vue from 'vue';
import Vuex from 'vuex';
import { getUserEntity_ss, getLanguage_ss, setLanguage_ss, setUserEntity_ss,getCurrentCompany_ss,setCurrentCompany_ss } from './app.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //状态
    userEntity: getUserEntity_ss(),
    language: getLanguage_ss(),
    isEdit: false,
    isAssignFunction: false,
    companyEntity:getCurrentCompany_ss()
  },
  mutations: {
    //同步操作
    setLanguage(state, language) {
      state.language = language;
      setLanguage_ss(language);
    },
    setUserEntity(state, userEntity) {
      state.userEntity = userEntity;
      setUserEntity_ss(userEntity);
    },
    setIsEdit(state, isedit){
      state.isEdit=isedit;
    },
    setIsAssignFunction(state, isAssignFunction){
      state.isAssignFunction=isAssignFunction;
    },
    setCurrentCompany(state,companyEntity){
      state.companyEntity = companyEntity;
      setCurrentCompany_ss(companyEntity);
    }
  },
  actions: {
    //异步操作
    setLanguage(context, language) {
      context.commit('setLanguage', language);
    },
    setUserEntity(context, userEntity) {
      context.commit('setUserEntity', userEntity);
    },
    setCurrentCompany(context, companyEntity) {
      context.commit('setCurrentCompany', companyEntity);
    },
  },
  modules: {
    //按模块划分
  },
  getters: {
    //读取操作
    userEntity: state => state.userEntity,
    companyId: state => { state.userEntity ? state.userEntity.CompanyId : undefined; },
    topNCountForNotification: state => 99,
    language: state => state.language,
    companyEntity:state => state.companyEntity,
  }
});
