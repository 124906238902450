<template>
  <div id="AddRDMPage">
    <el-card class="rdmCard">
      <!--面包屑-->
      <div class="breadcrumbView">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/RDMManage' }"
            > {{$t("menu.RDMManage")}}</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="$store.state.isEdit">{{
            $t("RDMManage.Edit")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else>{{
              $t("RDMManage.Add")
            }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- RDM信息 -->
      <div class="rdmInfo-content">
        <el-form
          ref="basicForm"
          :model="basicForm"
          label-width="23%"
          :rules="rules"
        >
          <el-row>
            <el-form-item
              :label="$t('addMachine.RDMNO') + ':'"
              prop="RDMNo"
            >
              <el-col :span="11">
                <el-input
                  :disabled="isEdit"
                  class="MachineSN"
                  v-model="basicForm.RDMNo"
                  :placeholder="$t('addMachine.RDMNO')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>          
          <el-row>
            <el-form-item :label="$t('common.company') + ':'" prop="CompanyId">
              <el-col :span="11">
                <el-select
                  v-model="basicForm.CompanyId"
                  filterable
                  v-if="companyList.length > 0"          
                  :placeholder="companyList[0].CompanyName"
                  @change="handleSelectionChange"
                >
                  <el-option
                    v-for="(item, key) in companyList"
                    :key="key"
                    :label="item.CompanyName"
                    :value="item.CompanyId"                  
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="$t('RDMManage.ModelNumber') + ':'" prop="ModelNumber">
              <el-col :span="11">
                <el-input
                  class="MachineSN"
                  v-model="basicForm.ModelNumber"
                  :placeholder="$t('RDMManage.ModelNumber')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="$t('RDMManage.DateCode') + ':'" prop="DataCode">
              <el-col :span="11">
                <el-input
                  class="MachineSN"
                  v-model="basicForm.DataCode"
                  :placeholder="$t('RDMManage.DateCode')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="$t('RDMManage.ProductNo') + ':'" prop="PN">
              <el-col :span="11">
                <el-select
                  v-model="basicForm.PN"
                  filterable
                  :placeholder="productNumberList[0].PN"  
                  v-if="productNumberList.length > 0"                
                >
                  <el-option
                    v-for="(item, key) in productNumberList"
                    :key="key"
                    :label="item.PN"
                    :value="item.PN"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="$t('RDMManage.Software') + ':'">
              
              <el-col :span="4">  {{$t('common.pleaseChoose')}}</el-col>
             
            </el-form-item>
          </el-row>
          <el-row class="assign-content">
            <el-form-item  > 
                <div class="assign-content-top">               
                  <el-col :span="24">
                    <div class="software-table">
                        <div class="table-box">
                            <el-table :data="tableData" :row-class-name="vehicleTableRowClassName" 
                            style="width: 100%;"   
                            @row-click="singleSelection"                     
                            :row-style="rowClass" >
                                <el-table-column
                                    align="center"
                                    width="30"
                                    class-name="default"
                                    >
                                    <template slot-scope="scope">
                                        <i v-if="scope.row.IsDefault == 1" class="fa fa-bookmark green"></i>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    width="30">
                                  <template slot-scope="scope">
                                      <el-radio class="radio" v-model="softwareSelection" :label="scope.$index">&nbsp;</el-radio>
                                  </template>
                                </el-table-column>
                                <!--el-table-column
                                    type="index"
                                    align="center"                                
                                    width="50"
                                    :index="indexMethod"
                                    >
                                </el-table-column-->
                                <el-table-column
                                    prop="Version"
                                    align="center"
                                    :label="$t('common.version')"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="Remark"
                                    align="center"
                                    :label="$t('common.Description')"
                                    width="500">
                                </el-table-column>                            
                                <el-table-column
                                    align="center"
                                    :label="$t('RDMManage.UploadTime')"
                                    width="150">
                                    <template slot-scope="scope">
                                        {{formatTimestampUTC(new Date(scope.row.UTime),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="pagination">
                            <div
                                v-if="rdmSearch.total > userInfo.PageSize"
                                class="paginationCount"
                            >
                                {{ (rdmSearch.page - 1) * userInfo.PageSize + 1 }}-{{
                                rdmSearch.page * userInfo.PageSize
                                }}
                                {{ $t("common.Page_of") }} {{ rdmSearch.total }} {{ $t("common.Pages") }}
                            </div>
                            <div class="paginationDiv">
                                <el-pagination
                                background
                                :page-size="userInfo.PageSize"
                                @current-change="handleCurrentChange"
                                :hide-on-single-page="true"
                                layout="prev, pager, next"
                                :total="rdmSearch.total"
                                >
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </el-col>
                </div>
          </el-form-item>
        </el-row>
          <el-row>
            <el-form-item :label="$t('RDMManage.ICCID') + ':'" prop="iccid">
              <el-col :span="11">
                <el-input
                 class="MachineSN"
                  v-model="basicForm.iccid"
                  :placeholder="$t('RDMManage.ICCID')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="$t('RDMManage.MISISDN') + ':'" prop="misisdn">
              <el-col :span="11">
                <el-input
                  class="MachineSN"
                  v-model="basicForm.misisdn"
                  :placeholder="$t('RDMManage.MISISDN')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item :label="$t('RDMManage.IMSI') + ':'" prop="imsi">
              <el-col :span="11">
                <el-input
                  class="MachineSN"
                  v-model="basicForm.imsi"
                  :placeholder="$t('RDMManage.IMSI')"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-row>
          <el-row>
            <div class="foot">
              <el-button @click="back">{{$t("addMachine.Return")}}</el-button>
              <el-button @click="clearForm">{{ $t("bizConfig.clear")}}</el-button>
              <el-button type="primary" @click="Submit">{{ $t("bizConfig.save") }}</el-button>
            </div>
        </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import rdmApi from "@/api/autogen/v2/rdm";
import carApi from "@/api/autogen/v2/car";
import companyApi from "@/api/autogen/v2/orgnization";
import productApi from "@/api/autogen/v2/product";
import {formatTimestampUTC} from '@/utils';
export default {
  name: "AddRDMPage",
  data() {
    var checkRDMNo =  (rule, value, callback) => {
      let data={
        RDMNo:value
      };
      if(this.isEdit){
        callback();
      }
      rdmApi.CheckIfRDMNoExisted(data).then(res=>{
        if(res.IsSuccess&&!res.Data){
          callback();
      }else {
          callback(
              new Error(
                  this.$t("RDMManage.RDMNoExist")
              )
          );

        }
      });
    };

    return {
      //表单数据
      basicForm: {
        RDMNo:"",
        ModelNumber:"",
        CompanyId:"",
        PN:"",
        DataCode:"",
        RDMDefId:"",
        iccid:"",
        misisdn:"",
        imsi:""
      },      
      //公司下拉框数据
      companyList:[],
      //品号下拉框数据
      productNumberList:[],
      rdmSearch: {
                page: 1,
                total: 0,
            },
      tableData: [],
      softwareSelection: "",
      selectRow: [],
      isEdit: false,     
      rules: {
        RDMNo: [
          {
            required: true,
            message:
              this.$t("addMachine.RDMNO") + this.$t("common.required"),
            trigger: "blur",
          },
          {
            validator: checkRDMNo,
            trigger:'blur'
          }
        ],
        CompanyId: [
          {
            required: true,
            message:
              this.$t("common.company") + this.$t("common.required"),
            trigger: "blur",
          }
        ],
      ModelNumber: [
          {
            required: true,
            message:
              this.$t("RDMManage.ModelNumber") + this.$t("common.required"),
            trigger: "blur",
          }
        ],
        DataCode: [
        {
            required: true,
            message:
              this.$t("RDMManage.DateCode") + this.$t("common.required"),
            trigger: "blur",
          }
        ],
        PN: [
        {
            required: true,
            message:
              this.$t("RDMManage.ProductNo") + this.$t("common.required"),
            trigger: "blur",
          }
        ],
      },
    };
  },
  computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
  },
  created() {
    this.initData();
    let self = this;
    self.GetRDMSoftwareList();
  },
  mounted() {},
  methods: {
    formatTimestampUTC,
    buildCompanyItems() {
            let self = this;
            companyApi.getCompanies().then((resp) => {
                let companyItems = resp.Data;
                for (var i = 0; i < companyItems.length; i++) {
                    self.companyList.push({
                        CompanyName: companyItems[i].CompanyName,
                        CompanyId: companyItems[i].CompanyId,
                    });
                }
                //console.log(companyList);
            });
        },     
      buildProductItems() {
            let self = this;
            var param = {
              CompanyId: this.basicForm.CompanyId,
              ProductNumber: this.PN
            };
            productApi.GetProductNumberList(param).then((resp) => {
                let productItems = resp.Data;
                this.productNumberList = [];
                for (var i = 0; i < productItems.length; i++) {
                    self.productNumberList.push({
                        ID: productItems[i].ID,
                        PN: productItems[i].ProductNumber,
                    });
                }
                //console.log(companyList);
            });
        },
      handleSelectionChange(val) {
        this.buildProductItems();
      },
    //获取软件列表
    GetRDMSoftwareList(){
            let param = {
                pageSize: this.userInfo.PageSize,
                pageIndex: this.rdmSearch.page,
            };
            rdmApi.GetRDMSoftwareList(param).then((res)=>{
                if(res&&res.IsSuccess&&res.Data){
                    this.tableData = res.Data;
                    this.rdmSearch.total = res.TotalCount;
                    for (var i = 0; i < this.tableData.length; i++) {
                      if(this.tableData[i].IsDefault==1)
                      {
                        this.softwareSelection=i;
                        this.basicForm.RDMDefId=this.tableData[i].RDMDefId;
                      }
                    }
                }
            });
        },

    //分页序号连续
    indexMethod(index) {
            index = (index + 1) + (this.rdmSearch.page - 1) * this.userInfo.PageSize;
            return index;
        },
       /**
         * @desc 分页
         */
    handleCurrentChange(val) {
            this.rdmSearch.page = val;
            this.GetRDMSoftwareList();
    },
     /**
      * @desc 自定义斑马纹
    */
    vehicleTableRowClassName({row, rowIndex}) {
            if (rowIndex%2==0) {
                 return '';
            } else {
                return 'even-row';
            }
        },
    singleSelection(row)
    {
      this.softwareSelection=this.tableData.indexOf(row);
      this.basicForm.RDMDefId=row.RDMDefId;
    },
    //选中行变色
    rowClass({ row, rowIndex }) {
            if (this.selectRow.includes(rowIndex)) {
                return { "background-color": "#E6F7FF" };
            }
    },
    clearForm()
    {
       //表单数据
       this.basicForm={
        RDMNo:"",
        ModelNumber:"",
        CompanyId:"",
        PN:"",
        DataCode:"",
        RDMDefId:"",
        iccid:"",
        misisdn:"",
        imsi:""
      };
    },
 //rdm assgin
 Submit(){
  let self = this;
  self.$refs["basicForm"].validate((valid) => {
    if (valid) {
      self.loading = true;
      let param = self.basicForm;
      if(!this.isEdit)
      {
        rdmApi.CreateRDM(param).then((res)=>{
        if(res&&res.IsSuccess){
            let content = '';
            let Url = '';
            if (res.Data) {
                Url = require("@/assets/image/success.png");
                content= this.$t("common.operationSuccess");
                this.back();
                }else{
                      Url = require("@/assets/image/error_76.png");
                      content= this.$t("common.operationFailure");
                }
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                          dangerouslyUseHTMLString: true,
                          confirmButtonText: this.$t("common.confirm"),
                          center: true,
                          showClose:false
                });                                     
        }else{
                      Url = require("@/assets/image/error_76.png");
                      content= this.$t("common.operationFailure");
                      this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                          dangerouslyUseHTMLString: true,
                          confirmButtonText: this.$t("common.confirm"),
                          center: true,
                          showClose:false
                     });  
                }
                 
        self.loading =false;
        }).catch((e) => {
                        let Url = require("@/assets/image/error_76.png");
                        let content= self.$t("common.operationFailure");
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.loading =false;
        }); 

      }
      else
      {
        rdmApi.EditRDM(param).then((res)=>{
        if(res&&res.IsSuccess){
            let content = '';
            let Url = '';
            if (res.Data) {
                Url = require("@/assets/image/success.png");
                content= this.$t("common.operationSuccess");
                this.back();
                }else{
                      Url = require("@/assets/image/error_76.png");
                      content= this.$t("common.operationFailure");
                }
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                          dangerouslyUseHTMLString: true,
                          confirmButtonText: this.$t("common.confirm"),
                          center: true,
                          showClose:false
                });                                     
        }else{
                      Url = require("@/assets/image/error_76.png");
                      content= this.$t("common.operationFailure");
                      this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                          dangerouslyUseHTMLString: true,
                          confirmButtonText: this.$t("common.confirm"),
                          center: true,
                          showClose:false
                     });  
                }
                 
        self.loading =false;
        }).catch((e) => {
                        let Url = require("@/assets/image/error_76.png");
                        let content= self.$t("common.operationFailure");
                        self.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                            dangerouslyUseHTMLString: true,
                            confirmButtonText: self.$t("common.confirm"),
                            center: true,
                            showClose:false
                        });
                        self.loading =false;
        }); 
      }
           
      } 
    }); 
  }, 
    //初始化页面数据
    initData(){
      var self = this;
      var params =  this.$route.params;
      if(params.RDMNo!=null){
          this.isEdit = true;
      }
      if(this.isEdit)
      {
        let self = this;
        var param = {
              RDMNo: params.RDMNo
        };
        rdmApi.GetRDMDetail_v2(param).then((resp) => {
          this.basicForm = resp.Data.RDM;   
          //console.log(resp.Data);    
          //console.log(this.basicForm);          
        });

      }

      this.buildCompanyItems();     
    },
    back() {
      this.$router.push({
        path: '/RDMManage/',
      });
    },
  },
};
</script>

<style lang="less">
.rdmCard .el-card__body {
  padding: 0px !important;
}
.rdmCard {
  border: 0px !important;
}
/*面包屑样式 */
.breadcrumbView {
  height: 30px;
  border-bottom: 1px solid #eaeef0;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
}
.breadcrumbView .el-breadcrumb {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
}
/*RDM信息样式 */
.rdmInfo-content {
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  .el-select {
    display: block;
  }
  .el-form-item__label {
    padding-right: 20px;
  }
  /*底部按钮样式 */
  .footer {
    text-align: left;
    .el-button {
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover {
      background: transparent !important;
    }
    .el-button--primary {
      margin-left: 20px;
    }
  }
  /*隐藏数字框右边按钮 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .tip {
    padding: 0px 14px 13px 14px;
    width: 100%;
    text-align: left;
    color: #fd990d;
    margin-top: -10px;
  }
  /*弹窗底部按钮样式 */
  .footerButton {
    .el-button {
      width: 110px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #cbcbcd;
      border-radius: 4px !important;
    }
    .el-button--primary {
      margin-left: 20px;
    }
  }
}
/*下拉框选项样式 */
.el-popper {
  .el-select-dropdown__item:hover {
    background-color: #fafafa;
  }
  .selected {
    font-weight: 400 !important;
    color: #3399ff !important;
    background-color: #ecf5ff;
  }
  .hover {
    background-color: #ecf5ff;
  }
}

input{
  border-radius: 3px;
}
/*输入框鼠标悬停添加阴影 */
input:hover,
textarea:hover,
.is-focus input {
  box-shadow: 0px 0px 8px 0px rgb(51 153 255 / 80%);
  border: 1px solid #3399ff !important;
  border-radius: 3px;
}
.el-input.is-disabled .el-input__inner{
  border-color: #ccc !important;
}
    .software-table{
                margin-top: 21px;
                .table-box {
                    border: 1px solid #F2F2F2;

                    /deep/ .el-table__header tr th {
                        background: #FAFAFA;
                    }
                    .rdmon{
                        cursor: pointer;
                        width: 19px;
                        height: 19px;
                        margin:0 auto;
                        background:url('../../assets/image/rdmon.png');
                    }
                    .point{
                        cursor: pointer;
                    }
                    .wifi-off{
                        width: 21px;
                        height: 18px;
                        margin:0 auto;
                        background:url('../../assets/image/wifi_off.png');
                    }
                    .gray{
                        font-size: 21px;
                        color: #999999;
                    }

                    .green{
                        font-size: 21px;
                        color: #00b194;
                    }
                    .red{
                        font-size: 21px;
                        color: #fe686a;
                    }
                }
                .pagination {
                    margin-top: 20px;
                    text-align: right;
                    padding-bottom: 40px;
                    .paginationCount {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;
                    }
                    .paginationDiv {
                        display: inline-block;
                    }
                }
            }
</style>
