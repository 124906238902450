import request from "@/utils/request";

              
//查询电子围栏
function getFences(data) {
  return request({
    url: window.GeoFenceAPI+'fence/getFences',
    method: 'POST',
    data
  });
}

export default {
  getFences
};