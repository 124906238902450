import request from "@/utils/request";


//获取品号列表
function GetProductNumberList(data) {
  return request({
    url: "v2/Product/GetProductNumberList",
    method: "post",
    data
  });
}


//新增某个公司的PN信息
function CreateProductNumber(data) {
  return request({
    url: "/v2/Product/CreateProductNumber",
    method: "post",
    data
  });
}

//编辑某个公司的PN信息
function EditProductNumber(data) {
  return request({
    url: "/v2/Product/EditProductNumber",
    method: "post",
    data
  });
}

//删除某个公司的PN信息
function DeleteProductNumber(data) {
  return request({
    url: "/v2/Product/DeleteProductNumber",
    method: "post",
    data
  });
}

//删除PN是否重复
function CheckIfProductNumberDuplicate(data) {
  return request({
    url: "/v2/Product/CheckIfProductNumberDuplicate",
    method: "post",
    data
  });
}

export default {
  GetProductNumberList,
  CreateProductNumber,
  EditProductNumber,
  DeleteProductNumber,
  CheckIfProductNumberDuplicate
};