<template>
     <div class="assign-box">
         <el-row class="assign-top">
            <div class="header-crumbs">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-if="$store.state.isAssignFunction" :to="{ path: '/RDMAssign' }" 
                    >{{ $t("menu.RDMManage") }}</el-breadcrumb-item>
                <el-breadcrumb-item  v-else :to="{ path: '/RDMManage' }" 
                    >{{ $t("menu.RDMAssign") }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t("RDMManage.Assign")}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </el-row>
        <el-row class="assign-content">
            <div class="assign-content-top">
                <el-col :span="16">
                    <div class="title">
                        {{$t("RDMManage.chooseCompany")}}
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="search">
                        <el-input
                            :placeholder="$t('common.company')"
                            suffix-icon="el-icon-search"
                            v-model="searchContent"
                        >
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="24">
                    <div class="company-box">
                        <el-row >
                            <div class="content">
                                <div v-if="tableData.length > 0">
                                <el-radio-group v-model="companyId">
                                    <el-radio
                                        v-for="(item, key) in tableData"
                                        :label="item.CompanyId"
                                        :key="key"
                                    >
                                        {{ item.CompanyName }}
                                        
                                    </el-radio>
                                </el-radio-group>
                            </div>
                            <div class="none-data-box" v-else>{{ $t("common.empty") }}</div>
                            </div>
                        </el-row>
                        <el-row>
                            <div class="foot">
                                <el-button  v-if="$store.state.isAssignFunction" @click="returnRDMAssign">{{ $t("addMachine.Return") }}</el-button>
                                <el-button v-else @click="returnRDMManage">{{ $t("addMachine.Return") }}</el-button>
                                <el-button type="primary" @click="Submit">{{ $t("bizConfig.save") }}</el-button>
                            </div>
                        </el-row>
                    </div>
                </el-col>
              
            </div>
        </el-row>
     </div>
</template>
<script>
import rdmApi from "@/api/autogen/v2/rdm";
import companyApi from "@/api/autogen/v2/orgnization";
export default {
    data() {
        return {
            searchContent:'',
            //选择的数据
            companyId: "",
            tableData:[],
            rdmnoList:[]
        };
    },
    computed: {
       
    },
    created(){
        this.buildCompanyItems();
        this.rdmnoList = this.$route.params.rdms;
    },
    methods: {
        buildCompanyItems() {
            let self = this;
            companyApi.getCompaniesByUserPrivilege().then((resp) => {
                let companyItems = resp.Data;
                for (var i = 0; i < companyItems.length; i++) {
                    self.tableData.push({
                        CompanyName: companyItems[i].CompanyName,
                        CompanyId: companyItems[i].CompanyId,
                    });
                }
            });
        },

        returnRDMAssign(){
            this.$router.push({path: '/RDMAssign'});
        },
        returnRDMManage(){
            this.$router.push({path: '/RDMManage'});
        },
        //rdm assgin
        Submit(){
            if(this.companyId === ""){
                let Url = require("@/assets/image/warning1.5x.png");
                this.$alert("<img src=" + Url + "><p class='text'>"+this.$t("common.selectCompany")+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    center: true,
                    showClose:false
                });
                return;
            }
            let param = {
                rdmnoList : this.rdmnoList,
                companyId :this.companyId
            };
            rdmApi.AssginRDM(param).then((res)=>{
                if(res&&res.IsSuccess){
                    let content = '';
                    let Url = '';
                    if (res.Data) {
                        Url = require("@/assets/image/success.png");
                        content= this.$t("common.operationSuccess");
                        if(this.$store.state.isAssignFunction)
                            this.returnRDMAssign();
                        else
                            this.returnRDMManage();
                    }else{
                        Url = require("@/assets/image/error_76.png");
                        content= this.$t("common.operationFailure");

                    }
                    
                    this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t("common.confirm"),
                        center: true,
                        showClose:false
                    });
                }
            });
        }
        
    },
    beforeDestroy() {
    },
    
};
</script>
<style lang="less" scoped>
    .assign-box{
        margin-top : 10px;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10); 
        .assign-top{
            border-bottom: 1px solid #e5e5e5;
            background: #fafafa;
            border-radius: 2px;
            padding: 0 18px;
            .header-crumbs {
                margin-left: 26px;
                font-size: 16px;
                font-weight: bold;
                float: left;
                height: 79px;
                line-height: 79px;
                .el-breadcrumb {
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }
        .assign-content{
            padding: 0 40px 60px;
            .assign-content-top{
                height: 55px;
                .title{
                    font-size: 14px;
                    float: left;
                    line-height: 55px;
                    color: #333333;
                }
                .search{
                    float: right;
                    line-height: 55px;
                    width: 100%;
                }
                .company-box{
                    text-align: left;
                    max-height: 700px;
                    border: 1px solid #cccccc;
                    overflow-y: auto;
                    /deep/.el-radio {
                        display: block;
                        margin: 10px auto;
                    }
                    .content{
                        padding: 20px;
                    }
                    .foot{
                        height: 78px;
                        border-top: 1px solid #cccccc;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        button {
                            width: 110px;
                            height: 38px;
                        }
                        .el-button + .el-button {
                            margin-left: 20px;
                        }
                    }
                }
                
            }
            
        }
    }
</style>