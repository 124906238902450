<template>
    <div class="rdm-infofrmation-box" v-loading="loading">
        <Card :title="$t('RDMManage.RDM_Detail')" class="marginBottom">
            <div class="rdm-infofrmation-basic-box" slot="content">
                <div class="rdm-infofrmation-basic-title">
                    {{rdmDetails.RDMNo}}
                </div>
                <div class="rdm-infofrmation-basic-table">
                    <table cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <span class="weight">{{$t('common.company')}}:</span>                               
                                <span>
                                    {{companyName}}
                                </span>                              
                            </td>
                            <td>
                                <span class="weight">{{$t('RDMManage.ModelNumber')}}:</span>                               
                                <span  v-if="rdmDetails.RDM">
                                    {{rdmDetails.RDM.ModelNumber}}
                                </span>
                            </td>                            
                        </tr>
                        <tr>
                            <td>
                                <span class="weight">{{$t('RDMManage.ProductNo')}}:</span>                               
                                <span  v-if="rdmDetails.RDM">
                                    {{rdmDetails.RDM.PN}}
                                </span>
                            </td>
                            <td>
                                <span class="weight">{{$t('RDMManage.DateCode')}}:</span>                               
                                <span  v-if="rdmDetails.RDM">
                                    {{rdmDetails.RDM.DataCode}}
                                </span>
                            </td>                            
                        </tr>
                        <tr>
                            <td>
                                <span class="weight">{{$t('RDMManage.NetworkStatus')}}:</span>
                                <!--span v-if="rdmDetails.SIMCardInfo">
                                    <el-tag :type="getBindtatus(rdmDetails.SIMCardInfo.SIMStatus,2)"
                                    >{{checkExistData(NetWorkStatus,rdmDetails.SIMCardInfo.SIMStatus, 'value').text}}</el-tag>
                                </span-->
                                <i v-if="rdmDetails.SIMCardInfo && rdmDetails.SIMCardInfo.SIMStatus==1" class="fa fa-wifi fa-lg green" :title="$t('RDMManage.NetWorkStatus_Normal')"></i>
                                <i v-else-if="rdmDetails.SIMCardInfo && rdmDetails.SIMCardInfo.SIMStatus==0" class="network-icon-off"  :title="$t('RDMManage.NetWorkStatus_Disabled')"></i>
                                <i v-else class="fa fa-wifi fa-lg gray"  :title="$t('RDMManage.NetWorkStatus_No')"></i>                                
                            </td>
                            <td>
                                <!--span class="weight">{{$t('RDMManage.SoftwareConfig')}}:</span>
                                <i v-if="rdmDetails.RDM.RDMDefId" class="sw-config-icon-yes" :title="$t('RDMManage.SoftwareConfigStatus_Yes')"></i>
                                <i v-else class="sw-config-icon-no" :title="$t('RDMManage.SoftwareConfigStatus_No')"></i-->  
                                <span class="weight">{{ $t("myvehicle.fieldVersion") }}：</span>
                                <span v-if="rdmDetails.FeildDisplayVersion">{{rdmDetails.FeildDisplayVersion}}</span>  
                                <span v-else>{{$t('RDMManage.SoftwareConfigStatus_No')}}</span>        
                            </td>                            
                        </tr>  
                        <tr>
                            <td>
                                <span class="weight">{{$t('RDMManage.MachineBindStatus')}}:</span>
                                <span v-if="rdmDetails.IsBandingMachine">
                                    <el-tag :type="getBindtatus(1,1)"
                                    >{{checkExistData(MachineBindStatus,1, 'value').text}}</el-tag>
                                </span>
                                <span v-else>
                                    <el-tag :type="getBindtatus(0,1)"
                                    >{{checkExistData(MachineBindStatus,0, 'value').text}}</el-tag>
                                </span>
                            </td>
                            <td>
                                <span class="weight"  v-if="rdmDetails.IsBandingMachine">{{$t('addMachine.MachineSN')}}:</span>                               
                                <span  v-if="rdmDetails.machine" class="qrcode"  @click="openMachineDetails(rdmDetails.machine.CompanyId,rdmDetails.machine.MachineId)" :title="$t('common.vehicleDetail')" >
                                    {{rdmDetails.machine.MachineSN}}
                                </span>                               
                            </td> 
                        </tr>                      
                    </table>
                </div>
                <div class="rdm-infofrmation-basic-list">
                    <div class="rdm-infofrmation-basic-list-icon">
                        <img src="../../assets/image/icon_rdm.png">
                    </div>
                    <div class="rdm-infofrmation-basic-list-content">
                        <div class="rdm-infofrmation-basic-list-title" v-if="rdmDetails.RDM">
                            {{rdmDetails.RDMNo}}({{rdmDetails.RDM.NodeId}})
                        </div>
                        <div class="rdm-infofrmation-basic-list-title" v-else>
                            {{rdmDetails.RDMNo}}
                        </div>
                        <div class="rdm-infofrmation-basic-list-desc" v-if="rdmDetails.RDMSoftware">
                            <p>
                                <span>{{ $t("myvehicle.latestVersion") }}：</span>
                                <span>{{rdmDetails.LatestVersion}}</span>
                            </p>
                            <p>
                                <span>{{ $t("myvehicle.fieldVersion") }}：</span>
                                <span>{{rdmDetails.FeildDisplayVersion}}</span>
                            </p>
                        </div>
                    </div>
                </div>                
            </div>
        </Card>
        <!--Card :title="$t('RDMManage.SoftwareConfig')" class="marginBottom">
            <div class="infofrmation-basic-box" slot="content">
                <div  class="infofrmation-basic-table" v-if="rdmDetails.RDM.RDMDefId">
                    <table cellpadding="0" cellspacing="0">
                        <tr>
                            <td class="tb-backgroud">
                                <span >{{$t('RDMManage.SoftwareVersion')}}:</span>                               
                            </td>  
                            <td>                           
                                <span   slot="reference" v-if="rdmDetails.RDM.RDMDefId">
                                    {{rdmDetails.RDMSoftware.Version}}
                                </span>
                            </td>                      
                        </tr>
                        <tr>
                            <td  class="tb-backgroud">
                                <span >{{$t('RDMManage.SoftwareDesc')}}:</span>                               
                            </td>    
                            <td>                           
                                <span  slot="reference" v-if="rdmDetails.RDMSoftware">
                                    {{rdmDetails.RDMSoftware.Remark}}
                                </span>
                            </td>                       
                        </tr>
                        <tr>
                            <td  class="tb-backgroud">
                                <span >{{$t('RDMManage.SoftwareUploadTime')}}:</span>                               
                            </td>    
                            <td>                            
                                <span slot="reference" v-if="rdmDetails.RDMSoftware">
                                    {{rdmDetails.RDMSoftware.UTime}}
                                </span>
                            </td>                          
                        </tr>
                    </table>
                </div>
            </div>            
        </Card-->
        <Card :title="$t('RDMManage.NetworkStatus')" class="marginBottom">
            <div class="rdm-infofrmation-basic-box" slot="content">
                <div  class="rdm-infofrmation-basic-table"  v-if="rdmDetails.SIMCardInfo">
                    <table cellpadding="0" cellspacing="0">
                        <tr>
                            <td  class="tb-backgroud">
                                <span >{{$t('RDMManage.ServiceProvider')}}:</span>                               
                            </td> 
                            <td>                             
                                <span slot="reference" v-if="rdmDetails.SIMCardInfo">
                                    {{checkExistData(NetWorkStatus_Provider,rdmDetails.SIMCardInfo.ServiceProvider, 'value').text}}
                                </span>
                            </td>                          
                        </tr>
                        <tr>
                            <td  class="tb-backgroud">
                                <span >{{$t('RDMManage.ICCID')}}:</span>                               
                            </td>  
                            <td>                              
                                <span slot="reference" v-if="rdmDetails.SIMCardInfo">
                                    {{rdmDetails.SIMCardInfo.iccid}}
                                </span>
                            </td>                           
                        </tr>
                        <tr>
                            <td  class="tb-backgroud">
                                <span >{{$t('RDMManage.MISISDN')}}:</span>                               
                            </td>
                            <td>                             
                                <span  slot="reference" v-if="rdmDetails.SIMCardInfo">
                                    {{rdmDetails.SIMCardInfo.misisdn}}
                                </span>
                            </td>                           
                        </tr>
                        <tr>
                            <td  class="tb-backgroud">
                                <span>{{$t('RDMManage.IMSI')}}:</span>                               
                            </td> 
                            <td>                               
                                <span slot="reference" v-if="rdmDetails.SIMCardInfo">
                                    {{rdmDetails.SIMCardInfo.imsi}}
                                </span>
                            </td>                           
                        </tr>
                        <tr>
                            <td  class="tb-backgroud">
                                <span>{{$t('RDMManage.LatestUpdateTime')}}:</span>                               
                            </td> 
                            <td>                               
                                <span slot="reference" v-if="rdmDetails.SIMCardInfo">
                                    {{formatTimestampUTC(new Date(rdmDetails.SIMCardInfo.UTime),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting)}}
                                </span>
                            </td>                           
                        </tr>
                        <tr>
                            <td  class="tb-backgroud">
                                <span >{{$t('RDMManage.NetworkStatus')}}:</span>                               
                            </td>   
                            <td>       
                                <el-row>
                                    <el-col :span="4" v-if="rdmDetails.SIMCardInfo && rdmDetails.SIMCardInfo.SIMStatus==1" class="green">                      
                                        {{checkExistData(NetWorkStatus,rdmDetails.SIMCardInfo.SIMStatus, 'value').text}}  
                                    </el-col>
                                    <el-col :span="4" v-else-if="rdmDetails.SIMCardInfo && rdmDetails.SIMCardInfo.SIMStatus==0" class="red">                      
                                        {{checkExistData(NetWorkStatus,rdmDetails.SIMCardInfo.SIMStatus, 'value').text}}  
                                    </el-col>
                                    <el-col :span="4" v-else class="gray">                      
                                        {{$t('RDMManage.NetWorkStatus_No')}}
                                    </el-col>
                                    <el-col :span="8" style="margin-top: -0.2%;"  v-if="rdmDetails.SIMCardInfo" >
                                        <el-button type="danger"  v-if="rdmDetails.SIMCardInfo.SIMStatus==1" @click="IsActive(rdmDetails.SIMCardInfo,0,formatTimestampUTC(new Date(rdmDetails.SIMCardInfo.UTime),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting))">{{$t('RDMManage.Deactivate')}}</el-button>
                                        <el-button type="success"  v-else-if="rdmDetails.SIMCardInfo.SIMStatus==0" @click="IsActive(rdmDetails.SIMCardInfo,1,formatTimestampUTC(new Date(rdmDetails.SIMCardInfo.UTime),'YYYY-MM-DD HH:mm:ss',userInfo.TimezoneSetting))">{{$t('RDMManage.Activate')}}</el-button>
                                    </el-col>
                                </el-row>
                            </td>                      
                        </tr>
                        <tr>
                            <td  class="tb-backgroud">
                                <span >{{$t('RDMManage.DataUsage')}}:</span>                                                              
                            </td>  
                            <td> 
                                <el-row>
                                    <el-col :span="8">
                                        <el-progress v-if="rdmDetails.SIMCardInfo" :text-inside="true" :show-text="false" :stroke-width="12" :percentage="rdmDetails.SIMCardInfo.UsedData*100/rdmDetails.SIMCardInfo.TotalData" :color="processingColor" class="process-backgroud"></el-progress>
                                        <el-progress  v-else :text-inside="false" :stroke-width="12" :show-text="false" :percentage="0" :color="processingColor" class="process-backgroud"></el-progress>                                    
                                    </el-col>
                                    <el-col :span="4" style="margin-top: -0.2%;"  v-if="rdmDetails.SIMCardInfo">
                                        {{rdmDetails.SIMCardInfo.UsedData}}MB/{{rdmDetails.SIMCardInfo.TotalData}}MB
                                    </el-col>
                                </el-row>
                            </td>                       
                        </tr>
                    </table>
                </div>
            </div>    
        </Card>
    </div>
</template>
<script>
import Card from "@/components/ui/Card.vue";
import { checkExistData,formatTimeStr,SubTime,now,formatTimestampUTC,deepClone} from '@/utils';
import { ConstDefinition } from "@/utils/constDefinition";
import rdmApi from "@/api/autogen/v2/rdm";
import companyApi from "@/api/autogen/v2/orgnization";
export default {
    name: '',
    components: {
        Card
    },
    props: {
        companyId: {
            type: String,
            default: '',
        },
        RDMNo: {
            type: String,
            default: '',
        },        
    },
    data() {
        return {
            SIMCardOperationTimeLimit:3,
            rdmDetails: {

            },
            tabActive: 1,
            iscollapse : 0,
            parameterValue: 1,
            // 软件配置状态
            SoftwareConfigStatus: [
                {
                    text: this.$t("RDMManage.SoftwareConfigStatus_Yes"),
                    value: ConstDefinition.SoftwareConfigStatus.Yes,
                },
                {
                    text: this.$t("RDMManage.SoftwareConfigStatus_No"),
                    value: ConstDefinition.SoftwareConfigStatus.No,
                },
            ],
            // 网络状态
            NetWorkStatus: [
                {
                    text: this.$t("RDMManage.NetWorkStatus_Normal"),
                    value: ConstDefinition.NetWorkStatus.Normal,
                },
                {
                    text: this.$t("RDMManage.NetWorkStatus_Disabled"),
                    value: ConstDefinition.NetWorkStatus.Disabled,
                },
                {
                    text: this.$t("RDMManage.NetWorkStatus_No"),
                    value: ConstDefinition.NetWorkStatus.NoSIMCard,
                },
            ],
            // 网络运营商
            NetWorkStatus_Provider: [
                {
                    text: this.$t("RDMManage.NetWorkStatus_Provider_CMCC"),
                    value: ConstDefinition.NetWorkStatus_Provider.CMCC,
                },
                {
                    text: this.$t("RDMManage.NetWorkStatus_Provider_CTCC"),
                    value: ConstDefinition.NetWorkStatus_Provider.CTCC,
                },
                {
                    text: this.$t("RDMManage.NetWorkStatus_Provider_CUCC"),
                    value: ConstDefinition.NetWorkStatus_Provider.CUCC,
                },
            ],
            // 车辆配置状态
            MachineBindStatus: [
                {
                    text: this.$t("RDMManage.MachineBindStatus_Yes"),
                    value: ConstDefinition.MachineBindStatus.Yes,
                },
                {
                    text: this.$t("RDMManage.MachineBindStatus_No"),
                    value: ConstDefinition.MachineBindStatus.No,
                },
            ],
            processingColor: '#00b194',
            loading:false,
            companyName: {
                type: String,
                default: '',
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },       
    },
    created() {
        this.initialData();
    },
    methods: {
        initialData()
        {
            this.loading=true;
            let self = this;
            this.getCompanyItems();
             /*var param = {
                CompanyId:this.companyId,
                filters: this.RDMNo
            }
            /*rdmApi.GetRDMList_v2(param).then((resp) => {
                if(resp.Data.RDMList)
                {
                 self.rdmDetails = resp.Data.RDMList[0]; 
                }
                //alert("dd");    
                //console.log(this.rdmDetails);
                //console.log(this.rdmDetails.RDM);
                //console.log(this.rdmDetails.SIMCardInfo);
            });*/
            var param = {
                RDMNo: this.RDMNo
            };
            rdmApi.GetRDMDetail_v2(param).then((resp) => {
                self.rdmDetails = resp.Data;    
                this.loading=false;         
            });
            
        },
        formatTimestampUTC,
        SubTime,
        now,
        getCompanyItems() {
            let self = this;
            companyApi.getCompanies().then((resp) => {
                let companyItems = resp.Data;
                for (var i = 0; i < companyItems.length; i++) {
                    if(companyItems[i].CompanyId==this.companyId)
                      this.companyName= companyItems[i].CompanyName;
                }
            });
        }, 
        checkExistData(array = [], value = '', key = '') {
            let data = checkExistData(array, value, key);

            if(data.isExist) {
                return data.data;
            } else {
                return {
                    [key]: value,
                };
            }
        },
        //折叠展开
        switchCard(type){
            this.iscollapse = type;
        },
        //tag类型
        getBindtatus(bindStatus,type) {
            let bindColor = {};
            if(type == 1){//软件配置车辆配置
                bindColor = {
                    0: 'No',
                    1: 'Yes',
                };
            }else if(type == 2){//网络状态
                bindColor = {
                    0: 'Disabled',
                    1: 'Normal',
                    2: 'No SIM Card'
                };
            }
            
            return bindColor[bindStatus];
        },

        tabClick(value, type) {
            if(this.tabActive != value || type == 'init') {
                this.tabActive = value;

                let iconData = [];
            }
        },
        /**
        * @desc 打开详情
        */
        openMachineDetails(CompanyId,MachineId) {
            this.$router.push({path: '/MachineDetails/' + CompanyId + '/' +MachineId});
        },
        //修改RDM Sim 状态
        IsActive(item,type,time){
            if(this.checkSIMCardOperationTime(time))
            {
                this.loading=true;
                let content = '';
                if(type == 1){
                    content = this.$t('RDMManage.confirmActivate');
                }else{
                    content = this.$t('RDMManage.confirmDeactivate');
                }
                let Url = require("@/assets/image/warning1.5x.png");
                this.$confirm("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t("common.confirm"),
                    cancelButtonText: this.$t("common.cancel"),
                    center: true,
                }).then(() => {
                    let array = [];
                    array.push(item.misisdn);
                    let prarm = {
                        msisdnList : array,
                        operType :type
                    };
                    rdmApi.ChangeSIMCardStatus(prarm).then((res)=>{
                        if(res&&res.IsSuccess){
                            let flag = 0;
                            if(res.Data.idsList[0].status){
                                flag = 1;
                            }

                            if (flag==1) {
                                Url = require("@/assets/image/success.png");
                                content= this.$t("common.operationSuccess");
                                this.initialData();
                            
                            }else{
                                Url = require("@/assets/image/error_76.png");
                                content= this.$t("common.operationFailure");

                            }
                            
                            this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: this.$t("common.confirm"),
                                center: true,
                                showClose:false
                            });
                        }
                        this.loading=false;
                    });
                }).catch(() => {
                    this.loading=false;       
                });
            }else{
                let Url = require("@/assets/image/error_76.png");
                let content=   this.$t("RDMManage.UpdateLimitTime");
                this.$alert("<img src=" + Url + "><p class='text'>"+content+"</p>", '', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: this.$t("common.confirm"),
                                center: true,
                                showClose:false
                            });              
            }
        },
        checkSIMCardOperationTime (value){
        let nowTime=formatTimestampUTC(now(),'YYYY-MM-DD HH:mm:ss',this.userInfo.TimezoneSetting);
        let diff=SubTime(nowTime,value);
        let min=diff/60000;
        if(min>this.SIMCardOperationTimeLimit)
           return true;        
        else 
            return false;
        },
    },
    mounted() {

        
    },
    beforeDestroy() {

    },

};
</script>
<style lang="less" scoped>
.emptyfault{
    padding: 15px 20px;
    height: 355px;
    text-align: center;
    font-size: 24px;
    .el-icon-s-order{
      font-size: 100px;
      margin-top: 111px;
      margin-bottom: 10px;
      color: #ccc;
    }
    .faulttext{
      color: #0079c1;
      font-weight: 700;
    }
  }
.rdm-infofrmation-box {
    p {
        margin: 0;
    }
    .marginBottom {
        margin-bottom: 20px;
    }

    .weight {
        font-weight: bold;
    }
    .qrcode {
        font-size: 14px;
        font-weight: 400;
        color: #0079c1;
        cursor: pointer;
    }
    .rdm-infofrmation-basic-box {
        .rdm-infofrmation-basic-title {
            margin-bottom: 9px;
            height: 48px;
            line-height: 48px;
            background: #0079c1;
            border: 1px solid #0079c1;
            color: #fff;
            font-size: 16px;
            padding-left: 25px;
        }

        .rdm-infofrmation-basic-table {
            margin-bottom: 9px;

            table {
                width: 100%;
                border-bottom: 1px solid #cccccc;

                tr {

                    td {
                        /*padding-left: 40px;*/
                        padding-left: 2.5%;
                        height: 48px;
                        border: 1px solid #cccccc;
                        border-right: 0;
                        border-bottom: 0;
                        box-sizing: border-box;

                        &:last-child {
                            border-right: 1px solid #cccccc;
                        }

                        &.infofrmation-basic-table-img {
                            width: 145px;
                            padding: 0;
                            position: relative;
                            text-align: center;

                            .car {
                                width: 73px;
                            }

                            .car-status {
                                position: absolute;
                                top: 20px;
                                left: 20px;
                                width: 51px;
                                height: 52px;
                            }
                        }

                        .weight {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .rdm-infofrmation-basic-list {
            margin-bottom: 9px;
            height: 72px;
            border: 1px solid #cccccc;
            border-radius: 0px 2px 2px 0px;

            .rdm-infofrmation-basic-list-icon {
                width: 146px;
                box-sizing: border-box;
                height: 100%;
                background: #F1F9FE;
                border-right: 1px solid #cccccc;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;

                img {
                    width: 59px;
                    height: 60px;
                }
            }

            .rdm-infofrmation-basic-list-content {
                float: left;
                font-size: 14px;
                color: #333;
                margin-top: 0.5%;
                /*padding-left: 9%;*/
                margin-left: 2.5%;

                .rdm-infofrmation-basic-list-title {
                     /*float: left;*/
                     color: #000;
                    font-weight: bold;
                    margin-bottom: 0.5%;
                    /*padding-left: 47px;*/
                    /*margin-left: 47px;*/
                }
                .rdm-infofrmation-basic-list-desc {
                    p {
                        /*float: left;*/
                        /*margin-right: 40px;*/
                        /*padding-left: 47px;*/
                        margin-bottom: 0.2%;
                    }
                }
            }
        }
    }

    .tb-backgroud
    {
        background-color: #F2F2F2;
        width: 20%;
    }

    .network-icon-off{
        display: inline-block;
        width: 21px;
        height: 18px;
        margin:0 auto;
        background:url('../../assets/image/wifi_off.png');
    }


    .sw-config-icon-yes {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 13px;
    background-image: url("../../assets/image/config_1.png");
    }
    .sw-config-icon-no {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 13px;
    background-image: url("../../assets/image/config_2.png");
    }
    .gray{
            font-size: 21px;
            color: #999999;
        }

    .green{
            font-size: 21px;
            color: #00b194;
    }
    .red{
            font-size: 21px;
            color: #FF4D4F;
    }
    .process-backgroud
    {
        background-color: #F2F2F2;
        border-radius: 6px;
    }
}
</style>