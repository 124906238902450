<template>
    <div class="machine-detail-box">
        <div class="row1">
            <div class="row1-header-box">
                <div class="row1-header-crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/CompanyList' }" v-if="userInfo.CompanyId == 0"
                        >{{ $t("myvehicle.company") }}</el-breadcrumb-item
                    >
                    <el-breadcrumb-item :to="{ path: '/VehicleList/'+paramsData.companyId }"
                        >{{detailsTitle.CompanyName}}</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{detailsTitle.MachineSN}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <!-- <div class="row1-header-crumbs" v-if="userInfo.CompanyId == 0">
                    <span @click="returnCompanyList">Copmany &nbsp;>&nbsp;</span>
                    <span @click="returnVehicleList">{{detailsTitle.CompanyName}} &nbsp;>&nbsp;</span>
                    <span>{{detailsTitle.MachineSN}}</span>
                </div>
                 <div class="row1-header-crumbs" v-else>
                    <span @click="returnVehicleList">{{detailsTitle.CompanyName}} &nbsp;>&nbsp;</span>
                    <span>{{detailsTitle.MachineSN}}</span>
                </div> -->
            </div>
            <div class="row1-body-box">
                <div :class="classObject">
                    <span v-for="(item,key) in tabsData" :key="key" @click="tabClick(key)" :class="{isSelected: key == activeKey}">
                        {{item.label}}
                    </span>
                </div>
                <div class="row1-content-box">
                    <component :is="tabsData[activeKey].component" :companyId="paramsData.companyId" :machineId="paramsData.machineId" @getHeaderData="getHeaderData"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Information from "./Information.vue";
import HistoryData from "./HistoryData.vue";
import Parameter from "./Parameter.vue";
import MachineControl from "./MachineControl.vue";
import MachineUpgrade from "./MachineUpgrade.vue";
//import { ifGrantedTheOperationForMyMachineFunction} from '@/utils';
export default {
    components: {
        Information,
        HistoryData,
        Parameter,
        MachineControl,
        MachineUpgrade
    },
    data() {
        return {
            paramsData: {
                companyId: '',
                machineId: '',
            },
            tabsData: [
                {
                    label: this.$t("myvehicle.machineDetailInformation"),
                    component: Information,
                },
                {
                    label: this.$t("myvehicle.historyDataAnalysis"),
                    component: HistoryData,
                },
            ],
            activeKey: 0,
            detailsTitle: {
                CompanyName: '',
                MachineSN: '',
            },
            /*privilege: {
                ParameterQueryAndModify:false,
                SoftwareUpgrade:false,
                MachineControl:false,
            },*/
            size: 'large',
            disabled: true
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userEntity;
        },
        classObject: function () {
            let classTmp= [
                    'row1-tab-box-'+this.tabsData.length
                ];
            console.log("classObject",classTmp);
            return classTmp;
        },
    },
    created() {       
        this.initData();
        this.initialFunctionOperationPrivilege();
    },
    methods: {
        //ifGrantedTheOperationForMyMachineFunction,
        initialFunctionOperationPrivilege()
        {
            /*this.privilege.ParameterQueryAndModify = ifGrantedTheOperationForMyMachineFunction("ParameterQueryAndModify");
            this.privilege.SoftwareUpgrade = ifGrantedTheOperationForMyMachineFunction("SoftwareUpgrade");
            this.privilege.MachineControl = ifGrantedTheOperationForMyMachineFunction("MachineControl");*/
            //if(this.privilege.ParameterQueryAndModify)
                this.tabsData.push({
                    label: this.$t("myvehicle.parameterQueryAndEdit"),
                    component: Parameter,
                });
            //if(this.privilege.SoftwareUpgrade)
                this.tabsData.push({
                    label: this.$t("myvehicle.machineUpgrade"),
                    component: MachineUpgrade,
                });
            //if(this.privilege.MachineControl)
                this.tabsData.push({
                    label: this.$t("myvehicle.machineControl"),
                    component: MachineControl,
                });
        },
        getHeaderData(data) {
            this.detailsTitle = data;
        },
        /**
         * @desc 初始化页面获取id
         */
        initData() {
            
            let { params, } = this.$route;

            if(params.companyId && params.machineId) {
                this.paramsData = params;
            } else {
                this.$router.go(-1);
                return; 
            }
        },
        tabClick(key) {
            if(this.activeKey != key) {
                this.activeKey = key;
            }
        },

        returnCompanyList(){
            this.$router.push({path: '/CompanyList'});
        },

        returnVehicleList(){
            this.$router.push({
                path: '/VehicleList/'+this.paramsData.companyId,
            });
        }

    },
    mounted: {}, 
};
</script>

<style lang="less" scoped>
.machine-detail-box {
    min-width: 867px;
    .row1 {
        box-sizing: border-box;
        border-radius: 2px;
        .row1-header-box {
            height: 70px;
            line-height: 70px;
            color: #000;
            background: #fafafa;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            padding: 0 42px;

            .row1-header-crumbs {
                font-size: 16px;
                font-weight: bold;
                float: left;

                
                .el-breadcrumb {
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }

        .row1-body-box {
            padding: 32px 40px;
            background: #FFFFFF;

            .row1-tab-box-5 {
                margin-bottom: 32px;
                span {              
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(20% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }
            .row1-tab-box-4 {
                margin-bottom: 32px;
                span {              
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(25% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }
            .row1-tab-box-3 {
                margin-bottom: 32px;
                span {              
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(33.3% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }
            .row1-tab-box-2 {
                margin-bottom: 32px;
                span {              
                    color: #666666;
                    font-size: 14px;
                    display: inline-block;
                    height: 38px;
                    background: #F2F2F2;
                    width: calc(50% - 1px);
                    line-height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    border-right: 1px solid #fff;
                    user-select:none;
                    &.isSelected,
                    &:hover {
                        background: #0079C1;
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>