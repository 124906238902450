import request from "@/utils/request";

              //获取车辆的位置
              function getCarLocation(data) {
                return request({
                  url: "v2/CarGeo/GetCarLocation",
                  method: "post",
                  data
                });
              }
              
              //查询车辆历史轨迹信息
              function queryCarGeoHistory(data) {
                return request({
                  url: "v2/CarGeo/QueryCarGeoHistory",
                  method: "post",
                  data
                });
              }
              

            export default {
                getCarLocation,
queryCarGeoHistory
            };