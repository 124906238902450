<template>
  <div id="AddMachineFaultConfigPage">
    <el-card class="machineCard">
      <!--面包屑-->
      <div class="breadcrumbView">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: 'CompanyList' }">{{$t("common.company")}}</el-breadcrumb-item>
          <el-breadcrumb-item :to="'/VehicleList/'+machineInfo.CompanyId">{{machineInfo.CompanyName}}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="$store.state.isEdit">{{
              $t("addMachine.EditMachine")
            }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else>{{
              $t("addMachine.addMachine")
            }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 步骤条 -->
      <div class="stepsView">
        <el-steps :active="active" align-center>
          <el-step :title="$t('addMachine.setp1')"></el-step>
          <el-step :title="$t('addMachine.setp2')"></el-step>
          <el-step :title="$t('addMachine.setp3')"></el-step>
          <el-step :title="$t('addMachine.setp4')"></el-step>
        </el-steps>
      </div>
      <!-- 设备配置信息 -->
      <div class="machineFaultConfig-content">
        <el-row>
          <el-col>
            <el-tabs v-model="activeTab" type="card" @tab-click="tabClick">
              <el-tab-pane v-for="item in machineInfo.MachineComponents.DeviceList" :key="item.DeviceId" :label="item.DeviceName" :name="item.DeviceId+''">
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <span class="device">{{ $t('addMachine.FaultList') }} (<span class="count">{{faultList.length}}</span>)</span>
          </el-col>
          <el-col :span="8" class="search">
            <el-input
              v-model="search"
              :placeholder="$t('addMachine.faultSearch')">
              <i slot="suffix" class="fa fa-search unit"></i>
            </el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            ref="table"
            :data="faultList.filter(data => !search || data.FaultName.toLowerCase().includes(search.toLowerCase())||data.FaultCode.toLowerCase().includes(search.toLowerCase()))"
            :cell-style="cellStyle"
            :header-cell-style="headCellStyle"
            height="500"
            >
            <el-table-column
              :label="$t('addMachine.FaultDisplayName')"
              prop="DisplayName"
              min-width="24%"
              align="left">
            </el-table-column>
            <el-table-column
              :label="$t('addMachine.FaultName')"
              prop="FaultName"
              min-width="24%"
              align="left">
            </el-table-column>
            <el-table-column
              :label="$t('addMachine.FaultCode')"
              prop="FaultCode"
              min-width="13%"
              align="center">
            </el-table-column>
            <el-table-column
                :label="$t('addMachine.CANAddress')"
              prop="ActiveAddress"
              min-width="13%"
              align="center">
            </el-table-column>
            <el-table-column
                :label="$t('addMachine.Remark')"
              min-width="13%"
              align="center">
              <template slot-scope="scope">
                <div class="operation" @click="toogleExpand(scope.row)"><i class="el-icon-document"></i></div>
              </template>
            </el-table-column>
            <el-table-column type="expand" width="1">
              <template slot-scope="scope">
                {{scope.row.Remark}}
              </template>
            </el-table-column>
            <el-table-column
                :label="$t('addMachine.Edit')"
              min-width="13%"
              align="center">
              <template slot-scope="scope">
                <div class="operation" @click="editFault(scope.$index,scope.row)"><i class="el-icon-edit"></i></div>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-dialog :title="$t('addMachine.editFault')" width="50%" :visible.sync="dialogVisible">
          <el-form ref="faultInfoForm" :model="faultInfoForm" label-width="24%" :rules="rules">
            <el-row>
              <el-form-item prop="FaultName" :label="$t('addMachine.faultName') + ':'" >
                <el-col :span="10">
                   <el-input disabled v-model="faultInfoForm.FaultName" />
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="DisplayName" :label="$t('addMachine.faultDisplayName') + ':'" >
                <el-col :span="10">
                   <el-input v-model="faultInfoForm.DisplayName" />
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="Remark" :label="$t('addMachine.remark') + ':'" >
                <el-col :span="18">
                   <el-input type="textarea" :rows="4" v-model="faultInfoForm.Remark" />
                </el-col>
              </el-form-item>
            </el-row>
            <el-row class="border">
              <el-form-item>
                <el-col class="dialogFooter">
                  <el-button @click="dialogCancel">{{$t('common.cancel')}}</el-button>
                  <el-button type="primary" @click="submit">{{$t('addMachine.Submit')}}</el-button>
                </el-col>
              </el-form-item>
            </el-row>
          </el-form>
        </el-dialog>
        <el-row>
          <el-col class="footer">
            <el-button @click="back">{{
              $t("addMachine.Previous")
            }}</el-button>
            <el-button type="primary" :disabled="disabled" @click="next">{{
              $t("addMachine.Next")
            }}</el-button>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import deviceApi from "@/api/autogen/v2/cardevices";
import carTypeApi from "@/api/autogen/v2/cartype";
export default {
  name: "AddMachineFaultConfigPage",
  data() {
    return {
      //车辆信息
      machineInfo:{},
      //设备列表
      deviceList:[],
      // 故障设备列表
      deviceInfoList:[],
      // 当前故障设备列表
      faultList:[],
      // 故障设备信息
      faultInfoForm:{},
      //机器类型
      machineType:{},
      // 按钮是否可操作
      disabled: false,
      //步骤条步骤
      active:2,
      // 当前tab
      activeTab:"",
      activeTabIndex:0,
      // 筛选内容
      search:"",
      //当前编辑的行位置
      activeIndex:0,
      //弹窗flag
      dialogVisible:false,
      typeDialogVisible:false,
      //是否编辑
      isEdit:false,
      rules:{
        TypeName: [
          {
            required: true,
            message:
              this.$t("addMachine.TypeName") + this.$t("common.required"),
            trigger: "blur",
          },
        ],
      }
    };
  },
  created() {
    this.machineInfo = this.$route.params;
    if(this.machineInfo.MachineId!=null){
      this.isEdit = true;
    }else{
      this.isEdit = false;
    }
    if(this.machineInfo.MachineComponents.DeviceList != null && this.machineInfo.MachineComponents.DeviceList.length>0){
      this.activeTab = this.machineInfo.MachineComponents.DeviceList[0].DeviceId+'';
    }
    this.getFaultList();
  },
  mounted(){

  },
  methods: {
    // 获取所有设备的fault列表
    getFaultList(){
      var self = this;
      this.deviceList = this.machineInfo.MachineComponents.DeviceList;
      if(this.deviceList != null && this.deviceList.length > 0){
        this.deviceList.forEach(item => {
          if(item.DeviceESEFaultList != null && item.DeviceESEFaultList.length>0){
            self.deviceInfoList.push(item);
            self.tabClick({index:0});
          }else if(item.DeviceFFaultList != null && item.DeviceFFaultList.length>0){
            self.deviceInfoList.push(item);
            self.tabClick({index:0});
          }else{
            var param = {
              DeviceId: item.DeviceId,
            };
            deviceApi.getDeviceFaultsList(param).then(res=>{
              if(res.IsSuccess){
                var data = res.Data;
                self.deviceInfoList.push(data);
                self.tabClick({index:0});
              }else {
                console.log("error submit!!");
                return false;
              }
            });
          }
        });
      }
    },
    //保存更改的数据
    submit(){
      //this.faultList[this.activeIndex] = this.faultInfoForm;
      //需要根据内容更新faultList，因为如果list是搜素后更改，则更新会出错
       var index=0;
       var FindOne=false;
       try{
        this.faultList.forEach(item => {
            if(item.FaultDefId == this.faultInfoForm.FaultDefId
            && item.FaultCode == this.faultInfoForm.FaultCode
            && item.FaultName == this.faultInfoForm.FaultName
            && item.ActiveAddress == this.faultInfoForm.ActiveAddress
            && item.BitSelect == this.faultInfoForm.BitSelect
            )
            {  
              FindOne=true;   
              throw ('终止循环'); // 抛出一个异常
            }else{
              index++;
            }

        });
       } catch (e) {
            //console.log('catch内容：' + e); // catch内容：终止循环
            //console.log("FindOne index=="+index);
       }
       if(FindOne)
       {
         this.faultList[index]=this.faultInfoForm;
       }       
      this.clearForm();
      this.dialogVisible = false;
    },
    //编辑故障设备
    editFault(index,row){
      this.faultInfoForm = JSON.parse(JSON.stringify(row));
      this.activeIndex = index;
      this.dialogVisible = true;
    },
    dialogCancel(){
      this.clearForm();
      this.dialogVisible = false;
    },
    // 清除表单数据
    clearForm(){
      this.faultInfoForm={};
    },
    //tab切换
    tabClick(tab, event){
      var self = this;
      var index = Number(this.activeTabIndex);
      // 切换前保存当前设备故障列表更改
      if(this.deviceInfoList[index].DeviceType == 0 && this.faultList.length>0){
        this.deviceInfoList[index].DeviceESEFaultList = JSON.parse(JSON.stringify(this.faultList));
        this.machineInfo.MachineComponents.DeviceList = this.deviceInfoList;
      }else if(this.faultList.length>0){
        this.deviceInfoList[index].DeviceFFaultList = JSON.parse(JSON.stringify(this.faultList));
        this.machineInfo.MachineComponents.DeviceList = this.deviceInfoList;
      }
      this.activeTabIndex = Number(tab.index);
      self.activeTab = self.deviceInfoList[self.activeTabIndex].DeviceId+'';
      if(this.deviceInfoList[this.activeTabIndex].DeviceType == 0){
        this.faultList = this.deviceInfoList[this.activeTabIndex].DeviceESEFaultList;
      }else {
        this.faultList = this.deviceInfoList[this.activeTabIndex].DeviceFFaultList;
      }
    },
    //展开备注
    toogleExpand(row) {
      let $table = this.$refs.table;
      $table.toggleRowExpansion(row);
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      var style = "";
      if (columnIndex === 0) {
        style = "padding-left:15px;";
      }
      return style;
    },
    // 表头行的 style 的回调方法
    headCellStyle({ row, column, rowIndex, columnIndex }) {
      var style = "";
      if (columnIndex === 0 && rowIndex === 0) {
        style = "padding-left:15px;";
      }
      return style;
    },
    //创建车型
    createMachineType(){
      var self = this;
      var machineType = this.machineInfo.MachineComponents.MachineType;
      var Devices = [];
      this.deviceList.forEach(item => {
        var device = {
          DeviceId: item.DeviceId,
          NodeId: item.NodeId,
          MenuDefId: item.MenuDefId,
          FaultDefId: item.FaultDefId,
          FileDefId: item.FileDefId,
        };
        Devices.push(device);
      });
      var date = new Date();
      var typeName = this.machineInfo.CompanyId+"_AutoCreated_MachineType_"+date.getFullYear()+(date.getMonth()+1)+date.getDate()+date.getHours()+date.getMinutes()+date.getSeconds()+date.getMilliseconds()+Math.floor(Math.random() * 10);
      var fd = new FormData();
      fd.append("TypeName",typeName);
      fd.append("QRCode",typeName);
      fd.append("CompanyId",this.machineInfo.CompanyId);
      fd.append("ImageFile",machineType.ImagePath);
      fd.append("VoltMin",machineType.VoltMin);
      fd.append("VoltMax",machineType.VoltMax);
      fd.append("Remark",machineType.Remark);
      fd.append("DevicesJson",JSON.stringify(Devices));
      carTypeApi.createMachineType(fd).then(res=>{
        if(res.IsSuccess){
          self.machineInfo.TypeId = res.Data.TypeId;
          self.machineInfo.TypeVersion = res.Data.TypeVersion;
          console.log(res);
          self.$router.push({name:'AddMachineMonitorConfig',params:self.machineInfo});
        }else{
          console.log(res);
          this.disabled = false;
        }
      }).catch((e) => {
        console.log(e);
        this.disabled = false;
      });
    },
    // 编辑车型
    editMachineType(data){
      var self = this;
      var Devices = [];
      this.deviceList.forEach(item => {
        var device = {
          DeviceId: item.DeviceId,
          NodeId: item.NodeId,
          MenuDefId: item.MenuDefId,
          FaultDefId: item.FaultDefId,
          FileDefId: item.FileDefId,
        };
        Devices.push(device);
      });
      var machineType = this.machineInfo.MachineComponents.MachineType;
      var fd = new FormData();
      fd.append("MachineId",this.machineInfo.MachineId);
      fd.append("RDMNO",this.machineInfo.RDMNO);
      fd.append("TypeId",machineType.TypeId);
      fd.append("TypeVersion",machineType.TypeVersion);
      fd.append("TypeName",machineType.TypeName);
      fd.append("QRCode",machineType.QRCode);
      fd.append("CompanyId",this.machineInfo.CompanyId);
      fd.append("ImageFile",machineType.ImagePath);
      fd.append("VoltMin",machineType.VoltMin);
      fd.append("VoltMax",machineType.VoltMax);
      fd.append("Remark",machineType.Remark);
      fd.append("DevicesJson",JSON.stringify(Devices));
      fd.append("IsNewMachineType",data.IsNewMachineType);
      fd.append("IsNewMachineTypeVersion",data.IsNewMachineTypeVersion);
      fd.append("IsPublishedType","0");
      carTypeApi.editMachineType(fd).then(res=>{
        if(res.IsSuccess){
          self.machineInfo.TypeId = res.Data.TypeId;
          self.machineInfo.TypeVersion = res.Data.TypeVersion;
          self.$router.push({name:'AddMachineMonitorConfig',params:self.machineInfo});
          console.log(res);
        }
      }).catch((e) => {
        console.log(e);
        this.disabled = false;
      });
    },
    //下一步
    next(){
      var self = this;
      this.disabled = true;
      if(this.deviceInfoList[this.activeTabIndex].DeviceType == 0 && this.faultList.length>0){
        this.deviceInfoList[this.activeTabIndex].DeviceESEFaultList = this.faultList;
      }else if(this.faultList.length>0){
        this.deviceInfoList[this.activeTabIndex].DeviceFFaultList = this.faultList;
      }
      var param = {
        ListDeviceProperties:this.deviceInfoList,
      };
      carTypeApi.updateFault(param).then(res=>{
        if(res.IsSuccess&&res.Data){
          if(self.isEdit){
            var param = {
              MachineId:self.machineInfo.MachineId,
              RDMNO:self.machineInfo.RDMNO,
              TypeId:self.machineInfo.TypeId,
              TypeVersion:self.machineInfo.TypeVersion,
              Devices:self.deviceList,
            };
            carTypeApi.checkIfNewMachineType(param).then(res=>{
              if(res.IsSuccess){
                if(res.Data.IsNewMachineType){
                  self.createMachineType();
                }else{
                  self.editMachineType(res.Data);
                }
              }else{
                console.log("error submit!!");
                return false;
              }
            });
          }else{
            self.createMachineType();
          }
        }else {
          this.disabled = false;
          console.log("error submit!!");
          return false;
        }
      }).catch((e) => {
        console.log(e);
        this.disabled = false;
      });
    },
    back(){
      // 保存当前设备故障列表更改
      if(this.deviceInfoList[this.activeTabIndex].DeviceType == 0 && this.faultList.length>0){
        this.deviceInfoList[this.activeTabIndex].DeviceESEFaultList = this.faultList;
        this.machineInfo.MachineComponents.DeviceList = this.deviceInfoList;
      }else if(this.faultList.length>0){
        this.deviceInfoList[this.activeTabIndex].DeviceFFaultList = this.faultList;
        this.machineInfo.MachineComponents.DeviceList = this.deviceInfoList;
      }
      this.$router.push({name:'AddMachineDevicesConfig',params:this.machineInfo});
    },
  },
};
</script>

<style lang="less">
/*车辆信息样式 */
.machineFaultConfig-content{
  padding: 0 12% 1% 12%;
  margin-top: 3%;
  .el-col{
    text-align: left;
  }

  .el-tabs--card > .el-tabs__header{
    border-bottom: 1px solid #eee;
    .el-tabs__nav {
      border: 0px solid;
    }
    .el-tabs__item.is-active {
      background: #047bc2;
      color: #fff;
    }
    .el-tabs__item {
      border-left: 0px solid;
      margin-right: 10px;
      background: #f5f6f7;
      color: #999;
      border-radius: 2px 2px 0px 0px;
    }
  }
  .device{
    font-weight: 700;
    line-height: 40px;
    .count{
      color:#0066CC;
      font-weight: 700;
    }
  }
  .unit {
    margin: 12px 10px;
    color: #666;
    font-size: 15px;
  }
  .el-table{
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    width: 100%;
    .operation{
      display: inline-block;
      font-size: 20px;
      .el-icon-edit{
        color: #047BC2;
      }
      .el-icon-document{
        color: #00b194;
      }
    }
    .el-table__expand-icon > .el-icon{
      display: none;
    }
    .el-table__expanded-cell{
      padding: 20px 25px;
      background: #fafafa;
    }
    th{
      background-color: #fafafa;
      font-weight: 400;
      color: #333;
    }
    td{
      border-bottom: 1px solid #f0f0f0;
    }
  }
  .el-textarea__inner{
    padding: 10px 15px !important;
  }
  #qrCode{
    display: none;
  }
  /*底部按钮样式 */
  .footer{
    text-align: center;
    margin: 30px 0;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  .border{
    padding-top: 20px;
    border-top: 1px solid #eaeef0;
    margin: 0 -2%;
  }
  .el-input.is-disabled .el-input__inner{
    border-color: #ccc;
  }
  /*弹窗底部按钮样式 */
  .dialogFooter{
    text-align: left;
    .el-button{
      width: 110px;
      height: 38px;
    }
    .el-button--default:hover{
      background: transparent !important;
    }
    .el-button--primary{
      margin-left: 20px;
    }
  }
  /*弹窗标题样式 */
  .el-dialog__header {
    padding: 20px !important;
    height: auto !important;
    text-align: left;
    background: #f2f2f2;
    border-bottom: 1px solid #eaeef0;
  }
}
</style>
