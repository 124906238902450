export function getUserEntity_ss() {
    let userJsonStr = sessionStorage.getItem("currentUser");
    // console.log(userJsonStr);
    if (!userJsonStr) {
        return undefined;
    }
    else {
        let userEntity = JSON.parse(userJsonStr);
        return userEntity;
    }
}
export function setUserEntity_ss(entity) {
    if (typeof entity === 'object') {
        entity = JSON.stringify(entity);
    }
    sessionStorage.setItem("currentUser", entity);
}
export function getLanguage_ss() {
    let language = sessionStorage.getItem("language");
    return language || 'ch';
}
export function setLanguage_ss(language) {

    sessionStorage.setItem("language", language);

}

export function getCurrentCompany_ss() {
    let companyJsonStr = sessionStorage.getItem("currentCompany");
    // console.log(userJsonStr);
    if (!companyJsonStr) {
        return undefined;
    }
    else {
        let companyEntity = JSON.parse(companyJsonStr);
        return companyEntity;
    }
}
export function setCurrentCompany_ss(entity) {
    if (typeof entity === 'object') {
        entity = JSON.stringify(entity);
    }
    sessionStorage.setItem("currentCompany", entity);
}
