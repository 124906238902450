import axios from 'axios';
import { Message } from 'element-ui';

// create an axios instance
const service = axios.create({
  baseURL: window.API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // add Authorization in headers before request sent 
    var auth_token = sessionStorage.getItem("auth_token");
    if (auth_token) {
      config.headers["Authorization"] = auth_token;
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] =
        "application/json; charset=utf-8";
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    return res;

  },
  error => {
    console.log('err' + error); // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 3 * 1000
    // })
    if (error && error.response && error.response.status == 401) {
      sessionStorage.clear();
      window.parent.location.href = "/#/Login";
    }
   // return Promise.reject(error);
  }
);

export default service;
